import React, { FC } from 'react';
import { IonButton,IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonImg, IonText, IonIcon } from '@ionic/react';
import styled from "styled-components";


interface AuctionProps {
  issue: number;
}


export const CaseCard = styled(IonCard)`
  box-shadow: 0px 0px 4px rgba(154,160,185,1);
  background:var(--ion-color-card);
  /* height:100px; */
  padding:10px;
  margin:10px;
  border-radius:5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  transition:1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition:1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition:1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition:1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition:1s cubic-bezier(0.075, 0.82, 0.165, 1);
   
  @media (max-width:640px){
    min-width:100%
  }

`;

const CaseHeading = styled.div`
  font-size:20px
 `
const CaseBody = styled.div`
  
`
const CaseFooter = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center
`

const Case: FC<AuctionProps> = ({issue}) => {
  return (
      <CaseCard routerDirection='forward'>
         <CaseHeading>
           <IonText>Header</IonText>
         </CaseHeading>
         <CaseBody>
           <IonText> 
              <p>{ issue }</p>
           </IonText>
         </CaseBody>
         <CaseFooter>
            <IonText color="primary">23 Vote</IonText>
            <IonButton size="small">Up vote</IonButton>
         </CaseFooter>
      </CaseCard>
  )
}


export default Case