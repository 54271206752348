export const addToMetamask = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImgUrl: string
) => {
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImgUrl, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};

export async function addNetwork(id: number) {
  let networkData;
  const ethereum = window.ethereum;
  if (ethereum && ethereum.isMetaMask) {
    switch (id) {
      //bsctestnet

      case 97:
        networkData = [
          {
            chainId: "0x61",

            chainName: "BSCTESTNET",

            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],

            nativeCurrency: {
              name: "BINANCE COIN",

              symbol: "BNB",

              decimals: 18,
            },

            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ];

        break;

      //bscmainet

      case 56:
        networkData = [
          {
            chainId: "0x38",

            chainName: "BSCMAINET",

            rpcUrls: ["https://bsc-dataseed1.binance.org"],

            nativeCurrency: {
              name: "BINANCE COIN",

              symbol: "BNB",

              decimals: 18,
            },

            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ];

        break;
      case 43114:
        networkData = [
          {
            chainId: "0xA86A",

            chainName: "Avalanche Network",

            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],

            nativeCurrency: {
              name: "AVAX",

              symbol: "AVAX",

              decimals: 18,
            },

            blockExplorerUrls: ["https://snowtrace.io/"],
          },
        ];

        break;
      case 137:
        networkData = [
          {
            chainId: "0x89",

            chainName: "Polygon Mainnet",

            rpcUrls: ["https://polygon-rpc.com/"],

            nativeCurrency: {
              name: "MATIC",

              symbol: "MATIC",

              decimals: 18,
            },

            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ];

        break;
      case 250:
        networkData = [
          {
            chainId: "0xFA",

            chainName: "Fantom Opera",

            rpcUrls: ["https://rpc.ftm.tools/"],

            nativeCurrency: {
              name: "Fantom",

              symbol: "FTM",

              decimals: 18,
            },

            blockExplorerUrls: [" https://ftmscan.com/"],
          },
        ];

        break;

      default:
        break;
    }
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",

        params: networkData,
      });
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",

            params: networkData,
          });
        } catch (e) {
          // handle "add" error
          console.error(e);
        }
      }
    }
  }
}

export const ethereumChainSwitch = (chainId: number) => {
  const ethereum = window.ethereum;
  let _chainId;
  if (ethereum && ethereum?.isMetaMask) {
    switch (chainId) {
      case 56:
        _chainId = "0x38";
        break;
      case 97:
        _chainId = "0x61";
        break;
      case 250:
        _chainId = "0xfa2";
        break;
      case 137:
        _chainId = "0x89";
        break;
      case 43114:
        _chainId = "0xA86A";
        break;
      default:
        _chainId = "0x38";
        break;
    }
  }
  return ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [
      {
        id: 1,
        jsonrpc: "2.0",
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: _chainId,
          },
        ],
      },
    ],
  });
};
