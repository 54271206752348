import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonSearchbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface SearchInputs {
  searchResult?: (searchResult: any) => void;
  placeholder?: string;
  searchHeader?: string;
  searchField?: string;
  searchField1?: string;
  searchField2?: string;
  rawData?: any[]; // array to filter
  includesImg?: boolean;
  imgKey?: string;
  secondarySearch?: (sInput: string) => any;
}

const SearchList = styled.div``;

const Searchbar: React.FC<SearchInputs> = ({
  placeholder,
  rawData,
  searchField,
  searchField1,
  searchField2,
  includesImg,
  searchHeader,
  imgKey,
  searchResult,
  secondarySearch,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [data, setData] = useState(rawData);
  let filteredData: any[] = rawData;
  const filterSearch = async () => {
    filteredData = rawData.filter((item) => {
      if (searchText.length < 2) return null;

      return (
        (searchField?.length &&
          item[searchField]?.toString().toLowerCase()?.includes(searchText)) ||
        (searchField1?.length &&
          item[searchField1]?.toString().toLowerCase()?.includes(searchText)) ||
        (searchField2?.length &&
          item[searchField2]?.toString().toLowerCase()?.includes(searchText))
      );
    });
    console.log("filteredData: ", filteredData);
    if (filteredData.length === 0 && searchText.length > 2) {
      console.log("filteredData line 57: ", filteredData);
      setSearching(true);
      const result = await secondarySearch(searchText);
      filteredData.push(result);
      setSearching(false);
    }
    setData(filteredData);
  };
  const searchHelper = (res: any) => {
    searchResult(res);
    setSearchText("");
  };
  useEffect(() => {
    filterSearch();
  }, [searchText]);
  return (
    <div>
      <p>{searchHeader}</p>
      <></>
      <IonSearchbar
        placeholder={placeholder}
        mode="ios"
        value={searchText}
        debounce={500}
        onIonChange={(e) => setSearchText(e.detail.value.toLowerCase())}
      ></IonSearchbar>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={searching}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <SearchList>
        {searchText.length >= 2 && data?.length > 0 ? (
          <IonList>
            {data?.map((data, k) => (
              <IonItem key={k} button onClick={() => searchHelper(data)}>
                {includesImg && data && (
                  <IonAvatar slot="start">
                    <img src={data[imgKey]} />
                  </IonAvatar>
                )}
                <IonLabel>
                  <h5>{data && data[searchField1]}</h5>
                  <h5>{searchField2.length && data && data[searchField2]}</h5>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        ) : null}
      </SearchList>
    </div>
  );
};

export default Searchbar;
