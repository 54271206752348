import React from "react";
import { IonButton, IonIcon, IonLabel, IonText } from "@ionic/react";
import styled from "styled-components";
import { addOutline, removeOutline } from "ionicons/icons";
import { LargeButton } from "./UnstakeCard";
import { PoolObject } from "../../types/farm-types";
const EarnedContainer = styled.div`
  max-width: 350px;
  height: 120px;
  border-radius: 25px;
  // border: 1px solid #ecbb2d;
  display: flex;
  flex-direction: row;
`;
const TextArea = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 25px 0 0 25px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ClaimContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 25px 25px 0;
`;

const ClaimButton = styled(IonButton)`
  --border-radius: 18px !important;
  height: 45px;
  border-radius: 10px;
  margin: 3px;
`;
const Label = styled(IonLabel)`
  margin-bottom: 12px;
`;

const Earned = styled.div`
  display: block;
`;

const H6 = styled.h6`
  font-size: 18px !important;
  font-weight: 800 !important;
  margin-bottom: 0 !important;
`;
interface StakedInputs {
  poolObject: PoolObject;
  locked: boolean;
  unlock: () => void;
  openDepositModal: (poolObj: PoolObject) => void;
  openWithdrawModal: (poolObj: PoolObject) => void;
}

const StakedList: React.FC<StakedInputs> = ({
  poolObject,
  locked,
  unlock,
  openDepositModal,
  openWithdrawModal,
}) => {
  return (
    <EarnedContainer>
      <TextArea>
        <Label>{poolObject.nameEarned}</Label>
        <Earned>
          <H6>{poolObject.userDeposit}</H6>
          <small>~${"3"}</small>
        </Earned>
      </TextArea>
      <ClaimContainer>
        {locked ? (
          <>
            <IonText> Start Farming</IonText>
            <LargeButton color='success' onClick={() => unlock()}>
              Unlock wallet
            </LargeButton>
          </>
        ) : (
          <>
            <ClaimButton
              color='success'
              onClick={() => openWithdrawModal(poolObject)}
              fill='outline'>
              <IonIcon icon={removeOutline} />
            </ClaimButton>
            <ClaimButton
              color='success'
              onClick={() => openDepositModal(poolObject)}
              fill='outline'>
              <IonIcon icon={addOutline} />
            </ClaimButton>
          </>
        )}
      </ClaimContainer>
    </EarnedContainer>
  );
};

export default StakedList;
