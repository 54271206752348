
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Avatar, Flex, GridComponent, Spacer, Text } from "components";
import NftUser from "components/Card/NftUser";
import {User} from "generated/graphql-frontend";



interface PeopleInterface{
    isFollowingUsers:any;
    activeTab:Number;
    followingRaw:any;
    followersRaw:any;
    renderUser:any;
}

const ConBody = styled.div`
  width: calc(100% + 260px);

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const FeedCon = styled.div`
  padding: 10px 20px;
  @media (max-width: 640px) {
    padding: 10px;
  }
`;

const TabFlex = styled(Flex)`
  @media (max-width: 640px) {
    width: 100%;
    overflow-x: auto;
    border-bottom: 2px solid #e1e0ff;
  }
`;

const TabText = styled(Text)`
  padding: 15px 30px;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
  &.active {
    background: rgba(106, 100, 255, 0.2);
  }
  &:hover {
    background: rgba(106, 100, 255, 0.2);
  }
  @media (max-width: 640px) {
    padding: 10px;
    font-size: 12px;
    white-space: nowrap;
  }
`;



const PeopleView:FC<PeopleInterface>  = ({isFollowingUsers,activeTab,followingRaw,followersRaw,renderUser}) => (
    <ConBody>
      <section>
        {isFollowingUsers ? (
          <FeedCon>
            <Spacer height={20} />
            <TabFlex>
              <TabText className={activeTab === 1 ? "active" : ""}>
                Following ({followingRaw?.data?.getUserFollowing?.length ?? 0})
              </TabText>
            </TabFlex>
            <Flex direction="column">
              {!followingRaw?.error &&
              !followingRaw?.loading &&
              followingRaw?.data?.getUserFollowing?.length &&
              activeTab === 1
                ? followingRaw?.data?.getUserFollowing?.map((user:any, k:any) => (
                    <NftUser
                      key={k}
                      mode="follower"
                      current_user={renderUser}
                      user={user as User}
                      account={renderUser?.address}
                    />
                  ))
                : activeTab === 1 && (
                    <div>This user is not following anyone</div>
                  )}
            </Flex>
          </FeedCon>
        ) : (
          <FeedCon>
            <Spacer height={20} />
            <TabFlex>
              <TabText className={activeTab === 1 ? "active" : ""}>
                Followers ({followersRaw?.data?.getUserFollowers?.length ?? 0})
              </TabText>
            </TabFlex>
            <Flex direction="column">
              {!followersRaw?.loading &&
              !followersRaw?.error &&
              followersRaw?.data?.getUserFollowers?.length &&
              activeTab === 1
                ? followersRaw?.data?.getUserFollowers?.map((user:any, k:number) => (
                    <NftUser
                      key={k}
                      mode="follower"
                      current_user={renderUser}
                      user={user as User}
                      account={renderUser?.address}
                    />
                  ))
                : activeTab === 1 && <div className="alert alert-info">This user has no follower(s)</div>}
            </Flex>
          </FeedCon>
        )}
      </section>
    </ConBody>
  );


  export default PeopleView;