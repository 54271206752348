/* eslint-disable require-yield */
import { all, fork } from "redux-saga/effects";
import * as auctionSaga from "./auctions-saga";
import * as caseSaga from "./cases-saga";
import * as cNftSaga from "./contract-auction-saga";
import * as farmSaga from "./farm-saga";
import * as lockerSaga from "./locker-saga";
import * as uiSaga from "./ui-saga";

// use them in parallel
export default function* rootSaga() {
  yield all(
    [
      ...Object.values(auctionSaga),
      ...Object.values(caseSaga),
      ...Object.values(lockerSaga),
      ...Object.values(uiSaga),

      ...Object.values(farmSaga),
      ...Object.values(cNftSaga),
    ].map(fork)
  );
}
