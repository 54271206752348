/* eslint-disable require-yield */
import { takeLatest,put,call } from 'redux-saga/effects'
import { FETCH_FARMS } from "./types";
import {  setLoading,setFarms, setUserDataLoaded, setFarmUser } from '../features/farms/FarmSlice';
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../states/farm';



function* fetchFarms(action: any) { 
    yield put(setLoading(true))
    const data:[]= yield call(fetchFarmsPublicDataAsync, action.payload.pids);
    if (data) {
      yield put(setFarms(data))
      yield put(setLoading(false))
      if(action.payload.user){
        const userData:[]= yield call(fetchFarmUserDataAsync, action.payload.user, action.payload.pids);
        if(userData){
          yield put(setFarmUser(userData))
          yield put (setUserDataLoaded(true))
        }
      }
    }else{
      alert('error')
      yield put(setLoading(false))

    } 
}


// use them in parallel
export default function* farmSaga() {
  yield takeLatest(FETCH_FARMS, fetchFarms)
}