import { IonContent } from "@ionic/react";
import { Flex, Footer, GridComponent, Hero } from "components";
import { ButtonPrimary } from "components/Button";
import { NftCard, NftCreatorCard, NftStepCard } from "components/Card";
import { SectionTitle } from "components/SectionTitle";
import { steps } from "data";
import { Auction, useCreateUserMutation } from "generated/graphql-frontend";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTopCreators, useTrendings } from "store/hooks";
import styled from "styled-components";
import { IUserReceiver } from "types/nft-user-types";
import { filterDuplicatesObj } from "utils/nft-user-helper";

const Container = styled.div`
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
`;
const NftTrending: React.FC<IUserReceiver> = ({ user, social, account }) => {
  const limit = 20;
  const [createUserMutation] = useCreateUserMutation();

  const [trendingCursor, setTrendingCursor] = useState<number>(null);
  const [topCreatorsCursor, setTopCreatorsCursor] =
    useState<{ mintedCount?: number; followersCount?: number }>(null);
  const { trendingErr, trendingLoading, trendings, fetchMoreTrending } =
    useTrendings(limit);
  const { loading, error, creators, fetchMoreCreators } = useTopCreators(limit);
  const [showMoreTrendingBtn, setShowMoreTrendingBtn] = useState<boolean>(true);
  const [showMoreCreatorBtn, setShowMoreCreatorBtn] = useState<boolean>(true);

  const [heroAuction, setHeroAuction] = useState<Auction>(null);
  const [active, setActive] = useState(false);
  const position = useRef(1);

  const handleHero = () => {
    if (trendings?.length > 0) {
      setHeroAuction(trendings[0] as Auction);
      if (trendings?.length > 1) {
        const interval = setInterval(() => {
          setHeroAuction(trendings[position.current] as Auction);
          if (position.current === trendings?.length - 1) position.current = 0;
          else position.current = position.current + 1;
        }, 10_000);
        setActive(true);
        return () => clearInterval(interval);
      }
    }
  };

  const forward = () => {
    position.current =
      position.current === trendings?.length - 1 ? 0 : position.current + 1;
    setHeroAuction(trendings[position.current] as Auction);
  };
  const backward = () => {
    position.current = position.current > 0 ? position.current - 1 : 0;
    setHeroAuction(trendings[position.current] as Auction);
  };

  const [, setState] = useState(null);

  function loadMoreTrendings() {
    return fetchMoreTrending({
      variables: {
        limit,
        viewCount: trendingCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.getTrending?.length) {
          setShowMoreTrendingBtn(false);
          return Object.assign({}, prev, {
            getTrending: filterDuplicatesObj([
              ...creators,
              ...prev.getTrending,
            ]),
          });
        }

        fetchMoreResult.getTrending.length &&
          setTrendingCursor(
            () =>
              fetchMoreResult.getTrending[
                fetchMoreResult.getTrending.length - 1
              ]?.viewCount
          );

        const combined = Object.assign({}, prev, {
          getTrending: filterDuplicatesObj([
            ...trendings,
            ...fetchMoreResult.getTrending,
          ]),
        });

        if (prev?.getTrending?.length < limit) setShowMoreTrendingBtn(false);
        return combined;
      },
    });
  }

  function loadMoreTopCreators() {
    return fetchMoreCreators({
      variables: {
        limit,
        followersCount: topCreatorsCursor.followersCount,
        mintedCount: topCreatorsCursor.mintedCount,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.getTopCreators?.length) {
          setShowMoreTrendingBtn(false);
          return Object.assign({}, prev, {
            getTopCreators: filterDuplicatesObj([
              ...creators,
              ...prev.getTopCreators,
            ]),
          });
        }

        fetchMoreResult.getTopCreators.length &&
          setTopCreatorsCursor(() => ({
            mintedCount:
              fetchMoreResult.getTopCreators[
                fetchMoreResult.getTopCreators.length - 1
              ]?.mintedCount,
            followersCount:
              fetchMoreResult.getTopCreators[
                fetchMoreResult.getTopCreators.length - 1
              ]?.followersCount,
          }));

        const combined = Object.assign({}, prev, {
          getTopCreators: filterDuplicatesObj([
            ...creators,
            ...fetchMoreResult.getTopCreators,
          ]),
        });

        if (prev?.getTopCreators?.length < limit) setShowMoreTrendingBtn(false);
        return combined;
      },
    });
  }
  useEffect(() => {
    if (account?.length && !user) {
      createUserMutation({
        variables: {
          address: account,
        },
      });
    }
    if (!trendingErr && !trendingLoading && trendings?.length) {
      setTrendingCursor(() => trendings[trendings?.length - 1]?.viewCount);
    }

    if (!error && !loading && creators?.length) {
      setTopCreatorsCursor(() => ({
        mintedCount: creators[creators?.length - 1]?.mintedCount,
        followersCount: creators[creators?.length - 1]?.followersCount,
      }));
    }

    return () => {
      setState({});
    };
    // eslint-disable-next-line
  }, [user, account, trendings, creators]);

  !active && handleHero();
  return (
    <IonContent>
      <Container>
        <div className="pool-p10">
          {trendings && (
            <Hero auction={heroAuction} {...{ backward, forward }} />
          )}

          {/* Trending Section */}

          <SectionTitle title="Trending token" shadowTitle="Trending" />
          <GridComponent>
            {trendings?.length > 0 &&
              trendings?.map((auction: any, i: number) => (
                <NftCard {...{ auction }} key={i} />
              ))}
            {/* <IonButton onClick={loadMoreTrendings}> Load More... </IonButton> */}
          </GridComponent>
          {/* Top Creator Section */}

          <SectionTitle title="Top Creators" shadowTitle="Artists" />
          <GridComponent>
            {creators?.length > 0 &&
              creators?.map((creator: any, i: number) => (
                <NftCreatorCard {...{ user, creator, account }} key={i} />
              ))}
            {/* <IonButton onClick={loadMoreTopCreators}> Load More... </IonButton> */}
          </GridComponent>

          {/* Create You Own section */}

          <div>
            <SectionTitle title="Create Your Own NFTs." shadowTitle="Create" />

            <Flex style={{ flexWrap: "wrap" }}>
              {steps.map(({ step, title, desc, color }, i) => (
                <NftStepCard {...{ step, title, desc, color }} key={i} />
              ))}
            </Flex>

            <div>
              <Link to="/nfts/create">
                <ButtonPrimary style={{ margin: "auto" }} width="200px">
                  Create Now!
                </ButtonPrimary>
              </Link>
            </div>
          </div>

          {/* Stay connected section */}
        </div>
      </Container>
      <br />

      <Footer />
    </IonContent>
  );
};

export default NftTrending;
