import { IonChip, useIonModal } from "@ionic/react";
import { Avatar, Flex, Spacer, Text } from "components";
import { ButtonPrimaryLight } from "components/Button";
import TransactionDeny from "components/Pool/TransactionDeny";
import TransactionSubmitted from "components/Pool/TransactionSubmitted";
import TransactionWaiting from "components/Pool/TransactionWaiting";
import {
  CheckIsFollowingDocument,
  useCheckIsFollowingLazyQuery,
  User,
  UserAddressQueryDocument,
  useRejectMinterRequestMutation,
  useUpdateMinterRequestMutation,
  useUpdateUserFollowingMutation,
} from "generated/graphql-frontend";
import { use_registerNewMinter } from "hooks/nfts/nft-hooks";
import { web3 } from "hooks/useWeb3Provider";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { ISocial } from "types/nft-user-types";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import {
  getChainFromChainId,
  getUserSocial,
  toChecksum,
} from "utils/nft-user-helper";
import { truncate } from "utils/text-help";

// https://ipfs.io/ipfs/QmPMzRbSmpYdhNfoLatxY4JBwTBD1kCjSdrr86rxYUdRXk

export const CardContainer = styled.div`
  width: 100%;
  background: var(--ion-color-card);
  box-shadow: 2.79091px 2.79091px 34.8864px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 20px 10px;
  overflow: hidden;
  filter: drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.08));
  display: flex;
  /* max-width: 600px; */
  position: "relative";
  & .p {
    line-height: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    margin: 20px 0px;
  }
`;

const Details = styled.div`
  margin-left: 15px;
`;

const Action = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface CardInterface {
  user?: User;
  current_user?: User;
  account?: string;
  mode?: "tree" | "follower";
  showRequestBtns?: boolean;
}

const Card: React.FC<CardInterface> = ({
  user,
  current_user,
  account,
  mode,
  showRequestBtns,
}) => {
  const history = useHistory();
  const { chainId } = useActiveWeb3React();
  const handleClick = () => {
    history.push(`/users/user/${user?.id}`);
  };
  const [social, setSocial] = useState<ISocial>(null);
  const userSocial = async () => {
    const _social = await getUserSocial(formatIpfsUrl(user?.profileHash));
    setSocial(_social);
  };
  if (user && !social) userSocial();
  const [updateUserFollowingMutation] = useUpdateUserFollowingMutation();
  const [checkImFollowing, { data }] = useCheckIsFollowingLazyQuery();
  const [checkIsFollowing, isFollowingRaw] = useCheckIsFollowingLazyQuery();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [txnSubmittedMsg, setTxnSubmittedMsg] = useState<string>("");
  const [waitingMsg, setWaitingMsg] = useState<string>("");
  const [updateMinterRequestMutation] = useUpdateMinterRequestMutation();

  const [, setState] = useState<boolean>(false);

  const follow = async () => {
    if (!account) await web3();
    if (account && current_user) {
      updateUserFollowingMutation({
        variables: {
          address: account,
          followingId: user?.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CheckIsFollowingDocument,
            variables: {
              currentUserId: current_user?.id, // value for 'currentUerId'
              viewUserId: user?.id, // value for 'viewUserId'
            },
          },
          {
            query: UserAddressQueryDocument,
            variables: {
              getUserAddress: account, // value for 'getUserByAddressAddress'
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (
      user &&
      current_user &&
      user?.id?.toLowerCase() !== current_user?.id?.toLowerCase()
    ) {
      checkImFollowing({
        variables: {
          currentUserId: current_user?.id, // value for 'currentUerId'
          viewUserId: user?.id, // value for 'viewUserId'
        },
      });

      checkIsFollowing({
        variables: {
          currentUserId: user?.id, // value for 'currentUerId'
          viewUserId: current_user?.id, // value for 'viewUserId'
        },
      });
    }
    return () => {
      setState(true);
    };
  }, [user, current_user]);

  const [rejectMinterRequestMutation] = useRejectMinterRequestMutation({});

  const reject = () => {
    let req = current_user.requestsReceived.find(
      ({ from }) =>
        from?.address?.toLowerCase() === user?.address?.toLowerCase()
    );
    rejectMinterRequestMutation({
      variables: {
        requestId: req.id,
      },
    });
  };

  const _confirm = async () => {
    const _web3 = await web3();
    let req = current_user.requestsReceived.find(
      ({ from }) =>
        from?.address?.toLowerCase() === user?.address?.toLowerCase()
    );
    if (!req) return; // notify user that request does not exist

    setWaitingMsg(
      `You are activating ${user?.username} as a new content creator.`
    );
    presentTxnWaiting();
    try {
      await use_registerNewMinter(
        toChecksum(user?.address),
        account,
        _web3,
        getChainFromChainId(chainId)
      )
        .on("transactionHash", (tx: any) => {
          console.log("trxn: ", tx);
          setTxnSubmittedMsg(`${tx}`);
          presentSubmitted();
          dismissTxnWaiting();
        })
        .on("receipt", (confirmationNumber: any, receipt: any) => {
          updateMinterRequestMutation({
            variables: {
              requestId: req.id, // value for 'updateMinterRequestRequestId'
            },
          });
        })
        .on("error", (error: any) => {
          setErrorMsg(error.message);
          presentError();
        });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleErrorDismiss = () => {
    dismissPresentError();
  };

  const handleTxSubmittedDismiss = () => {
    dismissSubmitted();
  };

  const handleWaitingDismiss = () => {
    dismissTxnWaiting();
  };

  const [presentError, dismissPresentError] = useIonModal(TransactionDeny, {
    close: handleErrorDismiss,
    msg: errorMsg,
  });

  const [presentSubmitted, dismissSubmitted] = useIonModal(
    TransactionSubmitted,
    {
      close: handleTxSubmittedDismiss,
      hash: txnSubmittedMsg,
    }
  );

  const [presentTxnWaiting, dismissTxnWaiting] = useIonModal(
    TransactionWaiting,
    { close: handleWaitingDismiss, msg: waitingMsg }
  );

  return (
    <>
      {user && current_user && (
        <CardContainer>
          <Flex justify="space-between" style={{ padding: 30 }}>
            <Avatar onClick={handleClick} size="64" style={{ flexShrink: 0 }}>
              <img
                src={
                  formatIpfsUrl(social?.imageHash) ??
                  `https://avatars.dicebear.com/api/male/${
                    user?.address ?? user?.id
                  }.svg?background=%236d64ff&&mood[]=happy`
                }
                alt={user?.name}
              />
            </Avatar>
            <Details>
              <Text size="20" weight="500">
                {user?.name ?? user?.username}
              </Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text size="20" weight="500">
                  {user?.username?.length ? `@${user?.username}` : ""}
                </Text>
                <Spacer width={5} />
                {isFollowingRaw?.data?.checkFollowing && (
                  <Text
                    size="14"
                    weight="500"
                    color="#333333"
                    style={{ opacity: 0.5 }}
                  >
                    follows you
                  </Text>
                )}
              </div>
              <Text size="16" weight="400">
                {truncate(social?.bio, 100)}
              </Text>

              <Action>
                <Flex>
                  <Flex align="center" justify="space-between">
                    {mode === "follower" && (
                      <Flex>
                        {data?.checkFollowing ? (
                          <div>
                            <ButtonPrimaryLight
                              color="primary"
                              fill="outline"
                              onClick={follow}
                              height="32"
                            >
                              Following
                            </ButtonPrimaryLight>
                          </div>
                        ) : (
                          <ButtonPrimaryLight
                            color="primary"
                            fill="outline"
                            onClick={follow}
                            height="32"
                          >
                            Follow
                          </ButtonPrimaryLight>
                        )}
                      </Flex>
                    )}

                    {mode === "tree" && showRequestBtns && (
                      <Flex>
                        <div>
                          <ButtonPrimaryLight onClick={_confirm}>
                            Accept
                          </ButtonPrimaryLight>
                        </div>
                        <IonChip onClick={reject} color="light">
                          Decline
                        </IonChip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Action>
            </Details>
          </Flex>
        </CardContainer>
      )}
    </>
  );
};

export default Card;
