import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface PopInput {
  onMouse: any;
  handleNotif: () => void;
  notCount: any;
}

const ListItem = styled.div`
  padding: 5px 10px;
  transition: 0.3s ease;
  min-width: 180px;

  &:hover {
    background: #f9f9f9;
  }
`;
const H6Bold = styled.h6`
  font-weight: 600;
  text-align: center;
  font-size: 18px;
`;

const Pop = styled.div`
  position: absolute;
  top: 100px;
  right: 20px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 3px;
`;

const CText = styled.div<{ size?: string }>`
  padding: 10px;
  pointer-events: none;
  font-weight: "normal";
  font-size: ${({ size }) => (size ? size + "px" : "12px")};
  color: "#000000";
  padding: "0px";

  @media (max-width: 640px) {
  }
  span {
    float: right;
    background: #ff0000;
    height: 27px;
    width: 33px;
    line-height: 25px;
    border-radius: 15px;
    text-align: center;
    position: relative;
    right: -20px;
  }
`;

const NavPopup: FC<PopInput> = ({ onMouse, handleNotif, notCount }) => {
  return (
    <Pop onMouseOver={onMouse}>
      <ListItem>
        <Link to="/me?page=dashboard">
          <CText size="16">My Dashboard</CText>
        </Link>
      </ListItem>
      <ListItem>
        <Link to="#">
          {/* <Link to="/me?page=earnings"> */}
          <CText size="16">My Earnings</CText>
        </Link>
      </ListItem>
      <ListItem>
        {" "}
        <Link to="/me?page=tree">
          <CText size="16">My Trees</CText>
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/me?page=followers">
          <CText size="16">My Followers</CText>
        </Link>
      </ListItem>
      <ListItem onClick={handleNotif}>
        <CText size="16">
          Notifications <span>{notCount}</span>
        </CText>
      </ListItem>
      {/* <ListItem onClick={deactivate}>
        <Link to="/">
          <CText size="16">Sign Out</CText>
        </Link>
      </ListItem> */}
    </Pop>
  );
};

export default NavPopup;
