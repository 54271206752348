import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
export type InputMaybe<T> = T | null;
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Auction = {
  __typename?: "Auction";
  auctionId: Scalars["String"];
  basePrice: Scalars["String"];
  bids?: Maybe<Array<Bid>>;
  buyer?: Maybe<User>;
  chain: Blockchain;
  closedAt?: Maybe<Scalars["String"]>;
  closingHash?: Maybe<Scalars["String"]>;
  closingValue?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  isPrimarySales: Scalars["Boolean"];
  likeCount?: Maybe<Scalars["Int"]>;
  likedBy?: Maybe<Array<User>>;
  nftCreator?: Maybe<User>;
  nftToken: NftToken;
  owner: User;
  payTos?: Maybe<PayTo>;
  paymentMethod: Scalars["String"];
  royalties?: Maybe<Array<Royalty>>;
  sharedHash?: Maybe<Array<Scalars["String"]>>;
  soldBy?: Maybe<User>;
  state?: Maybe<AuctionState>;
  trxHash: Scalars["String"];
  viewCount?: Maybe<Scalars["Int"]>;
};

export type AuctionInput = {
  auctionId: Scalars["String"];
  basePrice: Scalars["String"];
  chain: Blockchain;
  ownerAddress: Scalars["String"];
  paymentMethod: Scalars["String"];
  tokenAddress: Scalars["String"];
  tokenId: Scalars["String"];
  tokenUri: Scalars["String"];
  trxHash: Scalars["String"];
};

export enum AuctionState {
  Cancelled = "cancelled",
  Delisted = "delisted",
  InBid = "in_bid",
  Onsale = "onsale",
  Sold = "sold",
}

export type Bid = {
  __typename?: "Bid";
  auction: Auction;
  bidder: User;
  closedAt?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  state: BidState;
  trxHash: Scalars["String"];
  value: Scalars["String"];
};

export type BidInput = {
  auctionId: Scalars["String"];
  bidderAddress: Scalars["String"];
  newClosing: Scalars["String"];
  trxHash: Scalars["String"];
  value: Scalars["String"];
};

export enum BidState {
  Active = "active",
  Dropped = "dropped",
  Won = "won",
}

export enum Blockchain {
  Avax = "avax",
  Bsc = "bsc",
  Eth = "eth",
  Matic = "matic",
}

export type Decrypt = {
  __typename?: "Decrypt";
  auctionId: Scalars["String"];
  guestId?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type EarlyAdopter = {
  __typename?: "EarlyAdopter";
  address: Scalars["String"];
  chain: Blockchain;
  id: Scalars["ID"];
  signature: Scalars["String"];
  timestamp: Scalars["String"];
};

export type Earning = {
  __typename?: "Earning";
  auction: Auction;
  id: Scalars["ID"];
  status: EarningStatus;
  timestamp?: Maybe<Scalars["String"]>;
  to: User;
  trxHash?: Maybe<Scalars["String"]>;
  type: EarningType;
  value: Scalars["String"];
};

export type EarningInput = {
  auctionId: Scalars["String"];
  toAddress: Scalars["String"];
  type: EarningType;
  value: Scalars["String"];
};

export enum EarningStatus {
  Available = "Available",
  Claimed = "Claimed",
  Pending = "pending",
}

export enum EarningType {
  AuctionRef = "auction_ref",
  AuctionSale = "auction_sale",
  MinterRef = "minter_ref",
  Royalty = "royalty",
}

export enum EventType {
  Announcement = "announcement",
  AuctionDelisted = "auction_delisted",
  AuctionSold = "auction_sold",
  BidAccepted = "bid_accepted",
  Claimable = "claimable",
  NewBid = "new_bid",
  NewFollow = "new_follow",
  NewRequest = "new_request",
  OutBid = "out_bid",
  RequestConfirmed = "request_confirmed",
  RequestRejected = "request_rejected",
}

export type MinterAccessRequest = {
  __typename?: "MinterAccessRequest";
  createdAt: Scalars["String"];
  from: User;
  id: Scalars["ID"];
  status: Status;
  to: User;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelAuction: Auction;
  closeAuction: Auction;
  createAuction: Auction;
  createBid: Bid;
  createEarlyAdopter: EarlyAdopter;
  createNFTToken: NftToken;
  createUser: User;
  createViewInfo: ViewInfo;
  rejectMinterRequest: MinterAccessRequest;
  requestMinterAccess: MinterAccessRequest;
  updateAuctionLikes: Auction;
  updateAuctionSharedHash: Auction;
  updateAuctionViewCount: Auction;
  updateMinterRequest: MinterAccessRequest;
  updateNotification: Notification;
  updatePayTo: PayTo;
  updateUser: User;
  updateUserBeneficiary: User;
  updateUserFollowing: User;
  verifyUserEmail?: Maybe<User>;
};

export type MutationCancelAuctionArgs = {
  auctionId: Scalars["String"];
};

export type MutationCloseAuctionArgs = {
  auctionId: Scalars["ID"];
  buyerAddress: Scalars["String"];
  closingHash: Scalars["String"];
  value: Scalars["String"];
};

export type MutationCreateAuctionArgs = {
  auctionInput: AuctionInput;
};

export type MutationCreateBidArgs = {
  bidInput: BidInput;
};

export type MutationCreateEarlyAdopterArgs = {
  address: Scalars["String"];
  chain: Blockchain;
  signature: Scalars["String"];
};

export type MutationCreateNftTokenArgs = {
  nftTokenInput: NftTokenInput;
};

export type MutationCreateUserArgs = {
  address: Scalars["String"];
};

export type MutationCreateViewInfoArgs = {
  auctionId: Scalars["ID"];
  guestId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRejectMinterRequestArgs = {
  requestId: Scalars["ID"];
};

export type MutationRequestMinterAccessArgs = {
  fromId: Scalars["ID"];
  toId: Scalars["ID"];
};

export type MutationUpdateAuctionLikesArgs = {
  auctionId: Scalars["String"];
  userAddress: Scalars["String"];
};

export type MutationUpdateAuctionSharedHashArgs = {
  guestUserID: Scalars["ID"];
  refToken: Scalars["String"];
};

export type MutationUpdateAuctionViewCountArgs = {
  auctionId: Scalars["String"];
};

export type MutationUpdateMinterRequestArgs = {
  requestId: Scalars["ID"];
};

export type MutationUpdateNotificationArgs = {
  notificationId: Scalars["ID"];
};

export type MutationUpdatePayToArgs = {
  auctionId: Scalars["String"];
  percents: Array<Scalars["String"]>;
  toAddrs: Array<Scalars["String"]>;
};

export type MutationUpdateUserArgs = {
  address: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  profileHash?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type MutationUpdateUserBeneficiaryArgs = {
  beneficiaryAddr: Scalars["String"];
  userAddr: Scalars["String"];
};

export type MutationUpdateUserFollowingArgs = {
  address: Scalars["String"];
  followingId: Scalars["ID"];
};

export type MutationVerifyUserEmailArgs = {
  cypheredEmail: Scalars["String"];
};

export type NftHistory = {
  __typename?: "NFTHistory";
  auction: Auction;
  buyer?: Maybe<User>;
  id: Scalars["ID"];
  nftToken: NftToken;
  paymentMethod?: Maybe<Scalars["String"]>;
  seller: User;
  time?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum NftStandard {
  Erc721 = "erc721",
  Erc1155 = "erc1155",
}

export type NftToken = {
  __typename?: "NFTToken";
  burned?: Maybe<Scalars["Boolean"]>;
  chain: Blockchain;
  contractAddress: Scalars["String"];
  history?: Maybe<Array<NftHistory>>;
  id: Scalars["ID"];
  minter?: Maybe<User>;
  standard: NftStandard;
  tokenId: Scalars["String"];
  tokenUri: Scalars["String"];
};

export type NftTokenInput = {
  chain: Blockchain;
  contractAddress: Scalars["String"];
  minter?: Maybe<Scalars["String"]>;
  tokenId: Scalars["String"];
  tokenUri: Scalars["String"];
};

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  eventId: Scalars["ID"];
  eventType: EventType;
  id: Scalars["ID"];
  seen: Scalars["Boolean"];
  to: User;
};

export type PayTo = {
  __typename?: "PayTo";
  auction: Auction;
  id: Scalars["ID"];
  percents?: Maybe<Array<Scalars["String"]>>;
  tos?: Maybe<Array<User>>;
};

export type Query = {
  __typename?: "Query";
  checkFollowing: Scalars["Boolean"];
  decryptDBRef?: Maybe<Decrypt>;
  decryptRefToken?: Maybe<Decrypt>;
  generateDBRef: Scalars["String"];
  generateRefToken: Scalars["String"];
  getAuction: Auction;
  getAuctionByAuctionId?: Maybe<Auction>;
  getAuctions: Array<Auction>;
  getAuctionsHasOffer: Array<Auction>;
  getAuctionsIsClosed: Array<Auction>;
  getAuctionsOnChain: Array<Auction>;
  getBid: Bid;
  getBids: Array<Bid>;
  getCreators: Array<User>;
  getEarlyAdopter: EarlyAdopter;
  getHistoriesByNftTokenId?: Maybe<Array<NftHistory>>;
  getNftByAddressAndTokenId?: Maybe<NftToken>;
  getNftHistoriesByTokenAddressAndTokenId?: Maybe<Array<NftHistory>>;
  getNftToken: NftToken;
  getNfts: Array<NftToken>;
  getNotifications: Array<Notification>;
  getRequest: MinterAccessRequest;
  getRoyalties: Array<Royalty>;
  getRoyaltiesByUserId: Array<Royalty>;
  getRoyalty: Royalty;
  getTopCreators: Array<User>;
  getTrending?: Maybe<Array<Auction>>;
  getUser: User;
  getUserAuctionsByAuctionId: Array<Auction>;
  getUserAuctionsCollected: Array<Auction>;
  getUserAuctionsOnsale: Array<Auction>;
  getUserBids?: Maybe<Array<Bid>>;
  getUserByAddress?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  getUserByUsername?: Maybe<User>;
  getUserCreatedAuctions: Array<Auction>;
  getUserCreatedNfts: Array<NftToken>;
  getUserEarnings: Array<Earning>;
  getUserFollowers: Array<User>;
  getUserFollowing: Array<User>;
  getUserRequestsReceived?: Maybe<Array<MinterAccessRequest>>;
  getUserRequestsSent?: Maybe<Array<MinterAccessRequest>>;
  getUsers: Array<User>;
  getViewInfo: ViewInfo;
};

export type QueryCheckFollowingArgs = {
  currentUserId: Scalars["ID"];
  viewUserId: Scalars["ID"];
};

export type QueryDecryptDbRefArgs = {
  dbRef: Scalars["String"];
};

export type QueryDecryptRefTokenArgs = {
  refTooken: Scalars["String"];
};

export type QueryGenerateDbRefArgs = {
  guestUserId: Scalars["ID"];
  refToken: Scalars["String"];
};

export type QueryGenerateRefTokenArgs = {
  auctionID: Scalars["ID"];
  refId: Scalars["String"];
};

export type QueryGetAuctionArgs = {
  id: Scalars["ID"];
};

export type QueryGetAuctionByAuctionIdArgs = {
  auctionId: Scalars["String"];
};

export type QueryGetAuctionsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetAuctionsHasOfferArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetAuctionsIsClosedArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetAuctionsOnChainArgs = {
  chain: Blockchain;
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetBidArgs = {
  id: Scalars["ID"];
};

export type QueryGetBidsArgs = {
  auctionId: Scalars["ID"];
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetCreatorsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetEarlyAdopterArgs = {
  address: Scalars["String"];
};

export type QueryGetHistoriesByNftTokenIdArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  nftId: Scalars["ID"];
};

export type QueryGetNftByAddressAndTokenIdArgs = {
  address: Scalars["String"];
  tokenId: Scalars["String"];
};

export type QueryGetNftHistoriesByTokenAddressAndTokenIdArgs = {
  address: Scalars["String"];
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  tokenId: Scalars["String"];
};

export type QueryGetNftTokenArgs = {
  id: Scalars["ID"];
};

export type QueryGetNftsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetNotificationsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  userId: Scalars["ID"];
};

export type QueryGetRequestArgs = {
  id: Scalars["ID"];
};

export type QueryGetRoyaltiesByUserIdArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  userId: Scalars["ID"];
};

export type QueryGetRoyaltyArgs = {
  id: Scalars["ID"];
};

export type QueryGetTopCreatorsArgs = {
  followersCount?: Maybe<Scalars["Int"]>;
  limit: Scalars["Int"];
  mintedCount?: Maybe<Scalars["Int"]>;
};

export type QueryGetTrendingArgs = {
  limit: Scalars["Int"];
  viewCount?: Maybe<Scalars["Int"]>;
};

export type QueryGetUserArgs = {
  id: Scalars["ID"];
};

export type QueryGetUserAuctionsByAuctionIdArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserAuctionsCollectedArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserAuctionsOnsaleArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserBidsArgs = {
  address: Scalars["String"];
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetUserByAddressArgs = {
  address: Scalars["String"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"];
};

export type QueryGetUserByUsernameArgs = {
  username: Scalars["String"];
};

export type QueryGetUserCreatedAuctionsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserCreatedNftsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserEarningsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserFollowersArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  userId: Scalars["ID"];
};

export type QueryGetUserFollowingArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
  userId: Scalars["ID"];
};

export type QueryGetUserRequestsReceivedArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUserRequestsSentArgs = {
  cursor?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  limit: Scalars["Int"];
};

export type QueryGetUsersArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit: Scalars["Int"];
};

export type QueryGetViewInfoArgs = {
  auctionId: Scalars["ID"];
  guestId: Scalars["ID"];
};

export type Royalty = {
  __typename?: "Royalty";
  auction: Auction;
  id: Scalars["ID"];
  paymentMethod: Scalars["String"];
  royaltyTo: User;
  timestamp: Scalars["String"];
  trxHash: Scalars["String"];
  valuePaid: Scalars["String"];
};

export type RoyaltyInput = {
  auctionId: Scalars["String"];
  paymentMethod: Scalars["String"];
  royaltyTo: Scalars["String"];
  valuePaid: Scalars["String"];
};

export enum Status {
  Completed = "completed",
  Expired = "expired",
  Pending = "pending",
  Rejected = "rejected",
}

export type UpdateUserInput = {
  address: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  profileHash?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  address: Scalars["String"];
  addresses: Array<Scalars["String"]>;
  auctions?: Maybe<Array<Auction>>;
  beneficiaries?: Maybe<Array<User>>;
  bids?: Maybe<Array<Bid>>;
  collectedNfts?: Maybe<Array<Auction>>;
  email?: Maybe<Scalars["String"]>;
  emailCypher?: Maybe<Scalars["String"]>;
  emailVerified?: Maybe<Scalars["Boolean"]>;
  followers?: Maybe<Array<User>>;
  followersCount: Scalars["Int"];
  following?: Maybe<Array<User>>;
  followingCount: Scalars["Int"];
  id: Scalars["ID"];
  invitedBy?: Maybe<User>;
  isEarlyAdopter: Scalars["Boolean"];
  liked?: Maybe<Array<Auction>>;
  memberSince: Scalars["String"];
  mintedCount: Scalars["Int"];
  mintedNfts?: Maybe<Array<NftToken>>;
  mintedSoldCount: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  profileHash?: Maybe<Scalars["String"]>;
  refEarned?: Maybe<Array<Earning>>;
  referral?: Maybe<Array<User>>;
  referralsCount: Scalars["Int"];
  requestsReceived?: Maybe<Array<MinterAccessRequest>>;
  requestsSent?: Maybe<Array<MinterAccessRequest>>;
  royalties?: Maybe<Array<Royalty>>;
  salesEarned?: Maybe<Array<Earning>>;
  userType: UserType;
  username?: Maybe<Scalars["String"]>;
};

export enum UserType {
  Admin = "admin",
  Collector = "collector",
  Minter = "minter",
  RestrictedMinter = "restrictedMinter",
  UnknownMinter = "unknownMinter",
}

export type ViewInfo = {
  __typename?: "ViewInfo";
  auction: Auction;
  guest: User;
  id: Scalars["ID"];
  user: User;
};

export type GetAuctionQueryVariables = Exact<{
  getAuctionId: Scalars["ID"];
}>;

export type GetAuctionQuery = {
  __typename?: "Query";
  getAuction: {
    __typename?: "Auction";
    id: string;
    trxHash: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    closedAt?: string | null;
    sharedHash?: Array<string> | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    soldBy?: { __typename?: "User"; address: string; id: string } | null;
    owner: {
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
      id: string;
      userType: UserType;
    };
    bids?: Array<{
      __typename?: "Bid";
      id: string;
      closedAt?: string | null;
      createdAt: string;
      value: string;
      trxHash: string;
      state: BidState;
      bidder: { __typename?: "User"; address: string };
    }> | null;
    buyer?: {
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      id: string;
      username?: string | null;
    } | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      id: string;
      royaltyTo: {
        __typename?: "User";
        address: string;
        profileHash?: string | null;
        id: string;
        username?: string | null;
      };
    }> | null;
    likedBy?: Array<{
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      id: string;
      username?: string | null;
    }> | null;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
      standard: NftStandard;
    };
    payTos?: {
      __typename?: "PayTo";
      id: string;
      tos?: Array<{ __typename?: "User"; address: string; id: string }> | null;
    } | null;
  };
};

export type GetAuctionIdQueryVariables = Exact<{
  auctionId: Scalars["String"];
}>;

export type GetAuctionIdQuery = {
  __typename?: "Query";
  getAuctionByAuctionId?: {
    __typename?: "Auction";
    id: string;
    trxHash: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    closedAt?: string | null;
    sharedHash?: Array<string> | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    soldBy?: { __typename?: "User"; address: string; id: string } | null;
    owner: {
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    bids?: Array<{
      __typename?: "Bid";
      id: string;
      closedAt?: string | null;
      createdAt: string;
      value: string;
      trxHash: string;
      state: BidState;
      bidder: { __typename?: "User"; address: string };
    }> | null;
    buyer?: {
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      id: string;
      username?: string | null;
    } | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      id: string;
      royaltyTo: {
        __typename?: "User";
        address: string;
        profileHash?: string | null;
        id: string;
        username?: string | null;
      };
    }> | null;
    likedBy?: Array<{
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      id: string;
      username?: string | null;
    }> | null;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
      standard: NftStandard;
    };
    payTos?: {
      __typename?: "PayTo";
      id: string;
      tos?: Array<{ __typename?: "User"; address: string; id: string }> | null;
    } | null;
  } | null;
};

export type GetTrendingQueryVariables = Exact<{
  limit: Scalars["Int"];
  viewCount?: InputMaybe<Scalars["Int"]>;
}>;

export type GetTrendingQuery = {
  __typename?: "Query";
  getTrending?: Array<{
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  }> | null;
};

export type GetAuctionsQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetAuctionsQuery = {
  __typename?: "Query";
  getAuctions: Array<{
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  }>;
};

export type GetAuctionsHasOfferQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetAuctionsHasOfferQuery = {
  __typename?: "Query";
  getAuctionsHasOffer: Array<{
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  }>;
};

export type GetAuctionsOnChainQueryVariables = Exact<{
  limit: Scalars["Int"];
  chain: Blockchain;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetAuctionsOnChainQuery = {
  __typename?: "Query";
  getAuctionsOnChain: Array<{
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  }>;
};

export type CancelAuctionMutationVariables = Exact<{
  auctionId: Scalars["String"];
}>;

export type CancelAuctionMutation = {
  __typename?: "Mutation";
  cancelAuction: {
    __typename?: "Auction";
    id: string;
    state?: AuctionState | null;
    closedAt?: string | null;
  };
};

export type CloseAuctionMutationVariables = Exact<{
  auctionId: Scalars["ID"];
  value: Scalars["String"];
  buyerAddress: Scalars["String"];
  closingHash: Scalars["String"];
}>;

export type CloseAuctionMutation = {
  __typename?: "Mutation";
  closeAuction: {
    __typename?: "Auction";
    id: string;
    auctionId: string;
    closedAt?: string | null;
    closingHash?: string | null;
    closingValue?: string | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    viewCount?: number | null;
    paymentMethod: string;
    basePrice: string;
    likeCount?: number | null;
    buyer?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  };
};

export type CreateAuctionMutationVariables = Exact<{
  auctionInput: AuctionInput;
}>;

export type CreateAuctionMutation = {
  __typename?: "Mutation";
  createAuction: {
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  };
};

export type CreateBidMutationVariables = Exact<{
  bidInput: BidInput;
}>;

export type CreateBidMutation = {
  __typename?: "Mutation";
  createBid: {
    __typename?: "Bid";
    id: string;
    value: string;
    trxHash: string;
    state: BidState;
    closedAt?: string | null;
    createdAt: string;
    bidder: { __typename?: "User"; id: string; address: string };
  };
};

export type GenerateRefTokenQueryVariables = Exact<{
  auctionId: Scalars["ID"];
  refId: Scalars["String"];
}>;

export type GenerateRefTokenQuery = {
  __typename?: "Query";
  generateRefToken: string;
};

export type GetBidsQueryVariables = Exact<{
  auctionId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetBidsQuery = {
  __typename?: "Query";
  getBids: Array<{
    __typename?: "Bid";
    id: string;
    value: string;
    trxHash: string;
    state: BidState;
    closedAt?: string | null;
    createdAt: string;
    bidder: { __typename?: "User"; id: string; address: string };
    auction: { __typename?: "Auction"; id: string };
  }>;
};

export type GetAuctionsIsClosedQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetAuctionsIsClosedQuery = {
  __typename?: "Query";
  getAuctionsIsClosed: Array<{
    __typename?: "Auction";
    id: string;
    viewCount?: number | null;
    auctionId: string;
    basePrice: string;
    paymentMethod: string;
    closingValue?: string | null;
    likeCount?: number | null;
    state?: AuctionState | null;
    chain: Blockchain;
    createdAt: string;
    owner: {
      __typename?: "User";
      username?: string | null;
      profileHash?: string | null;
      name?: string | null;
      id: string;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenId: string;
      id: string;
      tokenUri: string;
    };
  }>;
};

export type GetBidQueryVariables = Exact<{
  bidId: Scalars["ID"];
}>;

export type GetBidQuery = {
  __typename?: "Query";
  getBid: {
    __typename?: "Bid";
    id: string;
    value: string;
    trxHash: string;
    state: BidState;
    closedAt?: string | null;
    createdAt: string;
    bidder: { __typename?: "User"; id: string; address: string };
    auction: { __typename?: "Auction"; id: string };
  };
};

export type UpdateAuctionLikesMutationVariables = Exact<{
  updateAuctionLikesAuctionId: Scalars["String"];
  updateAuctionLikesUserAddress: Scalars["String"];
}>;

export type UpdateAuctionLikesMutation = {
  __typename?: "Mutation";
  updateAuctionLikes: {
    __typename?: "Auction";
    id: string;
    auctionId: string;
    likeCount?: number | null;
    viewCount?: number | null;
    likedBy?: Array<{
      __typename?: "User";
      id: string;
      address: string;
      profileHash?: string | null;
      name?: string | null;
    }> | null;
  };
};

export type UpdateAuctionShareHashMutationVariables = Exact<{
  refToken: Scalars["String"];
  guestUserId: Scalars["ID"];
}>;

export type UpdateAuctionShareHashMutation = {
  __typename?: "Mutation";
  updateAuctionSharedHash: {
    __typename?: "Auction";
    id: string;
    auctionId: string;
  };
};

export type UpdateAuctionViewCountMutationVariables = Exact<{
  auctionId: Scalars["String"];
}>;

export type UpdateAuctionViewCountMutation = {
  __typename?: "Mutation";
  updateAuctionViewCount: {
    __typename?: "Auction";
    auctionId: string;
    id: string;
    viewCount?: number | null;
  };
};

export type UpdatePayToMutationVariables = Exact<{
  auctionId: Scalars["String"];
  toAddrs: Array<Scalars["String"]> | Scalars["String"];
  percents: Array<Scalars["String"]> | Scalars["String"];
}>;

export type UpdatePayToMutation = {
  __typename?: "Mutation";
  updatePayTo: { __typename?: "PayTo"; id: string };
};

export type CreateNftMutationMutationVariables = Exact<{
  nftTokenInput: NftTokenInput;
}>;

export type CreateNftMutationMutation = {
  __typename?: "Mutation";
  createNFTToken: {
    __typename?: "NFTToken";
    id: string;
    contractAddress: string;
    tokenId: string;
    tokenUri: string;
    chain: Blockchain;
  };
};

export type GetNftByAddressAndTokenIdQueryVariables = Exact<{
  address: Scalars["String"];
  tokenId: Scalars["String"];
}>;

export type GetNftByAddressAndTokenIdQuery = {
  __typename?: "Query";
  getNftByAddressAndTokenId?: {
    __typename?: "NFTToken";
    id: string;
    contractAddress: string;
    tokenId: string;
    tokenUri: string;
    history?: Array<{ __typename?: "NFTHistory"; id: string }> | null;
    minter?: {
      __typename?: "User";
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
      id: string;
    } | null;
  } | null;
};

export type GetNftHistoriesByTokenAddressAndTokenIdQueryVariables = Exact<{
  address: Scalars["String"];
  tokenId: Scalars["String"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetNftHistoriesByTokenAddressAndTokenIdQuery = {
  __typename?: "Query";
  getNftHistoriesByTokenAddressAndTokenId?: Array<{
    __typename?: "NFTHistory";
    id: string;
    value?: string | null;
    paymentMethod?: string | null;
    time?: string | null;
    buyer?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    seller: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      id: string;
      tokenId: string;
      tokenUri: string;
    };
    auction: {
      __typename?: "Auction";
      closedAt?: string | null;
      id: string;
      closingHash?: string | null;
      buyer?: {
        __typename?: "User";
        address: string;
        id: string;
        profileHash?: string | null;
      } | null;
    };
  }> | null;
};

export type GetHistoriesByNftTokenIdQueryVariables = Exact<{
  nftId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetHistoriesByNftTokenIdQuery = {
  __typename?: "Query";
  getHistoriesByNftTokenId?: Array<{
    __typename?: "NFTHistory";
    id: string;
    value?: string | null;
    paymentMethod?: string | null;
    time?: string | null;
    buyer?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    seller: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    };
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      id: string;
      tokenId: string;
      tokenUri: string;
    };
    auction: {
      __typename?: "Auction";
      closedAt?: string | null;
      id: string;
      closingHash?: string | null;
      buyer?: {
        __typename?: "User";
        address: string;
        id: string;
        profileHash?: string | null;
      } | null;
    };
  }> | null;
};

export type NfTsQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type NfTsQuery = {
  __typename?: "Query";
  getNfts: Array<{
    __typename?: "NFTToken";
    id: string;
    contractAddress: string;
    tokenId: string;
    tokenUri: string;
    minter?: { __typename?: "User"; address: string; id: string } | null;
    history?: Array<{
      __typename?: "NFTHistory";
      value?: string | null;
      buyer?: {
        __typename?: "User";
        address: string;
        id: string;
        profileHash?: string | null;
      } | null;
      seller: {
        __typename?: "User";
        address: string;
        id: string;
        profileHash?: string | null;
      };
    }> | null;
  }>;
};

export type AuctionUserQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type AuctionUserQuery = {
  __typename?: "Query";
  getUserByAddress?: {
    __typename?: "User";
    id: string;
    name?: string | null;
    username?: string | null;
    address: string;
    addresses: Array<string>;
    profileHash?: string | null;
  } | null;
};

export type CreateEarlyAdopterMutationVariables = Exact<{
  address: Scalars["String"];
  signature: Scalars["String"];
  chain: Blockchain;
}>;

export type CreateEarlyAdopterMutation = {
  __typename?: "Mutation";
  createEarlyAdopter: {
    __typename?: "EarlyAdopter";
    id: string;
    address: string;
    signature: string;
    chain: Blockchain;
  };
};

export type CreateUserMutationVariables = Exact<{
  address: Scalars["String"];
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "User";
    name?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    email?: string | null;
    memberSince: string;
    profileHash?: string | null;
    followers?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{ __typename?: "Bid"; id: string }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{
      __typename?: "NFTToken";
      contractAddress: string;
    }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      auction: { __typename?: "Auction"; auctionId: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  };
};

export type GetCreatorsQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetCreatorsQuery = {
  __typename?: "Query";
  getCreators: Array<{
    __typename?: "User";
    address: string;
    id: string;
    profileHash?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    memberSince: string;
  }>;
};

export type GetEarlyAdopterQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type GetEarlyAdopterQuery = {
  __typename?: "Query";
  getEarlyAdopter: {
    __typename?: "EarlyAdopter";
    address: string;
    signature: string;
    id: string;
  };
};

export type GetUserEarningsQueryVariables = Exact<{
  getUserEarningsId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserEarningsQuery = {
  __typename?: "Query";
  getUserEarnings: Array<{
    __typename?: "Earning";
    id: string;
    value: string;
    type: EarningType;
    timestamp?: string | null;
    trxHash?: string | null;
    status: EarningStatus;
    to: { __typename?: "User"; address: string; id: string };
    auction: {
      __typename?: "Auction";
      auctionId: string;
      id: string;
      basePrice: string;
    };
  }>;
};

export type GetRequestQueryVariables = Exact<{
  requestId: Scalars["ID"];
}>;

export type GetRequestQuery = {
  __typename?: "Query";
  getRequest: {
    __typename?: "MinterAccessRequest";
    id: string;
    createdAt: string;
    status: Status;
    to: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
    from: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
  };
};

export type GetRoyaltiesByUserIdqueryQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetRoyaltiesByUserIdqueryQuery = {
  __typename?: "Query";
  getRoyaltiesByUserId: Array<{
    __typename?: "Royalty";
    id: string;
    valuePaid: string;
    paymentMethod: string;
    auction: { __typename?: "Auction"; auctionId: string; id: string };
    royaltyTo: {
      __typename?: "User";
      address: string;
      profileHash?: string | null;
      username?: string | null;
    };
  }>;
};

export type GetTopCreatorsQueryVariables = Exact<{
  limit: Scalars["Int"];
  mintedCount?: InputMaybe<Scalars["Int"]>;
  followersCount?: InputMaybe<Scalars["Int"]>;
}>;

export type GetTopCreatorsQuery = {
  __typename?: "Query";
  getTopCreators: Array<{
    __typename?: "User";
    address: string;
    id: string;
    profileHash?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    memberSince: string;
    followersCount: number;
    mintedCount: number;
  }>;
};

export type GetUserAuctionByAuctionIdQueryVariables = Exact<{
  getUserAuctionsByAuctionIdId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserAuctionByAuctionIdQuery = {
  __typename?: "Query";
  getUserAuctionsByAuctionId: Array<{
    __typename?: "Auction";
    id: string;
    auctionId: string;
    closingValue?: string | null;
    likeCount?: number | null;
    viewCount?: number | null;
    chain: Blockchain;
    state?: AuctionState | null;
    basePrice: string;
    paymentMethod: string;
    bids?: Array<{
      __typename?: "Bid";
      id: string;
      value: string;
      state: BidState;
    }> | null;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      id: string;
      tokenId: string;
      tokenUri: string;
    };
  }>;
};

export type GetUserAuctionCollectedQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserAuctionCollectedQuery = {
  __typename?: "Query";
  getUserAuctionsCollected: Array<{
    __typename?: "Auction";
    id: string;
    paymentMethod: string;
    closingValue?: string | null;
    closedAt?: string | null;
    trxHash: string;
    likeCount?: number | null;
    viewCount?: number | null;
    basePrice: string;
    state?: AuctionState | null;
    auctionId: string;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenUri: string;
      tokenId: string;
      standard: NftStandard;
    };
    owner: {
      __typename?: "User";
      address: string;
      id: string;
      name?: string | null;
      profileHash?: string | null;
    };
    buyer?: { __typename?: "User"; address: string; id: string } | null;
  }>;
};

export type GetUserAuctionsOnsaleQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserAuctionsOnsaleQuery = {
  __typename?: "Query";
  getUserAuctionsOnsale: Array<{
    __typename?: "Auction";
    id: string;
    paymentMethod: string;
    closingValue?: string | null;
    basePrice: string;
    closedAt?: string | null;
    trxHash: string;
    likeCount?: number | null;
    viewCount?: number | null;
    state?: AuctionState | null;
    auctionId: string;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenUri: string;
      tokenId: string;
      standard: NftStandard;
    };
    owner: {
      __typename?: "User";
      address: string;
      id: string;
      name?: string | null;
      profileHash?: string | null;
    };
  }>;
};

export type GetUserBidsQueryVariables = Exact<{
  address: Scalars["String"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserBidsQuery = {
  __typename?: "Query";
  getUserBids?: Array<{
    __typename?: "Bid";
    id: string;
    value: string;
    trxHash: string;
    createdAt: string;
    state: BidState;
    closedAt?: string | null;
    auction: { __typename?: "Auction"; auctionId: string; id: string };
    bidder: { __typename?: "User"; address: string; id: string };
  }> | null;
};

export type GetUserCreatedAuctionsQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserCreatedAuctionsQuery = {
  __typename?: "Query";
  getUserCreatedAuctions: Array<{
    __typename?: "Auction";
    id: string;
    paymentMethod: string;
    closingValue?: string | null;
    basePrice: string;
    closedAt?: string | null;
    trxHash: string;
    likeCount?: number | null;
    viewCount?: number | null;
    state?: AuctionState | null;
    auctionId: string;
    nftToken: {
      __typename?: "NFTToken";
      contractAddress: string;
      tokenUri: string;
      tokenId: string;
      standard: NftStandard;
    };
    owner: {
      __typename?: "User";
      address: string;
      id: string;
      name?: string | null;
      profileHash?: string | null;
    };
  }>;
};

export type GetUserCreatedNftsQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
}>;

export type GetUserCreatedNftsQuery = {
  __typename?: "Query";
  getUserCreatedNfts: Array<{
    __typename?: "NFTToken";
    id: string;
    contractAddress: string;
    tokenId: string;
    tokenUri: string;
    burned?: boolean | null;
    standard: NftStandard;
    minter?: { __typename?: "User"; address: string; id: string } | null;
    history?: Array<{
      __typename?: "NFTHistory";
      id: string;
      value?: string | null;
    }> | null;
  }>;
};

export type GetUserFollowersQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserFollowersQuery = {
  __typename?: "Query";
  getUserFollowers: Array<{
    __typename?: "User";
    id: string;
    name?: string | null;
    address: string;
    profileHash?: string | null;
    username?: string | null;
    userType: UserType;
    followersCount: number;
    followingCount: number;
  }>;
};

export type GetUserFollowingQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserFollowingQuery = {
  __typename?: "Query";
  getUserFollowing: Array<{
    __typename?: "User";
    id: string;
    name?: string | null;
    address: string;
    profileHash?: string | null;
    username?: string | null;
    userType: UserType;
    followersCount: number;
    followingCount: number;
  }>;
};

export type CheckIsFollowingQueryVariables = Exact<{
  currentUserId: Scalars["ID"];
  viewUserId: Scalars["ID"];
}>;

export type CheckIsFollowingQuery = {
  __typename?: "Query";
  checkFollowing: boolean;
};

export type GetNotificationQueryVariables = Exact<{
  userId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetNotificationQuery = {
  __typename?: "Query";
  getNotifications: Array<{
    __typename?: "Notification";
    id: string;
    eventId: string;
    eventType: EventType;
    seen: boolean;
    createdAt: string;
    description: string;
    to: { __typename?: "User"; address: string; id: string };
  }>;
};

export type RejectMinterRequestMutationVariables = Exact<{
  requestId: Scalars["ID"];
}>;

export type RejectMinterRequestMutation = {
  __typename?: "Mutation";
  rejectMinterRequest: { __typename?: "MinterAccessRequest"; id: string };
};

export type RequestMinterAccessMutationVariables = Exact<{
  fromId: Scalars["ID"];
  toId: Scalars["ID"];
}>;

export type RequestMinterAccessMutation = {
  __typename?: "Mutation";
  requestMinterAccess: {
    __typename?: "MinterAccessRequest";
    id: string;
    createdAt: string;
    status: Status;
    from: {
      __typename?: "User";
      id: string;
      address: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
      userType: UserType;
    };
    to: {
      __typename?: "User";
      id: string;
      address: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
      userType: UserType;
    };
  };
};

export type UpdateUserBeneficiaryMutationVariables = Exact<{
  userAddr: Scalars["String"];
  beneficiaryAddr: Scalars["String"];
}>;

export type UpdateUserBeneficiaryMutation = {
  __typename?: "Mutation";
  updateUserBeneficiary: { __typename?: "User"; id: string };
};

export type UpdateMinterRequestMutationVariables = Exact<{
  requestId: Scalars["ID"];
}>;

export type UpdateMinterRequestMutation = {
  __typename?: "Mutation";
  updateMinterRequest: { __typename?: "MinterAccessRequest"; id: string };
};

export type UpdateNotificationMutationVariables = Exact<{
  notificationId: Scalars["ID"];
}>;

export type UpdateNotificationMutation = {
  __typename?: "Mutation";
  updateNotification: {
    __typename?: "Notification";
    id: string;
    seen: boolean;
  };
};

export type UpdateUserMutationVariables = Exact<{
  address: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  profileHash?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    address: string;
    email?: string | null;
    profileHash?: string | null;
    name?: string | null;
    username?: string | null;
    id: string;
  };
};

export type UpdateUserFollowingMutationVariables = Exact<{
  address: Scalars["String"];
  followingId: Scalars["ID"];
}>;

export type UpdateUserFollowingMutation = {
  __typename?: "Mutation";
  updateUserFollowing: {
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    address: string;
  };
};

export type UserAddressQueryQueryVariables = Exact<{
  getUserAddress: Scalars["String"];
}>;

export type UserAddressQueryQuery = {
  __typename?: "Query";
  getUserByAddress?: {
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    isEarlyAdopter: boolean;
    name?: string | null;
    email?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    memberSince: string;
    profileHash?: string | null;
    emailVerified?: boolean | null;
    liked?: Array<{
      __typename?: "Auction";
      auctionId: string;
      id: string;
    }> | null;
    salesEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    followers?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{
        __typename?: "Bid";
        id: string;
        value: string;
        trxHash: string;
        createdAt: string;
        closedAt?: string | null;
        state: BidState;
        bidder: { __typename?: "User"; address: string };
        auction: { __typename?: "Auction"; auctionId: string; id: string };
      }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      auction: { __typename?: "Auction"; basePrice: string };
    }> | null;
    bids?: Array<{
      __typename?: "Bid";
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{
      __typename?: "NFTToken";
      contractAddress: string;
    }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  } | null;
};

export type UserUsernameQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type UserUsernameQuery = {
  __typename?: "Query";
  getUserByUsername?: {
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    name?: string | null;
    email?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    memberSince: string;
    profileHash?: string | null;
    emailVerified?: boolean | null;
    liked?: Array<{
      __typename?: "Auction";
      auctionId: string;
      id: string;
    }> | null;
    salesEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    followers?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{
        __typename?: "Bid";
        id: string;
        value: string;
        trxHash: string;
        createdAt: string;
        closedAt?: string | null;
        state: BidState;
        bidder: { __typename?: "User"; address: string };
        auction: { __typename?: "Auction"; auctionId: string; id: string };
      }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      auction: { __typename?: "Auction"; basePrice: string };
    }> | null;
    bids?: Array<{
      __typename?: "Bid";
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{
      __typename?: "NFTToken";
      contractAddress: string;
      id: string;
      tokenId: string;
    }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  } | null;
};

export type UserEmailQueryQueryVariables = Exact<{
  getUserEmail: Scalars["String"];
}>;

export type UserEmailQueryQuery = {
  __typename?: "Query";
  getUserByEmail?: {
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    name?: string | null;
    email?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    memberSince: string;
    profileHash?: string | null;
    emailVerified?: boolean | null;
    liked?: Array<{
      __typename?: "Auction";
      auctionId: string;
      id: string;
    }> | null;
    salesEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    followers?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{
        __typename?: "Bid";
        id: string;
        value: string;
        trxHash: string;
        createdAt: string;
        closedAt?: string | null;
        state: BidState;
        bidder: { __typename?: "User"; address: string };
        auction: { __typename?: "Auction"; auctionId: string; id: string };
      }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      auction: { __typename?: "Auction"; basePrice: string };
    }> | null;
    bids?: Array<{
      __typename?: "Bid";
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{
      __typename?: "NFTToken";
      contractAddress: string;
    }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  } | null;
};

export type UserIdQueryQueryVariables = Exact<{
  getUserId: Scalars["ID"];
}>;

export type UserIdQueryQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    name?: string | null;
    email?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    memberSince: string;
    profileHash?: string | null;
    emailVerified?: boolean | null;
    isEarlyAdopter: boolean;
    liked?: Array<{
      __typename?: "Auction";
      auctionId: string;
      id: string;
    }> | null;
    salesEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    followers?: Array<{
      __typename?: "User";
      address: string;
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{
        __typename?: "Bid";
        id: string;
        value: string;
        trxHash: string;
        createdAt: string;
        closedAt?: string | null;
        state: BidState;
        bidder: { __typename?: "User"; address: string };
        auction: { __typename?: "Auction"; auctionId: string; id: string };
      }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      auction: { __typename?: "Auction"; basePrice: string };
    }> | null;
    bids?: Array<{
      __typename?: "Bid";
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{ __typename?: "NFTToken"; id: string }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  };
};

export type GetUserRequestsReceivedQueryVariables = Exact<{
  getUserRequestsReceivedId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserRequestsReceivedQuery = {
  __typename?: "Query";
  getUserRequestsReceived?: Array<{
    __typename?: "MinterAccessRequest";
    id: string;
    createdAt: string;
    status: Status;
    to: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
    from: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
  }> | null;
};

export type GetUserRequestsSentQueryVariables = Exact<{
  getUserRequestsSentId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserRequestsSentQuery = {
  __typename?: "Query";
  getUserRequestsSent?: Array<{
    __typename?: "MinterAccessRequest";
    id: string;
    createdAt: string;
    status: Status;
    to: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
    from: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    };
  }> | null;
};

export type UsersQueryQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type UsersQueryQuery = {
  __typename?: "Query";
  getUsers: Array<{
    __typename?: "User";
    id: string;
    followersCount: number;
    followingCount: number;
    name?: string | null;
    email?: string | null;
    address: string;
    addresses: Array<string>;
    username?: string | null;
    userType: UserType;
    memberSince: string;
    profileHash?: string | null;
    emailVerified?: boolean | null;
    liked?: Array<{
      __typename?: "Auction";
      auctionId: string;
      id: string;
    }> | null;
    salesEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    followers?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    invitedBy?: {
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
    } | null;
    following?: Array<{
      __typename?: "User";
      name?: string | null;
      email?: string | null;
      username?: string | null;
      id: string;
      address: string;
      addresses: Array<string>;
      profileHash?: string | null;
      followers?: Array<{ __typename?: "User"; id: string }> | null;
      following?: Array<{ __typename?: "User"; id: string }> | null;
    }> | null;
    referral?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      username?: string | null;
      name?: string | null;
    }> | null;
    auctions?: Array<{
      __typename?: "Auction";
      trxHash: string;
      paymentMethod: string;
      viewCount?: number | null;
      buyer?: { __typename?: "User"; address: string } | null;
      likedBy?: Array<{
        __typename?: "User";
        address: string;
        profileHash?: string | null;
      }> | null;
      bids?: Array<{
        __typename?: "Bid";
        id: string;
        value: string;
        trxHash: string;
        createdAt: string;
        closedAt?: string | null;
        state: BidState;
        bidder: { __typename?: "User"; address: string };
        auction: { __typename?: "Auction"; auctionId: string; id: string };
      }> | null;
      royalties?: Array<{
        __typename?: "Royalty";
        royaltyTo: {
          __typename?: "User";
          address: string;
          id: string;
          username?: string | null;
          name?: string | null;
        };
      }> | null;
    }> | null;
    royalties?: Array<{
      __typename?: "Royalty";
      valuePaid: string;
      auction: { __typename?: "Auction"; basePrice: string };
    }> | null;
    bids?: Array<{
      __typename?: "Bid";
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    collectedNfts?: Array<{ __typename?: "Auction"; id: string }> | null;
    mintedNfts?: Array<{
      __typename?: "NFTToken";
      contractAddress: string;
    }> | null;
    refEarned?: Array<{
      __typename?: "Earning";
      value: string;
      type: EarningType;
      status: EarningStatus;
      timestamp?: string | null;
      id: string;
      auction: { __typename?: "Auction"; auctionId: string; id: string };
    }> | null;
    beneficiaries?: Array<{
      __typename?: "User";
      address: string;
      id: string;
      profileHash?: string | null;
      name?: string | null;
      username?: string | null;
    }> | null;
    requestsReceived?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
    requestsSent?: Array<{
      __typename?: "MinterAccessRequest";
      id: string;
      createdAt: string;
      status: Status;
      from: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
      to: {
        __typename?: "User";
        id: string;
        address: string;
        profileHash?: string | null;
        name?: string | null;
        username?: string | null;
        userType: UserType;
      };
    }> | null;
  }>;
};

export type VerifyEmailMutationVariables = Exact<{
  cypheredEmail: Scalars["String"];
}>;

export type VerifyEmailMutation = {
  __typename?: "Mutation";
  verifyUserEmail?: { __typename?: "User"; id: string } | null;
};

export type CreateViewInfoMutationVariables = Exact<{
  userId: Scalars["ID"];
  guestId: Scalars["ID"];
  auctionId: Scalars["ID"];
}>;

export type CreateViewInfoMutation = {
  __typename?: "Mutation";
  createViewInfo: { __typename?: "ViewInfo"; id: string };
};

export type GetEarningQueryVariables = Exact<{
  getUserEarningsId: Scalars["ID"];
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type GetEarningQuery = {
  __typename?: "Query";
  getUserEarnings: Array<{
    __typename?: "Earning";
    id: string;
    value: string;
    type: EarningType;
    status: EarningStatus;
    timestamp?: string | null;
    trxHash?: string | null;
    auction: { __typename?: "Auction"; auctionId: string; id: string };
    to: { __typename?: "User"; address: string; id: string };
  }>;
};

export type GetViewInfoQueryVariables = Exact<{
  guestId: Scalars["ID"];
  auctionId: Scalars["ID"];
}>;

export type GetViewInfoQuery = {
  __typename?: "Query";
  getViewInfo: {
    __typename?: "ViewInfo";
    id: string;
    auction: { __typename?: "Auction"; auctionId: string; id: string };
    user: { __typename?: "User"; address: string; id: string };
    guest: { __typename?: "User"; address: string; id: string };
  };
};

export const GetAuctionDocument = gql`
  query GetAuction($getAuctionId: ID!) {
    getAuction(id: $getAuctionId) {
      id
      trxHash
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      closedAt
      soldBy {
        address
        id
      }
      sharedHash
      owner {
        address
        profileHash
        name
        username
        id
        userType
      }
      bids {
        id
        bidder {
          address
        }
        closedAt
        createdAt
        value
        trxHash
        state
      }
      buyer {
        address
        profileHash
        id
        username
      }
      royalties {
        valuePaid
        royaltyTo {
          address
          profileHash
          id
          username
        }
      }
      likedBy {
        address
        profileHash
        id
        username
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
        standard
      }
      likeCount
      royalties {
        id
        royaltyTo {
          address
          profileHash
        }
      }
      payTos {
        id
        tos {
          address
          id
        }
      }
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionQuery__
 *
 * To run a query within a React component, call `useGetAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionQuery({
 *   variables: {
 *      getAuctionId: // value for 'getAuctionId'
 *   },
 * });
 */
export function useGetAuctionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionQuery,
    GetAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuctionQuery, GetAuctionQueryVariables>(
    GetAuctionDocument,
    options
  );
}
export function useGetAuctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionQuery,
    GetAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuctionQuery, GetAuctionQueryVariables>(
    GetAuctionDocument,
    options
  );
}
export type GetAuctionQueryHookResult = ReturnType<typeof useGetAuctionQuery>;
export type GetAuctionLazyQueryHookResult = ReturnType<
  typeof useGetAuctionLazyQuery
>;
export type GetAuctionQueryResult = Apollo.QueryResult<
  GetAuctionQuery,
  GetAuctionQueryVariables
>;
export const GetAuctionIdDocument = gql`
  query GetAuctionId($auctionId: String!) {
    getAuctionByAuctionId(auctionId: $auctionId) {
      id
      trxHash
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      closedAt
      soldBy {
        address
        id
      }
      sharedHash
      owner {
        address
        profileHash
        name
        id
      }
      bids {
        id
        bidder {
          address
        }
        closedAt
        createdAt
        value
        trxHash
        state
      }
      buyer {
        address
        profileHash
        id
        username
      }
      royalties {
        valuePaid
        royaltyTo {
          address
          profileHash
          id
          username
        }
      }
      likedBy {
        address
        profileHash
        id
        username
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
        standard
      }
      likeCount
      royalties {
        id
        royaltyTo {
          address
          profileHash
        }
      }
      payTos {
        id
        tos {
          address
          id
        }
      }
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionIdQuery__
 *
 * To run a query within a React component, call `useGetAuctionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionIdQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useGetAuctionIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionIdQuery,
    GetAuctionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuctionIdQuery, GetAuctionIdQueryVariables>(
    GetAuctionIdDocument,
    options
  );
}
export function useGetAuctionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionIdQuery,
    GetAuctionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuctionIdQuery, GetAuctionIdQueryVariables>(
    GetAuctionIdDocument,
    options
  );
}
export type GetAuctionIdQueryHookResult = ReturnType<
  typeof useGetAuctionIdQuery
>;
export type GetAuctionIdLazyQueryHookResult = ReturnType<
  typeof useGetAuctionIdLazyQuery
>;
export type GetAuctionIdQueryResult = Apollo.QueryResult<
  GetAuctionIdQuery,
  GetAuctionIdQueryVariables
>;
export const GetTrendingDocument = gql`
  query GetTrending($limit: Int!, $viewCount: Int) {
    getTrending(limit: $limit, viewCount: $viewCount) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetTrendingQuery__
 *
 * To run a query within a React component, call `useGetTrendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      viewCount: // value for 'viewCount'
 *   },
 * });
 */
export function useGetTrendingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTrendingQuery,
    GetTrendingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTrendingQuery, GetTrendingQueryVariables>(
    GetTrendingDocument,
    options
  );
}
export function useGetTrendingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrendingQuery,
    GetTrendingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTrendingQuery, GetTrendingQueryVariables>(
    GetTrendingDocument,
    options
  );
}
export type GetTrendingQueryHookResult = ReturnType<typeof useGetTrendingQuery>;
export type GetTrendingLazyQueryHookResult = ReturnType<
  typeof useGetTrendingLazyQuery
>;
export type GetTrendingQueryResult = Apollo.QueryResult<
  GetTrendingQuery,
  GetTrendingQueryVariables
>;
export const GetAuctionsDocument = gql`
  query GetAuctions($limit: Int!, $cursor: String) {
    getAuctions(limit: $limit, cursor: $cursor) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionsQuery__
 *
 * To run a query within a React component, call `useGetAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAuctionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionsQuery,
    GetAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuctionsQuery, GetAuctionsQueryVariables>(
    GetAuctionsDocument,
    options
  );
}
export function useGetAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionsQuery,
    GetAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuctionsQuery, GetAuctionsQueryVariables>(
    GetAuctionsDocument,
    options
  );
}
export type GetAuctionsQueryHookResult = ReturnType<typeof useGetAuctionsQuery>;
export type GetAuctionsLazyQueryHookResult = ReturnType<
  typeof useGetAuctionsLazyQuery
>;
export type GetAuctionsQueryResult = Apollo.QueryResult<
  GetAuctionsQuery,
  GetAuctionsQueryVariables
>;
export const GetAuctionsHasOfferDocument = gql`
  query GetAuctionsHasOffer($limit: Int!, $cursor: String) {
    getAuctionsHasOffer(limit: $limit, cursor: $cursor) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionsHasOfferQuery__
 *
 * To run a query within a React component, call `useGetAuctionsHasOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionsHasOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionsHasOfferQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAuctionsHasOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionsHasOfferQuery,
    GetAuctionsHasOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuctionsHasOfferQuery,
    GetAuctionsHasOfferQueryVariables
  >(GetAuctionsHasOfferDocument, options);
}
export function useGetAuctionsHasOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionsHasOfferQuery,
    GetAuctionsHasOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionsHasOfferQuery,
    GetAuctionsHasOfferQueryVariables
  >(GetAuctionsHasOfferDocument, options);
}
export type GetAuctionsHasOfferQueryHookResult = ReturnType<
  typeof useGetAuctionsHasOfferQuery
>;
export type GetAuctionsHasOfferLazyQueryHookResult = ReturnType<
  typeof useGetAuctionsHasOfferLazyQuery
>;
export type GetAuctionsHasOfferQueryResult = Apollo.QueryResult<
  GetAuctionsHasOfferQuery,
  GetAuctionsHasOfferQueryVariables
>;
export const GetAuctionsOnChainDocument = gql`
  query GetAuctionsOnChain($limit: Int!, $chain: Blockchain!, $cursor: String) {
    getAuctionsOnChain(limit: $limit, chain: $chain, cursor: $cursor) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionsOnChainQuery__
 *
 * To run a query within a React component, call `useGetAuctionsOnChainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionsOnChainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionsOnChainQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      chain: // value for 'chain'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAuctionsOnChainQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionsOnChainQuery,
    GetAuctionsOnChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuctionsOnChainQuery,
    GetAuctionsOnChainQueryVariables
  >(GetAuctionsOnChainDocument, options);
}
export function useGetAuctionsOnChainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionsOnChainQuery,
    GetAuctionsOnChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionsOnChainQuery,
    GetAuctionsOnChainQueryVariables
  >(GetAuctionsOnChainDocument, options);
}
export type GetAuctionsOnChainQueryHookResult = ReturnType<
  typeof useGetAuctionsOnChainQuery
>;
export type GetAuctionsOnChainLazyQueryHookResult = ReturnType<
  typeof useGetAuctionsOnChainLazyQuery
>;
export type GetAuctionsOnChainQueryResult = Apollo.QueryResult<
  GetAuctionsOnChainQuery,
  GetAuctionsOnChainQueryVariables
>;
export const CancelAuctionDocument = gql`
  mutation CancelAuction($auctionId: String!) {
    cancelAuction(auctionId: $auctionId) {
      id
      state
      closedAt
    }
  }
`;
export type CancelAuctionMutationFn = Apollo.MutationFunction<
  CancelAuctionMutation,
  CancelAuctionMutationVariables
>;

/**
 * __useCancelAuctionMutation__
 *
 * To run a mutation, you first call `useCancelAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAuctionMutation, { data, loading, error }] = useCancelAuctionMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useCancelAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAuctionMutation,
    CancelAuctionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAuctionMutation,
    CancelAuctionMutationVariables
  >(CancelAuctionDocument, options);
}
export type CancelAuctionMutationHookResult = ReturnType<
  typeof useCancelAuctionMutation
>;
export type CancelAuctionMutationResult =
  Apollo.MutationResult<CancelAuctionMutation>;
export type CancelAuctionMutationOptions = Apollo.BaseMutationOptions<
  CancelAuctionMutation,
  CancelAuctionMutationVariables
>;
export const CloseAuctionDocument = gql`
  mutation CloseAuction(
    $auctionId: ID!
    $value: String!
    $buyerAddress: String!
    $closingHash: String!
  ) {
    closeAuction(
      auctionId: $auctionId
      value: $value
      buyerAddress: $buyerAddress
      closingHash: $closingHash
    ) {
      id
      auctionId
      buyer {
        address
        id
        profileHash
      }
      closedAt
      closingHash
      closingValue
      state
      owner {
        username
        profileHash
        name
        id
      }
      chain
      createdAt
      viewCount
      paymentMethod
      basePrice
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
    }
  }
`;
export type CloseAuctionMutationFn = Apollo.MutationFunction<
  CloseAuctionMutation,
  CloseAuctionMutationVariables
>;

/**
 * __useCloseAuctionMutation__
 *
 * To run a mutation, you first call `useCloseAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAuctionMutation, { data, loading, error }] = useCloseAuctionMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *      value: // value for 'value'
 *      buyerAddress: // value for 'buyerAddress'
 *      closingHash: // value for 'closingHash'
 *   },
 * });
 */
export function useCloseAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseAuctionMutation,
    CloseAuctionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseAuctionMutation,
    CloseAuctionMutationVariables
  >(CloseAuctionDocument, options);
}
export type CloseAuctionMutationHookResult = ReturnType<
  typeof useCloseAuctionMutation
>;
export type CloseAuctionMutationResult =
  Apollo.MutationResult<CloseAuctionMutation>;
export type CloseAuctionMutationOptions = Apollo.BaseMutationOptions<
  CloseAuctionMutation,
  CloseAuctionMutationVariables
>;
export const CreateAuctionDocument = gql`
  mutation CreateAuction($auctionInput: AuctionInput!) {
    createAuction(auctionInput: $auctionInput) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;
export type CreateAuctionMutationFn = Apollo.MutationFunction<
  CreateAuctionMutation,
  CreateAuctionMutationVariables
>;

/**
 * __useCreateAuctionMutation__
 *
 * To run a mutation, you first call `useCreateAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuctionMutation, { data, loading, error }] = useCreateAuctionMutation({
 *   variables: {
 *      auctionInput: // value for 'auctionInput'
 *   },
 * });
 */
export function useCreateAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAuctionMutation,
    CreateAuctionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAuctionMutation,
    CreateAuctionMutationVariables
  >(CreateAuctionDocument, options);
}
export type CreateAuctionMutationHookResult = ReturnType<
  typeof useCreateAuctionMutation
>;
export type CreateAuctionMutationResult =
  Apollo.MutationResult<CreateAuctionMutation>;
export type CreateAuctionMutationOptions = Apollo.BaseMutationOptions<
  CreateAuctionMutation,
  CreateAuctionMutationVariables
>;
export const CreateBidDocument = gql`
  mutation CreateBid($bidInput: BidInput!) {
    createBid(bidInput: $bidInput) {
      bidder {
        id
        address
      }
      id
      value
      trxHash
      state
      closedAt
      createdAt
    }
  }
`;
export type CreateBidMutationFn = Apollo.MutationFunction<
  CreateBidMutation,
  CreateBidMutationVariables
>;

/**
 * __useCreateBidMutation__
 *
 * To run a mutation, you first call `useCreateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBidMutation, { data, loading, error }] = useCreateBidMutation({
 *   variables: {
 *      bidInput: // value for 'bidInput'
 *   },
 * });
 */
export function useCreateBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBidMutation,
    CreateBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBidMutation, CreateBidMutationVariables>(
    CreateBidDocument,
    options
  );
}
export type CreateBidMutationHookResult = ReturnType<
  typeof useCreateBidMutation
>;
export type CreateBidMutationResult = Apollo.MutationResult<CreateBidMutation>;
export type CreateBidMutationOptions = Apollo.BaseMutationOptions<
  CreateBidMutation,
  CreateBidMutationVariables
>;
export const GenerateRefTokenDocument = gql`
  query GenerateRefToken($auctionId: ID!, $refId: String!) {
    generateRefToken(auctionID: $auctionId, refId: $refId)
  }
`;

/**
 * __useGenerateRefTokenQuery__
 *
 * To run a query within a React component, call `useGenerateRefTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRefTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRefTokenQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *      refId: // value for 'refId'
 *   },
 * });
 */
export function useGenerateRefTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateRefTokenQuery,
    GenerateRefTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateRefTokenQuery, GenerateRefTokenQueryVariables>(
    GenerateRefTokenDocument,
    options
  );
}
export function useGenerateRefTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateRefTokenQuery,
    GenerateRefTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateRefTokenQuery,
    GenerateRefTokenQueryVariables
  >(GenerateRefTokenDocument, options);
}
export type GenerateRefTokenQueryHookResult = ReturnType<
  typeof useGenerateRefTokenQuery
>;
export type GenerateRefTokenLazyQueryHookResult = ReturnType<
  typeof useGenerateRefTokenLazyQuery
>;
export type GenerateRefTokenQueryResult = Apollo.QueryResult<
  GenerateRefTokenQuery,
  GenerateRefTokenQueryVariables
>;
export const GetBidsDocument = gql`
  query GetBids($auctionId: ID!, $limit: Int!, $cursor: String) {
    getBids(auctionId: $auctionId, limit: $limit, cursor: $cursor) {
      bidder {
        id
        address
      }
      id
      value
      trxHash
      state
      closedAt
      createdAt
      auction {
        id
      }
    }
  }
`;

/**
 * __useGetBidsQuery__
 *
 * To run a query within a React component, call `useGetBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidsQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetBidsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBidsQuery, GetBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBidsQuery, GetBidsQueryVariables>(
    GetBidsDocument,
    options
  );
}
export function useGetBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBidsQuery, GetBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBidsQuery, GetBidsQueryVariables>(
    GetBidsDocument,
    options
  );
}
export type GetBidsQueryHookResult = ReturnType<typeof useGetBidsQuery>;
export type GetBidsLazyQueryHookResult = ReturnType<typeof useGetBidsLazyQuery>;
export type GetBidsQueryResult = Apollo.QueryResult<
  GetBidsQuery,
  GetBidsQueryVariables
>;
export const GetAuctionsIsClosedDocument = gql`
  query GetAuctionsIsClosed($limit: Int!, $cursor: String) {
    getAuctionsIsClosed(limit: $limit, cursor: $cursor) {
      id
      viewCount
      auctionId
      basePrice
      paymentMethod
      closingValue
      owner {
        username
        profileHash
        name
        id
      }
      nftToken {
        contractAddress
        tokenId
        id
        tokenUri
      }
      likeCount
      state
      chain
      createdAt
    }
  }
`;

/**
 * __useGetAuctionsIsClosedQuery__
 *
 * To run a query within a React component, call `useGetAuctionsIsClosedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionsIsClosedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionsIsClosedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAuctionsIsClosedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionsIsClosedQuery,
    GetAuctionsIsClosedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuctionsIsClosedQuery,
    GetAuctionsIsClosedQueryVariables
  >(GetAuctionsIsClosedDocument, options);
}
export function useGetAuctionsIsClosedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionsIsClosedQuery,
    GetAuctionsIsClosedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionsIsClosedQuery,
    GetAuctionsIsClosedQueryVariables
  >(GetAuctionsIsClosedDocument, options);
}
export type GetAuctionsIsClosedQueryHookResult = ReturnType<
  typeof useGetAuctionsIsClosedQuery
>;
export type GetAuctionsIsClosedLazyQueryHookResult = ReturnType<
  typeof useGetAuctionsIsClosedLazyQuery
>;
export type GetAuctionsIsClosedQueryResult = Apollo.QueryResult<
  GetAuctionsIsClosedQuery,
  GetAuctionsIsClosedQueryVariables
>;
export const GetBidDocument = gql`
  query GetBid($bidId: ID!) {
    getBid(id: $bidId) {
      bidder {
        id
        address
      }
      id
      value
      trxHash
      state
      closedAt
      createdAt
      auction {
        id
      }
    }
  }
`;

/**
 * __useGetBidQuery__
 *
 * To run a query within a React component, call `useGetBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidQuery({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useGetBidQuery(
  baseOptions: Apollo.QueryHookOptions<GetBidQuery, GetBidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBidQuery, GetBidQueryVariables>(
    GetBidDocument,
    options
  );
}
export function useGetBidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBidQuery, GetBidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBidQuery, GetBidQueryVariables>(
    GetBidDocument,
    options
  );
}
export type GetBidQueryHookResult = ReturnType<typeof useGetBidQuery>;
export type GetBidLazyQueryHookResult = ReturnType<typeof useGetBidLazyQuery>;
export type GetBidQueryResult = Apollo.QueryResult<
  GetBidQuery,
  GetBidQueryVariables
>;
export const UpdateAuctionLikesDocument = gql`
  mutation UpdateAuctionLikes(
    $updateAuctionLikesAuctionId: String!
    $updateAuctionLikesUserAddress: String!
  ) {
    updateAuctionLikes(
      auctionId: $updateAuctionLikesAuctionId
      userAddress: $updateAuctionLikesUserAddress
    ) {
      id
      auctionId
      likeCount
      likedBy {
        id
        address
        profileHash
        name
      }
      viewCount
    }
  }
`;
export type UpdateAuctionLikesMutationFn = Apollo.MutationFunction<
  UpdateAuctionLikesMutation,
  UpdateAuctionLikesMutationVariables
>;

/**
 * __useUpdateAuctionLikesMutation__
 *
 * To run a mutation, you first call `useUpdateAuctionLikesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuctionLikesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuctionLikesMutation, { data, loading, error }] = useUpdateAuctionLikesMutation({
 *   variables: {
 *      updateAuctionLikesAuctionId: // value for 'updateAuctionLikesAuctionId'
 *      updateAuctionLikesUserAddress: // value for 'updateAuctionLikesUserAddress'
 *   },
 * });
 */
export function useUpdateAuctionLikesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuctionLikesMutation,
    UpdateAuctionLikesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAuctionLikesMutation,
    UpdateAuctionLikesMutationVariables
  >(UpdateAuctionLikesDocument, options);
}
export type UpdateAuctionLikesMutationHookResult = ReturnType<
  typeof useUpdateAuctionLikesMutation
>;
export type UpdateAuctionLikesMutationResult =
  Apollo.MutationResult<UpdateAuctionLikesMutation>;
export type UpdateAuctionLikesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuctionLikesMutation,
  UpdateAuctionLikesMutationVariables
>;
export const UpdateAuctionShareHashDocument = gql`
  mutation UpdateAuctionShareHash($refToken: String!, $guestUserId: ID!) {
    updateAuctionSharedHash(refToken: $refToken, guestUserID: $guestUserId) {
      id
      auctionId
    }
  }
`;
export type UpdateAuctionShareHashMutationFn = Apollo.MutationFunction<
  UpdateAuctionShareHashMutation,
  UpdateAuctionShareHashMutationVariables
>;

/**
 * __useUpdateAuctionShareHashMutation__
 *
 * To run a mutation, you first call `useUpdateAuctionShareHashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuctionShareHashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuctionShareHashMutation, { data, loading, error }] = useUpdateAuctionShareHashMutation({
 *   variables: {
 *      refToken: // value for 'refToken'
 *      guestUserId: // value for 'guestUserId'
 *   },
 * });
 */
export function useUpdateAuctionShareHashMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuctionShareHashMutation,
    UpdateAuctionShareHashMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAuctionShareHashMutation,
    UpdateAuctionShareHashMutationVariables
  >(UpdateAuctionShareHashDocument, options);
}
export type UpdateAuctionShareHashMutationHookResult = ReturnType<
  typeof useUpdateAuctionShareHashMutation
>;
export type UpdateAuctionShareHashMutationResult =
  Apollo.MutationResult<UpdateAuctionShareHashMutation>;
export type UpdateAuctionShareHashMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuctionShareHashMutation,
  UpdateAuctionShareHashMutationVariables
>;
export const UpdateAuctionViewCountDocument = gql`
  mutation UpdateAuctionViewCount($auctionId: String!) {
    updateAuctionViewCount(auctionId: $auctionId) {
      auctionId
      id
      viewCount
    }
  }
`;
export type UpdateAuctionViewCountMutationFn = Apollo.MutationFunction<
  UpdateAuctionViewCountMutation,
  UpdateAuctionViewCountMutationVariables
>;

/**
 * __useUpdateAuctionViewCountMutation__
 *
 * To run a mutation, you first call `useUpdateAuctionViewCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuctionViewCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuctionViewCountMutation, { data, loading, error }] = useUpdateAuctionViewCountMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useUpdateAuctionViewCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuctionViewCountMutation,
    UpdateAuctionViewCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAuctionViewCountMutation,
    UpdateAuctionViewCountMutationVariables
  >(UpdateAuctionViewCountDocument, options);
}
export type UpdateAuctionViewCountMutationHookResult = ReturnType<
  typeof useUpdateAuctionViewCountMutation
>;
export type UpdateAuctionViewCountMutationResult =
  Apollo.MutationResult<UpdateAuctionViewCountMutation>;
export type UpdateAuctionViewCountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuctionViewCountMutation,
  UpdateAuctionViewCountMutationVariables
>;
export const UpdatePayToDocument = gql`
  mutation UpdatePayTo(
    $auctionId: String!
    $toAddrs: [String!]!
    $percents: [String!]!
  ) {
    updatePayTo(auctionId: $auctionId, toAddrs: $toAddrs, percents: $percents) {
      id
    }
  }
`;
export type UpdatePayToMutationFn = Apollo.MutationFunction<
  UpdatePayToMutation,
  UpdatePayToMutationVariables
>;

/**
 * __useUpdatePayToMutation__
 *
 * To run a mutation, you first call `useUpdatePayToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayToMutation, { data, loading, error }] = useUpdatePayToMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *      toAddrs: // value for 'toAddrs'
 *      percents: // value for 'percents'
 *   },
 * });
 */
export function useUpdatePayToMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayToMutation,
    UpdatePayToMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePayToMutation, UpdatePayToMutationVariables>(
    UpdatePayToDocument,
    options
  );
}
export type UpdatePayToMutationHookResult = ReturnType<
  typeof useUpdatePayToMutation
>;
export type UpdatePayToMutationResult =
  Apollo.MutationResult<UpdatePayToMutation>;
export type UpdatePayToMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayToMutation,
  UpdatePayToMutationVariables
>;
export const CreateNftMutationDocument = gql`
  mutation CreateNFTMutation($nftTokenInput: NftTokenInput!) {
    createNFTToken(nftTokenInput: $nftTokenInput) {
      id
      contractAddress
      tokenId
      tokenUri
      chain
    }
  }
`;
export type CreateNftMutationMutationFn = Apollo.MutationFunction<
  CreateNftMutationMutation,
  CreateNftMutationMutationVariables
>;

/**
 * __useCreateNftMutationMutation__
 *
 * To run a mutation, you first call `useCreateNftMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNftMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNftMutationMutation, { data, loading, error }] = useCreateNftMutationMutation({
 *   variables: {
 *      nftTokenInput: // value for 'nftTokenInput'
 *   },
 * });
 */
export function useCreateNftMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNftMutationMutation,
    CreateNftMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNftMutationMutation,
    CreateNftMutationMutationVariables
  >(CreateNftMutationDocument, options);
}
export type CreateNftMutationMutationHookResult = ReturnType<
  typeof useCreateNftMutationMutation
>;
export type CreateNftMutationMutationResult =
  Apollo.MutationResult<CreateNftMutationMutation>;
export type CreateNftMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateNftMutationMutation,
  CreateNftMutationMutationVariables
>;
export const GetNftByAddressAndTokenIdDocument = gql`
  query GetNftByAddressAndTokenId($address: String!, $tokenId: String!) {
    getNftByAddressAndTokenId(address: $address, tokenId: $tokenId) {
      id
      contractAddress
      tokenId
      tokenUri
      history {
        id
      }
      minter {
        address
        addresses
        profileHash
        username
        name
        id
      }
    }
  }
`;

/**
 * __useGetNftByAddressAndTokenIdQuery__
 *
 * To run a query within a React component, call `useGetNftByAddressAndTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftByAddressAndTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftByAddressAndTokenIdQuery({
 *   variables: {
 *      address: // value for 'address'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetNftByAddressAndTokenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNftByAddressAndTokenIdQuery,
    GetNftByAddressAndTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNftByAddressAndTokenIdQuery,
    GetNftByAddressAndTokenIdQueryVariables
  >(GetNftByAddressAndTokenIdDocument, options);
}
export function useGetNftByAddressAndTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNftByAddressAndTokenIdQuery,
    GetNftByAddressAndTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNftByAddressAndTokenIdQuery,
    GetNftByAddressAndTokenIdQueryVariables
  >(GetNftByAddressAndTokenIdDocument, options);
}
export type GetNftByAddressAndTokenIdQueryHookResult = ReturnType<
  typeof useGetNftByAddressAndTokenIdQuery
>;
export type GetNftByAddressAndTokenIdLazyQueryHookResult = ReturnType<
  typeof useGetNftByAddressAndTokenIdLazyQuery
>;
export type GetNftByAddressAndTokenIdQueryResult = Apollo.QueryResult<
  GetNftByAddressAndTokenIdQuery,
  GetNftByAddressAndTokenIdQueryVariables
>;
export const GetNftHistoriesByTokenAddressAndTokenIdDocument = gql`
  query GetNftHistoriesByTokenAddressAndTokenId(
    $address: String!
    $tokenId: String!
    $limit: Int!
    $cursor: String
  ) {
    getNftHistoriesByTokenAddressAndTokenId(
      address: $address
      tokenId: $tokenId
      limit: $limit
      cursor: $cursor
    ) {
      id
      buyer {
        address
        id
        profileHash
      }
      seller {
        address
        id
        profileHash
      }
      value
      paymentMethod
      time
      nftToken {
        contractAddress
        id
        tokenId
        tokenUri
      }
      auction {
        closedAt
        id
        closingHash
        buyer {
          address
          id
          profileHash
        }
      }
    }
  }
`;

/**
 * __useGetNftHistoriesByTokenAddressAndTokenIdQuery__
 *
 * To run a query within a React component, call `useGetNftHistoriesByTokenAddressAndTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftHistoriesByTokenAddressAndTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftHistoriesByTokenAddressAndTokenIdQuery({
 *   variables: {
 *      address: // value for 'address'
 *      tokenId: // value for 'tokenId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNftHistoriesByTokenAddressAndTokenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNftHistoriesByTokenAddressAndTokenIdQuery,
    GetNftHistoriesByTokenAddressAndTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNftHistoriesByTokenAddressAndTokenIdQuery,
    GetNftHistoriesByTokenAddressAndTokenIdQueryVariables
  >(GetNftHistoriesByTokenAddressAndTokenIdDocument, options);
}
export function useGetNftHistoriesByTokenAddressAndTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNftHistoriesByTokenAddressAndTokenIdQuery,
    GetNftHistoriesByTokenAddressAndTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNftHistoriesByTokenAddressAndTokenIdQuery,
    GetNftHistoriesByTokenAddressAndTokenIdQueryVariables
  >(GetNftHistoriesByTokenAddressAndTokenIdDocument, options);
}
export type GetNftHistoriesByTokenAddressAndTokenIdQueryHookResult = ReturnType<
  typeof useGetNftHistoriesByTokenAddressAndTokenIdQuery
>;
export type GetNftHistoriesByTokenAddressAndTokenIdLazyQueryHookResult =
  ReturnType<typeof useGetNftHistoriesByTokenAddressAndTokenIdLazyQuery>;
export type GetNftHistoriesByTokenAddressAndTokenIdQueryResult =
  Apollo.QueryResult<
    GetNftHistoriesByTokenAddressAndTokenIdQuery,
    GetNftHistoriesByTokenAddressAndTokenIdQueryVariables
  >;
export const GetHistoriesByNftTokenIdDocument = gql`
  query GetHistoriesByNftTokenId($nftId: ID!, $limit: Int!, $cursor: String) {
    getHistoriesByNftTokenId(nftId: $nftId, limit: $limit, cursor: $cursor) {
      id
      buyer {
        address
        id
        profileHash
      }
      seller {
        address
        id
        profileHash
      }
      value
      paymentMethod
      time
      nftToken {
        contractAddress
        id
        tokenId
        tokenUri
      }
      auction {
        closedAt
        id
        closingHash
        buyer {
          address
          id
          profileHash
        }
      }
    }
  }
`;

/**
 * __useGetHistoriesByNftTokenIdQuery__
 *
 * To run a query within a React component, call `useGetHistoriesByNftTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoriesByNftTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoriesByNftTokenIdQuery({
 *   variables: {
 *      nftId: // value for 'nftId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetHistoriesByNftTokenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHistoriesByNftTokenIdQuery,
    GetHistoriesByNftTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHistoriesByNftTokenIdQuery,
    GetHistoriesByNftTokenIdQueryVariables
  >(GetHistoriesByNftTokenIdDocument, options);
}
export function useGetHistoriesByNftTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHistoriesByNftTokenIdQuery,
    GetHistoriesByNftTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHistoriesByNftTokenIdQuery,
    GetHistoriesByNftTokenIdQueryVariables
  >(GetHistoriesByNftTokenIdDocument, options);
}
export type GetHistoriesByNftTokenIdQueryHookResult = ReturnType<
  typeof useGetHistoriesByNftTokenIdQuery
>;
export type GetHistoriesByNftTokenIdLazyQueryHookResult = ReturnType<
  typeof useGetHistoriesByNftTokenIdLazyQuery
>;
export type GetHistoriesByNftTokenIdQueryResult = Apollo.QueryResult<
  GetHistoriesByNftTokenIdQuery,
  GetHistoriesByNftTokenIdQueryVariables
>;
export const NfTsDocument = gql`
  query NFTs($limit: Int!, $cursor: String) {
    getNfts(limit: $limit, cursor: $cursor) {
      id
      contractAddress
      tokenId
      tokenUri
      minter {
        address
        id
      }
      history {
        buyer {
          address
          id
          profileHash
        }
        value
        seller {
          address
          id
          profileHash
        }
      }
    }
  }
`;

/**
 * __useNfTsQuery__
 *
 * To run a query within a React component, call `useNfTsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNfTsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNfTsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useNfTsQuery(
  baseOptions: Apollo.QueryHookOptions<NfTsQuery, NfTsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NfTsQuery, NfTsQueryVariables>(NfTsDocument, options);
}
export function useNfTsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NfTsQuery, NfTsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NfTsQuery, NfTsQueryVariables>(
    NfTsDocument,
    options
  );
}
export type NfTsQueryHookResult = ReturnType<typeof useNfTsQuery>;
export type NfTsLazyQueryHookResult = ReturnType<typeof useNfTsLazyQuery>;
export type NfTsQueryResult = Apollo.QueryResult<NfTsQuery, NfTsQueryVariables>;
export const AuctionUserDocument = gql`
  query AuctionUser($address: String!) {
    getUserByAddress(address: $address) {
      id
      name
      username
      address
      addresses
      profileHash
    }
  }
`;

/**
 * __useAuctionUserQuery__
 *
 * To run a query within a React component, call `useAuctionUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionUserQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAuctionUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuctionUserQuery,
    AuctionUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionUserQuery, AuctionUserQueryVariables>(
    AuctionUserDocument,
    options
  );
}
export function useAuctionUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuctionUserQuery,
    AuctionUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionUserQuery, AuctionUserQueryVariables>(
    AuctionUserDocument,
    options
  );
}
export type AuctionUserQueryHookResult = ReturnType<typeof useAuctionUserQuery>;
export type AuctionUserLazyQueryHookResult = ReturnType<
  typeof useAuctionUserLazyQuery
>;
export type AuctionUserQueryResult = Apollo.QueryResult<
  AuctionUserQuery,
  AuctionUserQueryVariables
>;
export const CreateEarlyAdopterDocument = gql`
  mutation CreateEarlyAdopter(
    $address: String!
    $signature: String!
    $chain: Blockchain!
  ) {
    createEarlyAdopter(
      address: $address
      signature: $signature
      chain: $chain
    ) {
      id
      address
      signature
      chain
    }
  }
`;
export type CreateEarlyAdopterMutationFn = Apollo.MutationFunction<
  CreateEarlyAdopterMutation,
  CreateEarlyAdopterMutationVariables
>;

/**
 * __useCreateEarlyAdopterMutation__
 *
 * To run a mutation, you first call `useCreateEarlyAdopterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEarlyAdopterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEarlyAdopterMutation, { data, loading, error }] = useCreateEarlyAdopterMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useCreateEarlyAdopterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEarlyAdopterMutation,
    CreateEarlyAdopterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEarlyAdopterMutation,
    CreateEarlyAdopterMutationVariables
  >(CreateEarlyAdopterDocument, options);
}
export type CreateEarlyAdopterMutationHookResult = ReturnType<
  typeof useCreateEarlyAdopterMutation
>;
export type CreateEarlyAdopterMutationResult =
  Apollo.MutationResult<CreateEarlyAdopterMutation>;
export type CreateEarlyAdopterMutationOptions = Apollo.BaseMutationOptions<
  CreateEarlyAdopterMutation,
  CreateEarlyAdopterMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($address: String!) {
    createUser(address: $address) {
      name
      followers {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        contractAddress
      }
      userType
      refEarned {
        auction {
          auctionId
        }
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const GetCreatorsDocument = gql`
  query GetCreators($limit: Int!, $cursor: String) {
    getCreators(limit: $limit, cursor: $cursor) {
      address
      id
      profileHash
      name
      username
      email
      memberSince
    }
  }
`;

/**
 * __useGetCreatorsQuery__
 *
 * To run a query within a React component, call `useGetCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetCreatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreatorsQuery,
    GetCreatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCreatorsQuery, GetCreatorsQueryVariables>(
    GetCreatorsDocument,
    options
  );
}
export function useGetCreatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreatorsQuery,
    GetCreatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCreatorsQuery, GetCreatorsQueryVariables>(
    GetCreatorsDocument,
    options
  );
}
export type GetCreatorsQueryHookResult = ReturnType<typeof useGetCreatorsQuery>;
export type GetCreatorsLazyQueryHookResult = ReturnType<
  typeof useGetCreatorsLazyQuery
>;
export type GetCreatorsQueryResult = Apollo.QueryResult<
  GetCreatorsQuery,
  GetCreatorsQueryVariables
>;
export const GetEarlyAdopterDocument = gql`
  query GetEarlyAdopter($address: String!) {
    getEarlyAdopter(address: $address) {
      address
      signature
      id
    }
  }
`;

/**
 * __useGetEarlyAdopterQuery__
 *
 * To run a query within a React component, call `useGetEarlyAdopterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarlyAdopterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarlyAdopterQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGetEarlyAdopterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEarlyAdopterQuery,
    GetEarlyAdopterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEarlyAdopterQuery, GetEarlyAdopterQueryVariables>(
    GetEarlyAdopterDocument,
    options
  );
}
export function useGetEarlyAdopterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEarlyAdopterQuery,
    GetEarlyAdopterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEarlyAdopterQuery,
    GetEarlyAdopterQueryVariables
  >(GetEarlyAdopterDocument, options);
}
export type GetEarlyAdopterQueryHookResult = ReturnType<
  typeof useGetEarlyAdopterQuery
>;
export type GetEarlyAdopterLazyQueryHookResult = ReturnType<
  typeof useGetEarlyAdopterLazyQuery
>;
export type GetEarlyAdopterQueryResult = Apollo.QueryResult<
  GetEarlyAdopterQuery,
  GetEarlyAdopterQueryVariables
>;
export const GetUserEarningsDocument = gql`
  query GetUserEarnings(
    $getUserEarningsId: ID!
    $limit: Int!
    $cursor: String
  ) {
    getUserEarnings(id: $getUserEarningsId, limit: $limit, cursor: $cursor) {
      id
      value
      to {
        address
        id
      }
      auction {
        auctionId
        id
        basePrice
      }
      type
      timestamp
      trxHash
      status
    }
  }
`;

/**
 * __useGetUserEarningsQuery__
 *
 * To run a query within a React component, call `useGetUserEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEarningsQuery({
 *   variables: {
 *      getUserEarningsId: // value for 'getUserEarningsId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserEarningsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEarningsQuery,
    GetUserEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserEarningsQuery, GetUserEarningsQueryVariables>(
    GetUserEarningsDocument,
    options
  );
}
export function useGetUserEarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEarningsQuery,
    GetUserEarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserEarningsQuery,
    GetUserEarningsQueryVariables
  >(GetUserEarningsDocument, options);
}
export type GetUserEarningsQueryHookResult = ReturnType<
  typeof useGetUserEarningsQuery
>;
export type GetUserEarningsLazyQueryHookResult = ReturnType<
  typeof useGetUserEarningsLazyQuery
>;
export type GetUserEarningsQueryResult = Apollo.QueryResult<
  GetUserEarningsQuery,
  GetUserEarningsQueryVariables
>;
export const GetRequestDocument = gql`
  query GetRequest($requestId: ID!) {
    getRequest(id: $requestId) {
      to {
        address
        id
        profileHash
        name
        username
      }
      from {
        address
        id
        profileHash
        name
        username
      }
      id
      createdAt
      status
    }
  }
`;

/**
 * __useGetRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequestQuery,
    GetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export function useGetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestQuery,
    GetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export type GetRequestQueryHookResult = ReturnType<typeof useGetRequestQuery>;
export type GetRequestLazyQueryHookResult = ReturnType<
  typeof useGetRequestLazyQuery
>;
export type GetRequestQueryResult = Apollo.QueryResult<
  GetRequestQuery,
  GetRequestQueryVariables
>;
export const GetRoyaltiesByUserIdqueryDocument = gql`
  query GetRoyaltiesByUserIdquery($userId: ID!, $limit: Int!, $cursor: String) {
    getRoyaltiesByUserId(userId: $userId, limit: $limit, cursor: $cursor) {
      id
      valuePaid
      paymentMethod
      auction {
        auctionId
        id
      }
      royaltyTo {
        address
        profileHash
        username
      }
    }
  }
`;

/**
 * __useGetRoyaltiesByUserIdqueryQuery__
 *
 * To run a query within a React component, call `useGetRoyaltiesByUserIdqueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoyaltiesByUserIdqueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoyaltiesByUserIdqueryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetRoyaltiesByUserIdqueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoyaltiesByUserIdqueryQuery,
    GetRoyaltiesByUserIdqueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoyaltiesByUserIdqueryQuery,
    GetRoyaltiesByUserIdqueryQueryVariables
  >(GetRoyaltiesByUserIdqueryDocument, options);
}
export function useGetRoyaltiesByUserIdqueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoyaltiesByUserIdqueryQuery,
    GetRoyaltiesByUserIdqueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoyaltiesByUserIdqueryQuery,
    GetRoyaltiesByUserIdqueryQueryVariables
  >(GetRoyaltiesByUserIdqueryDocument, options);
}
export type GetRoyaltiesByUserIdqueryQueryHookResult = ReturnType<
  typeof useGetRoyaltiesByUserIdqueryQuery
>;
export type GetRoyaltiesByUserIdqueryLazyQueryHookResult = ReturnType<
  typeof useGetRoyaltiesByUserIdqueryLazyQuery
>;
export type GetRoyaltiesByUserIdqueryQueryResult = Apollo.QueryResult<
  GetRoyaltiesByUserIdqueryQuery,
  GetRoyaltiesByUserIdqueryQueryVariables
>;
export const GetTopCreatorsDocument = gql`
  query GetTopCreators($limit: Int!, $mintedCount: Int, $followersCount: Int) {
    getTopCreators(
      limit: $limit
      mintedCount: $mintedCount
      followersCount: $followersCount
    ) {
      address
      id
      profileHash
      name
      username
      email
      memberSince
      followersCount
      mintedCount
    }
  }
`;

/**
 * __useGetTopCreatorsQuery__
 *
 * To run a query within a React component, call `useGetTopCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopCreatorsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      mintedCount: // value for 'mintedCount'
 *      followersCount: // value for 'followersCount'
 *   },
 * });
 */
export function useGetTopCreatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTopCreatorsQuery,
    GetTopCreatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopCreatorsQuery, GetTopCreatorsQueryVariables>(
    GetTopCreatorsDocument,
    options
  );
}
export function useGetTopCreatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopCreatorsQuery,
    GetTopCreatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopCreatorsQuery, GetTopCreatorsQueryVariables>(
    GetTopCreatorsDocument,
    options
  );
}
export type GetTopCreatorsQueryHookResult = ReturnType<
  typeof useGetTopCreatorsQuery
>;
export type GetTopCreatorsLazyQueryHookResult = ReturnType<
  typeof useGetTopCreatorsLazyQuery
>;
export type GetTopCreatorsQueryResult = Apollo.QueryResult<
  GetTopCreatorsQuery,
  GetTopCreatorsQueryVariables
>;
export const GetUserAuctionByAuctionIdDocument = gql`
  query GetUserAuctionByAuctionId(
    $getUserAuctionsByAuctionIdId: ID!
    $limit: Int!
    $cursor: String
  ) {
    getUserAuctionsByAuctionId(
      id: $getUserAuctionsByAuctionIdId
      limit: $limit
      cursor: $cursor
    ) {
      id
      bids {
        id
        value
        state
      }
      auctionId
      closingValue
      likeCount
      viewCount
      chain
      state
      basePrice
      paymentMethod
      nftToken {
        contractAddress
        id
        tokenId
        tokenUri
      }
    }
  }
`;

/**
 * __useGetUserAuctionByAuctionIdQuery__
 *
 * To run a query within a React component, call `useGetUserAuctionByAuctionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuctionByAuctionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuctionByAuctionIdQuery({
 *   variables: {
 *      getUserAuctionsByAuctionIdId: // value for 'getUserAuctionsByAuctionIdId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserAuctionByAuctionIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAuctionByAuctionIdQuery,
    GetUserAuctionByAuctionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAuctionByAuctionIdQuery,
    GetUserAuctionByAuctionIdQueryVariables
  >(GetUserAuctionByAuctionIdDocument, options);
}
export function useGetUserAuctionByAuctionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuctionByAuctionIdQuery,
    GetUserAuctionByAuctionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuctionByAuctionIdQuery,
    GetUserAuctionByAuctionIdQueryVariables
  >(GetUserAuctionByAuctionIdDocument, options);
}
export type GetUserAuctionByAuctionIdQueryHookResult = ReturnType<
  typeof useGetUserAuctionByAuctionIdQuery
>;
export type GetUserAuctionByAuctionIdLazyQueryHookResult = ReturnType<
  typeof useGetUserAuctionByAuctionIdLazyQuery
>;
export type GetUserAuctionByAuctionIdQueryResult = Apollo.QueryResult<
  GetUserAuctionByAuctionIdQuery,
  GetUserAuctionByAuctionIdQueryVariables
>;
export const GetUserAuctionCollectedDocument = gql`
  query GetUserAuctionCollected($userId: ID!, $limit: Int!, $cursor: String) {
    getUserAuctionsCollected(id: $userId, limit: $limit, cursor: $cursor) {
      id
      paymentMethod
      closingValue
      closedAt
      trxHash
      nftToken {
        contractAddress
        tokenUri
        tokenId
        standard
      }
      likeCount
      viewCount
      basePrice
      state
      auctionId
      owner {
        address
        id
        name
        profileHash
      }
      buyer {
        address
        id
      }
    }
  }
`;

/**
 * __useGetUserAuctionCollectedQuery__
 *
 * To run a query within a React component, call `useGetUserAuctionCollectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuctionCollectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuctionCollectedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserAuctionCollectedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAuctionCollectedQuery,
    GetUserAuctionCollectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAuctionCollectedQuery,
    GetUserAuctionCollectedQueryVariables
  >(GetUserAuctionCollectedDocument, options);
}
export function useGetUserAuctionCollectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuctionCollectedQuery,
    GetUserAuctionCollectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuctionCollectedQuery,
    GetUserAuctionCollectedQueryVariables
  >(GetUserAuctionCollectedDocument, options);
}
export type GetUserAuctionCollectedQueryHookResult = ReturnType<
  typeof useGetUserAuctionCollectedQuery
>;
export type GetUserAuctionCollectedLazyQueryHookResult = ReturnType<
  typeof useGetUserAuctionCollectedLazyQuery
>;
export type GetUserAuctionCollectedQueryResult = Apollo.QueryResult<
  GetUserAuctionCollectedQuery,
  GetUserAuctionCollectedQueryVariables
>;
export const GetUserAuctionsOnsaleDocument = gql`
  query GetUserAuctionsOnsale($userId: ID!, $limit: Int!, $cursor: String) {
    getUserAuctionsOnsale(id: $userId, limit: $limit, cursor: $cursor) {
      id
      paymentMethod
      closingValue
      basePrice
      closedAt
      trxHash
      nftToken {
        contractAddress
        tokenUri
        tokenId
        standard
      }
      likeCount
      viewCount
      state
      auctionId
      owner {
        address
        id
        name
        profileHash
      }
    }
  }
`;

/**
 * __useGetUserAuctionsOnsaleQuery__
 *
 * To run a query within a React component, call `useGetUserAuctionsOnsaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuctionsOnsaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuctionsOnsaleQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserAuctionsOnsaleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAuctionsOnsaleQuery,
    GetUserAuctionsOnsaleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAuctionsOnsaleQuery,
    GetUserAuctionsOnsaleQueryVariables
  >(GetUserAuctionsOnsaleDocument, options);
}
export function useGetUserAuctionsOnsaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuctionsOnsaleQuery,
    GetUserAuctionsOnsaleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuctionsOnsaleQuery,
    GetUserAuctionsOnsaleQueryVariables
  >(GetUserAuctionsOnsaleDocument, options);
}
export type GetUserAuctionsOnsaleQueryHookResult = ReturnType<
  typeof useGetUserAuctionsOnsaleQuery
>;
export type GetUserAuctionsOnsaleLazyQueryHookResult = ReturnType<
  typeof useGetUserAuctionsOnsaleLazyQuery
>;
export type GetUserAuctionsOnsaleQueryResult = Apollo.QueryResult<
  GetUserAuctionsOnsaleQuery,
  GetUserAuctionsOnsaleQueryVariables
>;
export const GetUserBidsDocument = gql`
  query GetUserBids($address: String!, $limit: Int!, $cursor: String) {
    getUserBids(address: $address, limit: $limit, cursor: $cursor) {
      id
      value
      auction {
        auctionId
        id
      }
      bidder {
        address
        id
      }
      trxHash
      createdAt
      state
      closedAt
    }
  }
`;

/**
 * __useGetUserBidsQuery__
 *
 * To run a query within a React component, call `useGetUserBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBidsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserBidsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserBidsQuery,
    GetUserBidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserBidsQuery, GetUserBidsQueryVariables>(
    GetUserBidsDocument,
    options
  );
}
export function useGetUserBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBidsQuery,
    GetUserBidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserBidsQuery, GetUserBidsQueryVariables>(
    GetUserBidsDocument,
    options
  );
}
export type GetUserBidsQueryHookResult = ReturnType<typeof useGetUserBidsQuery>;
export type GetUserBidsLazyQueryHookResult = ReturnType<
  typeof useGetUserBidsLazyQuery
>;
export type GetUserBidsQueryResult = Apollo.QueryResult<
  GetUserBidsQuery,
  GetUserBidsQueryVariables
>;
export const GetUserCreatedAuctionsDocument = gql`
  query GetUserCreatedAuctions($userId: ID!, $limit: Int!, $cursor: String) {
    getUserCreatedAuctions(id: $userId, limit: $limit, cursor: $cursor) {
      id
      paymentMethod
      closingValue
      basePrice
      closedAt
      trxHash
      nftToken {
        contractAddress
        tokenUri
        tokenId
        standard
      }
      likeCount
      viewCount
      state
      auctionId
      owner {
        address
        id
        name
        profileHash
      }
    }
  }
`;

/**
 * __useGetUserCreatedAuctionsQuery__
 *
 * To run a query within a React component, call `useGetUserCreatedAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreatedAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreatedAuctionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserCreatedAuctionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCreatedAuctionsQuery,
    GetUserCreatedAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCreatedAuctionsQuery,
    GetUserCreatedAuctionsQueryVariables
  >(GetUserCreatedAuctionsDocument, options);
}
export function useGetUserCreatedAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCreatedAuctionsQuery,
    GetUserCreatedAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCreatedAuctionsQuery,
    GetUserCreatedAuctionsQueryVariables
  >(GetUserCreatedAuctionsDocument, options);
}
export type GetUserCreatedAuctionsQueryHookResult = ReturnType<
  typeof useGetUserCreatedAuctionsQuery
>;
export type GetUserCreatedAuctionsLazyQueryHookResult = ReturnType<
  typeof useGetUserCreatedAuctionsLazyQuery
>;
export type GetUserCreatedAuctionsQueryResult = Apollo.QueryResult<
  GetUserCreatedAuctionsQuery,
  GetUserCreatedAuctionsQueryVariables
>;
export const GetUserCreatedNftsDocument = gql`
  query GetUserCreatedNfts($userId: ID!, $limit: Int!) {
    getUserCreatedNfts(id: $userId, limit: $limit) {
      id
      contractAddress
      minter {
        address
        id
      }
      tokenId
      tokenUri
      history {
        id
        value
      }
      burned
      standard
    }
  }
`;

/**
 * __useGetUserCreatedNftsQuery__
 *
 * To run a query within a React component, call `useGetUserCreatedNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreatedNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreatedNftsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserCreatedNftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCreatedNftsQuery,
    GetUserCreatedNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCreatedNftsQuery,
    GetUserCreatedNftsQueryVariables
  >(GetUserCreatedNftsDocument, options);
}
export function useGetUserCreatedNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCreatedNftsQuery,
    GetUserCreatedNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCreatedNftsQuery,
    GetUserCreatedNftsQueryVariables
  >(GetUserCreatedNftsDocument, options);
}
export type GetUserCreatedNftsQueryHookResult = ReturnType<
  typeof useGetUserCreatedNftsQuery
>;
export type GetUserCreatedNftsLazyQueryHookResult = ReturnType<
  typeof useGetUserCreatedNftsLazyQuery
>;
export type GetUserCreatedNftsQueryResult = Apollo.QueryResult<
  GetUserCreatedNftsQuery,
  GetUserCreatedNftsQueryVariables
>;
export const GetUserFollowersDocument = gql`
  query GetUserFollowers($userId: ID!, $limit: Int!, $cursor: String) {
    getUserFollowers(userId: $userId, limit: $limit, cursor: $cursor) {
      id
      name
      address
      profileHash
      username
      userType
      followersCount
      followingCount
    }
  }
`;

/**
 * __useGetUserFollowersQuery__
 *
 * To run a query within a React component, call `useGetUserFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFollowersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserFollowersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFollowersQuery,
    GetUserFollowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserFollowersQuery, GetUserFollowersQueryVariables>(
    GetUserFollowersDocument,
    options
  );
}
export function useGetUserFollowersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFollowersQuery,
    GetUserFollowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserFollowersQuery,
    GetUserFollowersQueryVariables
  >(GetUserFollowersDocument, options);
}
export type GetUserFollowersQueryHookResult = ReturnType<
  typeof useGetUserFollowersQuery
>;
export type GetUserFollowersLazyQueryHookResult = ReturnType<
  typeof useGetUserFollowersLazyQuery
>;
export type GetUserFollowersQueryResult = Apollo.QueryResult<
  GetUserFollowersQuery,
  GetUserFollowersQueryVariables
>;
export const GetUserFollowingDocument = gql`
  query GetUserFollowing($userId: ID!, $limit: Int!, $cursor: String) {
    getUserFollowing(userId: $userId, limit: $limit, cursor: $cursor) {
      id
      name
      address
      profileHash
      username
      userType
      followersCount
      followingCount
    }
  }
`;

/**
 * __useGetUserFollowingQuery__
 *
 * To run a query within a React component, call `useGetUserFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFollowingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserFollowingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFollowingQuery,
    GetUserFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserFollowingQuery, GetUserFollowingQueryVariables>(
    GetUserFollowingDocument,
    options
  );
}
export function useGetUserFollowingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFollowingQuery,
    GetUserFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserFollowingQuery,
    GetUserFollowingQueryVariables
  >(GetUserFollowingDocument, options);
}
export type GetUserFollowingQueryHookResult = ReturnType<
  typeof useGetUserFollowingQuery
>;
export type GetUserFollowingLazyQueryHookResult = ReturnType<
  typeof useGetUserFollowingLazyQuery
>;
export type GetUserFollowingQueryResult = Apollo.QueryResult<
  GetUserFollowingQuery,
  GetUserFollowingQueryVariables
>;
export const CheckIsFollowingDocument = gql`
  query CheckIsFollowing($currentUserId: ID!, $viewUserId: ID!) {
    checkFollowing(currentUserId: $currentUserId, viewUserId: $viewUserId)
  }
`;

/**
 * __useCheckIsFollowingQuery__
 *
 * To run a query within a React component, call `useCheckIsFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsFollowingQuery({
 *   variables: {
 *      currentUserId: // value for 'currentUserId'
 *      viewUserId: // value for 'viewUserId'
 *   },
 * });
 */
export function useCheckIsFollowingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckIsFollowingQuery,
    CheckIsFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckIsFollowingQuery, CheckIsFollowingQueryVariables>(
    CheckIsFollowingDocument,
    options
  );
}
export function useCheckIsFollowingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckIsFollowingQuery,
    CheckIsFollowingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckIsFollowingQuery,
    CheckIsFollowingQueryVariables
  >(CheckIsFollowingDocument, options);
}
export type CheckIsFollowingQueryHookResult = ReturnType<
  typeof useCheckIsFollowingQuery
>;
export type CheckIsFollowingLazyQueryHookResult = ReturnType<
  typeof useCheckIsFollowingLazyQuery
>;
export type CheckIsFollowingQueryResult = Apollo.QueryResult<
  CheckIsFollowingQuery,
  CheckIsFollowingQueryVariables
>;
export const GetNotificationDocument = gql`
  query GetNotification($userId: ID!, $limit: Int!, $cursor: String) {
    getNotifications(userId: $userId, limit: $limit, cursor: $cursor) {
      id
      eventId
      eventType
      seen
      createdAt
      to {
        address
        id
      }
      description
    }
  }
`;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationQuery,
    GetNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(
    GetNotificationDocument,
    options
  );
}
export function useGetNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationQuery,
    GetNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationQuery,
    GetNotificationQueryVariables
  >(GetNotificationDocument, options);
}
export type GetNotificationQueryHookResult = ReturnType<
  typeof useGetNotificationQuery
>;
export type GetNotificationLazyQueryHookResult = ReturnType<
  typeof useGetNotificationLazyQuery
>;
export type GetNotificationQueryResult = Apollo.QueryResult<
  GetNotificationQuery,
  GetNotificationQueryVariables
>;
export const RejectMinterRequestDocument = gql`
  mutation RejectMinterRequest($requestId: ID!) {
    rejectMinterRequest(requestId: $requestId) {
      id
    }
  }
`;
export type RejectMinterRequestMutationFn = Apollo.MutationFunction<
  RejectMinterRequestMutation,
  RejectMinterRequestMutationVariables
>;

/**
 * __useRejectMinterRequestMutation__
 *
 * To run a mutation, you first call `useRejectMinterRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMinterRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMinterRequestMutation, { data, loading, error }] = useRejectMinterRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRejectMinterRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectMinterRequestMutation,
    RejectMinterRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectMinterRequestMutation,
    RejectMinterRequestMutationVariables
  >(RejectMinterRequestDocument, options);
}
export type RejectMinterRequestMutationHookResult = ReturnType<
  typeof useRejectMinterRequestMutation
>;
export type RejectMinterRequestMutationResult =
  Apollo.MutationResult<RejectMinterRequestMutation>;
export type RejectMinterRequestMutationOptions = Apollo.BaseMutationOptions<
  RejectMinterRequestMutation,
  RejectMinterRequestMutationVariables
>;
export const RequestMinterAccessDocument = gql`
  mutation RequestMinterAccess($fromId: ID!, $toId: ID!) {
    requestMinterAccess(fromId: $fromId, toId: $toId) {
      id
      createdAt
      status
      from {
        id
        address
        profileHash
        name
        username
        userType
      }
      to {
        id
        address
        profileHash
        name
        username
        userType
      }
    }
  }
`;
export type RequestMinterAccessMutationFn = Apollo.MutationFunction<
  RequestMinterAccessMutation,
  RequestMinterAccessMutationVariables
>;

/**
 * __useRequestMinterAccessMutation__
 *
 * To run a mutation, you first call `useRequestMinterAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMinterAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMinterAccessMutation, { data, loading, error }] = useRequestMinterAccessMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      toId: // value for 'toId'
 *   },
 * });
 */
export function useRequestMinterAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMinterAccessMutation,
    RequestMinterAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMinterAccessMutation,
    RequestMinterAccessMutationVariables
  >(RequestMinterAccessDocument, options);
}
export type RequestMinterAccessMutationHookResult = ReturnType<
  typeof useRequestMinterAccessMutation
>;
export type RequestMinterAccessMutationResult =
  Apollo.MutationResult<RequestMinterAccessMutation>;
export type RequestMinterAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestMinterAccessMutation,
  RequestMinterAccessMutationVariables
>;
export const UpdateUserBeneficiaryDocument = gql`
  mutation UpdateUserBeneficiary(
    $userAddr: String!
    $beneficiaryAddr: String!
  ) {
    updateUserBeneficiary(
      userAddr: $userAddr
      beneficiaryAddr: $beneficiaryAddr
    ) {
      id
    }
  }
`;
export type UpdateUserBeneficiaryMutationFn = Apollo.MutationFunction<
  UpdateUserBeneficiaryMutation,
  UpdateUserBeneficiaryMutationVariables
>;

/**
 * __useUpdateUserBeneficiaryMutation__
 *
 * To run a mutation, you first call `useUpdateUserBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBeneficiaryMutation, { data, loading, error }] = useUpdateUserBeneficiaryMutation({
 *   variables: {
 *      userAddr: // value for 'userAddr'
 *      beneficiaryAddr: // value for 'beneficiaryAddr'
 *   },
 * });
 */
export function useUpdateUserBeneficiaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserBeneficiaryMutation,
    UpdateUserBeneficiaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserBeneficiaryMutation,
    UpdateUserBeneficiaryMutationVariables
  >(UpdateUserBeneficiaryDocument, options);
}
export type UpdateUserBeneficiaryMutationHookResult = ReturnType<
  typeof useUpdateUserBeneficiaryMutation
>;
export type UpdateUserBeneficiaryMutationResult =
  Apollo.MutationResult<UpdateUserBeneficiaryMutation>;
export type UpdateUserBeneficiaryMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserBeneficiaryMutation,
  UpdateUserBeneficiaryMutationVariables
>;
export const UpdateMinterRequestDocument = gql`
  mutation UpdateMinterRequest($requestId: ID!) {
    updateMinterRequest(requestId: $requestId) {
      id
    }
  }
`;
export type UpdateMinterRequestMutationFn = Apollo.MutationFunction<
  UpdateMinterRequestMutation,
  UpdateMinterRequestMutationVariables
>;

/**
 * __useUpdateMinterRequestMutation__
 *
 * To run a mutation, you first call `useUpdateMinterRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMinterRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMinterRequestMutation, { data, loading, error }] = useUpdateMinterRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUpdateMinterRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMinterRequestMutation,
    UpdateMinterRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMinterRequestMutation,
    UpdateMinterRequestMutationVariables
  >(UpdateMinterRequestDocument, options);
}
export type UpdateMinterRequestMutationHookResult = ReturnType<
  typeof useUpdateMinterRequestMutation
>;
export type UpdateMinterRequestMutationResult =
  Apollo.MutationResult<UpdateMinterRequestMutation>;
export type UpdateMinterRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateMinterRequestMutation,
  UpdateMinterRequestMutationVariables
>;
export const UpdateNotificationDocument = gql`
  mutation UpdateNotification($notificationId: ID!) {
    updateNotification(notificationId: $notificationId) {
      id
      seen
    }
  }
`;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, options);
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>;
export type UpdateNotificationMutationResult =
  Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser(
    $address: String!
    $email: String
    $username: String
    $name: String
    $profileHash: String
  ) {
    updateUser(
      address: $address
      email: $email
      username: $username
      name: $name
      profileHash: $profileHash
    ) {
      address
      email
      profileHash
      name
      username
      id
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      address: // value for 'address'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      name: // value for 'name'
 *      profileHash: // value for 'profileHash'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserFollowingDocument = gql`
  mutation UpdateUserFollowing($address: String!, $followingId: ID!) {
    updateUserFollowing(address: $address, followingId: $followingId) {
      id
      followersCount
      followingCount
      address
    }
  }
`;
export type UpdateUserFollowingMutationFn = Apollo.MutationFunction<
  UpdateUserFollowingMutation,
  UpdateUserFollowingMutationVariables
>;

/**
 * __useUpdateUserFollowingMutation__
 *
 * To run a mutation, you first call `useUpdateUserFollowingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFollowingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFollowingMutation, { data, loading, error }] = useUpdateUserFollowingMutation({
 *   variables: {
 *      address: // value for 'address'
 *      followingId: // value for 'followingId'
 *   },
 * });
 */
export function useUpdateUserFollowingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserFollowingMutation,
    UpdateUserFollowingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserFollowingMutation,
    UpdateUserFollowingMutationVariables
  >(UpdateUserFollowingDocument, options);
}
export type UpdateUserFollowingMutationHookResult = ReturnType<
  typeof useUpdateUserFollowingMutation
>;
export type UpdateUserFollowingMutationResult =
  Apollo.MutationResult<UpdateUserFollowingMutation>;
export type UpdateUserFollowingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserFollowingMutation,
  UpdateUserFollowingMutationVariables
>;
export const UserAddressQueryDocument = gql`
  query UserAddressQuery($getUserAddress: String!) {
    getUserByAddress(address: $getUserAddress) {
      id
      followersCount
      followingCount
      isEarlyAdopter
      name
      email
      liked {
        auctionId
        id
      }
      salesEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      followers {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
        name
        username
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
          bidder {
            address
          }
          value
          trxHash
          auction {
            auctionId
            id
          }
          createdAt
          closedAt
          state
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      email
      emailVerified
      royalties {
        auction {
          basePrice
        }
        valuePaid
      }
      bids {
        auction {
          auctionId
          id
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        contractAddress
      }
      userType
      refEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
    }
  }
`;

/**
 * __useUserAddressQueryQuery__
 *
 * To run a query within a React component, call `useUserAddressQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAddressQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAddressQueryQuery({
 *   variables: {
 *      getUserAddress: // value for 'getUserAddress'
 *   },
 * });
 */
export function useUserAddressQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAddressQueryQuery,
    UserAddressQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAddressQueryQuery, UserAddressQueryQueryVariables>(
    UserAddressQueryDocument,
    options
  );
}
export function useUserAddressQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAddressQueryQuery,
    UserAddressQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAddressQueryQuery,
    UserAddressQueryQueryVariables
  >(UserAddressQueryDocument, options);
}
export type UserAddressQueryQueryHookResult = ReturnType<
  typeof useUserAddressQueryQuery
>;
export type UserAddressQueryLazyQueryHookResult = ReturnType<
  typeof useUserAddressQueryLazyQuery
>;
export type UserAddressQueryQueryResult = Apollo.QueryResult<
  UserAddressQueryQuery,
  UserAddressQueryQueryVariables
>;
export const UserUsernameDocument = gql`
  query UserUsername($username: String!) {
    getUserByUsername(username: $username) {
      id
      followersCount
      followingCount
      name
      email
      liked {
        auctionId
        id
      }
      salesEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      followers {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
          bidder {
            address
          }
          value
          trxHash
          auction {
            auctionId
            id
          }
          createdAt
          closedAt
          state
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      email
      emailVerified
      royalties {
        auction {
          basePrice
        }
        valuePaid
      }
      bids {
        auction {
          auctionId
          id
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        contractAddress
        id
        tokenId
      }
      userType
      refEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
    }
  }
`;

/**
 * __useUserUsernameQuery__
 *
 * To run a query within a React component, call `useUserUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserUsernameQuery,
    UserUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserUsernameQuery, UserUsernameQueryVariables>(
    UserUsernameDocument,
    options
  );
}
export function useUserUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserUsernameQuery,
    UserUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserUsernameQuery, UserUsernameQueryVariables>(
    UserUsernameDocument,
    options
  );
}
export type UserUsernameQueryHookResult = ReturnType<
  typeof useUserUsernameQuery
>;
export type UserUsernameLazyQueryHookResult = ReturnType<
  typeof useUserUsernameLazyQuery
>;
export type UserUsernameQueryResult = Apollo.QueryResult<
  UserUsernameQuery,
  UserUsernameQueryVariables
>;
export const UserEmailQueryDocument = gql`
  query UserEmailQuery($getUserEmail: String!) {
    getUserByEmail(email: $getUserEmail) {
      id
      followersCount
      followingCount
      name
      email
      liked {
        auctionId
        id
      }
      salesEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      followers {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
          bidder {
            address
          }
          value
          trxHash
          auction {
            auctionId
            id
          }
          createdAt
          closedAt
          state
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      email
      emailVerified
      royalties {
        auction {
          basePrice
        }
        valuePaid
      }
      bids {
        auction {
          auctionId
          id
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        contractAddress
      }
      userType
      refEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
    }
  }
`;

/**
 * __useUserEmailQueryQuery__
 *
 * To run a query within a React component, call `useUserEmailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailQueryQuery({
 *   variables: {
 *      getUserEmail: // value for 'getUserEmail'
 *   },
 * });
 */
export function useUserEmailQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEmailQueryQuery,
    UserEmailQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserEmailQueryQuery, UserEmailQueryQueryVariables>(
    UserEmailQueryDocument,
    options
  );
}
export function useUserEmailQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEmailQueryQuery,
    UserEmailQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserEmailQueryQuery, UserEmailQueryQueryVariables>(
    UserEmailQueryDocument,
    options
  );
}
export type UserEmailQueryQueryHookResult = ReturnType<
  typeof useUserEmailQueryQuery
>;
export type UserEmailQueryLazyQueryHookResult = ReturnType<
  typeof useUserEmailQueryLazyQuery
>;
export type UserEmailQueryQueryResult = Apollo.QueryResult<
  UserEmailQueryQuery,
  UserEmailQueryQueryVariables
>;
export const UserIdQueryDocument = gql`
  query UserIDQuery($getUserId: ID!) {
    getUser(id: $getUserId) {
      id
      followersCount
      followingCount
      name
      email
      liked {
        auctionId
        id
      }
      salesEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      followers {
        address
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
          bidder {
            address
          }
          value
          trxHash
          auction {
            auctionId
            id
          }
          createdAt
          closedAt
          state
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      email
      emailVerified
      royalties {
        auction {
          basePrice
        }
        valuePaid
      }
      bids {
        auction {
          auctionId
          id
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        id
      }
      userType
      refEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      isEarlyAdopter
    }
  }
`;

/**
 * __useUserIdQueryQuery__
 *
 * To run a query within a React component, call `useUserIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdQueryQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useUserIdQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserIdQueryQuery,
    UserIdQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserIdQueryQuery, UserIdQueryQueryVariables>(
    UserIdQueryDocument,
    options
  );
}
export function useUserIdQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserIdQueryQuery,
    UserIdQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserIdQueryQuery, UserIdQueryQueryVariables>(
    UserIdQueryDocument,
    options
  );
}
export type UserIdQueryQueryHookResult = ReturnType<typeof useUserIdQueryQuery>;
export type UserIdQueryLazyQueryHookResult = ReturnType<
  typeof useUserIdQueryLazyQuery
>;
export type UserIdQueryQueryResult = Apollo.QueryResult<
  UserIdQueryQuery,
  UserIdQueryQueryVariables
>;
export const GetUserRequestsReceivedDocument = gql`
  query GetUserRequestsReceived(
    $getUserRequestsReceivedId: ID!
    $limit: Int!
    $cursor: String
  ) {
    getUserRequestsReceived(
      id: $getUserRequestsReceivedId
      limit: $limit
      cursor: $cursor
    ) {
      to {
        address
        id
        profileHash
        name
        username
      }
      from {
        address
        id
        profileHash
        name
        username
      }
      id
      createdAt
      status
    }
  }
`;

/**
 * __useGetUserRequestsReceivedQuery__
 *
 * To run a query within a React component, call `useGetUserRequestsReceivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRequestsReceivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRequestsReceivedQuery({
 *   variables: {
 *      getUserRequestsReceivedId: // value for 'getUserRequestsReceivedId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserRequestsReceivedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRequestsReceivedQuery,
    GetUserRequestsReceivedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRequestsReceivedQuery,
    GetUserRequestsReceivedQueryVariables
  >(GetUserRequestsReceivedDocument, options);
}
export function useGetUserRequestsReceivedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRequestsReceivedQuery,
    GetUserRequestsReceivedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRequestsReceivedQuery,
    GetUserRequestsReceivedQueryVariables
  >(GetUserRequestsReceivedDocument, options);
}
export type GetUserRequestsReceivedQueryHookResult = ReturnType<
  typeof useGetUserRequestsReceivedQuery
>;
export type GetUserRequestsReceivedLazyQueryHookResult = ReturnType<
  typeof useGetUserRequestsReceivedLazyQuery
>;
export type GetUserRequestsReceivedQueryResult = Apollo.QueryResult<
  GetUserRequestsReceivedQuery,
  GetUserRequestsReceivedQueryVariables
>;
export const GetUserRequestsSentDocument = gql`
  query GetUserRequestsSent(
    $getUserRequestsSentId: ID!
    $limit: Int!
    $cursor: String
  ) {
    getUserRequestsSent(
      id: $getUserRequestsSentId
      limit: $limit
      cursor: $cursor
    ) {
      to {
        address
        id
        profileHash
        name
        username
      }
      from {
        address
        id
        profileHash
        name
        username
      }
      id
      createdAt
      status
    }
  }
`;

/**
 * __useGetUserRequestsSentQuery__
 *
 * To run a query within a React component, call `useGetUserRequestsSentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRequestsSentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRequestsSentQuery({
 *   variables: {
 *      getUserRequestsSentId: // value for 'getUserRequestsSentId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserRequestsSentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRequestsSentQuery,
    GetUserRequestsSentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRequestsSentQuery,
    GetUserRequestsSentQueryVariables
  >(GetUserRequestsSentDocument, options);
}
export function useGetUserRequestsSentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRequestsSentQuery,
    GetUserRequestsSentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRequestsSentQuery,
    GetUserRequestsSentQueryVariables
  >(GetUserRequestsSentDocument, options);
}
export type GetUserRequestsSentQueryHookResult = ReturnType<
  typeof useGetUserRequestsSentQuery
>;
export type GetUserRequestsSentLazyQueryHookResult = ReturnType<
  typeof useGetUserRequestsSentLazyQuery
>;
export type GetUserRequestsSentQueryResult = Apollo.QueryResult<
  GetUserRequestsSentQuery,
  GetUserRequestsSentQueryVariables
>;
export const UsersQueryDocument = gql`
  query UsersQuery($limit: Int!, $cursor: String) {
    getUsers(limit: $limit, cursor: $cursor) {
      id
      followersCount
      followingCount
      name
      email
      liked {
        auctionId
        id
      }
      salesEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      followers {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      address
      addresses
      username
      userType
      email
      memberSince
      profileHash
      invitedBy {
        address
        id
        profileHash
      }
      following {
        name
        email
        username
        followers {
          id
        }
        following {
          id
        }
        id
        address
        addresses
        profileHash
      }
      referral {
        address
        id
        profileHash
        username
        name
      }
      auctions {
        trxHash
        buyer {
          address
        }
        paymentMethod
        likedBy {
          address
          profileHash
        }
        bids {
          id
          bidder {
            address
          }
          value
          trxHash
          auction {
            auctionId
            id
          }
          createdAt
          closedAt
          state
        }
        viewCount
        royalties {
          royaltyTo {
            address
            id
            username
            name
          }
        }
      }
      email
      emailVerified
      royalties {
        auction {
          basePrice
        }
        valuePaid
      }
      bids {
        auction {
          auctionId
          id
        }
      }
      collectedNfts {
        id
      }
      mintedNfts {
        contractAddress
      }
      userType
      refEarned {
        auction {
          auctionId
          id
        }
        value
        type
        status
        timestamp
        id
      }
      beneficiaries {
        address
        id
        profileHash
        name
        username
      }
      requestsReceived {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
      requestsSent {
        id
        createdAt
        status
        from {
          id
          address
          profileHash
          name
          username
          userType
        }
        to {
          id
          address
          profileHash
          name
          username
          userType
        }
      }
    }
  }
`;

/**
 * __useUsersQueryQuery__
 *
 * To run a query within a React component, call `useUsersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQueryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useUsersQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersQueryQuery,
    UsersQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQueryQuery, UsersQueryQueryVariables>(
    UsersQueryDocument,
    options
  );
}
export function useUsersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersQueryQuery,
    UsersQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQueryQuery, UsersQueryQueryVariables>(
    UsersQueryDocument,
    options
  );
}
export type UsersQueryQueryHookResult = ReturnType<typeof useUsersQueryQuery>;
export type UsersQueryLazyQueryHookResult = ReturnType<
  typeof useUsersQueryLazyQuery
>;
export type UsersQueryQueryResult = Apollo.QueryResult<
  UsersQueryQuery,
  UsersQueryQueryVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($cypheredEmail: String!) {
    verifyUserEmail(cypheredEmail: $cypheredEmail) {
      id
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      cypheredEmail: // value for 'cypheredEmail'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options
  );
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const CreateViewInfoDocument = gql`
  mutation CreateViewInfo($userId: ID!, $guestId: ID!, $auctionId: ID!) {
    createViewInfo(userId: $userId, guestId: $guestId, auctionId: $auctionId) {
      id
    }
  }
`;
export type CreateViewInfoMutationFn = Apollo.MutationFunction<
  CreateViewInfoMutation,
  CreateViewInfoMutationVariables
>;

/**
 * __useCreateViewInfoMutation__
 *
 * To run a mutation, you first call `useCreateViewInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewInfoMutation, { data, loading, error }] = useCreateViewInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      guestId: // value for 'guestId'
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useCreateViewInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateViewInfoMutation,
    CreateViewInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateViewInfoMutation,
    CreateViewInfoMutationVariables
  >(CreateViewInfoDocument, options);
}
export type CreateViewInfoMutationHookResult = ReturnType<
  typeof useCreateViewInfoMutation
>;
export type CreateViewInfoMutationResult =
  Apollo.MutationResult<CreateViewInfoMutation>;
export type CreateViewInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateViewInfoMutation,
  CreateViewInfoMutationVariables
>;
export const GetEarningDocument = gql`
  query GetEarning($getUserEarningsId: ID!, $limit: Int!, $cursor: String) {
    getUserEarnings(id: $getUserEarningsId, limit: $limit, cursor: $cursor) {
      id
      auction {
        auctionId
        id
      }
      value
      to {
        address
        id
      }
      type
      status
      timestamp
      trxHash
    }
  }
`;

/**
 * __useGetEarningQuery__
 *
 * To run a query within a React component, call `useGetEarningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarningQuery({
 *   variables: {
 *      getUserEarningsId: // value for 'getUserEarningsId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetEarningQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEarningQuery,
    GetEarningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEarningQuery, GetEarningQueryVariables>(
    GetEarningDocument,
    options
  );
}
export function useGetEarningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEarningQuery,
    GetEarningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEarningQuery, GetEarningQueryVariables>(
    GetEarningDocument,
    options
  );
}
export type GetEarningQueryHookResult = ReturnType<typeof useGetEarningQuery>;
export type GetEarningLazyQueryHookResult = ReturnType<
  typeof useGetEarningLazyQuery
>;
export type GetEarningQueryResult = Apollo.QueryResult<
  GetEarningQuery,
  GetEarningQueryVariables
>;
export const GetViewInfoDocument = gql`
  query GetViewInfo($guestId: ID!, $auctionId: ID!) {
    getViewInfo(guestId: $guestId, auctionId: $auctionId) {
      id
      auction {
        auctionId
        id
      }
      user {
        address
        id
      }
      guest {
        address
        id
      }
    }
  }
`;

/**
 * __useGetViewInfoQuery__
 *
 * To run a query within a React component, call `useGetViewInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewInfoQuery({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useGetViewInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetViewInfoQuery,
    GetViewInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetViewInfoQuery, GetViewInfoQueryVariables>(
    GetViewInfoDocument,
    options
  );
}
export function useGetViewInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewInfoQuery,
    GetViewInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetViewInfoQuery, GetViewInfoQueryVariables>(
    GetViewInfoDocument,
    options
  );
}
export type GetViewInfoQueryHookResult = ReturnType<typeof useGetViewInfoQuery>;
export type GetViewInfoLazyQueryHookResult = ReturnType<
  typeof useGetViewInfoLazyQuery
>;
export type GetViewInfoQueryResult = Apollo.QueryResult<
  GetViewInfoQuery,
  GetViewInfoQueryVariables
>;
