import erc721Abi from "abi/coterie-nft-token.json";
import bep20Abi from "abi/erc20.json";
import lpTokenAbi from "abi/lpTokenAbi.json";
import MultiCallAbi from "abi/Multicall.json";
import nftMarketAbi from "abi/nft-mktplace.json";
import StakingContract from "abi/staking.json";
import { getNftContractAddress } from "config/nftContracts";
import { Blockchain } from "generated/graphql-frontend";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { DEFAULT_GAS_PRICE, TESTNET_CHAIN_ID } from "../../config";
import { getStakingAddress } from "../addressHelper";
import { toBN } from "../BigNumber";
import { getDecimalAmount } from "../formatBalance";
import web3NoAccount from "../web3NoAccount";

export const getDefaultGasPrice = () => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  if (chainId === TESTNET_CHAIN_ID) {
    return 10;
  }
  return DEFAULT_GAS_PRICE;
};

export const getGasPriceInWei = (amountInGwei: number) => {
  return getDecimalAmount(toBN(amountInGwei).toString(), 9);
};

const getContract = (
  abi: any,
  address: string,
  web3?: Web3,
  account?: string
) => {
  const _web3 = web3 ?? web3NoAccount;
  const gasPrice =
    /* account ? getSettings(account).gasPrice : */ getDefaultGasPrice();

  return new _web3.eth.Contract(abi as unknown as AbiItem, address, {
    gasPrice: getGasPriceInWei(gasPrice).toString(),
  });
};

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3);
};

export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3);
};

export const getErc721Contract = (
  network?: Blockchain | "test",
  web3?: Web3
) => {
  return getContract(
    erc721Abi,
    getNftContractAddress("nftToken", network ?? "test"),
    web3
  );
};

export const makeNFTInterface = (web3: Web3, contractAddress: string) => {
  return getContract(erc721Abi, contractAddress, web3);
};

export const getNFTMarketplaceContract = (
  web3?: Web3,
  network?: Blockchain | "test"
) => {
  return getContract(
    nftMarketAbi,
    getNftContractAddress("nftMktplace", network ?? "test"),
    web3
  );
};

export const getStakingContract = (web3?: Web3) => {
  return getContract(StakingContract, getStakingAddress(), web3);
};

export const getMulticallContract = (
  web3?: Web3,
  network?: Blockchain | "test"
) => {
  return getContract(
    MultiCallAbi,
    getNftContractAddress("multiCall", network ?? "test"),
    web3
  );
};
