import React, { FC, useCallback, useEffect, useState } from "react";
import { IonToggle, IonIcon } from "@ionic/react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useUi } from "store/hooks";
import { useDispatch } from "react-redux";

const SideBar = styled.div`
  width: 17%;
  min-width: 190px;
  max-width: 300px;
  background: var(--ion-color-sidebar);
  height: 100vh;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  transition: 0.5s ease-in-out;
  z-index: 999;
  display: none;
  @media (max-width: 600px) {
    left: -360px;

    &.added {
      width: 270px;
      left: 0px;
    }
  }
`;

const Item = styled.div`
  color: var(--ion-color-primary-text);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 15px 0;
  cursor: pointer;

  & div {
    padding-left: 8px;
    font-size: 18px;
    & a {
      color: var(--ion-color-primary-text);
      display: block;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin: 0px 10px;
    width: 20px;
    height: 20px;
  }
`;

const SmallIcon = styled(IonIcon)`
  padding: 5px;
  width: 30px;
`;
const Icon = styled(IonIcon)`
  width: 60px;
  height: 17px;
  padding: 0 10px;
  margin: 0;
`;

const Nav: FC = () => {
  const dispatch = useDispatch();

  let { sidebar } = useUi();
  const location = useLocation();

  const [isNftPage, setIsNftPage] = useState(false);

  useEffect(() => {
    // console.log(location);
    // if (location.pathname.startsWith("/nfts")) setIsNftPage(true);
    // else setIsNftPage(false);
  }, [location]);

  const toggleTheme = () => {
    let currentTheme = localStorage.getItem("theme");
    document.body.classList.toggle("dark");
    let theme = currentTheme === "light" ? "dark" : "light";
    localStorage.setItem("theme", theme);
  };

  const toggleSideBar = useCallback(() => {
    dispatch({ type: "CLOSE_SIDEBAR" });
  }, [dispatch]);

  useEffect(() => {
    toggleSideBar();
  }, [toggleSideBar, location]);

  let show = sidebar ? "added" : "";

  return (
    <SideBar className={show}>
      <Link to='/'>
        <Item>
          <Icon src='/svgs/home.svg' />
          <div>
            {" "}
            <strong>Home</strong> {sidebar}
          </div>
          {!isNftPage ? (
            <div>
              {" "}
              <strong>Home</strong>
            </div>
          ) : null}
        </Item>
      </Link>

      <Link to='/trade'>
        <Item>
          <Icon src='/svgs/trade.svg' />
          {!isNftPage ? (
            <div>
              <strong>Trade</strong>
            </div>
          ) : null}
        </Item>
      </Link>

      <Link to='/farm'>
        <Item>
          <Icon src='/svgs/farm.svg' />

          {!isNftPage ? (
            <div>
              <strong>Farm</strong>
            </div>
          ) : null}
        </Item>
      </Link>

      <Link to='/pools'>
        <Item>
          <Icon src='/svgs/pool.svg' />
          {!isNftPage ? (
            <div>
              <strong>Pools</strong>
            </div>
          ) : null}
        </Item>
      </Link>

      <Link to='/nfts'>
        <Item>
          <Icon src='/svgs/nft.svg' />
          {!isNftPage ? (
            <div>
              <strong>NFTs Shop</strong>
            </div>
          ) : null}
        </Item>
      </Link>

      <Item>
        <Icon src='/svgs/launchpad.svg' />
        {!isNftPage ? (
          <div>
            <strong>Launchpad</strong>
          </div>
        ) : null}
      </Item>

      <Item>
        <Icon src='/svgs/ido.svg' />
        {!isNftPage ? (
          <div>
            <strong>IDO</strong>
          </div>
        ) : null}
      </Item>

      <Item>
        <Icon src='/svgs/info.svg' />
        {!isNftPage ? (
          <div>
            <strong>Info</strong>
          </div>
        ) : null}
      </Item>

      {!isNftPage ? (
        <Item>
          <Icon src='/svgs/more.svg' />
          <div>
            <strong>More</strong>
          </div>
          <IonIcon src='/svgs/darrow.svg' />
        </Item>
      ) : null}

      {!isNftPage ? (
        <Footer>
          <Col>
            <SmallIcon src='/svgs/light.svg' />
            <IonToggle color='primary' onIonChange={toggleTheme} />
          </Col>
          <Col>
            <SmallIcon src='/svgs/twitter.svg' />
            <SmallIcon src='/svgs/telegram.svg' />
          </Col>
        </Footer>
      ) : null}
    </SideBar>
  );
};

export default Nav;
