import BigNumber from "bignumber.js";
import { Blockchain } from "generated/graphql-frontend";
import Web3 from "web3";
import {
  getErc721Contract,
  makeNFTInterface,
} from "../../utils/contractHelps/contractsHelper";
import {
  approve,
  burn,
  mint,
  mintWithRoyalty,
  registerNewMinter,
  updateMinterAddress,
  updateRoyalty,
  updateTokenURI,
} from "../../utils/contractHelps/NFTs/nft-token/coterieNFTCalls";

const validate = (royaltyAddrs: string[], royaltyShares: number[]) => {
  console.log("royaltyAddrs: ", royaltyAddrs);
  console.log("royaltyShares: ", royaltyShares);
  let total = royaltyShares.reduce((s, share) => s + share, 0);
  console.log("Total: ", total);
  return total <= 10_000 && royaltyAddrs.length === royaltyShares.length;
};
export const use_mintNFTsWithRoyalty = (
  tokenURI: string,
  royaltyAddrs: string[],
  royaltyShare: BigNumber[],
  account: string,
  _web3: Web3,
  network: Blockchain | "test"
) => {
  const nShare = royaltyShare.map((s) => s.multipliedBy(100).toNumber());
  console.log("TRUTHY: ", validate(royaltyAddrs, nShare));
  if (!validate(royaltyAddrs, nShare))
    throw new Error("Invalid royalty distribution");

  const share = royaltyShare.map((s) => s.multipliedBy(100).toString(10));
  return mintWithRoyalty(
    getErc721Contract(network, _web3),
    tokenURI,
    royaltyAddrs,
    share,
    account
  );
};

export const use_mintNFTs = (
  tokenURI: string,
  account: string,
  _web3: Web3,
  network: Blockchain | "test"
) => {
  return mint(getErc721Contract(network, _web3), tokenURI, account);
};

export const use_eRC721Burn = (
  tokenId: BigNumber,
  account: string,
  _web3: Web3,
  network: Blockchain | "test"
) => {
  return burn(getErc721Contract(network, _web3), tokenId.toString(10), account);
};

export const use_registerNewMinter = (
  newMinter: string,
  account: string,
  _web3: Web3,
  network: Blockchain | "test"
) => {
  return registerNewMinter(
    getErc721Contract(network, _web3),
    newMinter,
    account
  );
};

export const use_updateRoyalty = (
  tokenId: BigNumber,
  royaltyAddrs: string[],
  royaltyShare: BigNumber[],
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  const nShare = royaltyShare.map((s) => s.toNumber());
  if (!validate(royaltyAddrs, nShare))
    throw new Error("Invalid royalty distribution");

  const share = royaltyShare.map((s) => s.toString(10));
  return updateRoyalty(
    getErc721Contract(network, _web3),
    tokenId.toString(10),
    royaltyAddrs,
    share,
    account
  );
};

export const use_updateTokenURI = (
  tokenId: BigNumber,
  tokenUri: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return updateTokenURI(
    getErc721Contract(network, _web3),
    tokenId.toString(10),
    tokenUri,
    account
  );
};

export const use_updateMinterAddress = (
  newMinterAddress: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return updateMinterAddress(
    getErc721Contract(network, _web3),
    newMinterAddress,
    account
  );
};

export const use_approval = (
  nftTokenAddress: string,

  account: string,
  _web3: Web3
) => {
  return approve(
    makeNFTInterface(_web3, nftTokenAddress),

    account
  );
};
