import { FC } from "react";
import { IonText, IonInput, IonLabel } from "@ionic/react";
import styled from "styled-components";

interface Props {
  value: string | number;
  placeholder: string;
  onIonChange: any;
  label: string;
  name: string;
  type: any;
  required: boolean;
  step?: any;
}

export const Input = styled(IonInput)`
  width: 100% !important;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
export const Info = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 10px;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 50%;
  color: #555;
  cursor: pointer !important;
  position: relative;
  z-index: 99 !important;
  pointer-events: all;
  margin: 0px 5px;

  &:hover {
    background: #f8f8f8;

    & > InfoMsg {
      display: block;
    }
  }
`;

export const InfoMsg = styled.div`
  display: none;
  padding: 10px;
  position: absolute;
  background: red;
  top: -20px;
`;

const InputField: FC<Props> = ({ label, ...props }) => {
  return (
    <InputContainer>
      <IonLabel position='floating'>{label}</IonLabel>
      <Input {...props} />
    </InputContainer>
  );
};

export default InputField;
