import { Flex, Spacer, Text } from "components";
import React from "react";
import styled from "styled-components";

export const Circle = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  padding: 20px;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  font-size: 60px;
  font-weight: 700;
  text-align: center;

  span {
    opacity: 0.15;
  }
`;

const Contain = styled.div`
  width: calc(100% / 4);

  @media (max-width: 640px) {
    width: 100%;
  }
`;

interface CardInterface {
  title: string;
  desc: string;
  step: number;
  color: string;
}

const Card: React.FC<CardInterface> = ({ title, desc, step, color }) => {
  return (
    <Contain>
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{ padding: 30 }}
      >
        <Circle {...{ color }}>
          <span>{step}</span>
        </Circle>
        <Spacer />
        <Text size="20" weight="500">
          {title}
        </Text>
        <Spacer />
        <Text style={{ textAlign: "center" }}>{desc}</Text>
      </Flex>
    </Contain>
  );
};

export default Card;
