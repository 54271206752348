import { IonIcon } from "@ionic/react";
import { Flex, Logo, Spacer, Text } from "components";
import { arrowUp } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import { openExternalLink } from "utils/nft-user-helper";

const Container = styled.div`
  padding: 20px 19px;
  padding-top: 50px;
  background: #fff;
  box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.08);
  position: relative;
  flex-shrink: 0;
  margin-top: 100px;
`;

const Arrow = styled.div`
  margin: 10px;
  padding: 10px;
  background: #000;
  display: inline;
  position: absolute;
  top: -43px;
  right: 0px;
  color: #fff;
  cursor: pointer;
`;

const TextCustom = styled.div<{
  weight?: string;
  size?: string;
  color?: string;
  sizeM?: string;
}>`
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  font-size: ${({ size }) => (size ? size + "px" : "12px")};
  color: ${({ color }) => (color ? color : "#000000")};
  padding: 0px 15px;
  margin: 5px 0px;

  @media (max-width: 640px) {
    font-size: ${({ sizeM }) => sizeM && sizeM + "px"};
  }
`;

const Footer: React.FC = () => {
  const gotoButton = () => {
    let content = document.querySelector("ion-content");
    content.scrollToTop(200);
  };

  return (
    <Container>
      <Flex justify="space-between" align="flex-start">
        <Flex direction="column" style={{ maxWidth: 270 }}>
          <Logo>
            <img
              style={{ transform: "scale(1.5) translateX(12px)" }}
              src="/assets/Coterie5.png"
              alt="Coterie logo"
            />
          </Logo>
          <Spacer />
          <Text size="14" weight="400" style={{ marginLeft: 15 }}>
            Create, Collaborate, Collect, and Flaunt unique tokenized crypto
            assets
          </Text>
        </Flex>
        <Flex>
          <Flex directionM="column">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => openExternalLink("https://t.me/CoterieDaoAnn")}
            >
              <TextCustom size="16" weight="400">
                Telegram
              </TextCustom>
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                openExternalLink("https://twitter.com/CoterieDaoHQ?t=7")
              }
            >
              <TextCustom size="16" weight="400">
                Twitter
              </TextCustom>
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                openExternalLink("http://coteriefinance.medium.com/")
              }
            >
              <TextCustom size="16" weight="400">
                Medium
              </TextCustom>
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => openExternalLink("https://discord.gg/9dnmVWjArH")}
            >
              <TextCustom size="16" weight="400">
                Discord
              </TextCustom>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Arrow onClick={() => gotoButton()}>
        <IonIcon mode="ios" size="large" icon={arrowUp} />
      </Arrow>
    </Container>
  );
};

export default Footer;
