import { useIonModal } from "@ionic/react";
import { GridComponent, GridFarm, ListFarm } from "components";
import PoolHeader from "components/Pool/Banners";
import FilterHeader from "components/Pool/FilterHeader";
import StakeCard from "components/Pool/StakeCard";
import TransactionDeny from "components/Pool/TransactionDeny";
import TransactionSubmitted from "components/Pool/TransactionSubmitted";
import TransactionWaiting from "components/Pool/TransactionWaiting";
import UnstakeCard from "components/Pool/UnstakeCard";
import use_approve from "hooks/pool/approve";
import useClaim from "hooks/pool/claim";
import useStake from "hooks/pool/stake";
import useUnstake from "hooks/pool/unstake";
import { web3 as connect, web3 } from "hooks/useWeb3Provider";
import React, { useEffect, useState } from "react";
import { Farm, Farmx } from "states/types";
import {
  useActiveWeb3React,
  useFarms,
  usePoolFarmsData,
  usePoolOnlyData,
  usePriceCoterieBusd,
} from "store/hooks";
import styled from "styled-components";
import { PoolObject } from "types/farm-types";
import { getAddress, getStakingAddress } from "utils/addressHelper";
import { MakePools } from "utils/poolsHelper";

const Container = styled.div`
  margin: 15px;
`;

const Home: React.FC = () => {
  const [grid, setGrid] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [waitingMsg, setWaitingMsg] = useState<string>("");
  const [txnSubmittedMsg, setTxnSubmittedMsg] = useState<string>("");
  const [locked, setLocked] = useState<boolean>(true);
  const { account } = useActiveWeb3React();
  const unlockWallet = async () => {
    await connect();
    setLocked(false);
  };
  let { loading, userDataLoaded } = useFarms();
  const data = usePoolOnlyData();
  usePoolFarmsData();

  useEffect(() => {
    if (account) {
      setLocked(false);
    }
  }, [account]);

  const delfyPrice = usePriceCoterieBusd();

  let poolObjects: PoolObject[] = [];
  let farmPoolsData: Farm[] = [];
  let poolUsersData: Farmx[] = [];
  if (!loading) {
    farmPoolsData = data;
    poolObjects = MakePools(farmPoolsData, delfyPrice);

    if (userDataLoaded) {
      data.forEach((d: Farm) => {
        const { userData, pid } = d;
        let index = farmPoolsData.findIndex(
          (farm) => getAddress(farm.pid) === getAddress(pid)
        );
        let newData = Object.assign({}, farmPoolsData[index], userData);
        poolUsersData.push(newData);
      });
      poolObjects = MakePools(poolUsersData, delfyPrice);
    }
  }

  const toggleDisplay = (value: boolean) => {
    setGrid(value);
  };

  const handlePresentStake = (poolObj: PoolObject) => {
    setPoolObj(poolObj);
    console.log("statePID: ", poolObj.pool);
    presentStake();
  };

  const handleWithdrawStake = (poolObj: PoolObject) => {
    setPoolObj(poolObj);
    console.log("statePID: ", poolObj.pool);
    presentUnstake();
  };
  const [poolObj, setPoolObj] = useState<PoolObject>();

  const handleDepositDismiss = (userInput: string) => {
    console.log("userInputs: ", userInput, poolObj.pool);
    dismissStake();
  };
  const handleWithdrawDismiss = (userInput: string) => {
    console.log("userInputs: ", userInput, poolObj.pool);
    dismissUnstake();
  };

  const _approve = async () => {
    setWaitingMsg(`Approving ${poolObj.poolName} token for farming`);
    presentTxnWaiting();
    let _web3 = await web3();
    use_approve(poolObj.pool, getStakingAddress(), account, _web3)
      .on("transactionHash", (tx: any) => {
        console.log("trxn: ", tx);
        setTxnSubmittedMsg(`${tx}`);
        presentSubmitted();
        dismissTxnWaiting();
      })
      .on("error", (error: any) => {
        console.log("txn errors", error);
        setErrorMsg(error.message);
        presentError();
      });
  };
  const handleErrorDismiss = () => {
    dismissPresentError();
  };
  const handleWaitingDismiss = () => {
    dismissTxnWaiting();
  };
  const handleTxSubmittedDismiss = () => {
    dismissSubmitted();
  };

  const _deposit = async (amount: number) => {
    setWaitingMsg(`Depositing ${poolObj.poolName} token to Delfy farming pool`);
    presentTxnWaiting();
    let _web3 = await web3();
    console.log("amnt: ", amount);

    useStake(poolObj.pool, amount, account, false, _web3)
      .on("transactionHash", (tx: any) => {
        console.log("trxn: ", tx);
        setTxnSubmittedMsg(`${tx}`);
        presentSubmitted();
        dismissTxnWaiting();
      })
      .on("error", (error: any) => {
        console.log("txn errors", error);
        setErrorMsg(error.message);
        presentError();
      });
  };

  const _claim = async (pool: PoolObject) => {
    setWaitingMsg(`Claiming earned ${pool.nameEarned} token from farming pool`);
    presentTxnWaiting();
    let _web3 = await web3();
    useClaim(pool.pool, account, _web3)
      .on("transactionHash", (tx: any) => {
        console.log("trxn: ", tx);
        setTxnSubmittedMsg(`${tx}`);
        presentSubmitted();
        dismissTxnWaiting();
      })
      .on("error", (error: any) => {
        console.log("txn errors", error);
        setErrorMsg(error.message);
        presentError();
      });
  };

  const _unstake = async (amount: number) => {
    setWaitingMsg(
      `Claiming earned ${poolObj.nameEarned} token from farming pool`
    );
    presentTxnWaiting();
    let _web3 = await web3();
    useUnstake(poolObj.pool, amount, account, false, _web3)
      .on("transactionHash", (tx: any) => {
        console.log("trxn: ", tx);
        setTxnSubmittedMsg(`${tx}`);
        presentSubmitted();
        dismissTxnWaiting();
      })
      .on("error", (error: any) => {
        console.log("txn errors", error);
        setErrorMsg(error.message);
        presentError();
      });
  };

  const [presentStake, dismissStake] = useIonModal(StakeCard, {
    onDismiss: handleDepositDismiss,
    poolObj: poolObj,
    locked,
    approve: _approve,
    deposit: _deposit,
  });
  const [presentUnstake, dismissUnstake] = useIonModal(UnstakeCard, {
    onDismiss: handleWithdrawDismiss,
    unstake: _unstake,
    unlockWallet,
    poolObj: poolObj,
    locked,
  });
  const [presentError, dismissPresentError] = useIonModal(TransactionDeny, {
    close: handleErrorDismiss,
    msg: errorMsg,
  });
  const [presentTxnWaiting, dismissTxnWaiting] = useIonModal(
    TransactionWaiting,
    { close: handleWaitingDismiss, msg: waitingMsg }
  );
  const [presentSubmitted, dismissSubmitted] = useIonModal(
    TransactionSubmitted,
    { close: handleTxSubmittedDismiss, hash: txnSubmittedMsg }
  );

  return (
    <>
      <PoolHeader
        title="Pool"
        description="No 1 Auction platform for crypto assets "
      />
      <FilterHeader toggleDisplay={toggleDisplay} grid={grid} />
      <Container>
        {grid ? (
          <GridComponent>
            <GridFarm
              openDepositModal={handlePresentStake}
              openWithdrawModal={handleWithdrawStake}
              unlock={unlockWallet}
              locked={locked}
              farms={poolObjects}
              claim={_claim}
            />
          </GridComponent>
        ) : (
          <ListFarm
            openDepositModal={handlePresentStake}
            openWithdrawModal={handleWithdrawStake}
            unlock={unlockWallet}
            locked={locked}
            farms={poolObjects}
            claim={_claim}
          />
        )}
      </Container>
    </>
  );
};

export default Home;
