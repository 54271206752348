import { IonContent, IonPage } from "@ionic/react";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { SideBar } from "../components";
const Body = styled.div`
  width: 83%;
  height: 100%;
  position: absolute;
  left: 17%;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 100%;
    left: 0;
  }
`;

interface RouterProps {
  address: string;
}

interface MatchProps extends RouteComponentProps<RouterProps> {}

const Pools: FC<MatchProps> = ({
  match: {
    params: { address: auctionAddress },
  },
}) => {
  let location = useLocation();
  let dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch({ type: "CLOSE_SIDEBAR" });
  };
  useEffect(() => {
    closeSidebar();
  }, [location]);
  return (
    <IonPage>
      <IonContent>
        <SideBar />
        <Body onClick={closeSidebar}>
          <div style={{ margin: "auto", maxWidth: "1400px" }}></div>
        </Body>
      </IonContent>
    </IonPage>
  );
};

export default Pools;
