import { IonIcon, IonItem, IonList, useIonToast } from "@ionic/react";
import { copy, logoTwitter, logoWhatsapp } from "ionicons/icons";
import React from "react";
import styled from "styled-components";

const Icon = styled(IonIcon)<{ col?: string }>`
  color: ${({ col }) => (col ? col : "primary")};
`;

const List = styled(IonList)`
  width: 80%;
  margin: auto;
`;

interface ISocialShare {
  onHide: () => void;
  text: string;
  location: string;
}

const SocialPop: React.FC<ISocialShare> = ({ onHide, text, location }) => {
  const tweet = () => {
    window.open(
      "https://twitter.com/share?url=" +
        encodeURIComponent(location) +
        "&text=" +
        text,
      "_blank"
    );
    onHide();
  };

  const [present] = useIonToast();

  const copyFunc = () => {
    onHide();
    present({
      position: "middle",
      mode: "ios",
      message: "Copied to Clipboard",
      duration: 3000,
      animated: true,
    });
    return navigator.clipboard.writeText(location);
  };
  const tgShare = () => {
    onHide();
    window.open(
      `https://t.me/share/url?url=${location}&text=${text}`,
      "_blank"
    );
  };
  const whatsAppShare = () => {
    onHide();
    window.open(
      `https://wa.me?text=${encodeURIComponent(`${location}\n\n\n${text}`)}`,
      "_blank"
    );
  };

  return (
    <IonList>
      <IonItem button onClick={tweet}>
        <Icon col={"#00acee"} icon={logoTwitter} />
        &nbsp;Twitter
      </IonItem>
      {/* <IonItem button>
        <Icon col={"#8a3ab9"} icon={logoInstagram} />
        &nbsp;Instagram
      </IonItem> */}
      <IonItem onClick={tgShare} button>
        <Icon src="/svgs/telegram.svg" col={"#4267B2"} />
        {/* <a href={tgShare()} target="_blank" rel="noopener noreferrer"> */}
        &nbsp;Telegram
        {/* </a> */}
      </IonItem>
      <IonItem onClick={whatsAppShare} button>
        <Icon col={"#128c7e"} icon={logoWhatsapp} />
        {/* <a href={whatsAppShare()} target="_blank" rel="noopener noreferrer"> */}
        &nbsp;WhatsApp
        {/* </a> */}
      </IonItem>
      {/* <IonItem button>
        <Icon col={"#4267B2"} icon={logoFacebook} />
        &nbsp;Facebook
      </IonItem> */}
      <IonItem onClick={copyFunc} lines="none" button>
        <Icon color="primary" icon={copy} />
        &nbsp; Copy Link
      </IonItem>
    </IonList>
  );
};

export default SocialPop;
