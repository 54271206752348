import styled from "styled-components";
import { IonButton } from "@ionic/react";

const Button = styled(IonButton)<{
  width?: string;
  height?: string;
  mHeight?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height+'px' : "48px")};
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;


  @media (max-width: 640px) {
    height: ${({ mHeight }) => (mHeight ? mHeight+'px' : "36px")};
  }
`;

export const ButtonPrimary = styled(Button)`
  --background: linear-gradient(0deg, #6a64ff 0%, rgba(106, 100, 255, 0) 292.71%);
  --color: white !important;
`;

export const ButtonSecondary = styled(Button)`
  --background: linear-gradient(0deg, #58f83e 0%, rgba(106, 100, 255, 0) 292.71%);
  --color: white;
`;

export const ButtonWhite= styled(Button)`
  --background: linear-gradient(0deg, #fff 0%, rgba(106, 100, 255, 0) 292.71%);
  --color: #000000 !important;
`;

export const ButtonPrimaryLight= styled(Button)`
  --background:rgba(106, 100, 255, 0.5);
  --color: #000000 !important;
`;

//   export const ButtonS = styled(Button)`
//     border: 1px solid ${({ theme }) => theme.colors.background};
//     background-color: ${({ theme }) => theme.colors.backgroundAlt};
//   `

//   export const LightGreyCard = styled(Button)`
//     border: 1px solid ${({ theme }) => theme.colors.cardBorder};
//     background-color: ${({ theme }) => theme.colors.background};
//   `

//   export const GreyCard = styled(Button)`
//     background-color: ${({ theme }) => theme.colors.dropdown};
//   `
