import { createSlice } from "@reduxjs/toolkit";

interface NftDetailType {
  detailsLoading: boolean;
  nftDetails: object | any;
}

const initialState = {
  detailsLoading: false,
  nftDetails: undefined,
} as NftDetailType;

const nftDetailsSlice = createSlice({
  name: "nftDetails",
  initialState,
  reducers: {
    setDetailLoading: (state, { payload }) =>
      void (state.detailsLoading = payload),
    setDetails: (state, { payload }) => {
      state.nftDetails = payload;
    },
  },
});

export const { setDetailLoading, setDetails } = nftDetailsSlice.actions;

export default nftDetailsSlice.reducer;
