import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNetwork } from "../../store/hooks";
import { getNSyb } from "../../utils/addressHelper";
import { getClam } from "./../../services/auctionService";

const DetailContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 4px #aaa;
  border-radius: 5px;
  margin: 10px 10px;
  padding: 10px;

  @media (max-width: 640px) {
    margin: 0px;
  }
`;

const ClaimableContainer = styled.div`
  padding: 10px;
  margin-bottom: 9px;
  border-radius: 10px;
  font-size: 1em;
  background: #2dd36f;
  text-align: center;

  & h2 {
    color: #fff;
  }
`;

const List = styled.div`
  display: block;
  padding: 10px;
  color: #2a2290;
  & span {
    color: #2dd36f;
    font-weight: bold;
  }
`;

const Claimable: React.FC<{ address: string; account: string; token: any }> = ({
  address,
  account,
  token,
}) => {
  const network = useNetwork();
  const networkSymbol = getNSyb(network);
  const [claim, setClame] = useState<any>({});

  const getClaims = async () => {
    try {
      let x = await getClam(address, account);
      setClame(x);
    } catch (err) {
      console.log(err, "helloo");
    }
  };

  useEffect(() => {
    getClaims();
    // eslint-disable-next-line
  }, [account]);

  return (
    <>
      <DetailContainer>
        <List>
          <b>Token Balance:</b> <span>{claim.tokenBal}</span> {token.symbol}{" "}
        </List>
        <List>
          <b>{networkSymbol} Balance:</b>{" "}
          <span>
            {claim.ethBal} {networkSymbol}
          </span>{" "}
        </List>
      </DetailContainer>
      <ClaimableContainer>
        <List>
          <h2>
            {" "}
            <b>Claimable:</b> {claim.claimable} {networkSymbol}{" "}
          </h2>{" "}
        </List>
      </ClaimableContainer>
    </>
  );
};

export default Claimable;
