import { IonIcon, IonText } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";
import { PoolObject } from "../../types/farm-types";
import EarnedCard from "./EarnedCard";
import StakedList from "./Staked";
import {
  chevronDownOutline,
  helpCircleOutline,
  chevronUpOutline,
} from "ionicons/icons";
import { useTotalLpTokenValue } from "store/hooks";

const DetailDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  overflow: hidden;
  display: flex;
  margin: 10px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  & .grid {
    width: calc(33.3% - 30px);
    margin: 10px;
    //  padding:0px 10px;
    @media (max-width: 600px) {
      width: 90%;
      & .iontext {
        font-size: 12px;
      }
    }
    & h4 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #6470cf;
    }
  }

  &.added {
    height: 150px;
  }
`;

const DetailCard = styled.div`
  border: 1px solid #6470cf;
  box-sizing: border-box;
  border-radius: 10px;
  height: 120px;
  &.first {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
    border: none;
    @media (max-width: 600px) {
      justify-content: space-evenly;
    }
  }
  &.bottom {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    height: 130px;
    &.first {
      padding: 10px 0;
    }
    &.bottom {
      width: 100%;
    }
  }
`;
const ListItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  font-size: 14px;
  line-height: 26px;
  border-bottom: 1px solid #ccc;
`;

const Items = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  padding: 10px;
  width: calc(100% / 6);
  display: flex;
  flex-direction: row;
  align-items: center;
  &.details {
    cursor: pointer;
    p {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  &:first-child {
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
  &:nth-of-type(3) {
    margin-right: 60px;
    @media (max-width: 600px) {
      margin-right: 60px;
    }
  }
  &:nth-of-type(2),
  &:nth-of-type(5) {
    @media (max-width: 600px) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const Icon = styled(IonIcon)`
  color: #6470cf;
`;

interface DetailsInputs {
  farm: PoolObject;
  locked: boolean;
  openDepositModal: (poolObj: PoolObject) => void;
  openWithdrawModal: (poolObj: PoolObject) => void;
  unlock: () => void;
  claim: (pool: PoolObject) => void;
}
const Details: React.FC<DetailsInputs> = ({
  farm,
  locked,
  openDepositModal,
  openWithdrawModal,
  unlock,
  claim,
}) => {
  return (
    <DetailDiv className={`detail${farm.index}`}>
      <DetailCard className='first grid'>
        <IonText className='iontext' color='success'>
          Get {farm.poolSymbol}
        </IonText>{" "}
        {/* link to deposit to pool goes here */}
        <IonText className='iontext' color='success'>
          View contract
        </IonText>{" "}
        {/* link to blockchain explorer goes here */}
        <IonText className='iontext' color='success'>
          See Pair information
        </IonText>{" "}
        {/* link to the pair contract goes here */}
      </DetailCard>
      <DetailCard className='grid'>
        <EarnedCard /*should be no.1*/
          {...{ poolObj: farm, claim: claim, connected: locked }}
        />
      </DetailCard>
      <DetailCard className='grid'>
        <StakedList
          unlock={unlock}
          locked={locked}
          openDepositModal={openDepositModal}
          openWithdrawModal={openWithdrawModal}
          poolObject={farm}
        />
      </DetailCard>
    </DetailDiv>
  );
};

const ListItem: React.FC<DetailsInputs> = ({
  farm,
  locked,
  openDepositModal,
  openWithdrawModal,
  unlock,
  claim,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const valueLocked = useTotalLpTokenValue(farm.poolSymbol);

  return (
    <>
      <ListItems>
        <Items>
          {farm.index + 1}. {farm.poolSymbol}
        </Items>
        <Items>
          {farm.earned}
          <Icon icon={helpCircleOutline} />
        </Items>
        <Items>
          {`${farm.apr}%`}
          <Icon src='/svgs/calculator.svg' />
        </Items>
        <Items>
          ${valueLocked.toNumber().toFixed(2)}
          <Icon icon={helpCircleOutline} />
        </Items>
        <Items>
          {farm.allocPoint}X
          <Icon icon={helpCircleOutline} />
        </Items>

        <Items
          className='details'
          onClick={() => (!open ? setOpen(true) : setOpen(false))}>
          {!open ? (
            <>
              <p>Details</p>
              <IonIcon icon={chevronDownOutline} />
            </>
          ) : (
            <>
              Hide <IonIcon icon={chevronUpOutline} />
            </>
          )}
        </Items>
      </ListItems>
      {open ? (
        <Details
          farm={farm}
          locked={locked}
          openDepositModal={openDepositModal}
          openWithdrawModal={openWithdrawModal}
          unlock={unlock}
          claim={claim}
        />
      ) : null}
    </>
  );
};

export default ListItem;
