import styled from "styled-components";

export { default as AuctionCard } from "./Auctions/AuctionCard";
export { default as AuctionDetails } from "./Auctions/AuctionDetails";
export { default as Balance } from "./Auctions/Balance";
export { default as Loading } from "./Auctions/Loading";
export { default as GlobalLoading } from "./GlobalLoading";
export { default as LoadingDetails } from "./Auctions/LoadingDetails";
export { default as LockerCases } from "./Auctions/LockerCase";
export { default as Footer } from "./Footer/Footer";
export { default as Input } from "./Form/Input";
export { default as InputField } from "./Form/InputField";
export { default as SelectField } from "./Form/SelectField";
export { default as Hero } from "./Hero/Hero";
// export { default as EmptyAuction} from './EmptyPost'
export { default as Metatag } from "./Metatag";
export { default as BuyModal } from "./Modal/BuyModal";
export { default as CaseModal } from "./Modal/CaseModal";
export { default as Exchange } from "./Modal/Exchange";
export { default as Nav } from "./NavBar/Nav";
export { default as GridFarm } from "./Pool/GridFarm";
export { default as ListFarm } from "./Pool/ListFarm";
export { default as SideBar } from "./Pool/SideBar";
export { default as SwapCard } from "./Pool/SwapCard";
export { default as Tab } from "./Pool/Tab";
export { default as Deposit } from "./Setup/Deposit";
export { default as MinMax } from "./Setup/MinMax";
export { default as ProjectUrl } from "./Setup/ProjectUrl";
export { default as Whitelist } from "./Setup/Whitelist";
export { default as UserSearchList } from "./UserSearchList";

export const GridComponent = styled.div`
  width: 100%;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* padding: 10px; */
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-gap: 1rem;
  align-items: center;
  // height: 100%;

  @media (max-width: 640px) {
    grid-gap: 1rem;
  }
`;


export const GridCreator = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  align-items: center;

  @media (max-width: 640px) {
    grid-gap: 1rem;
  }
`;

export const SetupContainer = styled.div`
  max-width: 450px;
  margin: 50px auto;
  position: relative;
  background: var(--ion-color-card);
  /* box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2); */
  border-radius: 7px;
  padding: 20px;
  overflow: hidden;
`;

export const Modal = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  position: fixed;
  background: rgb(8 20 110 / 43%);
`;

export const ModalCard1 = styled.div`
  padding: 10px;
  width: 350px;
  background: white;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border-radius: 30px;
  height: 370px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  z-index: 1000;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
`;

export const Spacer = styled.div<{
  height?: number;
  width?: number;
  heightM?: number;
  widthM?: number;
}>`
  height: 10px;
  height: ${({ height }) => height && height + "px"};
  width: 0px;
  width: ${({ width }) => width && width + "px"};

  @media (max-width: 640px) {
    height: ${({ heightM }) => heightM && heightM + "px"};
    width: ${({ widthM }) => widthM && widthM + "px"};
  }
`;

export const Flex = styled.div<{
  direction?: "row" | "column";
  align?: string;
  justify?: "space-between" | "space-evenly" | "space-around" | "center";
  directionM?: string;
  margin?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  align-items: ${({ align }) => (align ? align : "flex-start")};
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  margin: ${({ margin }) => (margin ? margin : "0")};

  &.home-p {
    @media (max-width: 640px) {
      padding: 10px 0px;
      margin: 0px 24px;
    }
  }
  @media (max-width: 640px) {
    flex-direction: ${({ directionM }) => directionM && directionM};
  }
`;

export const Text = styled.div<{
  weight?: string;
  size?: string;
  color?: string;
  sizeM?: string;
  padding?: string;
}>`
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  font-size: ${({ size }) => (size ? size + "px" : "12px")};
  color: ${({ color }) => (color ? color : "#000000")};
  padding: ${({ padding }) => (padding ? padding + "px" : "0px")};

  @media (max-width: 640px) {
    font-size: ${({ sizeM }) => sizeM && sizeM + "px"};
  }
`;

export const Avatar = styled.div<{ size?: string }>`
  height: ${({ size }) => (size ? size + "px" : "25px")};
  width: ${({ size }) => (size ? size + "px" : "25px")};
  border-radius: 50%;

  img {
    border-radius: 50%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border: 0;
    object-fit: cover;
  }
  &.creator {
    position: absolute;
    right: 10px;
    top: -30px;
  }
`;

export const Logo = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  width: 100px;

  img {
    width: 100%;
    /* height: 33px; */
    transform: scale(2) translateX(19px);
  }
`;

export const LoadingLogo=styled.div`
position:relative;
width:100px;
z-index:999;
margin:auto;   
img{
    animation:rotateIt 1.5s  infinite ;
}

@keyframes rotateIt{
    0%{
           transform:rotate(0deg)
       }
       100%{
           transform:rotate(360deg)
       }
   }
`