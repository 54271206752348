import tokens from "config/tokens";
import { IPaymentsTo } from "types/nft-user-types";
import { toChecksum } from "utils/nft-user-helper";
import { Contract } from "web3-eth-contract";

export const createAuction = (
  mktplaceContract: Contract,
  paymentsTo: IPaymentsTo[],
  token: string,
  tokenId: string,
  basePrice: string,
  paymentMethod: string,
  account: string
) => {
  console.log(`paymentsTo`, paymentsTo);
  let payTos = paymentsTo.map(({ to, percent }) => {
    return {
      to: toChecksum(to),
      percent: percent.multipliedBy(10).toString(10),
    };
  });
  console.log("PayTos: ", payTos);
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .createAuction(payTos, token, tokenId, basePrice, paymentMethod)
    .send({ from: account, gasPrice: "20000000000" });
};

export const makeBid = (
  mktplaceContract: Contract,
  auctionId: string,
  bidValue: string,
  paymentMethod: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  if (paymentMethod === tokens.bnb.address.bsc) {
    return mktplaceContract.methods
      .makeBid(auctionId, bidValue)
      .send({ from: account, value: bidValue });
  } else
    return mktplaceContract.methods
      .makeBid(auctionId, bidValue)
      .send({ from: account, gasPrice: "20000000000" });
};

export const updatePaymentMethod = (
  mktplaceContract: Contract,
  auctionId: string,
  paymentMtd: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .updatePaymentMethod(auctionId, paymentMtd)
    .send({ from: account, gasPrice: "20000000000" });
};

export const cancelAuction = (
  mktplaceContract: Contract,
  auctionId: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .cancelAuction(auctionId)
    .send({ from: account, gasPrice: "20000000000" });
};

export const closeAuction = (
  mktplaceContract: Contract,
  auctionId: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .closeAuction(auctionId)
    .send({ from: account, gasPrice: "20000000000" });
};
export const updateBasePrice = (
  mktplaceContract: Contract,
  auctionId: string,
  newBasePrice: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .updateBasePrice(auctionId, newBasePrice)
    .send({ from: account, gasPrice: "20000000000" });
};

export const changeAuctionOwnerAddress = (
  mktplaceContract: Contract,
  auctionId: string,
  newAuctionOwnerAddress: string,
  account: string
) => {
  mktplaceContract.handleRevert = true;
  return mktplaceContract.methods
    .changeAuctionOwnerAddress(auctionId, newAuctionOwnerAddress)
    .send({ from: account, gasPrice: "20000000000" });
};
