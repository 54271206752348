import React from "react";
import styled from "styled-components";
import { IonText } from "@ionic/react";


const InputContainer = styled.div`
    max-width: 386px;
    height: 87px;
    background: #2A3390;
    border-radius:20px;
    margin:10px auto;
    @media(max-width:600px){
        margin:10px 20px;
    }


`;


const Items = styled.div`
    display:flex;
    flex-direction:row;
    padding:10px;
    align-items:center;
    justify-content:space-between;

    & span{
        color: #CACCE3;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;

    }

`;
const ItemsIn = styled.div`
    display:flex;
    flex-direction:row;
    padding:10px;
    align-items:center;
    justify-content:space-between;

    position: relative;
    top: -18px;
`

const Logo = styled.div`
  display:flex;
  flex-direction:row;
  padding:10px;
  align-items:center;
  justify-content:space-between;
`
const SelectTo = styled.div`
     background: #5F66AC;
    border-radius: 15px; 
    padding:10px 24px;
    font-weight: bold;
    font-size: 16px;
    line-height:26px;
    color: #CACCE3;
    @media(max-width:600px){
        padding:5px 18px;
        font-size: 14px;
    }

    `
const Input = styled.input`
      font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    outline: none;
    color: #fff;
    background: #2a3390;
    border: none;
    max-width: 120px;

`

interface InputInterface {


}

const InputFrom: React.FC<InputInterface> = () => {

    return (
        <InputContainer>
            <Items>
                <span>To</span>
                <span>0.0002</span>
            </Items>

            <ItemsIn>
                <Input placeholder="0.0" step='any' type="number"></Input>
                <SelectTo>Select a token</SelectTo>
            </ItemsIn>
        </InputContainer>
    )
}

export default InputFrom