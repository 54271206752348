import { IonCard, IonIcon } from "@ionic/react";
import { Flex, Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import { BscScan, ConfirmCheck } from "components/SvgIcons";
import { getExplorerUrl } from "config";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { getChainFromChainId } from "utils/nft-user-helper";

const Container = styled(IonCard)`
  max-width: 500px;
  height: auto;
  border-radius: 5px;
  color: #333;
  padding: 0 20px;
  overflow-y: auto;
  margin: auto;
`;

const Close = styled.div`
  padding: 15px 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
`;

const ButtonFlex = styled(Flex)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px;
`;

const Contain = styled.div`
  padding: 10px;
  text-align: center;
`;

interface PropertyInt {
  close: () => void;
  trxHashUrl?: string;
  addTokenFunc?: () => void;
}

const Confirm: React.FC<PropertyInt> = ({
  close,
  addTokenFunc,
  trxHashUrl,
}: PropertyInt) => {
  const { chainId } = useActiveWeb3React();
  return (
    <Container>
      <div
        onClick={close}
        style={{
          float: "right",
          padding: "20px",
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          top: "10px",
        }}
      >
        <IonIcon icon={closeOutline} style={{ fontSize: 20 }} />
      </div>

      <Contain>
        <ConfirmCheck />

        <Text size="32" weight="500">
          Transaction successful
        </Text>
        <Spacer />
        <Text size="24">Your transaction has been sent</Text>

        <Spacer height={20} />

        <Flex justify="center">
          {/* <ButtonFlex align="center">
            <Metamask />
            <Spacer width={5} />
            <Text>Add Token to Metamask</Text>
          </ButtonFlex> */}

          <a
            href={`${getExplorerUrl(
              getChainFromChainId(chainId) ?? "test",
              "trx"
            )}${trxHashUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonFlex align="center">
              <BscScan />
              <Spacer width={5} />
              <Text>View Transaction on explorer</Text>
            </ButtonFlex>
          </a>
        </Flex>
        <Spacer height={50} />
        <ButtonPrimary onClick={close}>Close</ButtonPrimary>
      </Contain>
    </Container>
  );
};

export default Confirm;
