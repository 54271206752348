export const DEX_FACTORY = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"; // at index 0
export const DEX_ROUTER = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"; // at index 1
export const WETH = "0xc778417E063141139Fce010982780140Aa0cD5Ab"; // at index 2

export const ETHERSCAN_URL = "https://rinkeby.etherscan.io/tx/";
export const BINANCE_URL = "https://bscscan.com/tx/";
export const ETHERSCAN_ADDRESS_URL = "https://rinkeby.etherscan.io/address/";
export const BSC_ADDRESS_URL = "https://bscscan.com/address/";


export const MarketPlace ="0x73f59584f51FA96aeba21C53D8D05C5a6CD3A52C"
export const DelfyNft ="0x3fa95e9D449BecFe2a08aD235faB37A01E72B826"





// export const FACTORY_ADDRESS = "0x2a002A41d7879D58d6B897455d576aF08B47195f";
// export const FACTORY_ADDRESS = "0x2b1E8BbaB805477b44B08Bde24CF4200502B683e";
export const FACTORY_ADDRESS = "0x99417fE898A54C562F5C866EEc31BF17E9a782DE";
// export const FACTORY_ADDRESS = "0xa08feA16f38fA0feb6Aa53ef3C1683cd779ff985";
export const DELFY_LOCKER_ADDRESS =
  "0x43b499b84EAfe9cEF0fB2d7d5DeFb744B75A4e2F";
  
  // 
export const BSC_FACTORY_ADDRESS = "0xB6eA12B63036A77F822c359bBA66E8542D9BbCdc";
export const BSC_DELFY_LOCKER_ADDRESS ="0x18f1545d7d2564baD5546ed64a35503b5ba3073A";

  
export const NETWORK_ETH = 4
export const NETWORK_BSC = 97



export const EXCHANGE_BSC = [
  {
    name: "cheeseSwap",
    addresses: [
      "0xdd538E4Fd1b69B7863E1F741213276A6Cf1EfB3B",
      "0x3047799262d8D2EF41eD2a222205968bC9B0d895",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    logo: "exchanges/cheeswap.png",
  },
  {
    name: "pancakeSwap",
    addresses: [
      "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
      "0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    logo: "exchanges/pancake.jpg",
  },
  {
    name: "BSCSwap",
    addresses: [
      "0xCe8fd65646F2a2a897755A1188C04aCe94D2B8D0",
      "0xd954551853F55deb4Ae31407c423e67B1621424A",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    logo: "exchanges/bscswap.png",
  },
  {
    name: "BakerySwap",
    addresses: [
      "0x01bF7C66c6BD861915CdaaE475042d3c4BaE16A7",
      "0xCDe540d7eAFE93aC5fE6233Bee57E1270D3E330F",
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    ],
    logo: "exchanges/bakeryswap.jpg",
  },
];

export const EXCHANGE_ETH = [
  {
    name: "Uniswap",
    addresses: [
      "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
      "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    ],
    logo: "exchanges/uniswap.png",
  },
  {
    name: "SushSwap",
    addresses: [
      "0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac",
      "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    ],
    logo: "exchanges/sushswap.jpg",
  },
];

export const EXCHANGE_ETH_TEST = [
  {
    name: "ETH TESTNET",
    addresses: [
      "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
      "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      "0xc778417E063141139Fce010982780140Aa0cD5Ab",
    ],
    logo: "exchanges/sushswap.jpg",
  },
];

export const EXCHANGE_BSC_TEST = [
  {
    name: "BSC TESTNET",
    addresses: [
      "0xC6Ae75acE5974e751e72AF6c857057D9E896A5DA",
      "0x829461e539B1Be3606597511A4365301c10F9865",
      "0xBD9e181f3B23F8ED6f8Ea9f4E4c30Eb1DEe5b611",
    ],
    logo: "exchanges/sushswap.jpg",
  },
];

/* Use the bellow addresses for the BSC chain factory and WETH respectively */

export const BSC_DEX_FACTORY = "0xC6Ae75acE5974e751e72AF6c857057D9E896A5DA"; // at index 0
export const BSC_DEX_ROUTER = "0x829461e539B1Be3606597511A4365301c10F9865"; // at index 1
export const BSC_DEX_WETH = "0xBD9e181f3B23F8ED6f8Ea9f4E4c30Eb1DEe5b611"; // at index 2


export const RINKEBY_STAKING_ADDRESS = "0x5f72674ed9Eff7C2E9D82ce5d8258abe36dEfE6f"
export const BSC_TEST_STAKING_ADDRESS = ""
export const BSC_MAIN_STAKING_ADDRESS = ""

export const RINKEBY_NFTMKTPLACE_ADDRESS = "0x37F095d887B0735B461CF185cC4B2158D3C628C4"
export const BSC_TEST_NFTMKTPLACE_ADDRESS = ""
export const BSC_MAIN_NFTMKTPLACE_ADDRESS = ""

export const RINKEBY_MULTICALL_ADDRESS = "0xF8919bC5E99DB792d85B3734B5a809fE30E706ab"
export const BSC_TEST_MULTICALL_ADDRESS = ""
export const BSC_MAIN_MULTICALL_ADDRESS = ""

