import { IonButton, IonCard, IonIcon, IonInput } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";
import { closeOutline, openOutline } from "ionicons/icons";
import { PoolObject } from "types/farm-types";

export const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`;
export const Card = styled(IonCard)`
  width: 400px;
  height: 280px;
  background-color: #eee;
  border-radius: 25px;
  position: relative;
  margin: auto;
  top: 10%;
`;
export const InputAreaContainer = styled.div`
  width: 90%;
  height: 95px;
  margin: 20px auto 0;
  border-radius: 25px;
  border: 2px solid #ccdccd;
  position: relative;
  background-color: #ccc;
  display: flex;
  flex-diection: column;
`;

export const TextArea = styled.div`
  position: absolute;
  top: 0;
  height: 30%;
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  &p {
    top: 0;
    height: 100%x;
  }
`;
export const CardInput = styled(IonInput)`
  position: relative;
  height: 45%;
  top: 50%;
  margin: 0 10px;
  font-size: 18px;
  color: #000;
  max-width: 55% !important;
`;
export const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;
export const Icon = styled(IonIcon)`
  font-size: 25px;
`;

export const Divider = styled.div`
  padding: 1px;
  background-color: #cccddd;
`;
export const InputButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  &.btn {
    margin-right: 4px;
  }
`;
export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const LargeButton = styled(IonButton)`
  margin: 5px 20px 0;
  background-color: #2fdf75 !important;
`;

export const MediumButton = styled(IonButton)`
  --border-radius: 15px !important;
  width: 100%;
  margin: 4px 20px;
`;
export const TexWithLink = styled.div`
  align-items: center;
  text-align: center;
  font-size: 28px;
`;
export const Errors = styled.div`
  text-align: left;
  margin: 2px 20px;
  color: red;
`;

export const SmallButton = styled(IonButton)`
  --border-radius: 17px !important;
  margin: auto 7px;
`;
interface StakeInput {
  locked: boolean;
  poolObj: PoolObject;
  liquidityRouteLink?: string;
  approve: () => void;
  deposit: (amount: number) => void;
  onDismiss: () => void;
}
const StakeCard: React.FC<StakeInput> = (stakeInput: StakeInput) => {
  const [userInput, setUserInput] = useState<number>(0);
  return (
    <CardContainer>
      <Card>
        <Header>
          <div>Stake {stakeInput.poolObj.poolName}</div>
          <Icon onClick={() => stakeInput.onDismiss()} icon={closeOutline} />
        </Header>
        <Divider />
        <InputAreaContainer>
          <TextArea>
            <p>Stake</p>
            <p>Balance: {stakeInput.poolObj.walletBalance}</p>
          </TextArea>
          <InputButtonContainer>
            <CardInput
              step='any'
              placeholder='0.0000'
              min='0'
              type='number'
              onIonChange={(e) => setUserInput(+e.detail.value)}
            />

            <RightSide>
              <SmallButton className='btn' size='small' mode='ios'>
                max
              </SmallButton>
              <h6>{stakeInput.poolObj.poolSymbol}</h6>
            </RightSide>
          </InputButtonContainer>
        </InputAreaContainer>
        <Errors>
          No tokens to stake: Get {stakeInput.poolObj.poolSymbol} tokens
        </Errors>
        {stakeInput.locked ? (
          <LargeButton expand='block' color='success'>
            Unlock Wallet
          </LargeButton>
        ) : (
          <ButtonArea>
            {stakeInput.poolObj.allowance === "0" ? (
              <MediumButton onClick={() => stakeInput.approve()}>
                Approve
              </MediumButton>
            ) : (
              <MediumButton onClick={() => stakeInput.deposit(userInput)}>
                Confirm
              </MediumButton>
            )}
          </ButtonArea>
        )}
        <TexWithLink>
          <h6>
            Get {stakeInput.poolObj.poolSymbol}
            <IonIcon icon={openOutline} />
          </h6>
        </TexWithLink>
      </Card>
    </CardContainer>
  );
};

export default StakeCard;
