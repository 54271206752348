import { IonIcon } from "@ionic/react";
import Api from "api";
import { Avatar, Flex, Spacer, Text } from "components";
import {
  Auction,
  AuctionState,
  Bid,
  useGetAuctionLazyQuery,
  useGetNftByAddressAndTokenIdQuery,
} from "generated/graphql-frontend";
import { heartOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { INFTMetadata, ISocialFull } from "types/nft-user-types";
import { getAddressZero, getNFTERC721Address } from "utils/addressHelper";
import { getFullDisplayBalance } from "utils/formatBalance";
import { formatIpfsUrl, getMimeType } from "utils/ipfsUrlHelper";
import {
  getChainLogo,
  getTokenSymbol,
  getUserSocial,
} from "utils/nft-user-helper";
import { stringToCapitalized } from "utils/text-help";
// https://ipfs.io/ipfs/QmPMzRbSmpYdhNfoLatxY4JBwTBD1kCjSdrr86rxYUdRXk

export const CardContainer = styled.div`
  max-width: 360px;
  background: var(--ion-color-card);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 10px 0px;
  overflow: hidden;
  filter: drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.08));

  & .p {
    line-height: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    margin: 20px 0px;
  }
`;

const FlexCommon = `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const CardHeader = styled.div`
  ${FlexCommon};
  padding: 10px;
`;
const CardFooter = styled.div`
  ${FlexCommon};
`;

const ImageCon = styled.div`
  & img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }
`;
const VideoCon = styled.div`
  width: 100%;
  height: calc(100% * 9 / 16);
  position: relative;
  & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

interface CardInterface {
  auction?: Auction;
  bids?: Bid;
}

const Card: React.FC<CardInterface> = ({ auction }) => {
  const history = useHistory();

  const [getAuction, { data, loading, error }] = useGetAuctionLazyQuery();

  const [dbAuction, setDbAuction] = useState<Auction>(null);
  const [metadata, setMetadata] = useState<INFTMetadata>(null);
  const [profile, setProfile] = useState<ISocialFull>(null);
  const [hasBids, setHasBids] = useState<boolean>(false);
  const [mimeType, setMimeType] = useState("image");

  const isCoterie = () =>
    auction?.nftToken?.contractAddress === getNFTERC721Address();
  const [_, setState] = useState({});
  const dbNft = useGetNftByAddressAndTokenIdQuery({
    variables: {
      address: auction?.nftToken?.contractAddress ?? getAddressZero(), // value for 'getNftByAddressAndTokenIdAddress'
      tokenId: auction?.nftToken?.tokenId ?? "0", // value for 'getNftByAddressAndTokenIdTokenId'
    },
  });

  const getProfile = async () => {
    if (data?.getAuction) {
      const social = await getUserSocial(
        formatIpfsUrl(data?.getAuction?.owner?.profileHash)
      );
      setProfile({
        ...social,
        name: data?.getAuction?.owner?.name,
        username: data?.getAuction?.owner?.username,
        id: data?.getAuction?.owner?.id,
        address: data?.getAuction?.owner?.address,
      });
    }
  };

  const setMeta = async () => {
    const meta = await Api.nft.ipfs(formatIpfsUrl(auction?.nftToken?.tokenUri));
    const _data: any = meta.data;
    const mime = await getMimeType(_data?.image);
    setMetadata(meta?.data);
    setMimeType(mime);
  };

  useEffect(() => {
    if (auction) {
      getAuction({
        variables: {
          getAuctionId: auction?.id,
        },
      });
    }
    if (!loading && !error && data?.getAuction) {
      setDbAuction(data?.getAuction as Auction);
      setMeta();
      getProfile();
      setHasBids(() => auction && auction?.bids?.length > 0);
    }
    return () => {
      setState({});
    };
    // eslint-disable-next-line
  }, [auction, data]);
  const handleClick = () => {
    if (!loading && data?.getAuction)
      history.push(`/nfts/nft/${data?.getAuction?.id}`, {});
  };

  const bidValue = () => {
    if (auction) {
      if (+auction?.closingValue > 0) {
        return getFullDisplayBalance(+auction?.closingValue, 18, 3);
      }
      return getFullDisplayBalance(auction?.basePrice);
    }
  };

  return (
    <>
      {auction && (
        <CardContainer>
          <CardHeader>
            <Text>{`${dbAuction?.viewCount ?? 0} Views`}</Text>
            <Flex align="center">
              <IonIcon icon={heartOutline} />
              <Text style={{ marginLeft: "5px" }}>{dbAuction?.likeCount}</Text>
            </Flex>
          </CardHeader>

          {mimeType === "video" ? (
            <VideoCon>
              <video
                controls={true}
                muted={true}
                src={formatIpfsUrl(metadata?.image)}
              ></video>
            </VideoCon>
          ) : (
            <ImageCon onClick={handleClick} style={{ height: "250px" }}>
              <img src={formatIpfsUrl(metadata?.image)} alt={metadata?.name} />
            </ImageCon>
          )}

          <CardFooter>
            <Flex>
              <Flex direction="column">
                <Text weight="bold" size="16">
                  {stringToCapitalized(metadata?.name)}
                </Text>
                <Spacer />
                <Link to={`/users/user/${profile?.id}`}>
                  <Flex>
                    <Avatar>
                      <img
                        src={formatIpfsUrl(profile?.imageHash)}
                        alt={profile?.name}
                      />
                    </Avatar>
                    <Text style={{ margin: 5 }}>
                      {profile?.name || profile?.username}
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </Flex>

            <Flex>
              <Flex direction="column" align="flex-end">
                <Text style={{ margin: 3 }}>
                  {dbAuction?.bids?.length &&
                  dbAuction?.state !== AuctionState.Sold
                    ? `Current Bid`
                    : dbAuction?.bids?.length &&
                      dbAuction?.state === AuctionState.Sold
                    ? `Sold For`
                    : `Reserved Price`}
                </Text>
                <Spacer />
                <Flex>
                  <Avatar size="20">
                    <img
                      src={getChainLogo(dbAuction?.chain)}
                      alt={dbAuction?.chain}
                    />
                  </Avatar>
                  &nbsp;
                  <Text
                    size="16"
                    style={{ whiteSpace: "nowrap" }}
                  >{`${bidValue()} ${getTokenSymbol(
                    auction?.paymentMethod
                  )}`}</Text>
                </Flex>
              </Flex>
            </Flex>
          </CardFooter>
        </CardContainer>
      )}
    </>
  );
};

export default Card;
