import { IonButton, IonChip, IonIcon, IonInput } from "@ionic/react";
import { Avatar, Flex, Spacer, Text } from "components";
import { add, close } from "ionicons/icons";
import React, { useState } from "react";
import styled from "styled-components";
import { IUserSearch } from "types/nft-user-types";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import { truncateWalletAddress } from "utils/truncateWalletAddress";

interface UserSearchListInt {
  user?: IUserSearch;
  addFunc?: (addr: string, nbr: number) => boolean;
  removeFunc?: (addr: string) => void;
  placeholder?: string;
}

const Container = styled.div`
  background: rgba(106, 100, 255, 0.05);
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 15px 0px;
  padding: 5px 10px;
`;
const Detail = styled(IonInput)`
  // border-radius: 7px;
  /* width */
  margin: 0 20px 0 10px;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
`;

const AddedState = styled(IonChip)`
  margin: 0 20px 0 10px;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
`;

const UserSearchList: React.FC<UserSearchListInt> = ({
  user,
  addFunc,
  removeFunc,
  placeholder,
}) => {
  const { address, name, username, imageHash } = user;
  const [value, setValue] = useState<number>(null);
  const [added, setAdded] = useState<boolean>(false);

  const handleAdded = () => {
    const add = addFunc(address, value);
    setAdded(add);
  };

  const handleRemove = () => {
    console.log("Removed");
    removeFunc(address);
    setValue(0);
    setAdded(false);
  };
  return (
    <Container>
      <Flex align="center">
        <Avatar size="32">
          <img
            style={{ opacity: 0.5 }}
            src={
              imageHash?.length
                ? formatIpfsUrl(imageHash)
                : "/assets/user_avatar.jpg"
            }
            alt="user"
          />
        </Avatar>
        <Flex direction="column" style={{ marginLeft: 15 }}>
          <Text color="#333333">{`@${username}` ?? name}</Text>
          <Text color="#333333" style={{ marginTop: 5 }} size="10">
            {truncateWalletAddress(address, 8, 6)}
          </Text>
        </Flex>

        {!added ? (
          <Detail
            type="number"
            step="any"
            min="0"
            onIonChange={(e) => setValue(+e.detail.value)}
            placeholder={placeholder ?? "2.5%"}
          />
        ) : (
          <AddedState>{value}%</AddedState>
        )}

        <Flex align="center">
          {/* <Text color="rgba(51, 51, 51, 0.5);">406 USDT</Text> */}
          <Spacer width={5} />
          {added ? (
            <IonButton onClick={() => handleRemove()} fill="clear">
              <IonIcon
                mode="ios"
                color="danger"
                style={{ fontSize: 24 }}
                icon={close}
              />
            </IonButton>
          ) : (
            <IonButton onClick={handleAdded} disabled={!value} fill="clear">
              <IonIcon
                mode="ios"
                color="success"
                style={{ fontSize: 24 }}
                icon={add}
              />
            </IonButton>
          )}
          <Spacer width={5} />
        </Flex>
      </Flex>
    </Container>
  );
};

export default UserSearchList;
