import { createSlice } from '@reduxjs/toolkit'

interface CasesInterface{
  cases:{
    case0:any;
    case1:any;
    contributorsCount:number;
    canCreateCase:any;
    caseCreated:any
  } | undefined;
  loading:boolean;
  error?:any
}

const initialState:CasesInterface={
  cases:{
    case0:[],
    case1:[],
    contributorsCount:0,
    canCreateCase:'',
    caseCreated:''
  },
  loading:false,
  error:null
}
const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setCases: (state,{payload}) => {
    state.cases=payload;
    state.loading=false
    },
    setLoading:(state,{payload})=>void(state.loading=payload)
  }
})


export const { setCases,setLoading } = casesSlice.actions


export default casesSlice.reducer