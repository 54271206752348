import React from 'react';
import { PoolObject } from '../../types/farm-types';
import GridFarmCard from './GridFarmCard';

interface GridFarmInterface{
   farms?: Array<PoolObject>
    unlock?: ()=>void
    locked?: boolean
    openWithdrawModal?: (poolObj:PoolObject)=> void
    openDepositModal?: (poolObj:PoolObject)=> void
    claim?: (pool:PoolObject)=> void
}

const GridFarm: React.FC<GridFarmInterface> = ({farms, unlock, locked, openDepositModal, openWithdrawModal, claim}) => {
  return (
    <>
     {farms.map((farm:PoolObject,i:any) => (
        <GridFarmCard key={i} farm={farm} unlock={unlock} locked={locked} openDepositModal={openDepositModal} openWithdrawModal={openWithdrawModal} claim={claim}/>))
        }
    </>
  )
}

export default GridFarm

