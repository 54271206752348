import React, { FC } from "react";
import styled from "styled-components";
import { Avatar, Flex, GridComponent, Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import DataTable from "components/Pool/DataTable";
import { EarningsCard } from "components/Card";

interface PeopleInterface {}

const ConBody = styled.div`
  width: calc(100% + 260px);

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const Earnings = styled.div`
  display: flex;
  width: 70%;
  justify-content: center;
  flex-wrap: wrap;

  .item {
    padding: 10px;
  }

  @media (max-width: 640px) {
    width: 100%;
    justify-content: flex-start;

    .item {
    }
  }
`;

const Body = styled.div`
  padding-left: 15px;
  display: flex;
  justify-content: space-between;

  .data {
    width: 70%;
    margin: 0px 10px;

    @media (max-width: 640px) {
      width: 100%;
    }
  }
  @media (max-width: 640px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Header = styled.div`
  margin: 20px 0px;

  @media (max-width: 640px) {
  }
`;

const fields = [
  { label: "Unit Price", key: "price" },
  { label: "Unit Price(USD)", key: "price_usd" },
  { label: "Quantity", key: "qty" },
  { label: "Form", key: "from" },
];

const EarningsView: FC<PeopleInterface> = ({}) => (
  <ConBody>
    <section>
      <Header>
        <Flex justify="space-between">
          <Earnings>
            <Flex
              className="item"
              align="center"
              direction="column"
              justify="center"
            >
              <Text>Available Balance</Text>
              <Text size="28" sizeM="24" weight="600">
                $40,333.00
              </Text>
            </Flex>

            <Flex
              className="item"
              align="center"
              direction="column"
              justify="center"
            >
              <Text>Available Balance</Text>
              <Text size="28" sizeM="24" weight="600">
                $40,333.00
              </Text>
            </Flex>
            
            <Flex
              className="item"
              align="center"
              direction="column"
              justify="center"
            >
              <Text>Available Balance</Text>
              <Text size="28" sizeM="24" weight="600">
                $40,333.00
              </Text>
            </Flex>
          </Earnings>

          <div style={{ width: "30%" }}>
            <ButtonPrimary>
              Withdraw <Spacer width={10} />
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 14.053C20 16.492 18.015 18.478 15.575 18.478H4.436C1.99 18.478 0 16.487 0 14.042V9.157C0 6.718 1.985 4.733 4.425 4.733H5.367C5.781 4.733 6.117 5.069 6.117 5.483C6.117 5.897 5.781 6.233 5.367 6.233H4.425C2.813 6.233 1.5 7.545 1.5 9.157V14.042C1.5 15.661 2.816 16.978 4.436 16.978H15.575C17.187 16.978 18.5 15.665 18.5 14.053V9.168C18.5 7.55 17.184 6.233 15.566 6.233H14.633C14.219 6.233 13.883 5.897 13.883 5.483C13.883 5.069 14.219 4.733 14.633 4.733H15.566C18.011 4.733 20 6.723 20 9.168V14.053ZM13.447 10.3923L10.5302 13.3212C10.5065 13.345 10.4812 13.3672 10.4544 13.3876L10.531 13.3213C10.4916 13.3612 10.4482 13.3962 10.4016 13.4258C10.3899 13.4318 10.3785 13.4387 10.3669 13.4452C10.349 13.4566 10.33 13.4663 10.3106 13.4751C10.2968 13.4801 10.2831 13.4858 10.2692 13.4912C10.2502 13.4997 10.2307 13.5063 10.2109 13.5121C10.1953 13.5155 10.1797 13.5195 10.164 13.523C10.1449 13.5285 10.1257 13.532 10.1064 13.5347C10.0976 13.5348 10.0884 13.5359 10.0791 13.5369C10.0527 13.5409 10.0264 13.5423 10 13.5423L9.921 13.535L9.89827 13.5341C9.89658 13.5339 9.89488 13.5337 9.89319 13.5334L10 13.5423C9.94449 13.5423 9.88952 13.5362 9.83608 13.5242C9.82026 13.5195 9.8047 13.5155 9.78935 13.511C9.76931 13.5063 9.74977 13.4997 9.73055 13.4923C9.71688 13.4858 9.7032 13.4801 9.68972 13.4739C9.67004 13.4663 9.65102 13.4566 9.63242 13.4461C9.62152 13.4387 9.61011 13.4318 9.5989 13.4247C9.57983 13.414 9.56172 13.4013 9.54417 13.3878C9.5338 13.3786 9.52318 13.37 9.51281 13.3611C9.49752 13.3491 9.483 13.3355 9.469 13.3213L6.554 10.3923C6.408 10.2463 6.335 10.0543 6.335 9.8633C6.335 9.6713 6.408 9.4783 6.556 9.3323C6.85 9.0393 7.324 9.0413 7.616 9.3343L9.25 10.975V0.75C9.25 0.336 9.586 0 10 0C10.414 0 10.75 0.336 10.75 0.75V10.975L12.385 9.3343C12.677 9.0413 13.151 9.0393 13.445 9.3323C13.739 9.6253 13.739 10.0993 13.447 10.3923Z"
                  fill="white"
                />
              </svg>
            </ButtonPrimary>
          </div>
        </Flex>
      </Header>

      <Body>
        <div className="data">
          <DataTable
            {...{
              fields,
              data: [],
            }}
          />
        </div>
        <div>
          <EarningsCard />
        </div>
      </Body>
    </section>
  </ConBody>
);

export default EarningsView;
