import {
  IonButton,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
} from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { PoolObject } from "../../types/farm-types";
import { claim } from "utils/contractHelps/staking/stakingCalls";

const EarnedContainer = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 25px;
  // border: 1px solid #ecbb2d;
  display: flex;
  flex-direction: row;
`;
const TextArea = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 25px 0 0 25px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ClaimContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 25px 25px 0;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ClaimButton = styled(IonButton)`
  --border-radius: 18px !important;
  @media (max-width: 600px) {
    position: relative;
    top: 45px;
    right: 65px;
  }
`;
const Label = styled(IonLabel)`
  margin-bottom: 12px;
`;

const Earned = styled.div`
  display: block;
`;

const H6 = styled.h6`
  font-size: 18px !important;
  font-weight: 800 !important;
  margin-bottom: 0 !important;
  @media (max-width: 600px) {
    font-size: 14px !important;
  }
`;

interface CardInputs {
  poolObj: PoolObject;
  connected: boolean;
  claim: (pool: PoolObject) => void;
}

const EarnedCard: React.FC<CardInputs> = ({ poolObj, connected, claim }) => {
  return (
    <EarnedContainer>
      <TextArea>
        <Label>{poolObj.nameEarned} Earned</Label>
        <Earned>
          <H6>{poolObj.earned}</H6>
          <small>~${4}</small>
        </Earned>
      </TextArea>
      <ClaimContainer>
        <ClaimButton onClick={() => claim(poolObj)} fill='default'>
          Claim
        </ClaimButton>
      </ClaimContainer>
    </EarnedContainer>
  );
};

export default EarnedCard;
