import { createSlice } from '@reduxjs/toolkit'

const auctionSlice = createSlice({
  name: 'auctions',
  initialState: {
    auctions:[],
    auction:null,
    loading:true,
    error:false
  },
  reducers: {
    newAuction: (state,{payload}) => {
      // state.auctions.push(payload);
    },
    getAuction:(state,{payload})=>{
      // return state.auction=state.auctions.find(data===payload)      
    },
    setAuctions:(state,{payload})=>void(state.auctions=payload),
    setAuction:(state,{payload})=>{
      state.auction=payload
      state.loading=false
    },
    setLoading:(state,{payload})=>void(state.loading=payload)
    
  }
})


export const { newAuction,getAuction,setAuctions ,setAuction,setLoading} = auctionSlice.actions


export default auctionSlice.reducer