import { getAll, getClaim } from "./../utils/callHelper";
import { toEth, amountInPercent } from "./../utils/contractHelper";

// export const useFetchAuctions=()=>{
//     let {data}= useGetAuctions()
//      const fetchAuctions = useCallback(
//        async () => {
//         return await data
//        },
//        [],
//      )

//     return fetchAuctions
// }

const compareHadcap = (value: any, rate: any, hardCap: any) =>
  Math.round((value / rate / hardCap) * 100);

export const getAuction = async (address: string) => {
  const {
    roi,
    rate,
    hardCap,
    initialLiquidity,
    maximumPurchaseEth,
    minimumPurchaseEth,
    availableTokenForSale,
    amountRaised,
    ...others
  } = await getAll(address);

  return {
    roi: roi,
    rate: await toEth(rate.toString()),
    hardCap: await toEth(hardCap.toString()),
    availableTokenForSale: await toEth(hardCap),
    initialLiquidity: compareHadcap(
      await toEth(initialLiquidity.toString()),
      await toEth(rate.toString()),
      await toEth(hardCap.toString()),
    ),
    maximumPurchaseEth: await toEth(maximumPurchaseEth.toString()),
    minimumPurchaseEth: await toEth(minimumPurchaseEth.toString()),
    amountRaised: await toEth(amountRaised.toString()),
    amountPercent: amountInPercent(amountRaised, hardCap),
    ...others,
  };
};

export const getClam = async (address: string, account: string) => {
  const { claimable, tokenBal, ethBal } = await getClaim(address, account);

  return {
    claimable: await toEth(claimable.toString()),
    tokenBal: await toEth(tokenBal.toString()),
    ethBal: await toEth(ethBal.toString()),
  };
};
