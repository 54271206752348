/* eslint-disable require-yield */
import { takeEvery,put,call } from 'redux-saga/effects'
import { FETCH_AUCTIONS_LOCKER } from "./types";
import { setLocker,setLoading } from "../features/locker/LockerSlice";
import { getLocker } from '../services/lockerService'



function* fetchLocker(actions:any) {
    let {auctionAddress,address} =actions.payload
    try {
        yield put(setLoading(true))
        let data:{}=yield call(()=>getLocker(auctionAddress,address)) 
        yield put(setLocker(data))
    } catch (error) {
        console.log(error,"error")
    }

}

// use them in parallel
export default function* casesSaga() {
  yield takeEvery(FETCH_AUCTIONS_LOCKER, fetchLocker)
}