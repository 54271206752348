import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Auction = {
  __typename?: 'Auction';
  auctionId: Scalars['String'];
  basePrice: Scalars['String'];
  bids?: Maybe<Array<Bid>>;
  buyer?: Maybe<User>;
  chain: Blockchain;
  closedAt?: Maybe<Scalars['String']>;
  closingHash?: Maybe<Scalars['String']>;
  closingValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  isPrimarySales: Scalars['Boolean'];
  likeCount?: Maybe<Scalars['Int']>;
  likedBy?: Maybe<Array<User>>;
  nftCreator?: Maybe<User>;
  nftToken: NftToken;
  owner: User;
  payTos?: Maybe<PayTo>;
  paymentMethod: Scalars['String'];
  royalties?: Maybe<Array<Royalty>>;
  sharedHash?: Maybe<Array<Scalars['String']>>;
  soldBy?: Maybe<User>;
  state?: Maybe<AuctionState>;
  trxHash: Scalars['String'];
  viewCount?: Maybe<Scalars['Int']>;
};

export type AuctionInput = {
  auctionId: Scalars['String'];
  basePrice: Scalars['String'];
  chain: Blockchain;
  ownerAddress: Scalars['String'];
  paymentMethod: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokenId: Scalars['String'];
  tokenUri: Scalars['String'];
  trxHash: Scalars['String'];
};

export enum AuctionState {
  Cancelled = 'cancelled',
  Delisted = 'delisted',
  InBid = 'in_bid',
  Onsale = 'onsale',
  Sold = 'sold'
}

export type Bid = {
  __typename?: 'Bid';
  auction: Auction;
  bidder: User;
  closedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  state: BidState;
  trxHash: Scalars['String'];
  value: Scalars['String'];
};

export type BidInput = {
  auctionId: Scalars['String'];
  bidderAddress: Scalars['String'];
  newClosing: Scalars['String'];
  trxHash: Scalars['String'];
  value: Scalars['String'];
};

export enum BidState {
  Active = 'active',
  Dropped = 'dropped',
  Won = 'won'
}

export enum Blockchain {
  Avax = 'avax',
  Bsc = 'bsc',
  Eth = 'eth',
  Matic = 'matic'
}

export type Decrypt = {
  __typename?: 'Decrypt';
  auctionId: Scalars['String'];
  guestId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type EarlyAdopter = {
  __typename?: 'EarlyAdopter';
  address: Scalars['String'];
  chain: Blockchain;
  id: Scalars['ID'];
  signature: Scalars['String'];
  timestamp: Scalars['String'];
};

export type Earning = {
  __typename?: 'Earning';
  auction: Auction;
  id: Scalars['ID'];
  status: EarningStatus;
  timestamp?: Maybe<Scalars['String']>;
  to: User;
  trxHash?: Maybe<Scalars['String']>;
  type: EarningType;
  value: Scalars['String'];
};

export type EarningInput = {
  auctionId: Scalars['String'];
  toAddress: Scalars['String'];
  type: EarningType;
  value: Scalars['String'];
};

export enum EarningStatus {
  Available = 'Available',
  Claimed = 'Claimed',
  Pending = 'pending'
}

export enum EarningType {
  AuctionRef = 'auction_ref',
  AuctionSale = 'auction_sale',
  MinterRef = 'minter_ref',
  Royalty = 'royalty'
}

export enum EventType {
  Announcement = 'announcement',
  AuctionDelisted = 'auction_delisted',
  AuctionSold = 'auction_sold',
  BidAccepted = 'bid_accepted',
  Claimable = 'claimable',
  NewBid = 'new_bid',
  NewFollow = 'new_follow',
  NewRequest = 'new_request',
  OutBid = 'out_bid',
  RequestConfirmed = 'request_confirmed',
  RequestRejected = 'request_rejected'
}

export type MinterAccessRequest = {
  __typename?: 'MinterAccessRequest';
  createdAt: Scalars['String'];
  from: User;
  id: Scalars['ID'];
  status: Status;
  to: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelAuction: Auction;
  closeAuction: Auction;
  createAuction: Auction;
  createBid: Bid;
  createEarlyAdopter: EarlyAdopter;
  createNFTToken: NftToken;
  createUser: User;
  createViewInfo: ViewInfo;
  rejectMinterRequest: MinterAccessRequest;
  requestMinterAccess: MinterAccessRequest;
  updateAuctionLikes: Auction;
  updateAuctionSharedHash: Auction;
  updateAuctionViewCount: Auction;
  updateMinterRequest: MinterAccessRequest;
  updateNotification: Notification;
  updatePayTo: PayTo;
  updateUser: User;
  updateUserBeneficiary: User;
  updateUserFollowing: User;
  verifyUserEmail?: Maybe<User>;
};


export type MutationCancelAuctionArgs = {
  auctionId: Scalars['String'];
};


export type MutationCloseAuctionArgs = {
  auctionId: Scalars['ID'];
  buyerAddress: Scalars['String'];
  closingHash: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateAuctionArgs = {
  auctionInput: AuctionInput;
};


export type MutationCreateBidArgs = {
  bidInput: BidInput;
};


export type MutationCreateEarlyAdopterArgs = {
  address: Scalars['String'];
  chain: Blockchain;
  signature: Scalars['String'];
};


export type MutationCreateNftTokenArgs = {
  nftTokenInput: NftTokenInput;
};


export type MutationCreateUserArgs = {
  address: Scalars['String'];
};


export type MutationCreateViewInfoArgs = {
  auctionId: Scalars['ID'];
  guestId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRejectMinterRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationRequestMinterAccessArgs = {
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
};


export type MutationUpdateAuctionLikesArgs = {
  auctionId: Scalars['String'];
  userAddress: Scalars['String'];
};


export type MutationUpdateAuctionSharedHashArgs = {
  guestUserID: Scalars['ID'];
  refToken: Scalars['String'];
};


export type MutationUpdateAuctionViewCountArgs = {
  auctionId: Scalars['String'];
};


export type MutationUpdateMinterRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationUpdateNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationUpdatePayToArgs = {
  auctionId: Scalars['String'];
  percents: Array<Scalars['String']>;
  toAddrs: Array<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileHash?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserBeneficiaryArgs = {
  beneficiaryAddr: Scalars['String'];
  userAddr: Scalars['String'];
};


export type MutationUpdateUserFollowingArgs = {
  address: Scalars['String'];
  followingId: Scalars['ID'];
};


export type MutationVerifyUserEmailArgs = {
  cypheredEmail: Scalars['String'];
};

export type NftHistory = {
  __typename?: 'NFTHistory';
  auction: Auction;
  buyer?: Maybe<User>;
  id: Scalars['ID'];
  nftToken: NftToken;
  paymentMethod?: Maybe<Scalars['String']>;
  seller: User;
  time?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum NftStandard {
  Erc721 = 'erc721',
  Erc1155 = 'erc1155'
}

export type NftToken = {
  __typename?: 'NFTToken';
  burned?: Maybe<Scalars['Boolean']>;
  chain: Blockchain;
  contractAddress: Scalars['String'];
  history?: Maybe<Array<NftHistory>>;
  id: Scalars['ID'];
  minter?: Maybe<User>;
  standard: NftStandard;
  tokenId: Scalars['String'];
  tokenUri: Scalars['String'];
};

export type NftTokenInput = {
  chain: Blockchain;
  contractAddress: Scalars['String'];
  minter?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  tokenUri: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['String'];
  description: Scalars['String'];
  eventId: Scalars['ID'];
  eventType: EventType;
  id: Scalars['ID'];
  seen: Scalars['Boolean'];
  to: User;
};

export type PayTo = {
  __typename?: 'PayTo';
  auction: Auction;
  id: Scalars['ID'];
  percents?: Maybe<Array<Scalars['String']>>;
  tos?: Maybe<Array<User>>;
};

export type Query = {
  __typename?: 'Query';
  checkFollowing: Scalars['Boolean'];
  decryptDBRef?: Maybe<Decrypt>;
  decryptRefToken?: Maybe<Decrypt>;
  generateDBRef: Scalars['String'];
  generateRefToken: Scalars['String'];
  getAuction: Auction;
  getAuctionByAuctionId?: Maybe<Auction>;
  getAuctions: Array<Auction>;
  getAuctionsHasOffer: Array<Auction>;
  getAuctionsIsClosed: Array<Auction>;
  getAuctionsOnChain: Array<Auction>;
  getBid: Bid;
  getBids: Array<Bid>;
  getCreators: Array<User>;
  getEarlyAdopter: EarlyAdopter;
  getHistoriesByNftTokenId?: Maybe<Array<NftHistory>>;
  getNftByAddressAndTokenId?: Maybe<NftToken>;
  getNftHistoriesByTokenAddressAndTokenId?: Maybe<Array<NftHistory>>;
  getNftToken: NftToken;
  getNfts: Array<NftToken>;
  getNotifications: Array<Notification>;
  getRequest: MinterAccessRequest;
  getRoyalties: Array<Royalty>;
  getRoyaltiesByUserId: Array<Royalty>;
  getRoyalty: Royalty;
  getTopCreators: Array<User>;
  getTrending?: Maybe<Array<Auction>>;
  getUser: User;
  getUserAuctionsByAuctionId: Array<Auction>;
  getUserAuctionsCollected: Array<Auction>;
  getUserAuctionsOnsale: Array<Auction>;
  getUserBids?: Maybe<Array<Bid>>;
  getUserByAddress?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  getUserByUsername?: Maybe<User>;
  getUserCreatedAuctions: Array<Auction>;
  getUserCreatedNfts: Array<NftToken>;
  getUserEarnings: Array<Earning>;
  getUserFollowers: Array<User>;
  getUserFollowing: Array<User>;
  getUserRequestsReceived?: Maybe<Array<MinterAccessRequest>>;
  getUserRequestsSent?: Maybe<Array<MinterAccessRequest>>;
  getUsers: Array<User>;
  getViewInfo: ViewInfo;
};


export type QueryCheckFollowingArgs = {
  currentUserId: Scalars['ID'];
  viewUserId: Scalars['ID'];
};


export type QueryDecryptDbRefArgs = {
  dbRef: Scalars['String'];
};


export type QueryDecryptRefTokenArgs = {
  refTooken: Scalars['String'];
};


export type QueryGenerateDbRefArgs = {
  guestUserId: Scalars['ID'];
  refToken: Scalars['String'];
};


export type QueryGenerateRefTokenArgs = {
  auctionID: Scalars['ID'];
  refId: Scalars['String'];
};


export type QueryGetAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryGetAuctionByAuctionIdArgs = {
  auctionId: Scalars['String'];
};


export type QueryGetAuctionsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetAuctionsHasOfferArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetAuctionsIsClosedArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetAuctionsOnChainArgs = {
  chain: Blockchain;
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetBidArgs = {
  id: Scalars['ID'];
};


export type QueryGetBidsArgs = {
  auctionId: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetCreatorsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetEarlyAdopterArgs = {
  address: Scalars['String'];
};


export type QueryGetHistoriesByNftTokenIdArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  nftId: Scalars['ID'];
};


export type QueryGetNftByAddressAndTokenIdArgs = {
  address: Scalars['String'];
  tokenId: Scalars['String'];
};


export type QueryGetNftHistoriesByTokenAddressAndTokenIdArgs = {
  address: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  tokenId: Scalars['String'];
};


export type QueryGetNftTokenArgs = {
  id: Scalars['ID'];
};


export type QueryGetNftsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetNotificationsArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  userId: Scalars['ID'];
};


export type QueryGetRequestArgs = {
  id: Scalars['ID'];
};


export type QueryGetRoyaltiesByUserIdArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  userId: Scalars['ID'];
};


export type QueryGetRoyaltyArgs = {
  id: Scalars['ID'];
};


export type QueryGetTopCreatorsArgs = {
  followersCount?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  mintedCount?: Maybe<Scalars['Int']>;
};


export type QueryGetTrendingArgs = {
  limit: Scalars['Int'];
  viewCount?: Maybe<Scalars['Int']>;
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserAuctionsByAuctionIdArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserAuctionsCollectedArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserAuctionsOnsaleArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserBidsArgs = {
  address: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetUserByAddressArgs = {
  address: Scalars['String'];
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryGetUserCreatedAuctionsArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserCreatedNftsArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserEarningsArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserFollowersArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  userId: Scalars['ID'];
};


export type QueryGetUserFollowingArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  userId: Scalars['ID'];
};


export type QueryGetUserRequestsReceivedArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUserRequestsSentArgs = {
  cursor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryGetUsersArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetViewInfoArgs = {
  auctionId: Scalars['ID'];
  guestId: Scalars['ID'];
};

export type Royalty = {
  __typename?: 'Royalty';
  auction: Auction;
  id: Scalars['ID'];
  paymentMethod: Scalars['String'];
  royaltyTo: User;
  timestamp: Scalars['String'];
  trxHash: Scalars['String'];
  valuePaid: Scalars['String'];
};

export type RoyaltyInput = {
  auctionId: Scalars['String'];
  paymentMethod: Scalars['String'];
  royaltyTo: Scalars['String'];
  valuePaid: Scalars['String'];
};

export enum Status {
  Completed = 'completed',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type UpdateUserInput = {
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileHash?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  address: Scalars['String'];
  addresses: Array<Scalars['String']>;
  auctions?: Maybe<Array<Auction>>;
  beneficiaries?: Maybe<Array<User>>;
  bids?: Maybe<Array<Bid>>;
  collectedNfts?: Maybe<Array<Auction>>;
  email?: Maybe<Scalars['String']>;
  emailCypher?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  followers?: Maybe<Array<User>>;
  followersCount: Scalars['Int'];
  following?: Maybe<Array<User>>;
  followingCount: Scalars['Int'];
  id: Scalars['ID'];
  invitedBy?: Maybe<User>;
  isEarlyAdopter: Scalars['Boolean'];
  liked?: Maybe<Array<Auction>>;
  memberSince: Scalars['String'];
  mintedCount: Scalars['Int'];
  mintedNfts?: Maybe<Array<NftToken>>;
  mintedSoldCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  profileHash?: Maybe<Scalars['String']>;
  refEarned?: Maybe<Array<Earning>>;
  referral?: Maybe<Array<User>>;
  referralsCount: Scalars['Int'];
  requestsReceived?: Maybe<Array<MinterAccessRequest>>;
  requestsSent?: Maybe<Array<MinterAccessRequest>>;
  royalties?: Maybe<Array<Royalty>>;
  salesEarned?: Maybe<Array<Earning>>;
  userType: UserType;
  username?: Maybe<Scalars['String']>;
};

export enum UserType {
  Admin = 'admin',
  Collector = 'collector',
  Minter = 'minter',
  RestrictedMinter = 'restrictedMinter',
  UnknownMinter = 'unknownMinter'
}

export type ViewInfo = {
  __typename?: 'ViewInfo';
  auction: Auction;
  guest: User;
  id: Scalars['ID'];
  user: User;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Auction: ResolverTypeWrapper<Auction>;
  AuctionInput: AuctionInput;
  AuctionState: AuctionState;
  Bid: ResolverTypeWrapper<Bid>;
  BidInput: BidInput;
  BidState: BidState;
  Blockchain: Blockchain;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Decrypt: ResolverTypeWrapper<Decrypt>;
  EarlyAdopter: ResolverTypeWrapper<EarlyAdopter>;
  Earning: ResolverTypeWrapper<Earning>;
  EarningInput: EarningInput;
  EarningStatus: EarningStatus;
  EarningType: EarningType;
  EventType: EventType;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  MinterAccessRequest: ResolverTypeWrapper<MinterAccessRequest>;
  Mutation: ResolverTypeWrapper<{}>;
  NFTHistory: ResolverTypeWrapper<NftHistory>;
  NFTStandard: NftStandard;
  NFTToken: ResolverTypeWrapper<NftToken>;
  NftTokenInput: NftTokenInput;
  Notification: ResolverTypeWrapper<Notification>;
  PayTo: ResolverTypeWrapper<PayTo>;
  Query: ResolverTypeWrapper<{}>;
  Royalty: ResolverTypeWrapper<Royalty>;
  RoyaltyInput: RoyaltyInput;
  Status: Status;
  String: ResolverTypeWrapper<Scalars['String']>;
  UpdateUserInput: UpdateUserInput;
  User: ResolverTypeWrapper<User>;
  UserType: UserType;
  ViewInfo: ResolverTypeWrapper<ViewInfo>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Auction: Auction;
  AuctionInput: AuctionInput;
  Bid: Bid;
  BidInput: BidInput;
  Boolean: Scalars['Boolean'];
  Decrypt: Decrypt;
  EarlyAdopter: EarlyAdopter;
  Earning: Earning;
  EarningInput: EarningInput;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  MinterAccessRequest: MinterAccessRequest;
  Mutation: {};
  NFTHistory: NftHistory;
  NFTToken: NftToken;
  NftTokenInput: NftTokenInput;
  Notification: Notification;
  PayTo: PayTo;
  Query: {};
  Royalty: Royalty;
  RoyaltyInput: RoyaltyInput;
  String: Scalars['String'];
  UpdateUserInput: UpdateUserInput;
  User: User;
  ViewInfo: ViewInfo;
}>;

export type AuctionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auction'] = ResolversParentTypes['Auction']> = ResolversObject<{
  auctionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  basePrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bids?: Resolver<Maybe<Array<ResolversTypes['Bid']>>, ParentType, ContextType>;
  buyer?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['Blockchain'], ParentType, ContextType>;
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closingHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closingValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimarySales?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  likeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  likedBy?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  nftCreator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  nftToken?: Resolver<ResolversTypes['NFTToken'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  payTos?: Resolver<Maybe<ResolversTypes['PayTo']>, ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  royalties?: Resolver<Maybe<Array<ResolversTypes['Royalty']>>, ParentType, ContextType>;
  sharedHash?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  soldBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['AuctionState']>, ParentType, ContextType>;
  trxHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bid'] = ResolversParentTypes['Bid']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  bidder?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['BidState'], ParentType, ContextType>;
  trxHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DecryptResolvers<ContextType = any, ParentType extends ResolversParentTypes['Decrypt'] = ResolversParentTypes['Decrypt']> = ResolversObject<{
  auctionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  guestId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EarlyAdopterResolvers<ContextType = any, ParentType extends ResolversParentTypes['EarlyAdopter'] = ResolversParentTypes['EarlyAdopter']> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['Blockchain'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EarningResolvers<ContextType = any, ParentType extends ResolversParentTypes['Earning'] = ResolversParentTypes['Earning']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EarningStatus'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  trxHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EarningType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MinterAccessRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['MinterAccessRequest'] = ResolversParentTypes['MinterAccessRequest']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  cancelAuction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationCancelAuctionArgs, 'auctionId'>>;
  closeAuction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationCloseAuctionArgs, 'auctionId' | 'buyerAddress' | 'closingHash' | 'value'>>;
  createAuction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationCreateAuctionArgs, 'auctionInput'>>;
  createBid?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationCreateBidArgs, 'bidInput'>>;
  createEarlyAdopter?: Resolver<ResolversTypes['EarlyAdopter'], ParentType, ContextType, RequireFields<MutationCreateEarlyAdopterArgs, 'address' | 'chain' | 'signature'>>;
  createNFTToken?: Resolver<ResolversTypes['NFTToken'], ParentType, ContextType, RequireFields<MutationCreateNftTokenArgs, 'nftTokenInput'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'address'>>;
  createViewInfo?: Resolver<ResolversTypes['ViewInfo'], ParentType, ContextType, RequireFields<MutationCreateViewInfoArgs, 'auctionId' | 'guestId' | 'userId'>>;
  rejectMinterRequest?: Resolver<ResolversTypes['MinterAccessRequest'], ParentType, ContextType, RequireFields<MutationRejectMinterRequestArgs, 'requestId'>>;
  requestMinterAccess?: Resolver<ResolversTypes['MinterAccessRequest'], ParentType, ContextType, RequireFields<MutationRequestMinterAccessArgs, 'fromId' | 'toId'>>;
  updateAuctionLikes?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationUpdateAuctionLikesArgs, 'auctionId' | 'userAddress'>>;
  updateAuctionSharedHash?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationUpdateAuctionSharedHashArgs, 'guestUserID' | 'refToken'>>;
  updateAuctionViewCount?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<MutationUpdateAuctionViewCountArgs, 'auctionId'>>;
  updateMinterRequest?: Resolver<ResolversTypes['MinterAccessRequest'], ParentType, ContextType, RequireFields<MutationUpdateMinterRequestArgs, 'requestId'>>;
  updateNotification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationUpdateNotificationArgs, 'notificationId'>>;
  updatePayTo?: Resolver<ResolversTypes['PayTo'], ParentType, ContextType, RequireFields<MutationUpdatePayToArgs, 'auctionId' | 'percents' | 'toAddrs'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'address'>>;
  updateUserBeneficiary?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserBeneficiaryArgs, 'beneficiaryAddr' | 'userAddr'>>;
  updateUserFollowing?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserFollowingArgs, 'address' | 'followingId'>>;
  verifyUserEmail?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationVerifyUserEmailArgs, 'cypheredEmail'>>;
}>;

export type NftHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['NFTHistory'] = ResolversParentTypes['NFTHistory']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  buyer?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nftToken?: Resolver<ResolversTypes['NFTToken'], ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seller?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['NFTToken'] = ResolversParentTypes['NFTToken']> = ResolversObject<{
  burned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['Blockchain'], ParentType, ContextType>;
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  history?: Resolver<Maybe<Array<ResolversTypes['NFTHistory']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  minter?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  standard?: Resolver<ResolversTypes['NFTStandard'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PayToResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayTo'] = ResolversParentTypes['PayTo']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  percents?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tos?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  checkFollowing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckFollowingArgs, 'currentUserId' | 'viewUserId'>>;
  decryptDBRef?: Resolver<Maybe<ResolversTypes['Decrypt']>, ParentType, ContextType, RequireFields<QueryDecryptDbRefArgs, 'dbRef'>>;
  decryptRefToken?: Resolver<Maybe<ResolversTypes['Decrypt']>, ParentType, ContextType, RequireFields<QueryDecryptRefTokenArgs, 'refTooken'>>;
  generateDBRef?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGenerateDbRefArgs, 'guestUserId' | 'refToken'>>;
  generateRefToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGenerateRefTokenArgs, 'auctionID' | 'refId'>>;
  getAuction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType, RequireFields<QueryGetAuctionArgs, 'id'>>;
  getAuctionByAuctionId?: Resolver<Maybe<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetAuctionByAuctionIdArgs, 'auctionId'>>;
  getAuctions?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetAuctionsArgs, 'limit'>>;
  getAuctionsHasOffer?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetAuctionsHasOfferArgs, 'limit'>>;
  getAuctionsIsClosed?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetAuctionsIsClosedArgs, 'limit'>>;
  getAuctionsOnChain?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetAuctionsOnChainArgs, 'chain' | 'limit'>>;
  getBid?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<QueryGetBidArgs, 'id'>>;
  getBids?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<QueryGetBidsArgs, 'auctionId' | 'limit'>>;
  getCreators?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetCreatorsArgs, 'limit'>>;
  getEarlyAdopter?: Resolver<ResolversTypes['EarlyAdopter'], ParentType, ContextType, RequireFields<QueryGetEarlyAdopterArgs, 'address'>>;
  getHistoriesByNftTokenId?: Resolver<Maybe<Array<ResolversTypes['NFTHistory']>>, ParentType, ContextType, RequireFields<QueryGetHistoriesByNftTokenIdArgs, 'limit' | 'nftId'>>;
  getNftByAddressAndTokenId?: Resolver<Maybe<ResolversTypes['NFTToken']>, ParentType, ContextType, RequireFields<QueryGetNftByAddressAndTokenIdArgs, 'address' | 'tokenId'>>;
  getNftHistoriesByTokenAddressAndTokenId?: Resolver<Maybe<Array<ResolversTypes['NFTHistory']>>, ParentType, ContextType, RequireFields<QueryGetNftHistoriesByTokenAddressAndTokenIdArgs, 'address' | 'limit' | 'tokenId'>>;
  getNftToken?: Resolver<ResolversTypes['NFTToken'], ParentType, ContextType, RequireFields<QueryGetNftTokenArgs, 'id'>>;
  getNfts?: Resolver<Array<ResolversTypes['NFTToken']>, ParentType, ContextType, RequireFields<QueryGetNftsArgs, 'limit'>>;
  getNotifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QueryGetNotificationsArgs, 'limit' | 'userId'>>;
  getRequest?: Resolver<ResolversTypes['MinterAccessRequest'], ParentType, ContextType, RequireFields<QueryGetRequestArgs, 'id'>>;
  getRoyalties?: Resolver<Array<ResolversTypes['Royalty']>, ParentType, ContextType>;
  getRoyaltiesByUserId?: Resolver<Array<ResolversTypes['Royalty']>, ParentType, ContextType, RequireFields<QueryGetRoyaltiesByUserIdArgs, 'limit' | 'userId'>>;
  getRoyalty?: Resolver<ResolversTypes['Royalty'], ParentType, ContextType, RequireFields<QueryGetRoyaltyArgs, 'id'>>;
  getTopCreators?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetTopCreatorsArgs, 'limit'>>;
  getTrending?: Resolver<Maybe<Array<ResolversTypes['Auction']>>, ParentType, ContextType, RequireFields<QueryGetTrendingArgs, 'limit'>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetUserArgs, 'id'>>;
  getUserAuctionsByAuctionId?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetUserAuctionsByAuctionIdArgs, 'id' | 'limit'>>;
  getUserAuctionsCollected?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetUserAuctionsCollectedArgs, 'id' | 'limit'>>;
  getUserAuctionsOnsale?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetUserAuctionsOnsaleArgs, 'id' | 'limit'>>;
  getUserBids?: Resolver<Maybe<Array<ResolversTypes['Bid']>>, ParentType, ContextType, RequireFields<QueryGetUserBidsArgs, 'address' | 'limit'>>;
  getUserByAddress?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserByAddressArgs, 'address'>>;
  getUserByEmail?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserByEmailArgs, 'email'>>;
  getUserByUsername?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserByUsernameArgs, 'username'>>;
  getUserCreatedAuctions?: Resolver<Array<ResolversTypes['Auction']>, ParentType, ContextType, RequireFields<QueryGetUserCreatedAuctionsArgs, 'id' | 'limit'>>;
  getUserCreatedNfts?: Resolver<Array<ResolversTypes['NFTToken']>, ParentType, ContextType, RequireFields<QueryGetUserCreatedNftsArgs, 'id' | 'limit'>>;
  getUserEarnings?: Resolver<Array<ResolversTypes['Earning']>, ParentType, ContextType, RequireFields<QueryGetUserEarningsArgs, 'id' | 'limit'>>;
  getUserFollowers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserFollowersArgs, 'limit' | 'userId'>>;
  getUserFollowing?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserFollowingArgs, 'limit' | 'userId'>>;
  getUserRequestsReceived?: Resolver<Maybe<Array<ResolversTypes['MinterAccessRequest']>>, ParentType, ContextType, RequireFields<QueryGetUserRequestsReceivedArgs, 'id' | 'limit'>>;
  getUserRequestsSent?: Resolver<Maybe<Array<ResolversTypes['MinterAccessRequest']>>, ParentType, ContextType, RequireFields<QueryGetUserRequestsSentArgs, 'id' | 'limit'>>;
  getUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUsersArgs, 'limit'>>;
  getViewInfo?: Resolver<ResolversTypes['ViewInfo'], ParentType, ContextType, RequireFields<QueryGetViewInfoArgs, 'auctionId' | 'guestId'>>;
}>;

export type RoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Royalty'] = ResolversParentTypes['Royalty']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  royaltyTo?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trxHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valuePaid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  auctions?: Resolver<Maybe<Array<ResolversTypes['Auction']>>, ParentType, ContextType>;
  beneficiaries?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  bids?: Resolver<Maybe<Array<ResolversTypes['Bid']>>, ParentType, ContextType>;
  collectedNfts?: Resolver<Maybe<Array<ResolversTypes['Auction']>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailCypher?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  following?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  followingCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  isEarlyAdopter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  liked?: Resolver<Maybe<Array<ResolversTypes['Auction']>>, ParentType, ContextType>;
  memberSince?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mintedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mintedNfts?: Resolver<Maybe<Array<ResolversTypes['NFTToken']>>, ParentType, ContextType>;
  mintedSoldCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refEarned?: Resolver<Maybe<Array<ResolversTypes['Earning']>>, ParentType, ContextType>;
  referral?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  referralsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requestsReceived?: Resolver<Maybe<Array<ResolversTypes['MinterAccessRequest']>>, ParentType, ContextType>;
  requestsSent?: Resolver<Maybe<Array<ResolversTypes['MinterAccessRequest']>>, ParentType, ContextType>;
  royalties?: Resolver<Maybe<Array<ResolversTypes['Royalty']>>, ParentType, ContextType>;
  salesEarned?: Resolver<Maybe<Array<ResolversTypes['Earning']>>, ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ViewInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewInfo'] = ResolversParentTypes['ViewInfo']> = ResolversObject<{
  auction?: Resolver<ResolversTypes['Auction'], ParentType, ContextType>;
  guest?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Auction?: AuctionResolvers<ContextType>;
  Bid?: BidResolvers<ContextType>;
  Decrypt?: DecryptResolvers<ContextType>;
  EarlyAdopter?: EarlyAdopterResolvers<ContextType>;
  Earning?: EarningResolvers<ContextType>;
  MinterAccessRequest?: MinterAccessRequestResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NFTHistory?: NftHistoryResolvers<ContextType>;
  NFTToken?: NftTokenResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  PayTo?: PayToResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Royalty?: RoyaltyResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  ViewInfo?: ViewInfoResolvers<ContextType>;
}>;

