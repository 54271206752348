import * as React from "react";
import { IonIcon } from "@ionic/react";
import styled from "styled-components";
import { DownAngle, RightAngle } from "components/SvgIcons";

import { Flex, Text } from "components";
import { caretDownOutline } from "ionicons/icons";

interface FilterProps {
  children: any;
  title: string;
}

const Container = styled.div`
  /* padding: 10px; */
  @media (max-width: 600px) {
  }
`;

const Items = styled(Flex)`
  background: #fff;
  padding: 20px;
  border-top: 1px solid rgb(229, 232, 235);
  cursor: pointer;
  user-select: none;
`;

const Body = styled.div`
  padding: 20px;
  background: rgba(196, 196, 196, 0.1);
`;

const Icon = styled(IonIcon)`
  font-size: 25px;
`;

const Filter = ({ children, title }: FilterProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Container>
      <Items onClick={() => setOpen(!open)} justify="space-between">
        <Text size="16" weight="600">
          {title}
        </Text>
        {open ? <RightAngle /> : <DownAngle />}
      </Items>

      {open && <Body>{children}</Body>}
    </Container>
  );
};

export default Filter;
