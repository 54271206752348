import React, { useState } from "react";
import styled from "styled-components";
import { SwapCard, Tab } from "../components";
import PoolBanner from "../components/Pool/Banners";

const Container = styled.div`
  margin: auto;
  width: 100%;
  padding: 30px 0;
`;

const Home = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const setActive = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <PoolBanner
        title="Delfy Exchange"
        description="Trade token fast and easy "
      />
      <Container>
        <Tab activeIndex={activeIndex} setActive={setActive} />

        <SwapCard type={activeIndex} />
      </Container>
    </div>
  );
};

export default Home;
