import { IonCard, IonIcon } from "@ionic/react";
import { Flex, Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import { BscScan, ConfirmCheck } from "components/SvgIcons";
import { getExplorerUrl } from "config";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { getChainFromChainId } from "utils/nft-user-helper";

const Container = styled(IonCard)`
  max-width: 400px;
  width: 400px;

  height: auto;
  border-radius: 5px;
  color: #333;
  padding: 20px 20px;
  overflow-y: auto;
  margin: auto;

  @media(max-width:640px){
    width:100%;
  }
`;

const Close = styled.div`
  padding: 15px 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
`;

const ImageWrap = styled.div`
  width: 50px;
  height: 50px;
`;

const ConnectItem = styled(Flex)`
  width:100%;
  padding: 10px;
  box-shadow: 0px 0px 2px #ddd;
  margin:10px 0px;
  cursor:pointer;
  transition:.3s;
  
  &:hover{
    box-shadow: 0px 0px 3px #ccc;
  }
`;

interface PropertyInt {
  close: () => void;
  connect: (connector:any) => void;
}

const Confirm: React.FC<PropertyInt> = ({ close, connect }: PropertyInt) => {
  return (
    <Container>
      <div
        onClick={close}
        style={{
          float: "right",
          padding: "20px",
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          top: "10px",
        }}
      >
        <IonIcon icon={closeOutline} style={{ fontSize: 20 }} />
      </div>

      <div style={{marginTop:'50px'}}>
        <Flex direction="column" justify="center" style={{ width: "100%" }}>
          <ConnectItem align="center" onClick={()=>connect('metamask')}>
            <ImageWrap>
              <img src="/connector/metamask.png" alt="d" />
            </ImageWrap>
            <Text style={{ marginLeft: 15 }}>Metamask</Text>
          </ConnectItem>

          <ConnectItem align="center" onClick={()=>connect('walletconnect')}>
            <ImageWrap>
              <img src="/connector/walletconnect.png" alt="d" />
            </ImageWrap>
            <Text style={{ marginLeft: 15 }}>Wallet Connect</Text>
          </ConnectItem>
        </Flex>
      </div>
    </Container>
  );
};

export default Confirm;
