import erc721 from "abi/coterie-nft-token.json";
import erc721MarketAbi from "abi/nerc721.json";
import { ContractAuction } from "features/nftsAuction/NftAuctionSlice2";
import { getNFTMktplaceAddress } from "utils/addressHelper";
import { toBN } from "utils/BigNumber";
import multicall from "utils/multicall";

const getAuctionStatus = (status: number) => {
  if (status === 0) return "open";
  if (status === 1) return "cancelled";
  if (status === 2) return "sold";
};
// const mkt = "0xA697f6058f2469De929926136447535Cc2E6C235"
const fetchAuctionIds = async () => {
  const call = {
    address: getNFTMktplaceAddress(),
    name: "getAuctionIds",
  };
  try {
    const [rawAuctions] = await multicall(erc721MarketAbi, [call]);
    return rawAuctions[0].map((r: any) => r.toString());
  } catch (error) {
    console.log(`error`, error);
  }
};

export const fetchAuctions = async () => {
  const auctions = await fetchAuctionIds();

  try {
    const calls = auctions.map((id: any) => ({
      address: getNFTMktplaceAddress(),
      name: "getAuction",
      params: [id.toString()],
    }));
    const bCalls = auctions.map((id: any) => ({
      address: getNFTMktplaceAddress(),
      name: "getAuctionBids",
      params: [id.toString()],
    }));

    const rawAuctions = await multicall(erc721MarketAbi, calls);
    const rawBids = await multicall(erc721MarketAbi, bCalls);

    const tokenCalls = rawAuctions.map((auction: any) => {
      return {
        address: auction.token,
        name: "tokenURI",
        params: [toBN(auction.tokenId._hex).toString(10)],
      };
    });

    const tokenUris = await multicall(erc721, tokenCalls);

    const auctionz = rawAuctions.map((auction: any, index: number) => {
      const bid = rawBids[index][0].length
        ? rawBids[index][0].map((bid: any) => ({
            bidder: bid.bidder,
            createdAt: toBN(bid.createdAt._hex).toString(10),
            currentbid: toBN(bid.currentbid._hex).toString(10),
          }))
        : [];

      return {
        owner: auction.owner as string,
        basePrice: toBN(auction.basePrice._hex).toString(10),
        closedAt: toBN(auction.closedAt._hex).toString(10),
        createdAt: toBN(auction.createdAt._hex).toString(10),
        auctionId: toBN(auction.id._hex).toString(10),
        paymentMethod: auction.paymentMethod as string,
        tokenId: toBN(auction.tokenId._hex).toString(10),
        status: getAuctionStatus(auction.status),
        token: auction.token,
        buyer: auction.buyer,
        networkId: 97 || 56,
        tokenUri: tokenUris[index][0],
        bids: bid,
      } as ContractAuction;
    });
    return auctionz as ContractAuction[];
  } catch (error) {
    console.log("Call Error", error);
  }
};
