/* eslint-disable require-yield */
import { takeEvery,put,call } from 'redux-saga/effects'
import { FETCH_AUCTIONS_CASES} from "./types";
import { setCases,setLoading } from "../features/cases/CasesSlice";
import { getCases } from '../services/caseService'


function* fetchCases(action:any) { 
    try {
        yield put(setLoading(true))
        let data:{}=yield call(()=>getCases(action.payload)) 
        yield put(setCases(data))
    } catch (error) {
        console.log(error,"error")
    }

}

// use them in parallel
export default function* casesSaga() {
  yield takeEvery(FETCH_AUCTIONS_CASES, fetchCases)
}