const contracts = {
  staking: {
    test: "0x1888ea64223cDd4D99363031E9eFD758FD700327",
    bsc: "",
    matic: "",
    avax: "",
    eth: "",
  },

  nftMktplace: {
    test: "0x6A305Ed83b2a432052A90Be931Dc6823c7850615", //bsc testnet
    bsc: "",
    matic: "",
    avax: "",
    eth: "",
  },

  nftRegistry: {
    test: "0xc56608c953936D9424925DA3BE3a38AB242ff235", //bsc testnet
    bsc: "",

    matic: "",
    avax: "",
    eth: "",
  },
  nftToken: {
    test: "0x666164F8dB6a8973AAf37a6cf465990e6Ca4d4C7", //bsc testnet
    bsc: "",
    matic: "",
    avax: "",
    eth: "",
  },

  multiCall: {
    test: "0x36643Cf4e44f9928878606813376Bf857435B43A",
    bsc: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    matic: "",
    avax: "",
    eth: "",
  },
  bnbBusd: {
    test: "0x43631B04a5CBFE6D32deDeA593C4ca7B5Bb19dEb",
    bsc: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    matic: "",
    avax: "",
    eth: "",
  },
  bnbCoterie: {
    test: "0xDE3d5B6dc96A8Bc1aBB01B3A377f75405d3FdD8F",
    bsc: "",
    matic: "",
    avax: "",
    eth: "",
  },
  coterie: {
    test: "0x3F1156782304a46b349A1E86DE50b5a320A1Ca60",
    bsc: "",
    matic: "",
    avax: "",
    eth: "",
  },
  bnb: {
    bsc: "0x0000000000000000000000000000000000000000",
    test: "0x0000000000000000000000000000000000000000",
    matic: "",
    avax: "",
    eth: "",
  },
  coterie_gov: {
    bsc: "",
    test: "0x69C491Ff793508216cD9858D5ceF799f290f4EE9",
    matic: "",
    avax: "",
    eth: "",
  },
  owned_token: {
    bsc: "",
    test: "0xcA72B22F860A31204ac0f6098e2e9B10d591B8c7",
    matic: "",
    avax: "",
    eth: "",
  },
};

export default contracts;
