import {
  IonButton,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
} from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";
import { closeOutline, openOutline } from "ionicons/icons";
import { PoolObject } from "../../types/farm-types";
import { toBN } from "utils/BigNumber";
import { getBalanceAmount, getDecimalAmount } from "utils/formatBalance";

export const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`;
export const Card = styled(IonCard)`
  width: 360px;
  height: 430px;
  background-color: #f0f3f5;
  border-radius: 25px;
  position: relative;
  margin: auto;
`;
export const InputAreaContainer = styled.div`
  width: 90%;
  height: 95px;
  padding: 0 20px;
  margin: 8px auto 0;
  border-radius: 25px;
  border: 2px solid #dcdddc;
  position: relative;
  background-color: #eee;
  display: flex;
  flex-diection: column;
`;

export const TextArea = styled.div`
  position: absolute;
  top: 0;
  height: 30%;
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  &p {
    top: 0;
    height: 100%x;
  }
`;
export const CardInput = styled(IonInput)`
  position: relative;
  height: 45%;
  top: 50%;
  margin: 0 10px;
  font-size: 18px;
  max-width: 100% !important;
`;
export const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;
export const Icon = styled(IonIcon)`
  font-size: 25px; ;
`;

export const Divider = styled.div`
  padding: 1px;
  background-color: #cccddd;
`;
export const SubHeader = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  font-size: 28px;
  font-weight: bold;
`;
export const USDValue = styled.div``;

export const ItemContainer = styled(IonItem)`
  margin: 0px auto;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LargeButton = styled(IonButton)`
  margin: 0px 20px;
`;

export const SmallButton = styled(IonButton)`
  --border-radius: 17px !important;
  --box-shadow: none !important;
  margin: 10px auto;
`;
const LabelWrap = styled(IonLabel)`
  margin: 0 0 0 70%;
  font-size: 12px;
  display: block;
`;

export const TexWithLink = styled.div`
  align-items: center;
  text-align: center;
  font-size: 28px;
`;
export const Errors = styled.div`
  text-align: left;
  margin: 2px 20px;
  color: red;
`;

interface UnstakeInputs {
  poolObj: PoolObject;
  locked: boolean;
  unlockWallet: () => void;
  unstake: (value: number) => void;
  onDismiss: (userInput: string) => void;
}

const UnstakeCard: React.FC<UnstakeInputs> = (unstakeInputs: UnstakeInputs) => {
  const [userInput, setUserInput] = useState<number>(0);
  const [range, setRange] = useState<number>(0);
  const [value, setValue] = useState<number>(0);

  const setUsrInput = (_value: any) => {
    let stakedBal = getDecimalAmount(unstakeInputs.poolObj.userDeposit, 18);
    let val = toBN(_value);
    let valu = getBalanceAmount(
      stakedBal.times(val).dividedBy(100).toString(10),
    ).toNumber();
    setUserInput(valu);
    setValue(userInput);
    console.log("userInpt: ", userInput);
  };

  const findPercent = (val: number) => {
    let _val = getDecimalAmount(val.toString());
    let stakedBal = getDecimalAmount(unstakeInputs.poolObj.userDeposit, 18);
    let p = _val.dividedBy(stakedBal).times(100);
    console.log("P: ", +p);
    return Math.round(p.toNumber());
  };

  const handleValue = (val: string) => {
    if (findPercent(+val) !== range) {
      setRange(findPercent(+val));
      console.log("val: ", value);
    }
    return;
  };

  return (
    <CardContainer>
      <Card>
        <Header>
          <div>Unstake {unstakeInputs.poolObj.poolSymbol}</div>
          <Icon
            onClick={() => unstakeInputs.onDismiss(userInput.toString())}
            icon={closeOutline}
          />
        </Header>
        <Divider />
        <SubHeader>
          <h6>Unstake</h6>
          <h6>{unstakeInputs.poolObj.poolSymbol}</h6>
        </SubHeader>
        <InputAreaContainer>
          <CardInput
            step='any'
            placeholder='0.0000'
            type='number'
            onIonChange={(e) => handleValue(e.detail.value)}
            value={userInput}
          />
          <USDValue>
            <h6>~${"4"}</h6>
          </USDValue>
        </InputAreaContainer>
        <LabelWrap>Bal: {unstakeInputs.poolObj.userDeposit}</LabelWrap>
        <ItemContainer>
          <IonRange
            snaps={true}
            ticks={false}
            step={0.01}
            debounce={100}
            pin={true}
            value={range}
            onIonChange={(e) => setUsrInput(e.detail.value)}
          />
        </ItemContainer>
        <ButtonArea>
          <SmallButton onClick={() => setRange(25)} color='light' size='small'>
            25%
          </SmallButton>
          <SmallButton onClick={() => setRange(50)} color='light' size='small'>
            50%
          </SmallButton>
          <SmallButton onClick={() => setRange(75)} color='light' size='small'>
            75%
          </SmallButton>
          <SmallButton onClick={() => setRange(100)} color='light' size='small'>
            Max
          </SmallButton>
        </ButtonArea>
        {unstakeInputs.locked ? (
          <LargeButton
            color='success'
            onClick={() => unstakeInputs.unlockWallet()}
            expand='block'>
            Unlock Wallet
          </LargeButton>
        ) : (
          <LargeButton
            color='success'
            onClick={() => unstakeInputs.unstake(value)}
            expand='block'>
            Confirm
          </LargeButton>
        )}

        <TexWithLink>
          <h6>
            Get {unstakeInputs.poolObj.poolSymbol}{" "}
            <IonIcon icon={openOutline} />
          </h6>
        </TexWithLink>
      </Card>
    </CardContainer>
  );
};

export default UnstakeCard;
