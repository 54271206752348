import pinataSDK from "@pinata/sdk";

const pinata = pinataSDK(
  "3bf96ecb81c558794608",
  "cfd76b4a50aa1555a3a777085d749bda1610028169f0abf50c7841f427e45f89",
);

const pinataHelper = {
  pin_json: (body: any, name: string, userAddress?: string) => {
    return pinata.pinJSONToIPFS(body, {
      pinataMetadata: {
        name: name,
        userId: userAddress,
      },
      pinataOptions: {
        cidVersion: 0,
      },
    });
  },

  unpin_hash: (hash: string) => {
    console.log("Unpinning");
    return pinata.unpin(hash);
  },
};

export default pinataHelper;
