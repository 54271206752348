import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { Flex, Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import { ConfirmCheck, SuccessCheck, ErrorCheck } from "components/SvgIcons";
import { close } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
const Container = styled(IonCard)`
  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  color: #333;
  padding: 0 20px;
  overflow-y: auto;
  margin: auto;
`;

const Close = styled.div`
  padding: 15px 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
`;

const ButtonFlex = styled(Flex)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px;
`;

const Contain = styled.div`
  padding: 10px;
  text-align: center;
`;

interface PropertyInt {
  close?: () => void;
  title: string;
  description?: string;
  type: "success" | "error" | "info";
}

const RenderSuccess = (close: any, title: string, description: string) => (
  <Container>
    <Contain>
      <Spacer height={30} />
      <Text size="32" weight="500">
        {title}
      </Text>
      <Spacer height={30} />
      <SuccessCheck />
      <Spacer />
      <Text size="14">{description}</Text>
      <Spacer height={30} />
      <ButtonPrimary onClick={close}>Close</ButtonPrimary>
    </Contain>
  </Container>
);

const RenderError = (close: any, title: string, description: string) => (
  <Container>
    <Contain>
      <Spacer height={30} />
      <Text size="32" weight="500">
        {title}
      </Text>
      <Spacer height={30} />
      <div>
         <img src="/assets/warning2.png"  height="100" alt="Warning" />
      </div>
      <Spacer />
      <Text size="14">{description}</Text>
      <Spacer height={30} />
      <ButtonPrimary onClick={close}>Close</ButtonPrimary>
    </Contain>
  </Container>
);

const RenderInfo = (close: any, title: string, description: string) => (
  <Container>
    <Contain>
      <Spacer height={30} />
      <Text size="32" weight="500">
        {title}
      </Text>
      <Spacer height={30} />
      <SuccessCheck />
      <Spacer />
      <Text size="14">{description}</Text>
      <Spacer height={30} />
      <ButtonPrimary onClick={close}>Close</ButtonPrimary>
    </Contain>
  </Container>
);

const AlertModal: React.FC<PropertyInt> = ({
  close,
  type,
  title,
  description,
}: PropertyInt) => {
  return (
    <>
      {type === "success" && RenderSuccess(close, title, description)}
      {type === "error" && RenderError(close, title, description)}
      {type === "info" && RenderInfo(close, title, description)}
    </>
  );
};

export default AlertModal;
