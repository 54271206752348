import { createSlice } from '@reduxjs/toolkit'

interface LokerInterface{
  locker:any
  loading:boolean;
  error?:any
}

const initialState:LokerInterface={
  locker:null,
  loading:false,
  error:null
}
const lockerSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setLocker: (state,{payload}) => {
    state.locker=payload;
    state.loading=false
    },
    setLoading:(state,{payload})=>void(state.loading=payload)
  }
})


export const { setLocker,setLoading } = lockerSlice.actions


export default lockerSlice.reducer