import erc20 from "abi/erc20.json";
import { constants } from "ethers";
import multicall from "utils/multicall";
import { Contract } from "web3-eth-contract";

export const approve = (
  token: Contract,
  addressToApprove: string,
  account: string,
) => {
  return token.methods
    .approve(addressToApprove, constants.MaxUint256.toString())
    .send({ from: account , gasPrice: "20000000000"});
};

export const getAllowance = (
  tokenAddress: string,
  owner: string,
  spender: string,
) => {
  const calls = [
    {
      address: tokenAddress,
      name: "allowance",
      params: [owner, spender],
    },
  ];
  return multicall(erc20, calls);
};
