import { createSlice } from "@reduxjs/toolkit";
import farms from "../../config/farms";
import { Farm, FarmsState } from "../../states/types";
import { getAddress } from "../../utils/addressHelper";

const noAccountFarmConfig = farms.map((farm) => ({
  ...farm,
  userData: {
    allowance: "0",
    tokenBalance: "0",
    stakedBalance: "0",
    earnings: "0",
  },
}));

const initialState: FarmsState = {
  loading: true,
  data: noAccountFarmConfig,
  userDataLoaded: false,
};
const farmSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    setFarms: (state, { payload }) => {
      state.data = payload;
    },
    setLoading: (state, { payload }) => void (state.loading = payload),
    setFarmUser: (state, { payload }) => {
      payload.forEach((userData: any) => {
        const { pid } = userData;
        const index = state.data.findIndex(
          (farm) => getAddress(farm.pid) === getAddress(pid),
        );
        state.data[index] = { ...state.data[index], userData };
      });
    },
    setUserDataLoaded: (state, { payload }) =>
      void (state.userDataLoaded = payload),
  },
});

export const { setFarms, setLoading, setUserDataLoaded, setFarmUser } =
  farmSlice.actions;

export default farmSlice.reducer;

/* import { createSlice } from '@reduxjs/toolkit'
import { FarmsState } from '../../states/types'
import farms from '../../config/farms'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../../states/farm';
import { getAddress } from '../../utils/addressHelper';

const noAccountFarmConfig = farms.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
  },
}))

const initialState: FarmsState = {loading:false, data: noAccountFarmConfig, userDataLoaded: false }
const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    setLoading:(state, { payload } )=>void( state.loading = payload) 
  }, 
  extraReducers: (builder) => {
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action)=>{
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => getAddress(farm.pid) === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  }
})


export const { setLoading} = farmSlice.actions


export default farmSlice.reducer */
