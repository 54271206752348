import React, { useState } from "react";
import styled from "styled-components";
import {IonItem,IonTitle, IonButton,IonText} from "@ionic/react";
import { AuctionDetailsProps } from "../../utils/types";
import {useNetwork} from '../../store/hooks'
import {getNSyb,getScanAddress} from '../../utils/addressHelper'
import CaseCard from "./Cases/CaseCard";
import EmptyCase from "./Cases/EmptyCase";
import {CaseModal } from "../../components";


const DetailContainer = styled.div`
    background:var(--ion-color-card);
    box-shadow:0px 0px 4px #aaa;
    border-radius:5px;
    margin:0px 10px;
    padding:10px;

    @media (max-width:640px){
    margin:0px;  
  }
`;

const CaseHeader = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:0px 10px;
  font-weight:bolder;

  & h4{
      color:#2a2290;
  }
`


interface Cases{
    cases0:any;
    cases1:any;
    auctionAddress:string
}




const LockerCase: React.FC<Cases> = ({cases0,cases1,auctionAddress}) => {
  let  [show,setShow] =useState<boolean>(false)
  const network = useNetwork()
    const networkSymbol=getNSyb(network);
    const scanUrl=getScanAddress(network);
    
    const handleCase =()=>{
        alert('handling')
    }



    const close=()=>{
         setShow(false)
    }
    return (
        <DetailContainer>
         <CaseHeader>
             <h4 color="primary">Cases</h4>
             {(cases0.length > 1 || cases1.length > 1) && <IonText color="primary">View All</IonText>}
         </CaseHeader>
            {
                cases0.length < 1 ? <EmptyCase create={()=>setShow(true)} /> : cases0.map((issue:number,i:number)=> <CaseCard key={i} {...{issue}} />)
            }
          <CaseModal close={close} setShow={setShow} show={show} handleBuy={handleCase} {...{auctionAddress}} />
          
        </DetailContainer>
    )
}

export default LockerCase