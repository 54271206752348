import React from "react";
import styled from "styled-components";
import { IonText } from "@ionic/react";


 const TabContainer = styled.div`
    background: #6470CF;
    border-radius: 25px;
    height: 50px;
    max-width: 391px;
    display:flex;
    flex-direction:row;
    padding:10px;
    align-items:center;
    justify-content:space-around;
    margin:20px auto;
    position:relative;

    @media(max-width:600px){
        margin:15px 20px;
    }
   

`;

const Activator=styled.div`
    font-style: normal;
    font-weight: bold;
    background: #2A3390;
    border-radius: 25px;
    position:absolute;
    left:0px;
    width: 136px;
    /* height: 50px; */
    color:#fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.1em;
    padding:10px;
    text-align:center;
    cursor:pointer;
    transition:0.3s;

    &.tab1{
     left:0px;
     width: 136px;
    }
    &.tab2{
        left:120px;
     width: 140px;
    }
    &.tab3{
        left:255px;
       width: 136px;

    }

    @media(max-width:600px){
        width: 136px;
       left:0px;

        &.tab1{
        left:0px;
        width: 110px;
        }
        &.tab2{
        left:110px;
        width: 120px;
        }
        &.tab3{
        left:225px;
        width: 110px;

        }
    }

`

 const Items = styled.div`
    padding:10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #CACCE3;
    cursor:pointer;

    &.active{
         color:#fff;
    }

`;

interface TabInterface{
  activeIndex:number;
  setActive:any;
    
}

const Tab: React.FC<TabInterface> = ({activeIndex,setActive}) => {
  const classAdded=activeIndex === 0 ? 'tab1' : activeIndex=== 1 ? 'tab2' : 'tab3'  
  const activeText=activeIndex === 0 ? 'Swap' : activeIndex=== 1 ? 'Liquidity' : 'Bridge'  

    return (
        <TabContainer>
            <Activator className={classAdded}>{activeText}</Activator>
            <Items className="active" onClick={()=>setActive(0)}>Swap</Items>
            <Items onClick={()=>setActive(1)}>Liquidity</Items>
            <Items onClick={()=>setActive(2)}>Bridge</Items>
        </TabContainer>
    )
}

export default Tab