import AuctionAbi from "abi/auctionAbi.json";
import FactoryAbi from "abi/auctionFactoryAbi.json";
import Erc20Abi from "abi/erc20.json";
import LockerAbi from "abi/lockerAbi.json";
import { web3 } from "hooks/useWeb3Provider";


export const getContract = async (address: string, abi: any) => {
  let web3Init = await web3();
  return new web3Init.eth.Contract(abi, address);
};

export const auctionFacContract = async (address: string) => {
  const auctionFacContract = getContract(address, FactoryAbi);
  return auctionFacContract;
};

export const auctionContract = async (address: string) => {
  const auctionContract = getContract(address, AuctionAbi);

  return auctionContract;
};

export const tokenContract = async (tokenAddress: string) => {
  const tokenContract = getContract(tokenAddress, Erc20Abi);
  return tokenContract;
};

export const lockerContract = async (address: string) => {
  const lockerContract = getContract(address, LockerAbi);
  return lockerContract;
};

export const toEth = async (value: any) => {
  let web = await web3();
  return await web.utils.fromWei(value, "ether");
};

export const toWei = async (value: string) => {
  let web = await web3();
  return web.utils.toWei(value, "ether");
};

export const toBig = (value: string) => {
  return "";
};

export const amountInPercent = (amountRaised: any, hardCap: any) => {
  return Math.round((amountRaised / hardCap) * 100);
};

export const toE = (value: number) => {
  return value / 1e18;
};
