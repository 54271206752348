import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0px;
`;

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 998;
  background: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
`;

const Logo = styled.div`
  position: absolute;
  width: 100px;
  z-index: 999;
  left: 45%;
  top: 45%;
  transform: translate(0px, 0%);
  margin: auto;
  img {
    animation: rotateIt 1.5s infinite;
  }
  h4 {
    color: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
  }

  @keyframes rotateIt {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const GlobalLoading: React.FC<{ type: string }> = ({ type }) => {
  return (
    <Container>
      <Overlay />

      <Logo>
        <img src="/assets/loading-logo.png" alt="Coterie logo" />
        <h4>{type}</h4>
      </Logo>
    </Container>
  );
};
export default GlobalLoading;
