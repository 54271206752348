import React from 'react'
import { IonModal, IonButton } from '@ionic/react';
import styled from 'styled-components'
import { useNetwork } from './../../store/hooks'
import { EXCHANGE_BSC_TEST,EXCHANGE_ETH_TEST } from './../../address';

interface ExchangeProps {
    show:boolean;
    setShow:(val:boolean)=>void;
    handleAddress:(val:string[])=>void
}

const ExchangeModal=styled.div`
  display:flex;
  flex-wrap:wrap;
  padding:20px;

`
const ExchangeItem=styled.div`
    height: 180px;
    border-radius: 10px;
    box-shadow: 2px 3px 4px #ddd;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
    margin: 10px;
    justify-content: center;
    padding: 5px;
    align-items: center;
    cursor:pointer;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    
    &:hover{
        transform:scale(1.2);
        -webkit-transform:scale(1.2);
        -moz-transform:scale(1.2);
        -ms-transform:scale(1.2);
        -o-transform:scale(1.2);
        border:2px solid #2a2290;
    }
`



const Exchange: React.FC<ExchangeProps> = ({show,setShow,handleAddress}) => {
     let network=useNetwork()
    
    return (
          <IonModal isOpen={show} cssClass='my-custom-class' >
             <ExchangeModal className=''>
          {network === '4'
            ? EXCHANGE_ETH_TEST.map(({ name, logo, addresses }, i) => (
                <ExchangeItem
                  key={i}
                  onClick={() => handleAddress(addresses)}>
                  <img src={logo} alt='Logo' />
                  <strong>{name}</strong>
                </ExchangeItem>
              ))
            : EXCHANGE_BSC_TEST.map(({ name, logo, addresses }, i) => (
                <ExchangeItem
                  key={i}
                  onClick={() => handleAddress(addresses)}>
                  <img src={logo} alt='Logo' />
                  <strong>{name}</strong>
                </ExchangeItem>
              ))}
        </ExchangeModal>
          </IonModal>
        );
}

export default Exchange