import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebar:false,
  },
  reducers: {
    setToggle: (state) => {
      state.sidebar=!state.sidebar;
    },
    closeBar: (state) => {
      state.sidebar=false;
    },
  }
})


export const { setToggle,closeBar } = uiSlice.actions


export default uiSlice.reducer
