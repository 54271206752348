// const { InjectedConnector } = Connectors
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  1: "https://mainnet.infura.io/v3/84842078b09946638c03157f83405213" as string,
  4: "https://mainnet.infura.io/v3/84842078b09946638c03157f83405213" as string,
};

export const MetaMask = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 137, 43114],
});

// const rinkeby = new NetworkOnlyConnector({
//   providerURL: 'https://rinkeby.infura.io/v3/ae69348d48e148e79799e35958268f01'
// })

export const WalletConnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  qrcode: true,
});
