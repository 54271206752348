import {
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonTextarea,
  useIonModal,
} from "@ionic/react";
import {
  Flex,
  Footer,
  GlobalLoading,
  Input,
  SetupContainer,
  Text,
} from "components";
import { ButtonPrimary, ButtonWhite } from "components/Button";
// import TransactionSubmitted from "components/Pool/TransactionSubmitted";
import TConfirmModal from "components/Modal/TConfirmModal";
import Alert from "components/Pool/Alert";
import PropertyModal from "components/Pool/PropertyModal";
import Searchbar from "components/Pool/Searchbar";
import TransactionDeny from "components/Pool/TransactionDeny";
import TransactionWaiting from "components/Pool/TransactionWaiting";
import UserSearchList from "components/UserSearchList";
import {
  useCreateNftMutationMutation,
  useCreateUserMutation,
  User,
  useUsersQueryQuery,
} from "generated/graphql-frontend";
import { use_mintNFTs, use_mintNFTsWithRoyalty } from "hooks/nfts/nft-hooks";
import { web3 } from "hooks/useWeb3Provider";
import React, { useEffect, useState } from "react";
import { FilePond } from "react-filepond";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { IPaymentsTo, IUserReceiver, IUserSearch } from "types/nft-user-types";
import { getNFTERC721Address } from "utils/addressHelper";
import { toBN } from "utils/BigNumber";
import { ipfsPinHelper } from "utils/ipfsUrlHelper";
import {
  getChainFromChainId,
  getUserSearchArray,
  getUserSocial,
  toChecksum,
} from "utils/nft-user-helper";

const Container = styled.div`
  min-height: 100%;
  overflow: auto;
  transition: all 0.3s;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

interface FormValue {
  name: string;
  description: string;
}

const AddRoyaltyContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 1rem;
  padding: 10px;
`;

const TextArea = styled(IonTextarea)`
  width: 100% !important;
`;

const UserH6 = styled.h6`
  font-weight: 600;
  font-size: 18px;
`;

const PropertyCard = styled.div`
  background: rgba(106, 100, 255, 0.1);
  border: 1px solid #6a64ff;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 4px rgba(106, 100, 255, 0.08);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  text-align: center;
`;

const CreateNft: React.FC<IUserReceiver> = ({ user, social }) => {
  const [createUserMutation] = useCreateUserMutation();
  const [beneficiaries, setBeneficiaries] = useState<IUserSearch[]>([]);
  const [settledBeneficiaries, setSettledBeneficiaries] = useState<
    { address: string; share: number }[]
  >([]);
  const { account, chainId /*  deactivate, connector */ } =
    useActiveWeb3React();
  const [usersSearch, setUsersSearch] = useState<IUserSearch[]>([]);
  const [files, setFiles] = useState([]);
  const [nftImgHash, setNftImgHash] = useState<string>("");
  const [append, setAppend] = useState<string>("");
  const [nftMetadataHash, setNftMetadataHash] = useState<string>("");
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileProgress, setFileProgress] = useState<number>(0);
  const [addRoyalty, setAddRoyalty] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [txnSubmittedMsg, setTxnSubmittedMsg] = useState<string>("");
  const [waitingMsg, setWaitingMsg] = useState<string>("");
  const [creating, setCreating] = useState<boolean>(false);

  const [searchText, setSearchText] = useState("");

  const [nft, setNft] = useState<FormValue>({
    name: "",
    description: "",
  });
  let _nftMetadataHash = "";

  // graphql hook
  const [createNftMutationMutation] = useCreateNftMutationMutation();
  const { data, loading, error } = useUsersQueryQuery({
    variables: {
      limit: 20,
    },
  });
  const users = !loading && !error && data?.getUsers;
  const [properties, setProperty] = useState([{ type: "", name: "" }]);
  const [savedProperties, setSavedProperties] = useState([]);

  const searchUserAndCreateIfNull = async (search: string) => {
    if (search?.length) {
      let _user: User = null;
      _user = users.find(
        ({ addresses, name, username }) =>
          addresses
            .map((address) => address?.toLowerCase())
            .includes(search?.toLowerCase()) ||
          (name && name.toString().toLowerCase() === search?.toLowerCase()) ||
          (username &&
            username.toString().toLowerCase() === search?.toLowerCase())
      ) as User;
      if (_user) {
        const profile = await getUserSocial(user.profileHash);
        return {
          name: _user?.name?.length ? _user?.name : "new user",
          username: _user?.username?.length ? _user?.username : "new user",
          address: _user?.address,
          imageHash: profile?.imageHash?.length
            ? profile?.imageHash
            : "/assets/user_avatar.jpg",
        };
      }
      if (search?.toLowerCase().startsWith("0x") && search?.length === 42) {
        _user = (
          await createUserMutation({
            variables: {
              address: search,
            },
          })
        )?.data?.createUser as User;
        const profile = await getUserSocial(_user.profileHash);
        return {
          name: _user?.name?.length ? _user?.name : "new user",
          username: _user?.username.length ? _user?.username : "new_user",
          address: _user?.address,
          imageHash: profile?.imageHash?.length
            ? profile?.imageHash
            : "/assets/user_avatar.jpg",
        };
      }
    }
  };

  const handleChange = (e: any) => {
    setNft((prev: any) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const uploadMetadata = async () => {
    let jsonObj = savedProperties?.length
      ? JSON.stringify({
          ...nft,
          image: `${nftImgHash}`,
          properties: savedProperties,
        })
      : JSON.stringify({
          ...nft,
          image: `${nftImgHash}`,
        });
    if (nftImgHash?.length) {
      const { cid } = await ipfsPinHelper(
        Buffer.from(jsonObj),
        `${append.split(".").pop()}-nft-metadata.json`,
        console.log
      );
      _nftMetadataHash = `${cid.toString()}/${append
        .split(".")
        .pop()}-nft-metadata.json`;
      setNftMetadataHash(_nftMetadataHash);
    }
  };

  const uploadFile = async () => {
    let file = files[0].file;
    let size = file.size;
    let sizeInMB = (size / (1024 * 1024)).toFixed(2);
    if (+sizeInMB > 50) {
      setShowAlert(true);
      setFiles([]);
    } else {
      setUploadingImg(true);
      setFileSize(+sizeInMB);
      const { cid } = await ipfsPinHelper(
        file,
        file.name.replace(/\s/g, "") as string,
        setFileProgress
      );
      let hash = cid.toString();
      setAppend(file.name.replace(/\s/g, "") as string);
      setNftImgHash(`${hash}/${file.name.replace(/\s/g, "")}`);
      setUploadingImg(false);
    }
  };
  // make user search object array
  const userSearchArray = async () => {
    let usersToFilter: IUserSearch[] = [];
    if (!loading && !error && data?.getUsers?.length) {
      usersToFilter = await getUserSearchArray(user, data?.getUsers as User[]);
    }
    usersToFilter = [
      ...usersToFilter,
      {
        name: user?.name,
        username: user?.username,
        address: user?.address,
        imageHash: social?.imageHash,
      },
    ];
    setUsersSearch(usersToFilter);
  };

  const handleErrorDismiss = () => {
    dismissPresentError();
  };
  const handleTxSubmittedDismiss = () => {
    dismissSubmitted();
  };

  const handleWaitingDismiss = () => {
    dismissTxnWaiting();
  };

  const [presentError, dismissPresentError] = useIonModal(TransactionDeny, {
    close: handleErrorDismiss,
    msg: errorMsg,
  });

  const handleSave = () => {
    let isEmpty = false;
    properties.map(({ type, name }) => {
      if (type === "" || name === "") {
        isEmpty = true;
      }
    });
    dismissPresentProperty();

    if (isEmpty) {
      return;
    }
    setSavedProperties(properties);
  };

  const handleDismiseProps = () => {
    dismissPresentProperty();
  };

  const [presentProperty, dismissPresentProperty] = useIonModal(PropertyModal, {
    close: handleDismiseProps,
    msg: errorMsg,
    setProperty,
    properties,
    save: handleSave,
  });

  const handleDismiss = () => {
    dismissSubmitted();
  };

  const [presentSubmitted, dismissSubmitted] = useIonModal(TConfirmModal, {
    close: handleDismiss,
    trxHashUrl: txnSubmittedMsg,
  });

  const [presentTxnWaiting, dismissTxnWaiting] = useIonModal(
    TransactionWaiting,
    { close: handleWaitingDismiss, msg: waitingMsg }
  );

  const setUserAsDefault = (bool: boolean) => {
    if (user && bool) {
      let metadata = {
        name: user.name,
        username: user.username,
        address: user.address,
        imageHash: social?.imageHash,
      } as IUserSearch;
      setBeneficiaries([metadata]);
    } else setBeneficiaries([]);
  };

  const handleContinue = async () => {
    setCreating(true);
    if (nft?.description?.length && nft?.name?.length) {
      if (addRoyalty) {
        setShowSearch(true);
        await uploadMetadata();
        await createNFT();
      } else {
        await uploadMetadata();
        await createNFT();
      }
    }
    setNft({ name: "", description: "" });
    setFiles([]);
    setSettledBeneficiaries([]);
  };

  const searchResultHandler = (_user: IUserSearch) => {
    if (_user) {
      let newBenefactors: IUserSearch[] = [...beneficiaries];
      newBenefactors.push(_user);
      newBenefactors = newBenefactors
        .filter(
          (elem, index, self) =>
            self.findIndex(
              (t) =>
                t.address.toLocaleLowerCase() ===
                elem.address.toLocaleLowerCase()
            ) === index
        )
        .filter(Boolean);
      setBeneficiaries(newBenefactors);
    }
  };

  const _createNFTApi = (
    contractAddress: string,
    tokenId: string,
    tokenUri: string
  ) => {
    createNftMutationMutation({
      variables: {
        nftTokenInput: {
          contractAddress,
          tokenUri,
          tokenId,
          minter: user.address,
          chain: getChainFromChainId(chainId ?? 97),
        },
      },
    });
  };
  const createNFT = async (payTos?: IPaymentsTo[]) => {
    const _web3 = await web3();
    if (payTos) {
      if (!nftMetadataHash?.length) return;
      const users_addresses = payTos.map((pay) => toChecksum(pay.to));
      const user_shares = payTos.map((pay) => toBN(pay.percent));
      presentTxnWaiting();
      try {
        use_mintNFTsWithRoyalty(
          `${nftMetadataHash}`,
          users_addresses,
          user_shares,
          toChecksum(user.address),
          _web3,
          getChainFromChainId(chainId)
        )
          .on("transactionHash", (tx: any) => {
            setCreating(false);
            alert();
            setTxnSubmittedMsg(`${tx}`);
            presentSubmitted();
            dismissTxnWaiting();
          })
          .once("receipt", (receipt: any) => {
            setCreating(false);
            const event = receipt?.events?.Transfer?.returnValues;

            _createNFTApi(
              getNFTERC721Address(),
              event.tokenId.toString(),
              `${nftMetadataHash}`
            );
            window.location.reload();
          })
          .on("error", (error: any) => {
            setErrorMsg(error.message);
            presentError();
            setCreating(false);
          });
        setShowSearch(false);
        setBeneficiaries([]);
        setAddRoyalty(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      if (!_nftMetadataHash?.length) return;

      try {
        use_mintNFTs(
          `${_nftMetadataHash}`,
          toChecksum(user.address),
          _web3,
          getChainFromChainId(chainId)
        )
          .on("transactionHash", (tx: any) => {
            setTxnSubmittedMsg(`${tx}`);
            setCreating(false);
            presentSubmitted();
            dismissTxnWaiting();
          })
          .once("receipt", (receipt: any) => {
            const event = receipt?.events?.Transfer?.returnValues;
            _createNFTApi(
              getNFTERC721Address(),
              event.tokenId.toString(),
              _nftMetadataHash
            );
          })
          .on("error", (error: any) => {
            setErrorMsg(error.message);
            presentError();
            setCreating(false);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };
  const [, setState] = useState(false);
  useEffect(() => {
    if (account && user === null) {
      createUserMutation({
        variables: {
          address: account,
        },
      });
    }
    userSearchArray();
    return () => {
      setState(true);
    };
  }, [user, loading, account]);

  const handleCheck = (bool: boolean) => {
    setAddRoyalty(bool);

    setUserAsDefault(bool);
  };

  const handleAdd = (addr: string, share: number) => {
    if (addr && share > 0) {
      let settled = [...settledBeneficiaries, { address: addr, share }];
      settled.filter(Boolean);
      setSettledBeneficiaries(settled);
      return true;
    }
    return false;
  };
  const handleRemove = (addr: string) => {
    if (addr) {
      let settled = [...settledBeneficiaries];
      settled = settled.filter(
        ({ address }) => address.toLocaleLowerCase() !== addr.toLowerCase()
      );
      let bens = [...beneficiaries].filter(
        ({ address }) => address.toLowerCase() !== addr.toLowerCase()
      );
      setBeneficiaries(bens);
      setSettledBeneficiaries(settled);
    }
  };

  return (
    <IonContent>
      <Container>
        <div className="pool-p10">
          {creating && <GlobalLoading type="Creating..." />}
          <SetupContainer>
            <UserH6 style={{ marginLeft: "10px" }}>
              Please complete the NFT metadata form below
            </UserH6>
            <IonItem className="box">
              <Input
                required={true}
                label="Nft name"
                name="name"
                type="text"
                value={nft.name}
                placeholder="Nft name"
                onIonChange={(e: any) => handleChange(e)}
              />
            </IonItem>

            <IonItem className="box">
              <IonLabel position="floating">Description</IonLabel>
              <TextArea
                required={true}
                rows={9}
                value={nft.description}
                name="description"
                placeholder="Be thoughtful and articulate"
                onIonChange={(e: any) => handleChange(e)}
              ></TextArea>
            </IonItem>
            <AddRoyaltyContainer></AddRoyaltyContainer>
            <Alert
              {...{
                isOpen: showAlert,
                dismissAlert: setShowAlert,
                headerMsg: `File Size Too Large`,
                msg: `File size can not be greater than 50MB`,
                cancelText: "No",
                action: setShowAlert,
                actionInput: false,
                okText: "Yes",
              }}
            />
            <ButtonWhite onClick={() => presentProperty()}>
              Add Property
            </ButtonWhite>
            <br />

            <Flex style={{ flexWrap: "wrap" }}>
              {savedProperties.map((property, i) => (
                <PropertyCard key={i}>
                  <Text>{property.type}</Text>
                  <Text size="16" weight="500">
                    {property.name}
                  </Text>
                </PropertyCard>
              ))}
            </Flex>

            <FilePond
              files={files}
              allowReplace={false}
              allowProcess={true}
              dropOnPage={true}
              allowDrop={true}
              allowMultiple={false}
              allowImagePreview={true}
              instantUpload={false}
              server={{
                process: (
                  fieldName: any,
                  file: any,
                  metadata: any,
                  load: any,
                  error: any,
                  progress: any
                ) => {
                  uploadFile();
                  load(file);
                  // progress(fileProgress, fileSize);
                },
              }}
              name="files"
              labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              onupdatefiles={(fileItems) =>
                fileItems?.length && setFiles(fileItems)
              }
            />

            <Flex>
              <div>
                <IonCheckbox
                  slot="end"
                  color="primary"
                  onIonChange={(e) => handleCheck(e.detail.checked)}
                  checked={addRoyalty}
                />
              </div>
              <br />
              <div style={{ marginLeft: "10px" }}>
                Add royalty beneficiaries to receive payment on secondary sales.
                You can add up to 4 beneficiaries and as low as 0.01%{" "}
              </div>
            </Flex>
            <br />

            {addRoyalty && (
              <>
                <IonLabel position="floating">
                  Add Royalty Beneficiaries
                </IonLabel>

                <br />
                <Searchbar
                  {...{
                    placeholder: "Search Beneficiary",
                    includesImg: true,
                    searchField: "name",
                    searchField1: "username",
                    searchField2: "address",
                    rawData: usersSearch,
                    imgKey: "imageHash",
                    searchResult: searchResultHandler,
                    secondarySearch: searchUserAndCreateIfNull,
                  }}
                />

                {beneficiaries?.length ? (
                  beneficiaries.map((benficiary, k) => (
                    <UserSearchList
                      addFunc={handleAdd}
                      removeFunc={handleRemove}
                      key={k}
                      user={benficiary}
                      placeholder="2.5%"
                    />
                  ))
                ) : (
                  <div></div>
                )}
              </>
            )}
            <ButtonPrimary
              disabled={
                !files?.length ||
                !nft.name.length ||
                !nft.description.length ||
                !nftImgHash?.length ||
                creating
              }
              color="primary"
              expand="block"
              onClick={handleContinue}
            >
              <strong>Create NFT</strong>
            </ButtonPrimary>
          </SetupContainer>
        </div>
        <Footer />
      </Container>
    </IonContent>
  );
};
export default CreateNft;
