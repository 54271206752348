import { IonAlert } from "@ionic/react";
import React, { FC } from "react";
interface IAlert {
  isOpen: boolean;
  dismissAlert: (action: boolean) => void;
  headerMsg: string;
  msg: string;
  action: (input: any) => void;
  actionInput: any;
  cancelText?: string;
  okText?: string;
}
const Alert: FC<IAlert> = ({
  isOpen,
  dismissAlert,
  headerMsg,
  msg,
  action,
  actionInput,
  okText,
  cancelText,
}) => {
  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={() => dismissAlert(false)}
      cssClass="my-custom-class"
      header={headerMsg}
      message={msg}
      mode="md"
      buttons={[
        {
          text: `${cancelText}`,
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            dismissAlert(false);
          },
        },
        {
          text: `${okText}`,
          role: "confirm",
          handler: () => {
            action(actionInput);
          },
        },
      ]}
    />
  );
};

export default Alert;
