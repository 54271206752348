import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import axios from "axios";
import BigNumber from "bignumber.js";
import farmsConfig from "config/farms";
import { FarmCategory } from "config/types";
import {
  Auction,
  Bid,
  Blockchain,
  MinterAccessRequest,
  NftHistory,
  NftToken,
  Status,
  useGetAuctionsHasOfferLazyQuery,
  useGetAuctionsIsClosedLazyQuery,
  useGetAuctionsOnChainLazyQuery,
  useGetAuctionsQuery,
  useGetBidsLazyQuery,
  useGetHistoriesByNftTokenIdLazyQuery,
  useGetTopCreatorsQuery,
  useGetTrendingQuery,
  useGetUserAuctionCollectedLazyQuery,
  useGetUserAuctionsOnsaleLazyQuery,
  useGetUserCreatedAuctionsLazyQuery,
  useGetUserCreatedNftsLazyQuery,
  useGetUserRequestsReceivedLazyQuery,
  useGetUserRequestsSentLazyQuery,
  User,
  useUserIdQueryQuery,
} from "generated/graphql-frontend";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FETCH_FARMS } from "sagas/types";
import { Farm } from "states/types";
import { ISocialFull, IWalletNFT } from "types/nft-user-types";
import {
  getAddress,
  getBnbBusdLPAddress,
  getBnbCoterieGovLPAddress,
  getBnbCoterieLPAddress,
} from "utils/addressHelper";
import { BIG_ZERO, toBN } from "utils/BigNumber";
import filterFarmsByQuoteToken from "utils/farmsPriceHelpers";
import { getBalanceAmount } from "utils/formatBalance";
import {
  filterDuplicatesObj,
  getChainFromChainId,
  getChainHexFromChainId,
  getUserSocial,
} from "utils/nft-user-helper";
import { simpleRpcProvider } from "utils/providers";

export const useParamsQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export const useNetwork = () => {
  const network = useSelector((state: any) => state.user.networkId);
  return network;
};

export const useAccount = () => {
  const account = useSelector((state: any) => state.user.account);
  return account;
};

export const useAuction = () => {
  const auction = useSelector((state: any) => state.auctions);
  return auction;
};

export const useCases = () => {
  const cases = useSelector((state: any) => state.cases);
  return cases;
};

export const useUi = () => {
  const ui = useSelector((state: any) => state.uiState);
  return ui;
};

export const useFarms = () => {
  const farms = useSelector((state: any) => state.farms);
  return farms;
};

export const usePoolFarmsData = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();

  const ids = farmsConfig.map((farm) => {
    return getAddress(farm.pid);
  });
  const dispatchValue = () =>
    account
      ? { type: FETCH_FARMS, payload: { user: account, pids: ids } }
      : { type: FETCH_FARMS, payload: { pids: ids } };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(dispatchValue());
    }, 60_000);
    return () => clearInterval(interval);
  }, [dispatch, account]);
};

export const usePoolOnlyData = () => {
  const ids = farmsConfig
    .filter((farm) => farm.category === FarmCategory.pool)
    .map((farm) => {
      return getAddress(farm.pid);
    });

  const farms = useSelector((state: any) =>
    state.farms.data.filter((f: Farm) => ids.includes(getAddress(f.pid)))
  );
  return farms;
};
export const usePoolFarmsOnlyData = () => {
  const ids = farmsConfig
    .filter((farm) => farm.category === FarmCategory.farm)
    .map((farm) => {
      return getAddress(farm.pid);
    });

  const farms = useSelector((state: any) =>
    state.farms.data.filter((f: Farm) => ids.includes(getAddress(f.pid)))
  );
  return farms;
};

export const useFarmFromPid = (pid: string): Farm => {
  const farm = useSelector((state: any) =>
    state.farms.data.find((f: Farm) => getAddress(f.pid) === pid)
  );
  return farm;
};

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: string): BigNumber => {
  const farm = useFarmFromPid(pid);
  return farm && new BigNumber(farm.token.busdPrice);
};

// Return a farm for a given token symbol. The farm is filtered based on attempting to return a farm with a quote token from an array of preferred quote tokens
export const useFarmFromTokenSymbol = (
  tokenSymbol: string,
  preferredQuoteTokens?: string[]
): Farm => {
  const farms = useSelector((state: any) =>
    state.farms.data.filter((farm: Farm) => farm.token.symbol === tokenSymbol)
  );
  const filteredFarm = filterFarmsByQuoteToken(farms, preferredQuoteTokens);
  return filteredFarm;
};

export const useBusdPriceFromToken = (tokenSymbol: string): BigNumber => {
  const tokenFarm = useFarmFromTokenSymbol(tokenSymbol);
  const tokenPrice = useBusdPriceFromPid(getAddress(tokenFarm?.pid));
  return tokenPrice;
};

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: any) =>
    state.farms.data.find((f: Farm) => f.lpSymbol === lpSymbol)
  );
  return farm;
};

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol);
  const farmTokenPriceInUsd = useBusdPriceFromPid(getAddress(farm.pid));
  let lpTokenPrice = BIG_ZERO;

  if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(
      farm.tokenAmountTotal
    );
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2);
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(toBN(farm.lpTotalSupply));
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens);
  }

  return lpTokenPrice;
};

export const useTotalLpTokenValue = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol);
  const farmTokenPriceInUsd = useBusdPriceFromPid(getAddress(farm.pid));
  let lpTokenTotalPrice = BIG_ZERO;

  if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(
      farm.tokenAmountTotal
    );
    // Double it to get overall value in LP
    lpTokenTotalPrice = valueOfBaseTokenInFarm.times(2);
  }
  return lpTokenTotalPrice;
};

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(getBnbBusdLPAddress());
  return toBN(bnbBusdFarm.quoteToken.busdPrice);
};

export const usePriceCoterieBusd = (): BigNumber => {
  const coterieBnbFarm = useFarmFromPid(getBnbCoterieLPAddress());
  return toBN(coterieBnbFarm.token.busdPrice);
};

export const usePriceCoterieGovBusd = () => {
  const coterieGovBusd = useFarmFromPid(getBnbCoterieGovLPAddress());
  return toBN(coterieGovBusd.token.busdPrice);
};

const _getFarmFromPid = (pid: string) => {
  const farm = useSelector((state: any) =>
    state.farms.data.find((f: Farm) => getAddress(f.pid) === pid)
  );
  return farm as Farm;
};
export const useBusdPrices = () => {
  const dispatch = useDispatch();
  const ids = farmsConfig.map((farm) => {
    return getAddress(farm.pid);
  });
  dispatch({ type: FETCH_FARMS, payload: { pids: ids } });

  const coterieBnbFarm = _getFarmFromPid(getBnbCoterieLPAddress());
  const coterieUsd = toBN(coterieBnbFarm.token.busdPrice);

  const bnbBusdFarm = _getFarmFromPid(getBnbBusdLPAddress());
  const bnbUsd = toBN(bnbBusdFarm.quoteToken.busdPrice);

  const coterieGovBusd = _getFarmFromPid(getBnbCoterieGovLPAddress());
  const coterieGovUsd = toBN(coterieGovBusd.token.busdPrice);

  return { stables: toBN("1"), coterieUsd, bnbUsd, coterieGovUsd };
};

// export const useTrendingNfts = () => {
//   const { loading, data, error } = useGetTrendingQuery({
//     variables: {
//       limit: 4,
//     },
//   });
//   // filter the state nfts for the id

//   const { fullAuctions } = useSelector((state: any) => state.contractAuctions);
//   if (!loading && !error && data?.getTrending) {
//     const ids = data.getTrending.map((nft) => nft.auctionId);
//     return fullAuctions.filter((nft: ContractAuction) =>
//       ids.includes(nft.auctionId)
//     );
//   }
// };

// for the onSale nfts
export const useAuctionOwnerProfile = (address: string) => {
  return useSelector((state: any) => {
    return state.nfts.auctions.filter((nft: any) => {
      return nft.owner.id === address && nft.closedAt === null;
    });
  });
};

// refactor to useEffect
export const useUserFollowers = (userId: string) => {
  const { data, loading, error } = useUserIdQueryQuery({
    variables: {
      getUserId: userId ?? "6163832839c8c8b2fc254265", // value for 'getUserByIdId'
    },
  });
  const user = !loading && !error && data?.getUser;
  const [followers, setFollowers] = useState<ISocialFull[]>(null);
  const [isFollowerLoading, setIsFollowerLoading] = useState<boolean>(true);
  const [isFollowersError, setIsFollowerError] = useState(null);
  useEffect(() => {
    let isCalled = false;
    setIsFollowerLoading(true);
    async function getFollowers() {
      try {
        if (!isCalled && user) {
          const ffs = user.followers;
          const ffwrs = /* ffs?.length && */ (await Promise.all(
            ffs.map(async (ff) => {
              const social = await getUserSocial(ff.profileHash);
              return {
                id: ff.id,
                address: ff.address,
                name: ff.name,
                username: ff.username,
                bio: social.bio,
                imageHash: social.imageHash,
              };
            })
          )) as ISocialFull[];
          setFollowers(ffwrs);
        }
      } catch (error) {
        setIsFollowerError(error);
      }
    }
    !isCalled && getFollowers();
    setIsFollowerLoading(false);
    return () => {
      isCalled = true;
    };
  }, [user]);

  return {
    followers,
    isFollowerLoading,
    isFollowersError,
  };
};

export const useUserFollowing = (userId: string) => {
  const { data, loading, error } = useUserIdQueryQuery({
    variables: {
      getUserId: userId ?? "6163832839c8c8b2fc254265", // value for 'getUserByIdId'
    },
  });
  const user = !error && !loading && data?.getUser;
  const [followings, setFollowings] = useState<ISocialFull[]>(null);
  const [isFollowingLoading, setIsFollowingLoading] = useState<boolean>(true);
  const [isFollowingError, setIsFollowiingError] = useState(null);
  useEffect(() => {
    let isCalled = false;
    async function getSocial() {
      try {
        setIsFollowingLoading(true);
        if (user && !isCalled) {
          const ffs = user.following;
          const flls = (await Promise.all(
            ffs.map(async (ff: any) => {
              const social = await getUserSocial(ff.profileHash);
              return {
                id: ff.id,
                address: ff.address,
                name: ff.name,
                username: ff.username,
                bio: social.bio,
                imageHash: social.imageHash,
              };
            })
          )) as ISocialFull[];
          setFollowings(flls);
        }
      } catch (error) {
        setIsFollowiingError(error);
      }
    }
    !isCalled && getSocial();
    setIsFollowingLoading(false);
    return () => {
      isCalled = true;
    };
  }, [user]);
  return { followings, isFollowingLoading, isFollowingError };
};

export const useMoralisTokens = () => {
  const { account, chainId } = useActiveWeb3React();

  const [, setState] = useState(false);
  const [walletNfts, setWalletNfts] = useState<IWalletNFT[]>([]);
  const [isWalletLoading, setIsWalletLoading] = useState(true);
  const [walletError, setWalletError] = useState(null);

  useEffect(() => {
    setIsWalletLoading(true);
    async function getMoralis() {
      try {
        const url = `https://deep-index.moralis.io/api/v2/${account}/nft?chain=${getChainHexFromChainId(
          chainId ?? 97
        )}&format=decimal`;
        const res = await axios.get(url, {
          headers: {
            "X-API-Key": process.env.REACT_APP_MORALIS_API,
            accept: "application/json",
          },
        });
        const data: any = res.data;

        const filtered = data.result?.filter(
          (nft: any) => nft.contract_type === "ERC721"
        );
        const made = filtered.map((nft: any) => ({
          tokenId: nft.token_id,
          tokenUri: nft.token_uri,
          contractAddress: nft.token_address,
          symbol: nft.symbol,
          tokenName: nft.name,
          chain: getChainFromChainId(chainId),
        }));
        setWalletNfts(made);
        setIsWalletLoading(false);
      } catch (error) {
        console.log(`error`, error);
        setWalletError(error);
      }
    }
    getMoralis();
    console.log("walletNfts", walletNfts);
    return () => {
      setState(true);
    };
  }, [account, chainId]);
  return { walletNfts, walletError, isWalletLoading };
};

export const twitterBinding = async () => {
  const url = `/twitter/oauth/request_token`;
};

export const useActiveWeb3React =
  (): Web3ReactContextInterface<Web3Provider> => {
    const { library, chainId, ...web3React } = useWeb3React();
    const refEth = useRef(library);
    const [provider, setProvider] = useState(library || simpleRpcProvider);

    useEffect(() => {
      if (library !== refEth.current) {
        setProvider(library || simpleRpcProvider);
        refEth.current = library;
      }
    }, [library]);

    return {
      library: provider,
      chainId: chainId ?? parseInt(process.env.REACT_APP_CHAIN_ID, 10),
      ...web3React,
    };
  };

function erc20PriceCall(address: string, network: string) {
  return axios.get(
    `https://deep-index.moralis.io/api/v2/erc20/${address}/price?chain=${network}`,
    {
      headers: {
        "X-API-Key": process.env.REACT_APP_MORALIS_API,
        accept: "application/json",
      },
    }
  );
}

interface MoralisePriceCall {
  nativePrice: {
    value: string;
    decimals: number;
    name: string;
    symbol: string;
  };
  usdPrice: number;
  exchangeAddress: string;
  exchangeName: string;
}

export const useUSDPrice = () => {
  const bnbUsd = useRef(0);
  const ethUsd = useRef(0);
  const avaxUsd = useRef(0);
  const maticUsd = useRef(0);
  const [, setState] = useState(false);
  const bnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const ethAddress = "0x2170ed0880ac9a755fd29b2688956bd959f933f8";
  const avaxAddress = "0x1ce0c2827e2ef14d5c4f29a091d735a204794041";
  const maticAddress = "0xcc42724c6683b7e57334c4e856f4c9965ed682bd";
  useEffect(() => {
    async function getUsdPrice() {
      try {
        const bnbPriceData = (await erc20PriceCall(bnbAddress, "bsc"))
          .data as MoralisePriceCall;
        const ethPriceData = (await erc20PriceCall(ethAddress, "bsc"))
          .data as MoralisePriceCall;
        const avaxPriceData = (await erc20PriceCall(avaxAddress, "bsc"))
          .data as MoralisePriceCall;
        const maticPriceData = (await erc20PriceCall(maticAddress, "bsc"))
          .data as MoralisePriceCall;
        avaxUsd.current = avaxPriceData.usdPrice;
        bnbUsd.current = bnbPriceData.usdPrice;
        ethUsd.current = ethPriceData.usdPrice;
        maticUsd.current = maticPriceData.usdPrice;
      } catch (error) {
        console.error(error);
      }
    }
    getUsdPrice();
    const interval = setInterval(() => {
      getUsdPrice();
    }, 60000);
    return () => {
      clearInterval(interval);
      setState(true);
    };
  }, []);

  return {
    bnbUsd: bnbUsd.current,
    ethUsd: ethUsd.current,
    avaxUsd: avaxUsd.current,
    maticUsd: maticUsd.current,
    usdtUsd: 1,
    busdUsd: 1,
  };
};

export const useNfts = (
  limit: number,
  closingSoon?: boolean,

  hasOffer?: boolean,
  isNew?: boolean,
  onChain?: boolean,
  chain?: Blockchain,
  sold?: boolean
) => {
  const [auctions, setAuctions] = useState<Auction[]>(null);
  const [auctionsIsClosed, setAuctionsIsClosed] = useState<Auction[]>(null);
  const [auctionsIsNew, setAuctionsIsNew] = useState<Auction[]>(null);
  const [auctionsHasOffer, setAuctionsHasOffer] = useState<Auction[]>(null);
  const [auctionsOnChain, setAuctionsOnChain] = useState<Auction[]>(null);
  const { data, loading, error, fetchMore } = useGetAuctionsQuery({
    variables: {
      limit,
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  const [getAuctionsInBid, auctionsInBidRaw] =
    useGetAuctionsHasOfferLazyQuery();
  const [getAuctionsOnChain, auctionsOnChainRaw] =
    useGetAuctionsOnChainLazyQuery();
  const [getAuctionsSold, auctionsSoldRaw] = useGetAuctionsIsClosedLazyQuery();

  useEffect(() => {
    if (hasOffer) {
      getAuctionsInBid({
        variables: {
          limit,
        },
      });
      if (
        !auctionsInBidRaw?.error &&
        !auctionsInBidRaw?.loading &&
        !auctionsHasOffer &&
        auctionsInBidRaw?.data?.getAuctionsHasOffer
      ) {
        setAuctionsHasOffer(
          auctionsInBidRaw?.data?.getAuctionsHasOffer as Auction[]
        );
      }

      if (
        auctionsInBidRaw?.data?.getAuctionsHasOffer &&
        auctionsHasOffer &&
        auctionsHasOffer?.length !==
          auctionsInBidRaw?.data?.getAuctionsHasOffer?.length
      ) {
        setAuctionsHasOffer(
          () =>
            filterDuplicatesObj([
              ...auctionsHasOffer,
              ...auctionsInBidRaw?.data?.getAuctionsHasOffer,
            ]) as Auction[]
        );
      }
    }
  }, [hasOffer, auctionsInBidRaw?.data]);
  useEffect(() => {
    if (chain && onChain) {
      getAuctionsOnChain({
        variables: {
          chain,
          limit,
        },
      });
      if (
        !auctionsOnChainRaw?.error &&
        !auctionsOnChainRaw?.loading &&
        !auctionsOnChain &&
        auctionsOnChainRaw?.data?.getAuctionsOnChain
      ) {
        setAuctionsOnChain(
          auctionsOnChainRaw?.data?.getAuctionsOnChain as Auction[]
        );
      }

      if (
        auctionsOnChainRaw?.data?.getAuctionsOnChain &&
        auctionsOnChain &&
        auctionsOnChain?.length !==
          auctionsOnChainRaw?.data?.getAuctionsOnChain?.length
      ) {
        setAuctionsOnChain(
          () =>
            filterDuplicatesObj([
              ...auctionsOnChain,
              ...auctionsOnChainRaw?.data?.getAuctionsOnChain,
            ]) as Auction[]
        );
      }
    }
  }, [chain, onChain, auctionsOnChainRaw?.data]);
  useEffect(() => {
    if (sold) {
      getAuctionsSold({
        variables: {
          limit,
        },
      });
      if (
        !auctionsSoldRaw?.error &&
        !auctionsSoldRaw?.loading &&
        !auctionsIsClosed &&
        auctionsSoldRaw?.data?.getAuctionsIsClosed
      ) {
        setAuctionsIsClosed(
          auctionsSoldRaw?.data?.getAuctionsIsClosed as Auction[]
        );
      }

      if (
        auctionsSoldRaw?.data?.getAuctionsIsClosed &&
        auctionsIsClosed &&
        auctionsIsClosed?.length !==
          auctionsSoldRaw?.data?.getAuctionsIsClosed?.length
      ) {
        setAuctionsIsClosed(
          () =>
            filterDuplicatesObj([
              ...auctionsIsClosed,
              ...auctionsSoldRaw?.data?.getAuctionsIsClosed,
            ]) as Auction[]
        );
      }
    }
  }, [sold, auctionsSoldRaw?.data]);

  useEffect(() => {
    if (!error && !loading && !auctions && data?.getAuctions) {
      setAuctionsIsNew(data?.getAuctions as Auction[]);
    }
    console.log("data?.getAuctions :>> ", data?.getAuctions);
    if (
      data?.getAuctions &&
      auctionsIsNew &&
      auctionsIsNew?.length !== data?.getAuctions?.length
    ) {
      setAuctionsIsNew(
        () =>
          filterDuplicatesObj([
            ...auctionsIsNew,
            ...data?.getAuctions,
          ]) as Auction[]
      );
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (/* closingSoon && sold && onChain && hasOffer && */ sold) {
      setAuctions(
        () => filterDuplicatesObj([...(auctionsIsClosed || [])]) as Auction[]
      );
    } else if (closingSoon) {
      setAuctions(() =>
        auctionsHasOffer?.filter(
          (auction) =>
            new Date(auction.closedAt).getTime() / 1000 -
              new Date(Date.now()).getTime() / 1000 >
            18000
        )
      );
    } else if (onChain) {
      setAuctions(
        () => filterDuplicatesObj([...(auctionsOnChain || [])]) as Auction[]
      );
    } else if (hasOffer) {
      setAuctions(
        () => filterDuplicatesObj([...(auctionsHasOffer || [])]) as Auction[]
      );
    } else if (isNew) {
      setAuctions(
        () => filterDuplicatesObj([...(auctionsIsNew || [])]) as Auction[]
      );
    }
  }, [
    closingSoon,
    hasOffer,
    onChain,
    isNew,
    auctionsIsClosed,
    auctionsOnChain,
    auctionsHasOffer,
    auctionsIsNew,
  ]);

  return {
    auctions,
    loading,
    error,
    fetchMoreHasOffer: auctionsInBidRaw.fetchMore,
    fetchMoreSold: auctionsSoldRaw.fetchMore,
    fetchMoreOnChain: auctionsOnChainRaw.fetchMore,
    fetchMoreNfts: fetchMore,
  };
};

export const useTopCreators = (limit: number) => {
  const [creators, setCreators] = useState<User[]>(null);

  const { data, error, loading, fetchMore } = useGetTopCreatorsQuery({
    variables: {
      limit,
    },
  });

  useEffect(() => {
    if (!loading && !error && data?.getTopCreators) {
      setCreators(data?.getTopCreators as User[]);
    }

    if (
      data?.getTopCreators &&
      creators &&
      creators?.length !== data?.getTopCreators?.length
    ) {
      setCreators(
        () =>
          filterDuplicatesObj([...creators, ...data?.getTopCreators]) as User[]
      );
    }

    // eslint-disable-next-line
  }, [data]);

  return {
    creators,
    error,
    loading,
    fetchMoreCreators: fetchMore,
  };
};

export const useTrendings = (limit: number) => {
  const [trendings, setTrendings] = useState<Auction[]>(null);
  const { data, loading, error, fetchMore } = useGetTrendingQuery({
    variables: {
      limit,
    },
  });

  useEffect(() => {
    if (!loading && !error && data?.getTrending) {
      setTrendings(data?.getTrending as Auction[]);
    }

    if (
      data?.getTrending &&
      trendings &&
      trendings?.length !== data?.getTrending?.length
    ) {
      setTrendings(
        () =>
          filterDuplicatesObj([...trendings, ...data?.getTrending]) as Auction[]
      );
    }

    // eslint-disable-next-line
  }, [data]);

  return {
    trendings,
    trendingLoading: loading,
    trendingErr: error,
    fetchMoreTrending: fetchMore,
  };
};

export const useUserNftCreated = (userId: string, limit: number) => {
  const [created, setCreated] = useState<Auction[]>(null);

  const [getUserNftCreated, { data, loading, error, fetchMore }] =
    useGetUserCreatedAuctionsLazyQuery();

  useEffect(() => {
    if (userId?.length) {
      getUserNftCreated({
        variables: {
          userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }
    if (!loading && !error && data?.getUserCreatedAuctions) {
      setCreated(() => data?.getUserCreatedAuctions as Auction[]);
    }

    if (
      data?.getUserCreatedAuctions &&
      created &&
      created?.length !== data?.getUserCreatedAuctions?.length
    ) {
      setCreated(
        () =>
          filterDuplicatesObj([
            ...created,
            ...data?.getUserCreatedAuctions,
          ]) as Auction[]
      );
    }
  }, [data, userId]);

  return {
    created,
    createdLoading: loading,
    createdErr: error,
    fetchMoreCreated: fetchMore,
  };
};

export const useUserAuctionCollected = (limit: number, userId: string) => {
  const [getCollected, { data, loading, error, fetchMore }] =
    useGetUserAuctionCollectedLazyQuery();
  const [collected, setCollected] = useState<Auction[]>(null);

  useEffect(() => {
    if (userId?.length) {
      getCollected({
        variables: {
          userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }
    if (!loading && !error && data?.getUserAuctionsCollected) {
      setCollected(() => data?.getUserAuctionsCollected as Auction[]);
    }

    console.log(
      `data?.getUserAuctionsCollected`,
      data?.getUserAuctionsCollected
    );
    if (
      data?.getUserAuctionsCollected &&
      collected &&
      collected?.length !== data?.getUserAuctionsCollected?.length
    ) {
      setCollected(
        () =>
          filterDuplicatesObj([
            ...collected,
            ...data?.getUserAuctionsCollected,
          ]) as Auction[]
      );
    }
  }, [data, userId]);

  return {
    getCollected,
    collected,
    collectedLoading: loading,
    collectedErr: error,
    fetchMoreCollected: fetchMore,
  };
};

export const useUserAuctionsOnsale = (limit: number, userId: string) => {
  const [getOnsale, { data, loading, error, fetchMore }] =
    useGetUserAuctionsOnsaleLazyQuery();
  const [onsale, setOnsale] = useState<Auction[]>(null);

  useEffect(() => {
    if (userId?.length) {
      getOnsale({
        variables: {
          userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }
    if (!loading && !error && data?.getUserAuctionsOnsale) {
      setOnsale(() => data?.getUserAuctionsOnsale as Auction[]);
    }
    console.log(`data?.getUserAuctionsOnsale`, data?.getUserAuctionsOnsale);
    if (
      data?.getUserAuctionsOnsale &&
      onsale &&
      onsale?.length !== data?.getUserAuctionsOnsale?.length
    ) {
      setOnsale(
        () =>
          filterDuplicatesObj([
            ...onsale,
            ...data?.getUserAuctionsOnsale,
          ]) as Auction[]
      );
    }
  }, [data, userId]);

  return {
    onsale,
    onsaleLoading: loading,
    onsaleErr: error,
    fetchMoreOnsale: fetchMore,
  };
};

export const useUserNFTMinted = (limit: number, userId: string) => {
  const [getUserNfts, { data, loading, error, fetchMore }] =
    useGetUserCreatedNftsLazyQuery();
  const [nfts, setNfts] = useState<NftToken[]>(null);

  useEffect(() => {
    if (userId?.length) {
      getUserNfts({
        variables: {
          userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }

    if (!loading && !error && data?.getUserCreatedNfts) {
      setNfts(() => data?.getUserCreatedNfts as NftToken[]);
    }

    if (
      data?.getUserCreatedNfts &&
      nfts &&
      nfts?.length !== data?.getUserCreatedNfts?.length
    ) {
      setNfts(
        () =>
          filterDuplicatesObj([
            ...nfts,
            ...data?.getUserCreatedNfts,
          ]) as NftToken[]
      );
    }
  }, [data, userId]);

  return {
    nfts,
    nftsLoading: loading,
    nftsErr: error,
    fetchMoreNfts: fetchMore,
  };
};

export const useUserRequestsSent = (limit: number, userId: string) => {
  const [getUserReqSent, { data, error, loading, fetchMore }] =
    useGetUserRequestsSentLazyQuery();

  const [requestsSent, setRequestsSent] = useState<MinterAccessRequest[]>(null);
  useEffect(() => {
    if (userId?.length) {
      getUserReqSent({
        variables: {
          getUserRequestsSentId: userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }
    if (!loading && !error && data?.getUserRequestsSent) {
      setRequestsSent(data?.getUserRequestsSent as MinterAccessRequest[]);
    }

    if (
      data?.getUserRequestsSent &&
      requestsSent &&
      requestsSent?.length !== data?.getUserRequestsSent?.length
    ) {
      setRequestsSent(
        () =>
          filterDuplicatesObj([
            ...requestsSent,
            ...data?.getUserRequestsSent,
          ]) as MinterAccessRequest[]
      );
    }
  }, [data, userId]);

  return {
    requestsSent,
    requestSentErr: error,
    requestSentLoading: loading,
    fetchMoreRequestSent: fetchMore,
  };
};

export const useUserRequestsReceived = (limit: number, userId: string) => {
  const [getUserReqReceived, { data, error, loading, fetchMore }] =
    useGetUserRequestsReceivedLazyQuery();

  const [requestsReceived, setRequestsReceived] =
    useState<MinterAccessRequest[]>(null);
  const [completedRequests, setCompletedRequests] =
    useState<MinterAccessRequest[]>(null);
  const [pendingRequests, setPendingRequests] =
    useState<MinterAccessRequest[]>(null);

  useEffect(() => {
    if (userId?.length) {
      getUserReqReceived({
        variables: {
          getUserRequestsReceivedId: userId, // value for 'getUserCreatedNfTsAuctionsId'
          limit,
        },
      });
    }
    if (!loading && !error && data?.getUserRequestsReceived) {
      setRequestsReceived(
        () => data?.getUserRequestsReceived as MinterAccessRequest[]
      );
      setCompletedRequests(
        () =>
          data?.getUserRequestsReceived?.filter(
            ({ status }) => status === Status.Completed
          ) as MinterAccessRequest[]
      );
      setPendingRequests(
        () =>
          data?.getUserRequestsReceived?.filter(
            ({ status }) => status === Status.Pending
          ) as MinterAccessRequest[]
      );
    }

    if (
      data?.getUserRequestsReceived &&
      requestsReceived &&
      requestsReceived?.length !== data?.getUserRequestsReceived?.length
    ) {
      const filteredReqs = filterDuplicatesObj([
        ...requestsReceived,
        ...data?.getUserRequestsReceived,
      ]);
      setRequestsReceived(() => filteredReqs as MinterAccessRequest[]);
      setCompletedRequests(() =>
        filteredReqs.filter(
          ({ status }: MinterAccessRequest) => status === Status.Completed
        )
      );
      setPendingRequests(() =>
        filteredReqs.filter(
          ({ status }: MinterAccessRequest) => status === Status.Pending
        )
      );
    }
  }, [data, userId]);
  return {
    pendingRequests,
    requestsReceived,
    completedRequests,
    requestReceivedErr: error,
    requestReceivedLoading: loading,
    fetchMoreRequestReceived: fetchMore,
  };
};

export const useAuctionBids = (limit: number, auctionId: string) => {
  const [getBids, { data, error, loading, fetchMore }] = useGetBidsLazyQuery();
  const [bids, setBids] = useState<Bid[]>(null);

  useEffect(() => {
    if (limit && auctionId) {
      getBids({
        variables: {
          limit,
          auctionId,
        },
      });
    }
    if (!loading && !error && data?.getBids) {
      setBids(data?.getBids as Bid[]);
    }
    if (data?.getBids && bids && bids?.length !== data?.getBids?.length) {
      setBids(filterDuplicatesObj([...bids, ...data?.getBids]));
    }
  }, [data, auctionId, limit]);
  return {
    bids,
    bidsErr: error,
    bidsLoading: loading,
    fetchMoreBids: fetchMore,
  };
};

export const useGetNftHistory = (limit: number, nftId: string) => {
  const [getNftHistories, { data, error, loading, fetchMore }] =
    useGetHistoriesByNftTokenIdLazyQuery();

  const [histories, setHistories] = useState<NftHistory[]>(null);

  useEffect(() => {
    if (nftId && limit) {
      getNftHistories({
        variables: { nftId: nftId, limit },
      });
    }
    if (!error && !loading && data?.getHistoriesByNftTokenId) {
      setHistories(data?.getHistoriesByNftTokenId as NftHistory[]);
    }

    if (
      data?.getHistoriesByNftTokenId &&
      histories &&
      histories?.length !== data?.getHistoriesByNftTokenId?.length
    ) {
      setHistories(
        filterDuplicatesObj([...histories, ...data?.getHistoriesByNftTokenId])
      );
    }
  }, [data, nftId]);
  return {
    histories,
    fetchMoreHistory: fetchMore,
    historyLoading: loading,
    historyErr: error,
  };
};

function useGetAuctionsOnsaleQuery(): [any, any] {
  throw new Error("Function not implemented.");
}
// export const useTopCreators =(limit:number)=>{

//   useEffect(()=>{

//   }, [])

//   return {

//   }
// }
