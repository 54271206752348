import {
  IonAvatar,
  IonCheckbox,
  IonContent,
  IonInput,
  IonLabel,
  IonText,
  useIonModal,
} from "@ionic/react";
import {
  Flex,
  Footer,
  GlobalLoading,
  Spacer,
  Text,
  UserSearchList,
} from "components";
import { ButtonPrimary } from "components/Button";
// import TransactionSubmitted from "components/Pool/TransactionSubmitted";
import TConfirmModal from "components/Modal/TConfirmModal";
import Alert from "components/Pool/Alert";
import Popover from "components/Pool/Popover";
import Searchbar from "components/Pool/Searchbar";
import TransactionDeny from "components/Pool/TransactionDeny";
import TransactionWaiting from "components/Pool/TransactionWaiting";
import { Caret } from "components/SvgIcons";
import {
  useCreateAuctionMutation,
  useCreateUserMutation,
  User,
  useUsersQueryQuery,
} from "generated/graphql-frontend";
import { use_createAuction } from "hooks/nfts/mktplace-hooks";
import { use_approval } from "hooks/nfts/nft-hooks";
import { web3 } from "hooks/useWeb3Provider";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { use_isApproved } from "states/nft/nft-multicalls";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import {
  ICoterieNFTs,
  INFTDetails,
  IPaymentMethod,
  IPaymentsTo,
  IUserReceiver,
  IUserSearch,
  IWalletNFTResolved,
} from "types/nft-user-types";
import { toBN } from "utils/BigNumber";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import {
  getChainFromChainId,
  getPaymentMethods,
  getUserSearchArray,
  toChecksum,
} from "utils/nft-user-helper";
import NftDetail from "./NftDetail";

const Container = styled.div`
  min-height: 100%;
  overflow: auto;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 90%;
  margin: auto;

  @media (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TokenContainer = styled.div`
  width: 40%;
  margin: 0px auto;

  @media (max-width: 640px) {
    width: 100%;
    padding: 10px;
  }
`;
const FormContainer = styled.div`
  width: 40%;
  margin: auto;

  @media (max-width: 640px) {
    width: 100%;
    padding: 10px;
  }
`;

const FormControl = styled.div`
  background: rgba(106, 100, 255, 0.05);
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;

  input {
    padding: 10px;
    background: rgba(106, 100, 255, 0.05);
    outline: none;
    border: none;
  }
`;

const ListNfts: React.FC<IUserReceiver> = ({ user, account, social }) => {
  const hs = useHistory();
  const [createUserMutation] = useCreateUserMutation();
  const { chainId } = useActiveWeb3React();
  const { data, loading, error } = useUsersQueryQuery({
    variables: { limit: 20 },
  });

  const [paymentMtds, setPaymentMtds] = useState<IPaymentMethod[]>();

  const [payTos, setPayTos] = useState<IPaymentsTo[]>();
  const [paymentMtd, setPaymentMtd] = useState(
    getPaymentMethods(chainId ?? 97)[0]
  );
  const [split, setSplit] = useState<boolean>(false);
  const [usersSearch, setUsersSearch] = useState<IUserSearch[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<IUserSearch[]>([]);
  const [tokenData, setTokenData] = useState<INFTDetails | ICoterieNFTs>();
  const [showAlert, setShowAlert] = useState(false);
  const [doApproval, setDoApproval] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [txnSubmittedMsg, setTxnSubmittedMsg] = useState<string>("");
  const [waitingMsg, setWaitingMsg] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [listing, setListing] = useState<boolean>(false);

  const basePrice = useRef<number>(0);
  // const payTos = useRef<IPaymentsTo[]>(defaultPayTo);
  const totalPaytos = useRef<number>(100);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [createAuctionMutation] = useCreateAuctionMutation();
  const handleErrorDismiss = () => {
    dismissPresentError();
  };
  const handleTxSubmittedDismiss = () => {
    dismissSubmitted();
  };

  const handleWaitingDismiss = () => {
    dismissTxnWaiting();
  };

  const [presentError, dismissPresentError] = useIonModal(TransactionDeny, {
    close: handleErrorDismiss,
    msg: errorMsg,
  });

  const [presentSubmitted, dismissSubmitted] = useIonModal(TConfirmModal, {
    close: handleTxSubmittedDismiss,
    trxHashUrl: txnSubmittedMsg,
  });

  const [presentTxnWaiting, dismissTxnWaiting] = useIonModal(
    TransactionWaiting,
    { close: handleWaitingDismiss, msg: waitingMsg }
  );
  const token = hs.location.state as IWalletNFTResolved;

  const userSearchArray = async () => {
    let usersToFilter: IUserSearch[] = [];
    if (!loading && !error && data?.getUsers?.length) {
      usersToFilter = await getUserSearchArray(user, data?.getUsers as User[]);
    }
    usersToFilter = [
      ...usersToFilter,
      {
        name: user?.name,
        username: user?.username,
        address: user?.address,
        imageHash: social?.imageHash,
      },
    ];
    setUsersSearch(usersToFilter as IUserSearch[]);
  };

  const searchUserAndCreateIfNull = async (search: string) => {
    if (search.toLowerCase().startsWith("0x") && search.length === 42) {
      // Create new User in the database and add to beneficiaries
      createUserMutation({
        variables: {
          address: search,
        },
      });
    }
    return "";
  };

  const searchResultHandler = (_user: IUserSearch) => {
    if (!beneficiaries?.length) setBeneficiaries([_user]);
    else {
      let newBenefactors: IUserSearch[] = [...beneficiaries];
      newBenefactors.push(_user);
      newBenefactors = newBenefactors.filter(
        (elem, index, self) => index === self.indexOf(elem)
      );
      setBeneficiaries(newBenefactors.filter(Boolean));
    }
  };

  const setUserAsDefault = async () => {
    let metadata = {
      name: user?.name,
      username: user?.username,
      address: user?.address,
      imageHash: social?.imageHash,
    };
    setBeneficiaries([metadata]);
  };

  const makeNftObject = () => {
    // if (token?.tokenAddress === "delfyNFT") {
    //   //  get minter info and append to token
    // } else {
    const _token = {
      ...token,
      tokenImageUri: formatIpfsUrl(token?.image),
    };
    setTokenData(_token);
  };

  const approve = async () => {
    const _web3 = await web3();
    presentTxnWaiting();
    use_approval(toChecksum(token.tokenAddress), toChecksum(account), _web3)
      .on("transactionHash", (tx: any) => {
        setTxnSubmittedMsg(`${tx}`);
        presentSubmitted();
        dismissTxnWaiting();
      })
      .once("receipt", (receipt: any) => {
        const event = receipt?.events?.Transfer?.returnValues;
        dismissSubmitted();
      })
      .on("error", (error: any) => {
        setErrorMsg(error.message);
        presentError();
      });
  };

  const listNFT = async () => {
    setListing(true);
    try {
      if (totalPaytos.current < 100) {
        setShowAlert(true);
        return;
      }
      let [isApproved] = await use_isApproved(
        toChecksum(token.tokenAddress),
        toChecksum(account)
      );

      if (!isApproved[0]) {
        setDoApproval(true);
        setShowAlert(true);
        return;
      }
      if (basePrice.current > 0) {
        const _web3 = await web3();
        use_createAuction(
          payTos,
          token?.tokenAddress,
          toBN(token?.tokenId),
          toBN(basePrice.current),
          paymentMtd.token_address,
          _web3,
          account,
          getChainFromChainId(chainId)
        )
          .on("transactionHash", (tx: any) => {
            setTxnSubmittedMsg(`${tx}`);

            presentSubmitted();
            dismissTxnWaiting();
            setListing(false);
          })
          .once("receipt", (receipt: any) => {
            const event = receipt?.events.AuctionCreated;
            let values = event.returnValues;
            setListing(false);
            createAuctionMutation({
              variables: {
                auctionInput: {
                  chain: getChainFromChainId(chainId),
                  ownerAddress: account.toLowerCase(),
                  trxHash: event.transactionHash,
                  tokenAddress: values.token.toLowerCase(),
                  tokenId: values.tokenId,
                  basePrice: values._basePrice,
                  paymentMethod: values.paymentMethod.toLowerCase(),
                  tokenUri: formatIpfsUrl(token.tokenUri),
                  auctionId: values.id,
                }, // value for 'auctionInput'
              },

              update: (cache, { data: { createAuction } }) => {
                try {
                  setListing(false);
                } catch (error) {
                  setListing(false);
                }
              },
            });
            hs.push("/me");
            dismissSubmitted();
          })
          .on("error", (error: any) => {
            setErrorMsg(error.message);
            presentError();
            setListing(false);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [, setState] = useState(null);

  useEffect(() => {
    userSearchArray();
    makeNftObject();
    setPaymentMtds(getPaymentMethods(chainId ?? 97));
    setPayTos(() => [{ to: account, percent: toBN(100) }]);
    return () => {
      setState({});
    };
  }, [user, loading, account, chainId]);

  const handleCheck = (bool: boolean) => {
    setSplit(bool);

    if (bool) {
      setPayTos(() => []);
      if (payTos.length === 0) {
        totalPaytos.current = 0;
      }
      setUserAsDefault();
    } else {
      setPayTos(() => [{ to: account, percent: toBN(100) }]);
      totalPaytos.current = 100;
    }
    setIsDisabled(
      payTos.length > 0 && totalPaytos.current === 100 && basePrice.current > 0
    );
  };

  const handleAdd = (addr: string, nbr: number) => {
    const numbers_ = [...payTos].map((payto) => payto.percent.toNumber());
    const total = numbers_?.length
      ? numbers_.reduce((initial: number, nbr) => nbr + initial)
      : 0;
    if (total + nbr > 100) {
      setShowAlert(true);

      return false;
    } else {
      setPayTos(() => [...payTos, { to: addr, percent: toBN(nbr) }]);
      totalPaytos.current = total + nbr;
      setIsDisabled(
        payTos.length > 0 &&
          totalPaytos.current === 100 &&
          basePrice.current > 0
      );

      return true;
    }
  };

  const handleRemove = (addr: string) => {
    setPayTos(() =>
      payTos.filter((payto) => payto.to.toLowerCase() !== addr.toLowerCase())
    );
    const numbers_ = [...payTos].map((payto) => payto.percent.toNumber());
    totalPaytos.current = numbers_?.length
      ? numbers_.reduce((initial: number, nbr) => nbr + initial)
      : 0;
    setIsDisabled(
      payTos.length > 0 && totalPaytos.current === 100 && basePrice.current > 0
    );
    const benfs = [...beneficiaries].filter(
      ({ address }) => address.toLowerCase() !== addr.toLowerCase()
    );
    setBeneficiaries(benfs);
  };

  const action = (item?: any) => {
    setPaymentMtd(item);
    setShowPopover({ showPopover: false, event: null });
  };

  const handleChange = (e: any) => {
    basePrice.current = +e.detail.value ?? 0;
    setIsDisabled(
      payTos.length > 0 && totalPaytos.current === 100 && basePrice.current > 0
    );
  };

  return (
    <IonContent>
      {listing && <GlobalLoading type="Listing..." />}
      <Container>
        <div className="pool-p10">
          <InnerContainer>
            <TokenContainer>
              <NftDetail tokenData={tokenData} />
            </TokenContainer>
            <FormContainer>
              <Text size="32" sizeM="24" weight="500">
                Complete the form below
              </Text>
              <br />
              <IonLabel position="floating">Payment Method</IonLabel>
              <Spacer />
              <FormControl>
                <Flex
                  align="center"
                  style={{
                    flex: 1,
                    borderRight: "1px solid gray",
                    padding: " 0px 20px",
                  }}
                  justify="space-between"
                >
                  <div
                    onClick={(e: any) => {
                      setShowPopover({ showPopover: true, event: e });
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    Choose Payment Method
                    <Spacer width={20} />
                    <Caret />
                  </div>
                </Flex>
              </FormControl>

              <br />
              <IonLabel position="floating">Reserve Price</IonLabel>
              <Spacer />
              <FormControl>
                <Flex align="center">
                  <Flex
                    align="center"
                    style={{
                      flex: 1,
                      borderRight: "1px solid gray",
                      padding: " 0px 20px",
                    }}
                    justify="space-between"
                  >
                    <IonInput
                      min="0"
                      onIonChange={(e) => handleChange(e)}
                      step="any"
                      type="number"
                    />
                  </Flex>
                  <Flex align="center">
                    <IonAvatar
                      style={{
                        maxWidth: 20,
                        maxHeight: 20,
                        margin: "0px 10px",
                      }}
                    >
                      <img src={paymentMtd.icon} alt={paymentMtd.value} />
                    </IonAvatar>
                    <Text>
                      <b>{paymentMtd.value}</b>
                    </Text>
                    {/* <Caret /> */}
                    <Spacer width={10} />
                  </Flex>
                </Flex>
                <Popover
                  list={{ items: paymentMtds, action }}
                  hasIcon={true}
                  {...{ popoverState, setPopover: setShowPopover }}
                />
              </FormControl>

              <br />

              <Flex>
                <IonCheckbox
                  slot="end"
                  color="primary"
                  onIonChange={(e) => handleCheck(e.detail.checked)}
                  checked={split}
                />
                <Spacer width={10} />
                <Text size="16" weight="500">
                  Split Payments
                </Text>
              </Flex>

              {split && (
                <>
                  <br />
                  <IonLabel position="floating">
                    <h4>
                      <b>Add Collaborators (Total must be 100%)</b>
                    </h4>
                  </IonLabel>
                  <Spacer />
                  <IonText>
                    Add collaborators to receive payment when this auction is
                    completed; you can add upto 5 collaborators and as low as
                    0.1%.
                  </IonText>
                  <br />

                  <Searchbar
                    {...{
                      placeholder: "Search Beneficiary",
                      includesImg: true,
                      searchField: "name",
                      searchField1: "username",
                      searchField2: "address",
                      rawData: usersSearch,
                      imgKey: "imageHash",
                      searchResult: searchResultHandler,
                      secondarySearch: searchUserAndCreateIfNull,
                    }}
                  />

                  {beneficiaries?.length ? (
                    beneficiaries.map((benficiary, k) => (
                      <UserSearchList
                        addFunc={handleAdd}
                        removeFunc={handleRemove}
                        key={k}
                        user={benficiary}
                        placeholder="50%"
                      />
                    ))
                  ) : (
                    <div></div>
                  )}
                </>
              )}
              <ButtonPrimary
                disabled={!isDisabled}
                onClick={() => listNFT()}
                style={{ marginTop: "20px" }}
                expand="block"
                color="primary"
                shape="round"
                fill="solid"
              >
                <strong>List Token</strong>
              </ButtonPrimary>
            </FormContainer>
            <Alert
              {...{
                isOpen: showAlert,
                dismissAlert: setShowAlert,
                headerMsg: doApproval
                  ? `Approve Token`
                  : `Inappropriate Distribution`,
                msg: doApproval
                  ? `Approve Coterie market place to list your token`
                  : `Total MUST equal 100%`,
                cancelText: doApproval ? "Back" : "Cancel",
                action: doApproval ? approve : setShowAlert,
                actionInput: doApproval ? null : false,
                okText: doApproval ? "Yes" : "OK",
              }}
            />
          </InnerContainer>
        </div>
        <div style={{ marginTop: "70px" }}>
          <Footer />
        </div>
      </Container>
    </IonContent>
  );
};

export default ListNfts;
