import { getNFTMktplaceAddress } from "utils/addressHelper";
import { Contract } from "web3-eth-contract";

//  royalty 100% as 10,000 &&
//  1% as 100, 0.1% as 10, &&  0.01% as 1 which is the minimum

// @param addressTo default as user address
//  @params metadatahash token uri
// @params royaltyFees
export const mintWithRoyalty = (
  coterieNFTContract: Contract,
  tokenUri: string,
  royaltyAddrs: string[],
  royaltyShares: string[],
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .mintWithRoyalty(tokenUri, royaltyAddrs, royaltyShares)
    .send({ from: account, gasPrice: "20000000000" });
};

export const mint = (
  coterieNFTContract: Contract,
  tokenUri: string,
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .mint(tokenUri)
    .send({ from: account, gasPrice: "20000000000" });
};

export const burn = (
  coterieNFTContract: Contract,
  tokenId: string,
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .burn(tokenId)
    .send({ from: account, gasPrice: "20000000000" });
};

export const registerNewMinter = (
  coterieNFTContract: Contract,
  newMinterAddress: string,
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .registerNewMinter(newMinterAddress)
    .send({ from: account, gasPrice: "20000000000" });
};

export const updateMinterAddress = (
  coterieNFTContract: Contract,
  minterAddress: string,
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .updateMinterAddress(minterAddress)
    .send({ from: account, gasPrice: "20000000000" });
};

export const updateRoyalty = (
  coterieNFTContract: Contract,
  tokenId: string,
  royaltyAddrs: string[],
  royaltyShares: string[],
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .updateRoyalty(tokenId, royaltyAddrs, royaltyShares)
    .send({ from: account, gasPrice: "20000000000" });
};

export const updateTokenURI = (
  coterieNFTContract: Contract,
  tokenId: string,
  tokenUri: string,
  account: string
) => {
  coterieNFTContract.handleRevert = true;
  return coterieNFTContract.methods
    .updateTokenURI(tokenId, tokenUri)
    .send({ from: account, gasPrice: "20000000000" });
};

export const approve = (erc721Contract: Contract, account: string) => {
  erc721Contract.handleRevert = true;
  return erc721Contract.methods
    .setApprovalForAll(getNFTMktplaceAddress(), true)
    .send({ from: account, gasPrice: "20000000000" });
};
