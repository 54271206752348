import React,{useState} from 'react'
import { IonModal, IonButton,IonItem } from '@ionic/react';
import styled from 'styled-components'
import { InputField } from "..";
import {getNSyb} from './../../utils/addressHelper'
import { useNetwork } from '../../store/hooks'
import  {useMinMax}  from "./../../hooks/useMinMax";


interface BuyTokenInt {
    show:boolean;
    setShow:(val:boolean)=>void;
    close:()=>void;
    handleBuy :(val:string[])=>void;
    auctionAddress:string;
}

const Header=styled.div`
   text-align:center;
   padding:10px;
   background:#2a2290;
   color:white;

`


const BuyButton=styled.div`
  float:right;
  display:inline;
  text-align:right;
  padding:0px 10px;
`

const BuyTokenModal: React.FC<BuyTokenInt> = ({show,setShow,handleBuy,auctionAddress,close}) => {
    const [value,setValue]=useState<number>(0)
    const network = useNetwork()
    const networkSymbol = getNSyb(network);
    const {min,max,hardCap }=useMinMax(auctionAddress)

    
   const handleChange=(e:any)=>{
     setValue(e.target.value)
    }

  
    return (
          <IonModal isOpen={show} onDidDismiss={close} cssClass='buy-modal' >
            <Header><h2>Buy token with ETH</h2></Header>
            <div>
              <div className='buyWithEth'>
                    <h4> Min Buy:{min} {networkSymbol}</h4>
                    <h4>Max Buy: {max > 0 ? max : hardCap} {networkSymbol}</h4>
              </div> 
            <IonItem className="box">
               <InputField step="any" required={true} label="Amount To purchase" name="min" type="number" value={value} placeholder="Min" onIonChange={handleChange} />
            </IonItem>
            <BuyButton ><IonButton  color="primary" shape="round" fill="solid">Buy</IonButton></BuyButton>
            </div>
          </IonModal>
        );
}

export default BuyTokenModal