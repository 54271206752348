import React, { useState } from "react";
import { IonAvatar, IonCard, IonButton, IonIcon } from "@ionic/react";
import styled from "styled-components";
import {
  addOutline,
  removeOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";
import { PoolObject } from "../../types/farm-types";
import { useTotalLpTokenValue } from "../../store/hooks";

const PoolCard = styled(IonCard)`
  height: 100%;
  width: 320px;
  // padding: 20px;
  border-radius: 25px;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px 0px;
`;
const Badge = styled.div`
  border-radius: 30.2px;
  line-height: 21px;
  background: #6470cf;
  letter-spacing: -0.302571px;
  padding: 5px 15px;
  color: #ffffff;
  height: 30px;
  margin: auto 0;
  font-weight: bold;
  font-size: 18px;
`;

const NameContainer = styled.div`
  text-align: left;
  padding: 0 20px;
  margin: 0;
  color: #000;
  font-weight: 700;
`;

const H6 = styled.h6`
  font-size: 16px;
  font-weight: 550;
  color: #3f389b;
`;

const Key = styled.p`
  color: #000;
`;

const Value = styled.p`
  color: #6470cf;
  font-weight: 700;
  font-size: 18px;
`;

const AprContainer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

const EarnedContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const EarnedH6 = styled.h6`
  font-size: 20px;
  font-weight: 550;
  color: #6470cf;
  margin: 5px 0 0;
  @media (max-width: 600px) {
    font-size: 12px !important;
  }
`;

const EarnedButton = styled(IonButton)`
  background-color: #6470cf;
  border-radius: 7px;
`;
const PText = styled.p`
  display: block;
  margin: 0;
  color: #000;
  font-size: 12px;
  font-weight: 750;
`;

const SmallText = styled.small`
  margin: 0;
  color: #000;
  font-size: 11px;
  // font-weight: 550;
`;

const StakeUnstakeContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

const StakeUnstakeButtons = styled.div``;
const ClaimButton = styled(IonButton)`
  --border-radius: 18px !important;
  height: 45px;
  border-radius: 10px;
  margin: 3px;
`;

const LargeButton = styled(IonButton)`
  --border-radius: 12px !important;
  margin: 5px 20px 0;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ccdddd;
  width: 100%;
  margin: 20px 0 0;
`;
const DetailsContainer = styled.div``;
const DetailOpenerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 16px;
  padding: 0 20px 20px;
`;

const LinkButton = styled.a`
  text-decoration: none;
  color: #6470cf;
  padding: 8px;
`;

const VLContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
`;

interface CardInterface {
  farm: PoolObject;
  unlock: () => void;
  locked: boolean;
  openWithdrawModal: (poolObj: PoolObject) => void;
  openDepositModal: (poolObj: PoolObject) => void;
  claim: (pool: PoolObject) => void;
}

const GridFarmCard: React.FC<CardInterface> = ({
  farm,
  unlock,
  locked,
  openDepositModal,
  openWithdrawModal,
  claim,
}) => {
  const valueLocked = useTotalLpTokenValue(farm.poolSymbol);

  const [open, setOpen] = useState<boolean>(false);
  return (
    <PoolCard>
      <HeaderContainer>
        <IonAvatar>
          <img src='/assets/tokenLogo.png' alt='tokenlogo' />
        </IonAvatar>
        <Badge>{farm.allocPoint}X</Badge>
      </HeaderContainer>
      <NameContainer>
        <h6>{farm.poolSymbol}</h6>
      </NameContainer>
      <AprContainer>
        <Key>APR</Key>
        <Value>
          {farm.apr}% <IonIcon src='/svgs/calculator.svg' />
        </Value>
      </AprContainer>
      <EarnedContainer>
        <span>
          <PText>DELFY EARNED</PText>
          {!locked ? (
            <EarnedH6>{farm.earned}</EarnedH6>
          ) : (
            <EarnedH6>{"0"}</EarnedH6>
          )}

          <SmallText>~1000 USD</SmallText>
        </span>
        <EarnedButton
          disabled={locked}
          onClick={() => claim(farm)}
          fill='clear'>
          Harvest
        </EarnedButton>
      </EarnedContainer>
      {!locked ? (
        <StakeUnstakeContainer>
          <span>
            <PText>DELFY STAKED</PText>
            {!locked ? (
              <EarnedH6>{farm.userDeposit}</EarnedH6>
            ) : (
              <EarnedH6>{"0.000"}</EarnedH6>
            )}

            <SmallText>~1000 USD</SmallText>
          </span>
          <StakeUnstakeButtons>
            <ClaimButton onClick={() => openWithdrawModal(farm)} fill='outline'>
              <IonIcon icon={removeOutline} />
            </ClaimButton>
            <ClaimButton onClick={() => openDepositModal(farm)} fill='outline'>
              <IonIcon icon={addOutline} />
            </ClaimButton>
          </StakeUnstakeButtons>
        </StakeUnstakeContainer>
      ) : (
        <LargeButton onClick={() => unlock()} expand='block'>
          Unlock Wallet
        </LargeButton>
      )}
      <Divider />
      <DetailsContainer>
        <DetailOpenerContainer>
          <IonIcon size='large' src='/svgs/verified.svg' />
          <div>
            <IonButton
              fill='clear'
              onClick={() => {
                open ? setOpen(false) : setOpen(true);
              }}>
              {!open ? (
                <>
                  Details <IonIcon icon={chevronDownOutline} />
                </>
              ) : (
                <>
                  Hide <IonIcon icon={chevronUpOutline} />
                </>
              )}
            </IonButton>
          </div>
        </DetailOpenerContainer>
        {open ? (
          <>
            <VLContainer>
              <H6>Total Staked:</H6>
              <H6>{`$${valueLocked.toNumber().toFixed(2)}`}</H6>
            </VLContainer>
            <Details>
              <LinkButton href='#'>See Token Info</LinkButton>
              <LinkButton href='#'>View Project Site</LinkButton>
              <LinkButton href='#'>View Contract</LinkButton>
              <LinkButton href='#'>Add To Metamask</LinkButton>
            </Details>
          </>
        ) : null}
      </DetailsContainer>
    </PoolCard>
  );
};

export default GridFarmCard;
