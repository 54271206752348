import Api from "api";
import axios from "axios";
import { create } from "ipfs-http-client";

const client = create({
  url: new URL("https://ipfs.infura.io:5001/api/v0/add?params"),
});

export const formatIpfsUrl = (tokenUri: string) => {
  if (tokenUri) {
    if (
      tokenUri?.toString()?.startsWith("https") ||
      tokenUri?.toString()?.startsWith("/")
    )
      return tokenUri;
    else {
      const lastStr = searchAndDelete(
        searchAndDelete(searchAndDelete(tokenUri, "ipfs://"), "ipfs.io"),
        "/ipfs/"
      );
      if (lastStr?.startsWith("bafy"))
        return `https://${lastStr.split("/")[0]}.ipfs.dweb.link/${lastStr
          .split("/")
          .pop()}`;

      return `https://ipfs.io/ipfs/${lastStr}`;
    }
  }
};

export const uploadFile = (fileU: any): Promise<any> => {
  let data = new FormData();
  data.append("file", fileU);
  console.log(data, ".... services", fileU);
  return Api.nft.create(data);
};

const searchAndDelete = (word: string, searchTerm: string) => {
  if (word?.length && searchTerm?.length) {
    var str = word;
    var n = str?.search(searchTerm);
    while (str?.search(searchTerm) > -1) {
      n = str?.search(searchTerm);
      str =
        str?.substring(0, n) +
        str?.substring(n + searchTerm?.length, str?.length);
    }
    return str;
  }
};

export const getMime = async (hashUrl: string) => {
  try {
    let m = await fetch(hashUrl, {
      method: "GET",
    });
    return m.blob();
  } catch (error) {
    console.log(error);
  }
};

export const ipfsPinHelper = (
  file: any,
  name: string,
  func: (val: any) => void
) => {
  if (file && name && func) {
    console.log("Name: ", name);
    const fileDetail = {
      path: `/${name}`,
      content: file,
      progress: (prog: any) => func(prog),
    };
    return client.add(fileDetail, {
      wrapWithDirectory: true,
      cidVersion: 1,
      pin: true,
    });
  }
};

export const getMimeType = async (url: string) => {
  if (url) {
    const res = await axios.head(formatIpfsUrl(url));
    const mimeType = res.headers["content-type"];
    const isVideo = mimeType?.includes("video");
    const isImage = mimeType?.includes("image");
    const isModel = mimeType?.includes("model");
    console.log("mimeType", mimeType);
    if (isVideo) return "video";
    if (isImage) return "image";
    if (isModel) return "model";
    else return "unknown";
  }
};

export const formatExplorerUrl = (address: string) => {
  return `https://test.bscscan.com/address/${address}`;
};
