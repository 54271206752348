/**
 * Truncates a wallet's address
 */
export const truncateWalletAddress = (
  address: string,
  startLength = 4,
  endLength = 4
) => {
  return (
    address &&
    `${address?.substring(0, startLength)}...${address?.substring(
      address?.length - endLength
    )}`
  );
};
