/* eslint-disable require-yield */
import { takeEvery,put } from 'redux-saga/effects'
import { setToggle as setToggleAction,closeBar as closeBarAction } from "../features/user/UiSlice";


function* setToggle() { 
  yield put(setToggleAction())
}

function* closeBar() { 
    yield put(closeBarAction())
}

// use them in parallel
export default function* iuSaga() {
  yield takeEvery('TOGGLE_SIDEBAR',setToggle)
  yield takeEvery('CLOSE_SIDEBAR',closeBar)
}