import React, { FC } from 'react';
import { IonText, IonIcon,IonLabel,IonSelect } from '@ionic/react';
import styled from "styled-components";
import { timeOutline } from 'ionicons/icons';
// import Moment from "react-moment";


interface Props {
  onIonChange: any;
  label: string;
  require:boolean;
  interface:any;

}


export const Select = styled(IonSelect)`
  width:100%;
  background:green;
`


export const InputContainer = styled.div`
  width:100% !important;
  background:red;  
  /* border:2px solid #222 */
`
export const Info = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 10px;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 50%;
  color: #555;
  cursor: pointer !important;
  position: relative;
  z-index: 99 !important;
  pointer-events: all;
  margin: 0px 5px;


  &:hover{
    background:#f8f8f8;

    & > InfoMsg{
      display:block;
    }
  }

`

export const InfoMsg = styled.div`
  display:none;
  padding:10px;
  position:absolute;
  background:red;
  top:-20px
`




const SelectField: FC<Props> = ({label,children,...props}) => {


  return (
    <InputContainer >
        <IonLabel position="floating">{label}<Info>?</Info></IonLabel>
        <Select {...props}>
          {children}
        </Select>
    </InputContainer>
  )
}


export default SelectField