import React,{useState} from 'react'
import { IonModal, IonButton,IonItem,IonLabel,IonSelect,IonSelectOption, IonTextarea } from '@ionic/react';
import styled from 'styled-components'
import { InputField } from "..";
import {getNSyb} from '../../utils/addressHelper'
import { useNetwork } from '../../store/hooks'
import  {useMinMax}  from "../../hooks/useMinMax";


interface BuyTokenInt {
    show:boolean;
    setShow:(val:boolean)=>void;
    close:()=>void;
    handleBuy :(val:string[])=>void;
    auctionAddress:string;
}

const Header=styled.div`
   text-align:center;
   padding:10px;
   background:#2a2290;
   color:white;

`


const BuyButton=styled.div`
  float:right;
  display:inline;
  text-align:right;
  padding:0px 10px;
`

const BuyTokenModal: React.FC<BuyTokenInt> = ({show,setShow,handleBuy,auctionAddress,close}) => {
    const [value,setValue]=useState<string | null>(null)
    const network = useNetwork()
    const networkSymbol = getNSyb(network);
    const {min,max,hardCap }=useMinMax(auctionAddress)

    
   const handleChange=(e:any)=>{
     setValue(e.target.value)
    }

  
    return (
          <IonModal isOpen={show} onDidDismiss={close} cssClass='buy-modal' >
            <Header><h2>Create case</h2></Header>
            <div>
            <IonItem className="box">
               <IonLabel position="floating">Issues</IonLabel>
               <IonTextarea  name="issues"  value={value} placeholder='Protect your investment here...' onIonChange={handleChange} />
            </IonItem>
            <IonItem className="box">
                <IonLabel position="floating">Select a case type</IonLabel>
                <IonSelect placeholder='Days' interface='action-sheet' name='salesPeriodDay' value={'h'} onIonChange={(e: any) => handleChange(e)} >
                 
                  <IonSelectOption value={0}>Release Liquidity to Exchange</IonSelectOption>
                  <IonSelectOption value={1}>Seize Liquidity for Refund</IonSelectOption>
                </IonSelect>
              </IonItem>
            <BuyButton ><IonButton  color="primary" shape="round" fill="solid">Publish</IonButton></BuyButton>
            </div>
          </IonModal>
        );
}

export default BuyTokenModal