import { getAllLocker } from "../utils/callHelper";
import { toEth } from "./../utils/contractHelper";

export const getLocker = async (auctionAddress: string, address: string) => {
  const { lpAmount, ...rest } = await getAllLocker(address, auctionAddress);

  return {
    lpAmount: toEth(lpAmount.toNumber()),
    ...rest,
  };
};
