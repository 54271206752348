import { Flex, Text } from "components";
import { Empty } from "components/SvgIcons";
import React from "react";
import styled from "styled-components";

export const Container = styled.div``;

const IonTextCustom = styled.div`
  width: 95%;
  margin: auto;
  @media (max-width: 600px) {
    font-size: 14px;
    width: 100%;
    margin-right: 20px;
    &:nth-of-type(2),
    &:nth-of-type(5) {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
`;

const Header = styled.div`
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.08);
  padding: 20px 10px;
`;

const Row = styled(Flex)`
  background: rgba(106, 100, 255, 0.02);
  padding: 20px 10px;
`;
const Body = styled.div`
  background: #fff;
  padding: 10px 0px;
`;
const EmptyCon = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
interface FieldInterface {
  label: string;
  key: string;
}
interface DataTableInterface {
  fields: FieldInterface[];
  data: any;
  hasUrl?: boolean;
  urlField?: string;
  url?: string;
}

const DataTable: React.FC<DataTableInterface> = ({
  fields = [],
  data = [],
  hasUrl,
  url,
  urlField,
}) => {
  return (
    <Container>
      <Header>
        <Flex>
          {fields.map((field, i) => (
            <Text
              style={{ flex: 1 }}
              // style={{ padding: "0px 20px" }}
              size="14"
              weight="500"
              key={"header-" + i + 1}
            >
              {field.label}
            </Text>
          ))}
        </Flex>
      </Header>
      <Body>
        <Flex direction="column">
          {data?.length < 1 ? (
            <EmptyCon>
              <Empty />
            </EmptyCon>
          ) : (
            data?.map((value: any, index: number) => (
              <Row
                key={index}
                style={{
                  width: "100%",
                  backgroundColor:
                    index % 2 === 0
                      ? "rgba(106, 100, 255, 0.05)"
                      : "rgba(106, 100, 255, 0.01)",
                }}
              >
                {fields.map((field, index) => (
                  <div style={{ flex: 1 }} key={index}>
                    <Text size="14" key={"header-" + index + 1}>
                      {value[field.key]}
                    </Text>
                  </div>
                ))}
              </Row>
            ))
          )}
        </Flex>
      </Body>
    </Container>
  );
};

export default DataTable;
