export const truncate = (words: string, length: number) => {
  let _wd = words?.length && words.trim();
  let wd = "";
  if (_wd?.length > length) {
    wd = `${_wd.slice(0, length)}...`;
  } else {
    wd = _wd;
  }
  return wd;
};

export const stringToCapitalized = (_string: string) => {
  if(_string && _string?.length)
  return _string.replace(/\b\w/g, (l) => l.toUpperCase());
};
