import { createSlice } from "@reduxjs/toolkit";


export interface ContractAuction {
  owner: string;
  token: string;
  tokenId: string;
  basePrice: string;
  paymentMethod: string;
  buyer: string;
  createdAt: string;
  tokenUri: string;
  auctionId: string;
  status: "open" | "sold" | "cancelled";
  closedAt?: string;
  bids: ContractBid[]
  networkId: number;

}




interface ContractBid {
  currentbid: string;
  bidder: string;
  createdAt: string;
}
export interface ContractAuctionType {
  fullAuctions: ContractAuction[];
  fullAuctionLoading: boolean;
}

const initialState = {
  fullAuctions: [],
  fullAuctionLoading: false,
} as ContractAuctionType;

const contractAuctionSlice = createSlice({
  name: "contract_auctions",
  initialState,
  reducers: {
    setAuctions: (state, { payload }) => {
      state.fullAuctions = payload;
    },
    setAuctionLoading: (state, { payload }) =>
      void (state.fullAuctionLoading = payload),
  },
});

export const { setAuctions, setAuctionLoading } = contractAuctionSlice.actions;

export default contractAuctionSlice.reducer;
