import React from "react";
import styled from "styled-components";
import { IonText } from "@ionic/react";


const InputContainer = styled.div`
    max-width: 386px;
    height: 87px;
    background: #2A3390;
    border-radius:20px;
    margin:10px auto;

    @media(max-width:600px){
        margin:15px 20px;
    }

`;


const Items = styled.div`
    display:flex;
    flex-direction:row;
    padding:10px;
    align-items:center;
    justify-content:space-between;

    & span{
        color: #CACCE3;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;

    }

`;
const ItemsIn = styled.div`
    display:flex;
    flex-direction:row;
    padding:10px;
    align-items:center;
    justify-content:space-between;

    position: relative;
    top: -18px;
`

const Logo = styled.div`
  display:flex;
  flex-direction:row;
  padding:10px;
  align-items:center;
  justify-content:space-between;
`
const Select = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        color:#CACCE3;
        font-weight: bold;
        font-size: 18px;
        line-height:29px;

    }
`
const Input = styled.input`
      font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    outline: none;
    color: #fff;
    background: #2a3390;
    border: none;
    max-width: 120px;

   

`

interface InputInterface {


}

const InputFrom: React.FC<InputInterface> = () => {

    return (
        <InputContainer>
            <Items>
                <span>from</span>
                <span>0.0002</span>
            </Items>

            <ItemsIn>
                <Input placeholder="0.0" step='any' type="number"></Input>
                <Select>
                    <Logo>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2726 6.54543C12.2726 7.41341 11.9278 8.24585 11.3141 8.8596C10.7003 9.47336 9.86788 9.81816 8.9999 9.81816C8.13192 9.81816 7.29949 9.47336 6.68573 8.8596C6.07198 8.24585 5.72717 7.41341 5.72717 6.54543C5.72717 5.67745 6.07198 4.84502 6.68573 4.23126C7.29949 3.61751 8.13192 3.27271 8.9999 3.27271C9.86788 3.27271 10.7003 3.61751 11.3141 4.23126C11.9278 4.84502 12.2726 5.67745 12.2726 6.54543ZM10.6363 6.54543C10.6363 6.97942 10.4639 7.39564 10.157 7.70252C9.85011 8.00939 9.43389 8.1818 8.9999 8.1818C8.56591 8.1818 8.14969 8.00939 7.84282 7.70252C7.53594 7.39564 7.36354 6.97942 7.36354 6.54543C7.36354 6.11144 7.53594 5.69523 7.84282 5.38835C8.14969 5.08147 8.56591 4.90907 8.9999 4.90907C9.43389 4.90907 9.85011 5.08147 10.157 5.38835C10.4639 5.69523 10.6363 6.11144 10.6363 6.54543Z" fill="#CACCE3" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C4.02955 0 0 4.02955 0 9C0 13.9705 4.02955 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02955 13.9705 0 9 0ZM1.63636 9C1.63636 10.71 2.21973 12.2842 3.19745 13.5344C3.8841 12.6326 4.76992 11.9019 5.78572 11.3992C6.80152 10.8964 7.91979 10.6354 9.05318 10.6364C10.1719 10.6353 11.2761 10.8896 12.2817 11.3799C13.2872 11.8702 14.1676 12.5836 14.8557 13.4656C15.5646 12.5359 16.042 11.4506 16.2482 10.2998C16.4544 9.14888 16.3836 7.96544 16.0417 6.84735C15.6997 5.72926 15.0965 4.70868 14.2818 3.87003C13.4671 3.03139 12.4644 2.39879 11.3567 2.0246C10.249 1.6504 9.06807 1.54536 7.9117 1.71816C6.75534 1.89096 5.65674 2.33664 4.70681 3.01832C3.75688 3.69999 2.98294 4.59808 2.44901 5.63825C1.91508 6.67843 1.63651 7.83079 1.63636 9ZM9 16.3636C7.3096 16.3662 5.67022 15.7846 4.35927 14.7175C4.88694 13.9621 5.58928 13.3453 6.40652 12.9197C7.22377 12.494 8.13174 12.2721 9.05318 12.2727C9.96313 12.272 10.8601 12.4884 11.6697 12.9039C12.4792 13.3194 13.1779 13.9221 13.7078 14.6618C12.3867 15.7637 10.7203 16.366 9 16.3636Z" fill="#CACCE3" />
                        </svg>

                        <IonText><span>BNB</span> </IonText>
                    </Logo>
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.6 0L5.6 4L9.6 0L11.2 0.8L5.6 6.4L0 0.8L1.6 0Z" fill="white" />
                    </svg>


                </Select>
            </ItemsIn>
        </InputContainer>
    )
}

export default InputFrom