import { IonAvatar, IonButton } from "@ionic/react";
import { Flex, Spacer } from "components";
import { ButtonSecondary, ButtonWhite } from "components/Button";
import { RightAngle } from "components/SvgIcons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { web3 as connect } from "../../hooks/useWeb3Provider";
import { authMenu, menu } from "./Menu";

const CreateButn = styled(IonButton)`
  color: #6a64ff;
  --border-radius: 18px !important;
`;

const MobileMenuContainer = styled.div`
  position: fixed;
  background: #fff;
  top: 86px;
  left: 0px;
  height: 0vh;
  width: 100%;
  z-index: 998;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.open {
    opacity: 1;
    height: calc(90vh - 86px);
  }
`;

const Menu = styled.div`
  flex: 1;
`;

const MenuFooter = styled.div`
  padding: 5px 30px;
`;

const MenuList = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ type }) => (type === "auth" ? "0px 10px" : "5px 30px")};

  h4,
  div {
    color: #333333;
    margin-left: 10px;
    line-height: 27.2px;
    font-weight: 500;
    font-size: 16px;
  }
`;
const AuthMenu = styled.div<{ height?: number }>`
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  width: 80%;
  margin: auto;
  transition: 0.3s ease-in-out;
  height: ${({ height }) => height && height + "px"};
  overflow: hidden;
`;

interface MobileMenuProps {
  open: boolean;
  account: string;
  imageHash: string;
  name: string;
  formatIpfsUrl: (arg: string) => string;
}

export const MobileMenu = ({
  open,
  account,
  name,
  imageHash,
  formatIpfsUrl,
}: MobileMenuProps) => {
  const [locked, setLocked] = useState<boolean>(true);
  const [drop, setDrop] = useState<boolean>(false);

  const unlockWallet = async () => {
    await connect();
    if (account.length) {
      setLocked(false);
    }
  };

  useEffect(() => {
    if (!account) setLocked(true);
    if (account) {
      setLocked(false);
    }
  }, [account]);

  // const toggleTheme = () => {
  //   let currentTheme = localStorage.getItem("theme");
  //   document.body.classList.toggle("dark");

  //   let theme = currentTheme === "light" ? "dark" : "light";
  //   localStorage.setItem("theme", theme);
  // };

  return (
    <MobileMenuContainer className={open ? "open" : ""}>
      <Menu>
        {menu.map((item, i) => (
          <Link to={item.href} key={i}>
            <MenuList>
              {item.icon}
              <h4> {item.value}</h4>
            </MenuList>
          </Link>
        ))}

        {!locked && (
          <div onClick={() => setDrop(!drop)}>
            <MenuList
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: -10,
              }}
            >
              <Flex align="center">
                <IonAvatar
                  style={{ width: "35px", height: "35px" }}
                  onClick={(e: any) => null}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={formatIpfsUrl(imageHash)}
                    alt={name}
                  />
                  {/* <IonIcon mode="ios" size="large" icon={personCircle} /> */}
                </IonAvatar>
                <h4>My Account </h4>
              </Flex>
              <Flex>
                <RightAngle />
              </Flex>
            </MenuList>
            <AuthMenu height={drop ? 250 : 0}>
              {authMenu.map((item, i) => (
                <MenuList key={i} type="auth">
                  <Link to={item.href}>
                    <MenuList>
                      <h4> {item.value}</h4>
                    </MenuList>
                  </Link>
                </MenuList>
              ))}
            </AuthMenu>
          </div>
        )}
      </Menu>
      <MenuFooter>
        <Spacer height={10} />
        {locked ? (
          <ButtonSecondary borderRadius="5px" onClick={() => unlockWallet()}>
            Connect Wallet
          </ButtonSecondary>
        ) : (
          <Link to="/nfts/create">
            <ButtonWhite borderRadius="5px" onClick={() => unlockWallet()}>
              Create
            </ButtonWhite>
          </Link>
        )}
      </MenuFooter>
    </MobileMenuContainer>
  );
};
