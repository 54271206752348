import { IonContent } from "@ionic/react";
import { Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import { PageNotFound } from "components/SvgIcons";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  /* background: $fff; */
  margin-top: 100px;
`;
const Body = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
`;

const Content = styled.div`
  transform: translateY(-60px);
  text-align: center;
`;

const NotFound = () => {
  return (
    <IonContent>
      <Container>
        <Body>
          <PageNotFound />
          <Content>
            <Text size="48" weight="600">
              Page not found
            </Text>
            <Spacer />
            <Text size="24" weight="400">
              We could not find the page you looking for.
            </Text>
            <Spacer />
            <Link to="/">
              <ButtonPrimary width="50%" style={{ margin: "auto" }}>
                Go Home
              </ButtonPrimary>
            </Link>
          </Content>
        </Body>
      </Container>
    </IonContent>
  );
};

export default NotFound;
