import { getAllCases } from "../utils/callHelper";

export const getCases = async (address: string) => {
  const { contributorsCount, ...all } = await getAllCases(address);

  return {
    contributorsCount: contributorsCount.toNumber(),
    ...all,
  };
};
