import { ShopOutLine } from "components/SvgIcons";
import { KeyValue } from "../Pool/Popover";

export const menu: KeyValue[] = [
  {
    value: "NFT Shop",
    key: "dashboard",
    href: "/nfts",
    icon: <ShopOutLine />,
  },
  // {
  //   value: "Exchanges",
  //   key: "exchanges",
  //   href: "/exchanges",
  //   icon: <Exchange />,
  // },
  // {
  //   value: "Products",
  //   key: "products",
  //   href: "/products",
  //   icon: <Products />,
  // },
];

export const authMenu: KeyValue[] = [
  {
    value: "Dashboard",
    key: "dashboard",
    href: "/me",
  },

  {
    value: "Tree",
    key: "tree",
    href: "/me?page=tree",
  },
  {
    value: "Followers",
    key: "followers",
    href: "/me?page=followers",
  },
];
