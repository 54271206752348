import React from "react";
import styled from "styled-components";
import {
  IonItem,
  IonToggle,
  IonSegment,
  IonSegmentButton,
  IonButton,
} from "@ionic/react";

const Container = styled.div`
  margin: 20px 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #6470cf;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  & svg {
    margin: 0px 10px;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    width: 80px;
  }
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & .toggle {
    /* width:300px; */
  }

  & span {
    color: #6470cf;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  background: #cacce3;
`;

const IonButtonCus = styled(IonButton)`
  --background: #6470cf;
  margin: 0px 20px;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

const Con = styled.div``;

const Col = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
interface BannerInterface {
  toggleDisplay: (val: boolean) => void;
  grid: boolean;
}

const FilterHeader: React.FC<BannerInterface> = ({ toggleDisplay, grid }) => {
  return (
    <Container>
      <Col>
        <Grid>
          <svg
            onClick={() => toggleDisplay(true)}
            width='19'
            height='20'
            viewBox='0 0 19 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g opacity={grid ? "0.8" : "0.5"}>
              <rect width='5' height='6' fill='#131C63' />
              <rect x='7' width='5' height='6' fill='#131C63' />
              <rect x='14' width='5' height='6' fill='#131C63' />
              <rect y='7' width='5' height='6' fill='#131C63' />
              <rect x='7' y='7' width='5' height='6' fill='#131C63' />
              <rect x='14' y='7' width='5' height='6' fill='#131C63' />
              <rect y='14' width='5' height='6' fill='#131C63' />
              <rect x='7' y='14' width='5' height='6' fill='#131C63' />
              <rect x='14' y='14' width='5' height='6' fill='#131C63' />
            </g>
          </svg>

          <svg
            onClick={() => toggleDisplay(false)}
            width='27'
            height='19'
            viewBox='0 0 27 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g opacity={grid ? "0.5" : "0.8"}>
              <rect x='7' width='20' height='5' fill='#131C63' />
              <rect width='5' height='5' fill='#131C63' />
              <rect x='7' y='7' width='20' height='5' fill='#131C63' />
              <rect y='7' width='5' height='5' fill='#131C63' />
              <rect x='7' y='14' width='20' height='5' fill='#131C63' />
              <rect y='14' width='5' height='5' fill='#131C63' />
            </g>
          </svg>
        </Grid>

        <Toggle>
          <IonItem className='toggle'>
            <IonToggle color='primary' /> <span>Staked Only</span>
          </IonItem>
          <Con>
            <IonSegment value='Live' mode='ios'>
              <IonSegmentButton color='primary' value='Live'>
                Live
              </IonSegmentButton>
              <IonSegmentButton value='favorites'>Ended</IonSegmentButton>
            </IonSegment>
          </Con>
        </Toggle>
      </Col>

      <Col>
        <Filter>
          <IonButtonCus>
            Trending
            <svg
              width='14'
              height='8'
              viewBox='0 0 14 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1 1L7 7L13 1'
                stroke='#6470CF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IonButtonCus>

          <Input placeholder='Search' />
        </Filter>
      </Col>
    </Container>
  );
};

export default FilterHeader;
