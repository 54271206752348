import erc721Abi from "abi/coterie-nft-token.json";
import { getNFTMktplaceAddress } from "utils/addressHelper";
import multicall from "utils/multicall";

export const use_isApproved = (tokenContract: string, ownerAddress: string) => {
  const calls = [
    {
      address: tokenContract,
      name: "isApprovedForAll",
      params: [ownerAddress, getNFTMktplaceAddress()],
    },
  ];
  return multicall(erc721Abi, calls);
};
