import {
  IonButton,
  IonCard,
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonSpinner,
  useIonModal,
  useIonPopover,
  useIonToast
} from "@ionic/react";
import BigNumber from "bignumber.js";
import {
  Avatar,
  Flex,
  Footer,
  GridComponent,
  Input,
  Loading,
  Spacer,
  Text
} from "components";
import LoadingDetails from "components/Auctions/LoadingDetails";
import { ButtonPrimary, ButtonSecondary, ButtonWhite } from "components/Button";
import { NftCard } from "components/Card";
import Alert from "components/Pool/Alert";
import DataTable from "components/Pool/DataTable";
import TransactionDeny from "components/Pool/TransactionDeny";
import TransactionSubmitted from "components/Pool/TransactionSubmitted";
import TransactionWaiting from "components/Pool/TransactionWaiting";
import SocialPop from "components/Popover/SocialPop";
import { Pencel, Share } from "components/SvgIcons";
import tokens from "config/tokens";
import {
  Auction,
  AuctionState,
  GetAuctionDocument,
  GetAuctionQuery,
  GetBidsDocument,
  useCancelAuctionMutation,
  useCloseAuctionMutation,
  useCreateBidMutation,
  useGenerateRefTokenLazyQuery,
  useGetAuctionQuery,
  useGetUserAuctionByAuctionIdQuery,
  useUpdateAuctionLikesMutation,
  useUpdateAuctionShareHashMutation,
  useUpdateAuctionViewCountMutation,
  useUserAddressQueryQuery
} from "generated/graphql-frontend";
import {
  use_cancelAuction,
  use_closeAuction,
  use_makeBid
} from "hooks/nfts/mktplace-hooks";
import { use_approve } from "hooks/pool";
import { web3 as connect, web3 } from "hooks/useWeb3Provider";
// import DataTable from "components/Pool/DataTable";
import { copyOutline, eyeOutline, heart, trash } from "ionicons/icons";
import moment, { Moment } from "moment";
import { FC, useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import {
  useActiveWeb3React,
  useAuctionBids,
  useGetNftHistory,
  useParamsQuery,
  useUSDPrice
} from "store/hooks";
import styled from "styled-components";
import { IHistoryTable, INFTMetadata, ISocial } from "types/nft-user-types";
import { getNFTMktplaceAddress } from "utils/addressHelper";
import { toBN, toDefaultDecimal } from "utils/BigNumber";
import { getAllowance } from "utils/contractHelps/ERC20/erc20Calls";
import { calender } from "utils/conv";
import {
  getLocalStorageTokenString,
  getLocalStorageTokenView,
  makeRef
} from "utils/encrypt";
import {
  formartUSD,
  getBalanceNumber,
  getFullDisplayBalance
} from "utils/formatBalance";
import {
  formatExplorerUrl,
  formatIpfsUrl,
  getMimeType
} from "utils/ipfsUrlHelper";
import {
  filterDuplicatesObj,
  getChainFromChainId,
  getChainFullName,
  getIpfsDetails,
  getNFTsTokenStandard,
  getTokenSymbol,
  getUsdValue,
  getUserSocial,
  makeOffertable,
  toChecksum
} from "utils/nft-user-helper";
import { stringToCapitalized, truncate } from "utils/text-help";
import { truncateWalletAddress } from "utils/truncateWalletAddress";
import web3NoAccount from "utils/web3NoAccount";
import Web3 from "web3";

const Con = styled.div`
  padding: 20px;

  @media (max-width: 640px) {
    padding: 5px;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  background: #fff;
  min-height: 100%;
  margin-top: 100px;
  overflow-y: auto;
`;

const Contain = styled.div`
  width: 100%;
`;
const Details = styled.div`
  width: 50%;
  padding: 20px;

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const ShareCon = styled.div`
  display: block;
  &.mobile {
    display: none;
  }
  @media (max-width: 640px) {
    display: none;
    &.mobile {
      display: block;
    }
  }
`;
const PropertyCard = styled.div`
  background: rgba(106, 100, 255, 0.1);
  border: 1px solid #6a64ff;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 4px rgba(106, 100, 255, 0.08);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  text-align: center;
`;

const Offers = styled.div`
  width: 50%;
  padding: 10px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const Detail = styled.div`
  width: 30%;
  padding: 10px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const Preview = styled.div`
  width: 50%;
  height: 400px;

  & img {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 640px) {
    width: 100%;
    height: auto;
  }
`;

interface RouterProps {
  id: string;
}
interface MatchProps extends RouteComponentProps<RouterProps> {}

const NftDetails: FC<MatchProps> = ({
  match: {
    params: { id },
  },
}) => {
  const limit = 10;
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const urlParams = useParamsQuery();

  const { chainId, account } = useActiveWeb3React();
  const [updateAuctionLikesMutation] = useUpdateAuctionLikesMutation({
    awaitRefetchQueries: true,
  });

  const userObject = useUserAddressQueryQuery({
    variables: {
      getUserAddress: account, // value for 'getUserByAddressAddress'
    },
  });

  const [updateAuctionViewCountMutation] = useUpdateAuctionViewCountMutation();
  const [updateAuctionShareHashMutation] = useUpdateAuctionShareHashMutation();
  const [present] = useIonToast();
  const { bnbUsd, ethUsd, avaxUsd, busdUsd, maticUsd } = useUSDPrice();
  const [closeAuctionMutation] = useCloseAuctionMutation();
  const [createBidMutation] = useCreateBidMutation();
  const [cancelAuctionMutation] = useCancelAuctionMutation();
  const [doApproval, setDoApproval] = useState(false);
  const [value, setValue] = useState<BigNumber>(toBN(0));
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [txnSubmittedMsg, setTxnSubmittedMsg] = useState<string>("");
  const [waitingMsg, setWaitingMsg] = useState<string>("");
  const [tokenMeta, setTokenMeta] = useState<INFTMetadata>(null);
  const [ownerInfo, setOwnerInfo] = useState<ISocial>(null);
  const [price, setPrice] = useState<BigNumber>(toBN(0));
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [mimeType, setMimeType] = useState("image");
  const [userAuctions, setUserAuctions] = useState<Auction[]>(null);

  const [nftHistory, setNftHistory] = useState<IHistoryTable[]>(null);

  const [auction, setAuction] = useState<Auction>(null);
  const [, setState] = useState(false);
  const [timerMachine, setTimerMachine] = useState<Moment>(null);
  const [hour, setHour] = useState("00");
  const ref = urlParams.get("ref");
  const [generateRef, generateRefRaw] = useGenerateRefTokenLazyQuery();
  const { data, error, loading } = useGetAuctionQuery({
    variables: {
      getAuctionId: id,
    },
  });

  const userAuctionQ = useGetUserAuctionByAuctionIdQuery({
    variables: {
      getUserAuctionsByAuctionIdId: id, // value for 'getUserAuctionsByAuctionIdId'
      limit: 4,
    },
  });

  const { bids, fetchMoreBids, bidsErr, bidsLoading } = useAuctionBids(
    limit,
    id
  );
  const [nftTokenId, setNftTokenId] = useState<string>(null);
  const { histories, historyErr, historyLoading, fetchMoreHistory } =
    useGetNftHistory(limit, nftTokenId);

  const auctionMeta = async (_auction: Auction) => {
    try {
      if (auction) {
        const meta = await getIpfsDetails(auction?.nftToken?.tokenUri);
        const userI = await getUserSocial(auction?.owner?.profileHash);
        const _data: any = meta.data;
        const mime = await getMimeType(_data?.image);
        setMimeType(mime);
        setTokenMeta(meta.data);
        setOwnerInfo(userI);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const localStorage = window.localStorage;

  const handleErrorDismiss = () => {
    dismissPresentError();
  };

  const handleTxSubmittedDismiss = () => {
    dismissSubmitted();
  };

  const handleWaitingDismiss = () => {
    dismissTxnWaiting();
  };
  const handleBidDismiss = () => {
    dismissBidForm();
  };

  const [presentPop, dismissPop] = useIonPopover(SocialPop, {
    onHide: () => dismissPop(),
    location: `${window.location.origin}/nfts/nft/${makeRef(account, id)}`,
    text: `${truncate(tokenMeta?.description, 200)}`,
  });

  const updateSharedHash = (refToken: string) => {
    if (userObject?.data?.getUserByAddress)
      updateAuctionShareHashMutation({
        variables: {
          guestUserId: userObject?.data?.getUserByAddress?.id,
          refToken,
        },
      });
  };

  useEffect(() => {
    if (!id) history.push("/nfts");
    if (!loading && !error && data?.getAuction) {
      setAuction(data?.getAuction as Auction);
      setNftTokenId(data?.getAuction?.nftToken?.id);
      auctionMeta(data?.getAuction as Auction);
      if (history.location.search?.length && data?.getAuction) {
        generateRef({
          variables: {
            auctionId: id,
            refId: ref,
          },
        });
      }
      account &&
        setIsLiked(
          () =>
            data?.getAuction?.likedBy?.length &&
            data?.getAuction?.likedBy?.some(
              ({ address }) => address.toLowerCase() === account.toLowerCase()
            )
        );
      updateViews();
    }

    if (
      !userAuctionQ.loading &&
      !userAuctionQ.error &&
      userAuctionQ.data?.getUserAuctionsByAuctionId?.length
    ) {
      setUserAuctions(
        userAuctionQ?.data?.getUserAuctionsByAuctionId as Auction[]
      );
    }

    makeHistoryTable();
    getUsdPriceFromPaymentMethod();
    const timeOut = setInterval(
      () => {
        const diff =
          new Date(auction?.closedAt).getTime() - new Date().getTime();
        const hr = Math.floor(diff / 1000 / 3600);
        setHour(() => (hr < 10 ? "0" + hr : hr.toString()));
        setTimerMachine(
          moment(new Date(new Date(auction?.closedAt).getTime() - Date.now()))
        );
      },

      1000
    );
    const diff = new Date(auction?.closedAt).getTime() - new Date().getTime();
    const hr = Math.floor(diff / 1000 / 3600);
    setHour(() => (hr < 10 ? "0" + hr : hr.toString()));
    setTimerMachine(moment(new Date(+auction?.closedAt * 1000 - Date.now())));
    return () => {
      clearInterval(timeOut);
      setState(true);
    };
    // eslint-disable-next-line
  }, [
    account,
    id,
    userAuctionQ.data,
    data,
    histories,
    bids,
    userObject?.data,
    bnbUsd,
  ]);

  useEffect(() => {
    if (history.location.search?.length) handleRef();
    // getUsdPriceFromPaymentMethod();
  }, [generateRefRaw?.data]);

  function convertToUSD(pmtMtd: string, value: string) {
    const paymentSymbol = getTokenSymbol(pmtMtd);
    switch (paymentSymbol) {
      case "ETH":
        return getUsdValue(value, ethUsd);
      case "BNB":
        return getUsdValue(value, bnbUsd);
      case "MATIC":
        return getUsdValue(value, maticUsd);
      case "BUSD":
        return getUsdValue(value, busdUsd);
      case "AVAX":
        return getUsdValue(value, avaxUsd);
      default:
        return getUsdValue(value, 1);
    }
  }

  function makeHistoryTable() {
    const nHs = histories?.map((nft: any) => {
      return {
        event: "Auction",
        price: formartUSD(convertToUSD(nft?.paymentMethod, nft?.value)),
        qty: "1",
        from: truncateWalletAddress(nft?.seller?.address),
        to: truncateWalletAddress(nft?.buyer?.address),
        date: calender(nft?.auction?.closedAt),
        hash: nft?.auction?.closingHash,
      };
    });
    setNftHistory(nHs);
  }

  const updateViews = () => {
    const lastUpd = localStorage.getItem(
      getLocalStorageTokenView(auction?.auctionId)
    );
    let date = (Date.now() / 1000).toFixed(0);
    const uintDate =
      lastUpd &&
      parseInt(
        lastUpd
          ? (new Date(lastUpd?.substring(2))?.getTime() / 1000).toFixed(0)
          : "0"
      );
    if (!lastUpd || +date - uintDate > 21600) {
      localStorage.removeItem(getLocalStorageTokenView(auction?.auctionId));
      if (auction) {
        updateAuctionViewCountMutation({
          variables: {
            auctionId: auction?.auctionId,
          },
          update: (cache, { data: { updateAuctionViewCount } }) => {
            try {
              const current = cache.readQuery<GetAuctionQuery>({
                query: GetAuctionDocument,
                variables: { getAuctionId: id },
              });
              return cache.writeQuery({
                query: GetAuctionDocument,
                data: {
                  ...current,
                  viewCount: updateAuctionViewCount.viewCount,
                },
              });
            } catch (error) {
              console.error(error);
            }
          },
        });
        localStorage.setItem(
          getLocalStorageTokenView(auction?.auctionId),
          `${auction?.auctionId}-${new Date().toISOString()}`
        );
      }
    }
  };

  function handleRef() {
    if (data?.getAuction) {
      const localStore = localStorage.getItem(
        getLocalStorageTokenString(data?.getAuction?.auctionId)
      );
      const refToken = generateRefRaw?.data?.generateRefToken;

      if (userObject?.data?.getUserByAddress && localStore && refToken) {
        updateSharedHash(refToken);
        return localStorage.removeItem(
          getLocalStorageTokenString(data?.getAuction?.auctionId)
        );
      }

      if (userObject?.data?.getUserByAddress && refToken && !localStore) {
        return updateSharedHash(refToken);
      } else if (
        !localStore &&
        !userObject?.data?.getUserByAddress &&
        refToken
      ) {
        return localStorage.setItem(
          getLocalStorageTokenString(data?.getAuction?.auctionId),
          refToken
        );
      }
    }
  }

  const [presentError, dismissPresentError] = useIonModal(TransactionDeny, {
    close: handleErrorDismiss,
    msg: errorMsg,
  });

  const [presentSubmitted, dismissSubmitted] = useIonModal(
    TransactionSubmitted,
    {
      close: handleTxSubmittedDismiss,
      hash: txnSubmittedMsg,
    }
  );

  const [presentTxnWaiting, dismissTxnWaiting] = useIonModal(
    TransactionWaiting,
    { close: handleWaitingDismiss, msg: waitingMsg }
  );
  const getMinBid = () => {
    if (+auction?.closingValue > 0)
      return getBalanceNumber(
        toBN(auction?.closingValue).plus(
          toBN(auction?.closingValue).multipliedBy(0.1)
        )
      ).toFixed(4);
    else return getBalanceNumber(auction.basePrice).toFixed(4);
  };

  const handleSubmit = async () => {
    if (!account) return await connect();
    if (
      auction?.paymentMethod?.toLowerCase() !==
      tokens.bnb.address.bsc.toLowerCase()
    ) {
      const allowance = await getAllowance(
        toChecksum(auction.paymentMethod),
        account,
        getNFTMktplaceAddress()
      );
      if (toBN(allowance).toNumber() < toBN(getMinBid()).toNumber()) {
        setDoApproval(true);
        setShowAlert(true);
      } else presentBidForm();
    } else {
      presentBidForm();
    }
  };

  const [presentBidForm, dismissBidForm] = useIonModal(BidForm, {
    close: handleBidDismiss,
    bidValueSetter: makeBid,
    auctionName: tokenMeta?.name,
    subHeading:
      +auction?.closingValue > 0 &&
      `Minimum Bid: ${getMinBid()} ${getTokenSymbol(auction?.paymentMethod)}`,
  });

  const approve = async () => {
    setWaitingMsg(
      `Approving Coterie marketplace to spend ${getTokenSymbol(
        auction?.paymentMethod
      )}`
    );
    const _web3 = await web3();
    presentTxnWaiting();
    use_approve(
      toChecksum(auction?.paymentMethod),
      getNFTMktplaceAddress(),
      account,
      _web3
    )
      .on("transactionHash", (tx: any) => {
        setTxnSubmittedMsg(`${tx}`);

        presentSubmitted();
        dismissTxnWaiting();
      })
      .once("receipt", (receipt: any) => {
        const event = receipt?.events?.Approve?.returnValues;
        if (receipt.revertReason)
          return setErrorMsg(
            web3NoAccount.utils.hexToAscii(receipt.revertReason)
          );
        dismissSubmitted();
        presentBidForm();
      })
      .on("error", (error: any) => {
        dismissTxnWaiting();
        setErrorMsg(error.message);
        presentError();
      });
  };

  const handleCancelAuction = () => {
    setShowAlert(true);
  };
  async function makeBid(_value?: BigNumber) {
    setWaitingMsg(`Bidding for ${tokenMeta?.name}`);
    const _web3 = (await web3()) as Web3;
    presentTxnWaiting();
    try {
      use_makeBid(
        toBN(auction?.auctionId),
        _value,
        toChecksum(auction?.paymentMethod),
        _web3,
        account,
        getChainFromChainId(chainId)
      )
        .on("transactionHash", (tx: any) => {
          setTxnSubmittedMsg(`${tx}`);

          presentSubmitted();
          dismissTxnWaiting();
        })
        .once("receipt", (receipt: any) => {
          const event = receipt?.events?.BidMade;
          if (receipt.revertReason) {
            return setErrorMsg(
              web3NoAccount.utils.hexToAscii(receipt.revertReason)
            );
          }
          if (event?.returnValues)
            createBidMutation({
              variables: {
                bidInput: {
                  trxHash: event?.transactionHash,
                  bidderAddress: account,
                  value: toDefaultDecimal(_value).toString(10),
                  auctionId: auction.auctionId.toString(),
                  newClosing: event?.returnValues?.newClosing.toString(),
                },
              },
              awaitRefetchQueries: true,
              // refetchQueries: () => [
              //   {
              //     query: GetBidsDocument,
              //     variables: {
              //       auctionId: id,
              //       limit: 5,
              //     },
              //   },
              //   {
              //     query: GetAuctionDocument,
              //     variables: { getAuctionId: id },
              //   },
              // ],
              update: (cache, { data: { createBid } }) => {
                const _data = Object.assign({}, bids, {
                  getBids: bids?.length
                    ? filterDuplicatesObj([...bids, createBid])
                    : [createBid],
                });
                cache.writeQuery({
                  query: GetBidsDocument,
                  variables: {
                    auctionId: id,
                    limit: 5,
                  },
                  data: _data,
                });
              },
            });
          dismissSubmitted();
          dismissBidForm();
        })
        .on("error", (error: any) => {
          console.error("txn errors, on-error call", error);
          //  web3.utils.hexToAscii(revertReason)
          setErrorMsg(error.message);
          presentError();
        });
    } catch (error) {
      console.error("Error from catch: ", error);
      // setErrorMsg(error.rev);
    }
  }

  const cancelAuction = async () => {
    if (!account) return await connect();
    setWaitingMsg(`Closing ${tokenMeta?.name} auction`);
    const _web3 = (await web3()) as Web3;
    presentTxnWaiting();
    use_cancelAuction(
      toBN(auction?.auctionId),
      _web3,
      account,
      getChainFromChainId(chainId)
    )
      .on("transactionHash", (tx: any) => {
        setTxnSubmittedMsg(`${tx}`);

        presentSubmitted();
        dismissTxnWaiting();
      })
      .once("receipt", (receipt: any) => {
        const event = receipt?.events?.Cancelled;
        if (receipt.revertReason)
          return setErrorMsg(
            web3NoAccount.utils.hexToAscii(receipt.revertReason)
          );
        if (event?.returnValues)
          cancelAuctionMutation({
            variables: {
              auctionId: auction.auctionId,
            },
            // awaitRefetchQueries: true,
            // refetchQueries: () => [
            //   {
            //     query: GetAuctionsDocument,
            //   },
            // ],
            update: (cache, { _ }: any) => {
              try {
                const current = cache.readQuery<GetAuctionQuery>({
                  query: GetAuctionDocument,
                  variables: { getAuctionId: id },
                });

                const data = Object.assign({}, current.getAuction, {
                  closingHash: event.transactionHash,
                  state: AuctionState.Cancelled,
                  closedAt: new Date().toISOString(),
                });
                cache.writeQuery<GetAuctionQuery>({
                  query: GetAuctionDocument,
                  variables: { getAuctionId: id },
                  data: {
                    __typename: "Query",
                    getAuction: data,
                  },
                });
              } catch (error) {
                console.error(error);
              }
            },
          });
        dismissSubmitted();
        dismissBidForm();
        history.push("/nfts");
      })
      .on("error", (error: any) => {
        dismissTxnWaiting();
        setErrorMsg(error.message);
        presentError();
      });
  };

  const closeAuction = async () => {
    if (!account) return await connect();
    setWaitingMsg(`Closing ${tokenMeta?.name} auction`);
    const _web3 = (await web3()) as Web3;
    presentTxnWaiting();
    use_closeAuction(
      toBN(auction?.auctionId),
      _web3,
      account,
      getChainFromChainId(chainId)
    )
      .on("transactionHash", (tx: any) => {
        setTxnSubmittedMsg(`${tx}`);

        presentSubmitted();
        dismissTxnWaiting();
      })
      .once("receipt", (receipt: any) => {
        const event = receipt?.events?.Executed;
        if (receipt.revertReason)
          return setErrorMsg(
            web3NoAccount.utils.hexToAscii(receipt.revertReason)
          );
        if (event?.returnValues)
          closeAuctionMutation({
            variables: {
              auctionId: id,
              value: event.returnValues.closingValue,
              closingHash: event.transactionHash,
              buyerAddress:
                bids?.length && bids[bids.length - 1].bidder.address,
            },

            awaitRefetchQueries: true,
            refetchQueries: () => [
              {
                query: GetAuctionDocument,
                variables: { getAuctionId: id },
              },
            ],
            // update: (cache, { _ }: any) => {

            //   try {
            //     const current = cache.readQuery<GetAuctionQuery>({
            //       query: GetAuctionDocument,
            //       variables: { getAuctionId: id },
            //     });

            //     const data = Object.assign({}, current.getAuction, {
            //       closingValue: event.returnValues.closingValue,
            //       closingHash: event.transactionHash,
            //       state: AuctionState.Sold,
            //       closedAt: new Date().toISOString(),
            //     });
            //     cache.writeQuery<GetAuctionQuery>({
            //       query: GetAuctionDocument,
            //       variables: { getAuctionId: id },
            //       data: {
            //         __typename: "Query",
            //         getAuction: data,
            //       },
            //     });
            //   } catch (error) {
            //     console.error(error);
            //   }
            // },
          });
        dismissSubmitted();
        dismissBidForm();
      })
      .on("error", (error: any) => {
        dismissTxnWaiting();
        setErrorMsg(error.message);
        presentError();
      });
  };

  const likeNft = async () => {
    if (!account) return await connect();
    const isOwner =
      auction?.owner?.address?.toLowerCase() === account?.toLowerCase();
    if (!isOwner) {
      await updateAuctionLikesMutation({
        variables: {
          updateAuctionLikesAuctionId: auction?.auctionId,
          updateAuctionLikesUserAddress: account,
        },

        update: (cache, { data: { updateAuctionLikes } }) => {
          try {
            const current = cache.readQuery<GetAuctionQuery>({
              query: GetAuctionDocument,
              variables: { getAuctionId: id },
            });

            return cache.writeQuery({
              query: GetAuctionDocument,
              data: {
                ...current,
                likeCount: updateAuctionLikes.likeCount,
                likedBy: updateAuctionLikes.likedBy,
              },
            });
          } catch (error) {
            console.error(error);
          }
        },
      });
      setIsLiked(() =>
        auction?.likedBy?.some(
          ({ address }) => address.toLowerCase() === account.toLowerCase()
        )
      );
    }
  };

  const copyHelper = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    present({
      position: "middle",
      mode: "ios",
      message: "Copied to Clipboard",
      duration: 3000,
      animated: true,
    });
  };

  const createShareUrl = async (e: any) => {
    if (auction) {
      if (!account) await connect();
      presentPop({
        event: e.nativeEvent,
        mode: "ios",
        translucent: true,
        animated: true,
      });
    }
  };

  const canClose = () =>
    +auction?.closingValue > 0 &&
    (account.toLowerCase() === auction?.owner?.address?.toLowerCase() ||
      account.toLowerCase() ===
        (bids?.length &&
          bids[bids?.length - 1].bidder?.address?.toLowerCase()));

  const isClosed = () => {
    if (auction) {
      return (
        +auction?.closingValue > 0 &&
        moment(new Date()) > moment(auction?.closedAt) &&
        auction?.state === AuctionState.Sold
      );
    }
  };

  function getUsdPriceFromPaymentMethod() {
    if (auction) {
      const { paymentMethod, chain } = auction;
      const symbol = getTokenSymbol(paymentMethod);
      switch (symbol) {
        case "BNB":
          setPrice(toBN(bnbUsd));
          break;
        case "BUSD":
          setPrice(toBN(1));
          break;
        case "AVAX":
          setPrice(toBN(avaxUsd));
          break;
        case "ETH":
          setPrice(toBN(ethUsd));
          break;
        case "MATIC":
          setPrice(toBN(maticUsd));
          break;
        default:
          setPrice(toBN(1));
          break;
      }
    }
  }

  const fields = [
    {
      label: `Unit Price(${getTokenSymbol(auction?.paymentMethod)})`,
      key: "price",
    },
    { label: "Unit Price(USD)", key: "price_usd" },
    { label: "Quantity", key: "qty" },
    { label: "Timestamp", key: "expiration" },
    { label: "Form", key: "from" },
  ];

  const historyFields = [
    { label: "Event", key: "event" },
    { label: "Unit Price(USD)", key: "price" },
    { label: "Quantity", key: "qty" },
    { label: "Form", key: "from" },
    { label: "To", key: "to" },
    { label: "Date", key: "date" },
  ];

  return (
    <IonContent>
      <Container>
        <Con>
          {!auction ? (
            <LoadingDetails />
          ) : (
            <Contain className="pool-p10">
              {/* <Metatag
                title={tokenMeta?.name}
                pageUrl={`https://coteriefinance.org/nfts/${auction?.id}`}
                imageUrl={formatIpfsUrl(tokenMeta?.image)}
                description={tokenMeta?.description}
              /> */}
              <Flex directionM="column">
                {!tokenMeta?.image?.length ? (
                  <Preview>
                    <IonSpinner />
                  </Preview>
                ) : (
                  <Preview>
                    {mimeType === "video" ? (
                      <video
                        controls={true}
                        muted={true}
                        src={formatIpfsUrl(tokenMeta?.image)}
                      ></video>
                    ) : (
                      <img
                        src={formatIpfsUrl(tokenMeta?.image)}
                        alt={tokenMeta?.name ?? "Art work"}
                      />
                    )}
                  </Preview>
                )}

                <Details>
                  <Flex align="center" justify="space-between">
                    <Flex align="center">
                      <Flex align="center">
                        <IonIcon size="large" mode="ios" icon={eyeOutline} />
                        <Spacer width={5} />
                        <Text>{auction?.viewCount ?? 0} views</Text>
                      </Flex>
                      <Spacer width={50} />
                      <Flex onClick={() => likeNft()} align="center">
                        <IonIcon
                          size="large"
                          color={isLiked ? "danger" : "medium"}
                          icon={heart}
                        />{" "}
                        <Spacer width={5} />
                        <Text>{auction?.likeCount ?? 0} likes</Text>
                      </Flex>
                    </Flex>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {account?.toLowerCase() ===
                        auction?.owner?.address?.toLowerCase() &&
                        !auction?.bids?.length && (
                          <ShareCon onClick={handleCancelAuction} className="">
                            <IonButton fill="clear">
                              {}
                              <IonIcon
                                size="large"
                                color="danger"
                                icon={trash}
                              />{" "}
                            </IonButton>
                          </ShareCon>
                        )}
                      {
                        <>
                          <ShareCon
                            onClick={(e: any) => createShareUrl(e)}
                            className="mobile"
                          >
                            <Share />
                          </ShareCon>
                          <ShareCon>
                            <ButtonWhite
                              onClick={(e: any) => createShareUrl(e)}
                            >
                              <Share /> Share
                            </ButtonWhite>
                          </ShareCon>
                        </>
                      }
                    </div>
                  </Flex>

                  <Spacer height={30} />
                  <Text size="48" sizeM="36" weight="500">
                    {stringToCapitalized(tokenMeta?.name)}
                  </Text>
                  <Spacer height={30} />
                  <Flex>
                    <Flex direction="column">
                      {+auction?.closingValue > 0 ? (
                        <Text>Current bid</Text>
                      ) : (
                        <Text>Reserved Price</Text>
                      )}
                      <Spacer height={5} />
                      <Text size="32" sizeM="24" weight="500">
                        {`${getFullDisplayBalance(
                          +auction.closingValue === 0
                            ? auction?.basePrice
                            : auction?.closingValue
                        )} ${getTokenSymbol(auction?.paymentMethod)}`}
                      </Text>
                      <Spacer height={5} />
                    </Flex>
                    <div
                      style={{
                        border: "1px solid #000000",
                        height: 88,
                        margin: "0 40px",
                      }}
                    ></div>
                    <Flex direction="column">
                      <Text>Bid ends in</Text>
                      <Spacer height={5} />
                      <Text size="32" sizeM="24">
                        {!auction?.closingValue || !timerMachine
                          ? "00:00:00"
                          : new Date().getTime() <
                            new Date(auction.closedAt).getTime()
                          ? `${hour}h: ${
                              timerMachine.minutes() < 10
                                ? "0" + timerMachine.minutes()
                                : timerMachine.minutes()
                            }m: ${
                              timerMachine.seconds() < 10
                                ? "0" + timerMachine.seconds()
                                : timerMachine.seconds()
                            }s`
                          : moment(new Date(auction?.closedAt)).fromNow()}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex align="center">
                    <Avatar size="48">
                      <img
                        src={
                          formatIpfsUrl(ownerInfo?.imageHash) ??
                          `https://avatars.dicebear.com/api/male/${auction?.owner?.address}.svg?background=%236d64ff&&mood[]=happy`
                        }
                        alt="my imag"
                      />
                    </Avatar>
                    <Spacer width={10} />
                    <Flex direction="column">
                      <Text size="16" weight="500">
                        {data?.getAuction?.owner?.name}
                      </Text>
                      <Text color="rgba(0,0,0,0.5)">Owner</Text>
                    </Flex>
                  </Flex>
                  <Spacer height={15} />

                  {account?.toLowerCase() !==
                    auction?.owner?.address?.toLowerCase() &&
                    !isClosed() && (
                      <ButtonSecondary
                        disabled={
                          auction?.state !== AuctionState.Onsale ||
                          (auction?.closedAt &&
                            new Date().getTime() >
                              new Date(auction?.closedAt).getTime())
                        }
                        onClick={handleSubmit}
                        width="140px"
                      >
                        {auction?.closedAt &&
                        new Date().getTime() >
                          new Date(auction?.closedAt).getTime()
                          ? `Auction Closed`
                          : `Place Bid`}
                      </ButtonSecondary>
                    )}
                  {canClose() &&
                  (account?.toLowerCase() ===
                    auction?.owner?.address?.toLowerCase() ||
                    (bids?.length &&
                      bids[
                        bids && bids?.length - 1
                      ]?.bidder?.address?.toLowerCase() ===
                        account.toLowerCase()))
                    ? auction?.state !== AuctionState.Sold && (
                        <ButtonSecondary
                          disabled={
                            new Date().getTime() <
                            new Date(auction?.closedAt).getTime()
                          }
                          onClick={closeAuction}
                          width="140px"
                        >
                          Close Auction
                        </ButtonSecondary>
                      )
                    : isClosed() && (
                        <IonChip color="success">Completed</IonChip>
                      )}
                  {!canClose() &&
                    !isClosed() &&
                    account?.toLowerCase() ===
                      auction?.owner?.address?.toLowerCase() && (
                      <ButtonWhite
                        onClick={() => history.push("/nfts/token/")}
                        width="180px"
                      >
                        <Pencel />
                        <Spacer width={10} /> Edit Auction
                      </ButtonWhite>
                    )}

                  <Alert
                    {...{
                      isOpen: showAlert,
                      dismissAlert: setShowAlert,
                      headerMsg: doApproval
                        ? `Approve Token`
                        : `Delisting ${stringToCapitalized(tokenMeta?.name)}`,

                      msg: doApproval
                        ? auction &&
                          `Approve Coterie market place to spend ${getTokenSymbol(
                            auction?.paymentMethod
                          )}`
                        : ` ${stringToCapitalized(
                            tokenMeta?.name
                          )} will be delisted from Coterie Market.`,
                      cancelText: "Cancel",
                      action: doApproval ? approve : cancelAuction,
                      actionInput: null,
                      okText: doApproval ? "Approve" : "Delist",
                    }}
                  />
                  <Spacer height={25} />

                  <div
                    style={{
                      border: "1px solid #ccc",
                      width: "100%",
                      margin: "0 40px",
                      marginLeft: "0px",
                    }}
                  ></div>
                  <Spacer height={25} />
                  <Text size="32" sizeM="20" weight="500">
                    Description
                  </Text>
                  <Text size="14" style={{ lineHeight: "24px" }}>
                    {tokenMeta?.description}
                  </Text>
                </Details>
              </Flex>
            </Contain>
          )}

          <Spacer />
          {tokenMeta?.properties && tokenMeta?.properties?.length && (
            <div>
              <Text size="32" sizeM="20" weight="500">
                Properties
              </Text>
              <Spacer />
              <Flex>
                {tokenMeta?.properties?.map((prop: any, k: number) => (
                  <PropertyCard key={k}>
                    <Text>{prop?.type}</Text>
                    <Text size="16" weight="500">
                      {prop?.name}
                    </Text>
                    <Text size="16" color="rgba(0,0,0,0.5)">
                      {prop?.value}
                    </Text>
                  </PropertyCard>
                ))}
              </Flex>
            </div>
          )}
          <Spacer height={20} />

          {auction && (
            <Flex directionM="column">
              <Offers>
                <Text size="32" sizeM="20" weight="500">
                  Offers
                </Text>
                <Spacer />

                <DataTable
                  {...{
                    fields,
                    data: makeOffertable(bids, price, auction?.paymentMethod),
                  }}
                />
              </Offers>
              <Detail>
                <Spacer />
                <Text size="32" sizeM="20" weight="500">
                  Details
                </Text>
                <Spacer />
                <Flex justify="space-between" margin="10px 0px">
                  <Text size="14" weight="500">
                    Contract Address
                  </Text>
                  <Text size="14">
                    <a
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#7974ff",
                      }}
                      href={formatExplorerUrl(
                        auction?.nftToken?.contractAddress
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {auction &&
                        truncateWalletAddress(
                          auction?.nftToken?.contractAddress,
                          6,
                          4
                        )}
                    </a>
                  </Text>
                </Flex>
                <Flex justify="space-between" margin="10px 0px">
                  <Text size="14" weight="500">
                    Token ID
                  </Text>
                  <Text
                    onClick={() => copyHelper(auction?.nftToken?.tokenId)}
                    size="14"
                  >
                    <IonIcon mode="ios" icon={copyOutline} />{" "}
                    {truncate(auction?.nftToken?.tokenId, 13)}
                  </Text>
                </Flex>
                <Flex justify="space-between" margin="10px 0px">
                  <Text size="14" weight="500">
                    Token Standard
                  </Text>
                  <Text size="14">
                    {auction &&
                      getNFTsTokenStandard(auction?.nftToken?.standard)}
                  </Text>
                </Flex>
                <Flex justify="space-between" margin="10px 0px">
                  <Text size="14" weight="500">
                    Blockchain
                  </Text>
                  <Text size="14">
                    {auction && getChainFullName(auction?.chain)}
                  </Text>
                </Flex>
                <Flex justify="space-between" margin="10px 0px">
                  <Text size="14" weight="500">
                    Metadata
                  </Text>
                  <Text size="14">
                    <a
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#7974ff",
                      }}
                      href={formatIpfsUrl(auction?.nftToken?.tokenUri)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {auction &&
                        truncate(
                          formatIpfsUrl(auction?.nftToken?.tokenUri),
                          20
                        )}
                    </a>
                  </Text>
                </Flex>
              </Detail>
            </Flex>
          )}

          <Text size="32" sizeM="20" weight="500">
            Trading History
          </Text>
          <Spacer />

          <DataTable
            data={!historyLoading && !historyErr ? nftHistory : []}
            fields={historyFields}
          />

          <Spacer height={20} />
          <Text size="32" sizeM="20" weight="500">
            Other Auctions By{" "}
            <Link to={`/user/${auction?.owner?.id}`}>
              <span style={{ color: "rgba(0,0,0,0.5)" }}>
                {auction?.owner?.username
                  ? `@${auction?.owner?.username}`
                  : auction?.owner?.name}
              </span>{" "}
            </Link>
          </Text>

          <GridComponent>
            {userAuctionQ?.loading ? (
              Array.from(Array(8)).map((_, i) => <Loading key={i} />)
            ) : !userAuctions?.length ? (
              <p>Empty</p>
            ) : (
              userAuctions?.length &&
              [...userAuctions]
                ?.splice(0, 6)
                ?.map((auction: Auction, i: number) => (
                  <NftCard key={i} {...{ auction }} />
                ))
            )}
          </GridComponent>
          <Spacer />
        </Con>
        <Footer />
      </Container>
    </IonContent>
  );
};

export default NftDetails;

interface IBidForm {
  bidValueSetter?: (value: BigNumber) => void;
  auctionName?: string;
  subHeading?: string;
  close: () => void;
}

const BidCard = styled(IonCard)`
  border-radius: 25px;
  padding: 20px;
  max-width: 300px;
`;

const Header = styled.h5`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const BidForm: React.FC<IBidForm> = ({
  bidValueSetter,
  auctionName,
  subHeading,
  close,
}) => {
  const [value, setValue] = useState<string>(null);
  const handleClick = () => {
    if (value && +value > 0) bidValueSetter(toBN(value));
  };

  return (
    <BidCard>
      <Header>
        <b>Bidding for {auctionName}</b>
      </Header>
      <IonItem className="box">
        <Input
          required={true}
          label={subHeading}
          name="name"
          type="number"
          value={value}
          placeholder="10"
          onIonChange={(e: any) => setValue(e.detail.value)}
        />
      </IonItem>
      <ButtonWrapper>
        <ButtonPrimary color="primary" expand="block" onClick={handleClick}>
          <b>Bid</b>
        </ButtonPrimary>
        <ButtonPrimary
          color="primary"
          fill="outline"
          expand="block"
          onClick={close}
        >
          <b>Cancel</b>
        </ButtonPrimary>
      </ButtonWrapper>
    </BidCard>
  );
};
