import { IonButton,IonIcon } from '@ionic/react'
import React from 'react'
import { informationCircleOutline } from 'ionicons/icons';
import styled from "styled-components";


interface EmptyCaseProps {
  create:()=>void
}

const Empty = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;

  p{
      padding:10px
  }
 `

const EmptyCase: React.FC<EmptyCaseProps> = ({create}) => {
        return (
        <div>
           <Empty>
                <IonIcon icon={informationCircleOutline}  size="large"></IonIcon>
                <p>No active case on this auctions</p>
           </Empty>
            <IonButton onClick={create}>Create case</IonButton>
        </div>
        );
}

export default EmptyCase