import { web3 } from 'hooks/useWeb3Provider';
import { auctionContract, auctionFacContract, lockerContract, tokenContract } from './contractHelper';





// =====================================================
//  FACTORY CALLS
// =====================================================

export const auctionAddress=async (contract:any,token:string)=>await contract.methods.getAuction(token).call();

// =====================================================
//  TOKEN CALLS
// =====================================================

export const totalSupply=async (contract:any)=>await contract.methods.totalSupply().call();

export const tokenName=async (contract:any)=>await contract.methods.name().call();

export const symbol=async (contract:any)=>await contract.methods.symbol().call();



// =====================================================
//  CASES CALLS
// =====================================================

export const case0=async (contract:any)=>await contract.methods.viewCases(0).call();
export const case1=async (contract:any)=>await contract.methods.viewCases(1).call();
export const contributorsCount=async (contract:any)=>await contract.methods.contributorsCount().call();
export const canCreateCase=async (contract:any)=>await contract.methods.canCreateCase().call();
export const caseCreated=async (contract:any)=>await contract.methods.caseCreated().call()

// =====================================================
//  LOCKER CALLS
// =====================================================

export const auctionDetails=async (contract:any,auctionAddress:string)=>await contract.methods.auctionDetails(auctionAddress).call();
export const getLPAmount=async (contract:any,auctionAddress:string)=>await contract.methods.getLPAmount(auctionAddress).call();


// =====================================================
//  CONTRACT CALLS
// =====================================================

export const roi=async (contract:any)=> await contract.methods.roi().call();

export const rate=async (contract:any)=> await contract.methods.rate().call();

export const amountRaised=async (contract:any)=>await contract.methods.amountRaised().call()


export const initialLiquidity=async (contract:any)=> await contract.methods.initialLiquidity().call();


export const salesCompleted=async (contract:any)=>await contract.methods.salesCompleted().call();

export const exchangeLaunched=async (contract:any)=>await contract.methods.exchangeLaunched().call();

export const amountToken=async (contract:any)=>await contract.methods.amountToken().call();

export const owner=async (contract:any)=>await contract.methods.owner().call();

export const getTokenBal=async (contract:any)=>await contract.methods.getTokenBal().call();

export const openTill=async (contract:any)=>await contract.methods.openTill().call();

export const minimumPurchaseEth=async (contract:any)=>await contract.methods.minimumPurchaseEth().call();

export const maximumPurchaseEth=async (contract:any)=>await contract.methods.maximumPurchaseEth().call();

export const availableTokenForSale=async (contract:any)=>await contract.methods.availableTokenForSale().call();


export const hardCap=async (contract:any)=>await contract.methods.hardCap().call();


export const ethBal=async (contract:any)=>await contract.methods.getEthBal().call();


export const tokenAddress=async (contract:any)=>await contract.methods.token().call();

export const claimable=async (contract:any,account:string)=>await contract.methods.getClaimable(account).call();

export const tokenBal=async (contract:any)=>await contract.methods.getTokenBal().call();


// export const progress = Math.round((amountRaised / hardcap) * 100);

export const getAll=async (address:string)=>{
   const contract =await auctionContract(address)
    return {
    rate:await rate(contract),
    roi:await roi(contract),
    amountRaised:await amountRaised(contract),
    initialLiquidity:await initialLiquidity(contract),
    ethBal:await ethBal(contract),
    auctionAddress:address,
    minimumPurchaseEth:await minimumPurchaseEth(contract),
    maximumPurchaseEth:await maximumPurchaseEth(contract),
    availableTokenForSale:await availableTokenForSale(contract),
    hardCap:await hardCap(contract),
    owner:await owner(contract),
    getTokenBal:await getTokenBal(contract),
    openTill:await openTill(contract),
    exchangeLaunched:await exchangeLaunched(contract),
    salesCompleted:await salesCompleted(contract),
    symbol:await getSymbol(await tokenAddress(contract)),
    tokenName:await getTokenName(await tokenAddress(contract))
   }
}

export const getClaim =async (address:string,account:string)=>{
    const contract =await auctionContract(address)
    let web3Init= await web3()
    let ethBal= await web3Init.eth.getBalance(address)
    
    console.log(ethBal)
    
    return {
        claimable:await claimable(contract,account),
        tokenBal:await tokenBal(contract),
        ethBal
     }
}

export const getTokenName=async (address:string)=>{
    const contract =await tokenContract(address)
    return tokenName(contract)
}

export const getSymbol=async (address:string)=>{
    const contract =await tokenContract(address)
    return symbol(contract)
}

export const getToken=async (address:string)=>{
    const contract =await tokenContract(address)
    return {
        name:await case0(contract),
        symbol:await symbol(contract),
        supply:await totalSupply(contract)
    }
}
export const getAllCases=async (address:string)=>{
    const contract =await auctionContract(address)
    return {
        case0:await case0(contract),
        case1:await case1(contract),
        contributorsCount:await contributorsCount(contract),
        canCreateCase:await canCreateCase(contract),
        caseCreated:await caseCreated(contract),
    }
}


export const getAllLocker=async (address:string,auctionAddress:string)=>{
    const contract =await lockerContract(address)
    return {
        auctionDetails:await auctionDetails(contract,auctionAddress),
        lpAmount:await getLPAmount(contract,auctionAddress)
    }
}

export const getAuctionAddress=async (token:string,address:string)=>{
    const contract =await auctionFacContract(address)
    return auctionAddress(contract,token)
}
