import { IonAvatar, IonHeader } from "@ionic/react";
import { Logo, Spacer } from "components";
import { ButtonSecondary, ButtonWhite } from "components/Button";
import { KeyValue } from "components/Pool/Popover";
import { Cancel, Menu, Search } from "components/SvgIcons";
import { injected } from "connector/injected";
import { useGetNotificationLazyQuery } from "generated/graphql-frontend";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import { menu } from "./Menu";
import { MobileMenu } from "./MobileMenu";
import NavPopup from "./NavPopup";
import NotifItems from "./NotiItems";

const User = styled.div`
  display: inline-block;
  position: relative;
  left: 15px;
  top: -5px;
  cursor: pointer;

  @media (max-width: 600px) {
    // float: right;
    right: 10px;
  }
`;
const NavContainer = styled.div`
  position: relative;
  background: white;
  height: 86px;
  display: flex;
  align-items: end !important;
  box-shadow: 4px 4px 36px rgba(0, 0, 0, 0.08);
  z-index: 999;
  @media (min-width: 640px) {
    align-items: center;
  }
`;
const NavInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const RightNav = styled.div`
  display: flex;
  margin-right: 10px;
  &.desktop {
    display: none;
  }
  div {
    margin: 0px 5px;
  }

  @media (min-width: 640px) {
    &.desktop {
      display: flex;
      align-items: center;

      a {
        white-space: nowrap;
      }
      h4 {
        color: #000000;
        font-size: 16px;
        padding: 0px 20px;
      }
    }

    &.mobile {
      display: none;
    }
  }

  @media (max-width: 640px) {
    &.desktop {
      display: none;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22.3214px;
  line-height: 26px;
  letter-spacing: 0.05em;
  line-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 30px;

  & .pool-bar {
    cursor: pointer;
  }
`;

const NotificationCon = styled.div`
  background: #ffffff;
  box-shadow: -4px 4px 20px 10px rgb(0 0 0 / 8%);
  border-radius: 15px;
  width: 460px;
  /* height: 693px; */
  max-height: 83vh;
  position: absolute;
  right: 15px;
  top: 86px;
  padding-bottom: 20px;
`;

const NotiHeader = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 15px;
`;

const NotiBody = styled.div`
  max-height: 60vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    background: #333333;
  }

  a {
    color: black !important;
  }
`;

const All = styled.div`
  background: #ffffff;
  box-shadow: inset 0px -5px 4px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  text-align: center;
  padding: 10px;
  margin: 0px 15px !important;
`;

interface NavInput {
  account?: string;
  imageHash?: string;
  name?: string;
  user?: any;
}
const Nav: FC<NavInput> = ({ account, imageHash, name, user }) => {
  let dispatch = useDispatch();
  const { activate } = useActiveWeb3React();
  const unlockWallet = () => {
    try {
      activate(injected);
      dispatch({ type: "SET_ACCOUNT", payload: account });
      if (account) {
        setLocked(false);
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const toggleSideBar = () => {
    dispatch({ type: "TOGGLE_SIDEBAR" });
  };

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [locked, setLocked] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>("");
  const [drop, setDrop] = useState<boolean>(false);
  const [notif, setNotif] = useState<boolean>(false);

  const [getNotification, { loading, data, fetchMore }] =
    useGetNotificationLazyQuery();
  const limit = 10;

  useEffect(() => {
    if (user) {
      const { id } = user;
      getNotification({
        variables: {
          userId: id,
          limit,
          cursor: "",
        },
      });
      data?.getNotifications?.filter((data) => data.seen === false);
    }
  }, [getNotification, data, user]);

  const location = useLocation();
  const list: KeyValue[] = [
    { value: "Dashboard", key: "dashboard" },
    { value: "My NFTs", key: "nfts" },
    { value: "My Tree", key: "tree" },
    { value: "Followers", key: "followers" },
    // { value: "Earnings", key: "earnings" }, //  Todo:  For referral earnings and auction sales
    { value: "Close", key: "close" },
  ];

  const action = (item: KeyValue) => {
    if (item.key === "dashboard") {
      history.push({
        pathname: "/me",
        search: "?page=dashboard",
      });
    }
    if (item.key === "tree") {
      history.push({
        pathname: "/me",
        search: "?page=tree",
      });
    }

    if (item.key === "people") {
      history.push({
        pathname: "/me",
        search: "?page=people",
      });
    }

    //  if (item.key === "earnings") {
    //    history.push({
    //      pathname: "/nfts/me",
    //      search: "?page=earning",
    //    });
    //  }
    if (item.key === "close")
      setShowPopover({
        showPopover: false,
        event: undefined,
      });
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
  };

  useEffect(() => {
    setOpen(false);
    if (account) {
      setLocked(false);
    }
    setAvatar(imageHash);
  }, [location, account, imageHash]);

  const history = useHistory();
  const navigateTo = () => history.push("/nfts/create");

  if (location.pathname === "/") return <></>;

  const handleOpen = () => {
    let timer = setTimeout(() => setDrop(false), 5000);

    clearTimeout(timer);
  };

  const alertNow = () => {
    alert();
  };

  const handleNotifView = () => {
    setNotif(!notif);
  };

  return (
    <IonHeader>
      <NavContainer>
        <NavInner>
          <Link to="/">
            <Logo>
              <img src="/svgs/coterie_rz.svg" alt="Coterie logo" width="100" />
            </Logo>
          </Link>

          {/* Mobile */}

          <RightNav className="mobile">
            <div onClick={toggleSideBar}>
              <Search />
            </div>
            <div onClick={() => setOpen(!open)}>
              {open ? <Cancel /> : <Menu />}
            </div>
          </RightNav>

          {/* Desktop */}

          <RightNav className="desktop">
            <div>
              {" "}
              <Search />
            </div>
            {menu.map((item, k) => (
              <Link to={item.href} key={k}>
                <div>
                  <h4> {item.value}</h4>
                </div>
              </Link>
            ))}

            <ButtonWhite width="120" onClick={navigateTo}>
              Create
            </ButtonWhite>
            <Spacer width={10} />
            {locked ? (
              <ButtonSecondary onClick={unlockWallet}>
                Connect Wallet
              </ButtonSecondary>
            ) : (
              <IonAvatar
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                className="nav-avata"
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    imageHash
                      ? formatIpfsUrl(avatar)
                      : `https://avatars.dicebear.com/api/male/${
                          account ?? "account"
                        }.svg?background=%236d64ff&&mood[]=happy`
                  }
                  alt={name ?? "name"}
                />
                <div className="div">
                  <NavPopup
                    handleNotif={handleNotifView}
                    onMouse={() => setDrop(true)}
                    notCount={
                      data?.getNotifications?.filter(
                        (data) => data.seen === false
                      ).length
                    }
                  />
                </div>
                {/* <IonIcon mode="ios" size="large" icon={personCircle} /> */}
              </IonAvatar>
            )}
            {notif && (
              <NotificationPop
                handleNotif={handleNotifView}
                {...{ loading, data, user }}
              />
            )}
          </RightNav>
        </NavInner>
      </NavContainer>
      <MobileMenu {...{ imageHash, name, account, open, formatIpfsUrl }} />
    </IonHeader>
  );
};

const NotificationPop: FC<{
  handleNotif: () => void;
  user: any;
  loading: boolean;
  data: any;
}> = ({ handleNotif, user, loading, data }) => {
  return (
    <NotificationCon>
      <NotiHeader>
        <h1>Notifications</h1>
        <div onClick={handleNotif} style={{ cursor: "pinter" }}>
          <Cancel color="#ff0000" />
        </div>
      </NotiHeader>

      <NotiBody>
        {loading ? (
          <p>Loading..</p>
        ) : data?.getNotifications?.length < 1 ? (
          <div>Empty</div>
        ) : (
          data?.getNotifications?.map((notification: any, k: number) => (
            <NotifItems
              key={k}
              // description={notification.description}
              {...notification}
            />
          ))
        )}
      </NotiBody>
      <Link to="/me/notifications">
        <All>See All</All>
      </Link>
    </NotificationCon>
  );
};

export default Nav;
