import React from "react";
import styled from "styled-components";
import { IonText } from "@ionic/react";


export const BannerContainer = styled.div`
    background: linear-gradient(to bottom, #6470CF, #131c63);
    padding:10px;
    height: 152px;
    color:White;
    // width: 100%;
    & h1{
        font-weight:900;
    }
`;

interface BannerInterface{
  title:string;
  description?:string
    
}

const PoolBanner: React.FC<BannerInterface> = ({title,description}) => {


    return (
        <BannerContainer>
            <IonText>
                <h1>{ title} </h1>
            </IonText>
            <IonText >
              <p>{ description }</p>
            </IonText>
            

        </BannerContainer>
    )
}

export default PoolBanner