import { IonApp, IonPage, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import "filepond/dist/filepond.min.css";
import {
  useCreateUserMutation,
  User,
  useUserAddressQueryLazyQuery,
} from "generated/graphql-frontend";
import React, { FC, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { registerPlugin } from "react-filepond";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ISocial } from "types/nft-user-types";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import { getUserSocial } from "utils/nft-user-helper";
import "./App.scss";
import { Nav as Header, SideBar } from "./components";
import {
  EmailVerify,
  Home,
  ListNfts,
  Nft,
  NftCreate,
  NftsDetails,
  NotFound,
  Profile,
  Trade,
  UpdateProfile,
} from "./pages";
import "./theme/variables.css";

const FilePondPluginMediaPreview = require("filepond-plugin-media-preview");

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);
const Container = styled(IonPage)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  /* left: 17%; */
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 100%;
    left: 0;
  }
`;

const App: FC = () => {
  const account = useSelector((state: any) => state.user.account);
  const location = useLocation();

  const [getUserByAddress, { data, loading, error }] =
    useUserAddressQueryLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const [createUserMutation] = useCreateUserMutation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [social, setSocial] = useState<ISocial>({});

  const getSocial = async () => {
    let _social = await getUserSocial(formatIpfsUrl(user?.profileHash));
    setSocial(_social);
  };
  const dispatch = useDispatch();

  const [user, setUser] = useState<User>(null);

  const setTheme = (theme: string) => {
    document.body.classList.add(theme);
  };
  const ethereum = window.ethereum;
  const loadBlockchainData = async () => {
    // todo: handle error if there is no ethereum
    // advise to install metamask or unlock wallet if (location.pathname.startsWith("/nfts"))
    if (ethereum) {
      const accounts =
        ethereum && (await ethereum.request({ method: "eth_accounts" }));
      const networkId = ethereum && (await ethereum.networkVersion);
      if (accounts?.length > 0) {
        dispatch({ type: "SET_ACCOUNT", payload: accounts[0] });
        dispatch({ type: "SET_NETWORK", payload: networkId });
      }
    }
  };

  !account && loadBlockchainData();
  const [, setState] = useState(false);
  useEffect(() => {
    let theme: any = localStorage.getItem("theme");
    setTheme(theme);
    if (account) {
      getUserByAddress({
        variables: {
          getUserAddress: account, // value for 'getUserByAddressAddress'
        },
      });
      if (!data?.getUserByAddress) {
        createUserMutation({
          variables: {
            address: account,
          },
        });
        getUserByAddress({
          variables: {
            getUserAddress: account, // value for 'getUserByAddressAddress'
          },
        });
      }
    }
    if (location.pathname.includes("nfts")) {
      setShowSidebar(false);
    } else setShowSidebar(true);
    if (!loading && !error && data?.getUserByAddress) {
      setUser(data?.getUserByAddress as User);
      getSocial();
    }
    return () => {
      setState(true);
    };
    // eslint-disable-next-line
  }, [account, user, data?.getUserByAddress]);

  return (
    <IonApp>
      <IonReactRouter>
        <Header
          imageHash={
            social?.imageHash ??
            `https://avatars.dicebear.com/api/male/${
              account ?? "account"
            }.svg?background=%236d64ff&&mood[]=happy`
          }
          name={user?.username ?? "Coterie User"}
          {...{ account, user }}
        />
        <Container
        /* style={{ display: "flex", flexDirection: "column", height: "100%" }} */
        >
          {!location.pathname.includes("nfts") ? <SideBar /> : null}
          <Body>
            <IonRouterOutlet>
              <Route path="/" exact={true}>
                <Home {...{ user, account, social }} />
              </Route>
              {/* <Route path="/farm" component={Farm} exact />
              <Route path="/pools" component={AllPools} exact />
              */}
              <Route path="/nfts" exact>
                <Nft {...{ user, account, social }} />
              </Route>
              <Route component={NftsDetails} path="/nfts/nft/:id" exact></Route>
              <Route path="/nfts/create" exact>
                <NftCreate {...{ user, account, social }} />
              </Route>
              <Route path="/nfts/token" exact>
                <ListNfts {...{ user, account, social }} />
              </Route>
              <Route path="/me" exact>
                <Profile {...{ user, account, social }} />
              </Route>
              <Route path="/users/user/:userId" exact>
                {" "}
                <Profile {...{ user, account, social }} />{" "}
              </Route>
              <Route path="/me/update" exact>
                <UpdateProfile {...{ user, account, social }} />
              </Route>
              {/* <Route path="/nfts/preview" component={Preview} exact />
              <Route path="/nfts/trending" exact>
                <NftTrending {...{ user, account, social }} />
              </Route> */}

              {/* <Route path="/me/notifications" exact>
                <Notifications {...{ user, account, social }} />
              </Route> */}

              <Route
                path="/email-verification/:token"
                component={EmailVerify}
                exact
              ></Route>

              <Route path="/trade" component={Trade} exact />

              <Route component={NotFound} />
            </IonRouterOutlet>
          </Body>
        </Container>
      </IonReactRouter>
    </IonApp>
  );
};

export default React.memo(App);
