import React from 'react'
import styled from "styled-components";

import {IonSkeletonText} from '@ionic/react';


const LoadingCon = styled.div`
  margin:10px;
  display:flex;

  @media (max-width:640px){
    margin:10px 0px
  }
`


const Loading: React.FC = () => {
        return (
            <LoadingCon >
                <div style={{ width: '300px'}}>
                    <IonSkeletonText animated style={{ width: '300px',height:'200px',borderRadius:'10px' }} />
                    <IonSkeletonText animated style={{ width: '200px'}} />
                    <IonSkeletonText animated style={{ width: '100px'}} />
                    <IonSkeletonText animated style={{ width: '150px'}} />
                    <IonSkeletonText animated style={{ width: '100px',height:'30px',float:'right'}} />
                    <IonSkeletonText animated style={{ width: '150px',marginTop:"20px"}} />
                </div >
                <div style={{ display:'flex',flexDirection:'column', margin:"10px"}}>
                  <IonSkeletonText animated style={{ width: '300px',height:'140px',borderRadius:'10px' }} />
                  <IonSkeletonText animated style={{ width: '300px',height:'140px',borderRadius:'10px' }} />
                </div>
            </LoadingCon>
        );
}

export default Loading