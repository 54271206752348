import { combineReducers } from "@reduxjs/toolkit";
import auctionReducer from "./auctions/AuctionSlice";
import casesReducer from "./cases/CasesSlice";
import farmReducer from "./farms/FarmSlice";
import lockerReducer from "./locker/LockerSlice";
import nftDetailReducer from "./nfts/NftDetailsSlice";
import contractAuctionReducer from "./nftsAuction/NftAuctionSlice2";
import uiStateReducer from "./user/UiSlice";
import userReducer from "./user/UserSlice";

export const rootReducers = combineReducers({
  user: userReducer,
  locker: lockerReducer,
  auctions: auctionReducer,
  cases: casesReducer,
  uiState: uiStateReducer,
  nftDetails: nftDetailReducer,
  farms: farmReducer,
  contractAuctions: contractAuctionReducer
});

export type RootState = ReturnType<typeof rootReducers>;
