import tokens from "./tokens";
import { FarmCategory, FarmConfig } from "./types";

const farms: FarmConfig[] = [
  {
    pid: { test: "0xDE3d5B6dc96A8Bc1aBB01B3A377f75405d3FdD8F", bsc: "" },
    lpSymbol: "Coterie-BNB",
    category: FarmCategory.farm,
    lpAddresses: {
      test: "0xDE3d5B6dc96A8Bc1aBB01B3A377f75405d3FdD8F",
      bsc: "",
    },
    token: tokens.coterie,
    quoteToken: tokens.wbnb,
  },

  {
    pid: { test: "0xd10D9D037a14f84a49aADA9A7217DB2AC4f38f14", bsc: "" },
    lpSymbol: "BASIC-BNB",
    category: FarmCategory.farm,
    lpAddresses: {
      test: "0xd10D9D037a14f84a49aADA9A7217DB2AC4f38f14",
      bsc: "",
    },
    token: tokens.basic,
    quoteToken: tokens.wbnb,
  },
  {
    pid: {
      test: "0x43631B04a5CBFE6D32deDeA593C4ca7B5Bb19dEb",
      bsc: "",
    },
    lpSymbol: "BUSD-BNB",
    category: FarmCategory.farm,
    lpAddresses: {
      test: "0x43631B04a5CBFE6D32deDeA593C4ca7B5Bb19dEb",
      bsc: "",
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: tokens.coterie.address,
    lpSymbol: "Coterie",
    category: FarmCategory.pool,
    lpAddresses: {
      test: "0xDE3d5B6dc96A8Bc1aBB01B3A377f75405d3FdD8F",
      bsc: "",
    },
    token: tokens.coterie,
    quoteToken: tokens.wbnb,
  },

  {
    pid: tokens.basic.address,
    lpSymbol: "BASIC",
    category: FarmCategory.pool,
    lpAddresses: {
      test: "0xd10D9D037a14f84a49aADA9A7217DB2AC4f38f14",
      bsc: "",
    },
    token: tokens.basic,
    quoteToken: tokens.wbnb,
  },
];

export default farms;
