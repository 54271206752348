import { IonCard, IonIcon, IonText } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { closeOutline } from "ionicons/icons";
import { Text,LoadingLogo,Spacer} from "components";

const LoadingCardContainer = styled(IonCard)`
  max-width: 500px;
  min-height:250px;
  border-radius: 5px;
  color: #333;
  padding: 20px;
  top: 50%;
  margin: auto;
`;
const GifWrapper = styled.div`
  width: 60%;
  margin: auto;
  padding: 0 20px;
`;
const TextArea = styled(IonText)`
  text-align: center;
`;

const Header = styled.div`
  padding: 8px 16px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: bold;
`;
const Icon = styled(IonIcon)`
  font-size: 25px;
`;

interface TrxWaiting {
  msg: string;
  close: () => void;
}

const TransactionWaiting: React.FC<TrxWaiting> = (inputs: TrxWaiting) => {
  return (
    <div>
      <LoadingCardContainer>
        {/* <Header >
          <Icon onClick={()=>inputs.close()} icon={closeOutline}/>
        </Header> */}
        <GifWrapper>
          <LoadingLogo>
            <img src="/assets/loading-logo.png" alt="Coterie logo" />
          </LoadingLogo>
        </GifWrapper>

        <TextArea>
          <Text size="32">Waiting For Confirmation</Text>
          <Spacer />
          <Text size="16">{inputs.msg}</Text>
          <Spacer />
          <Text size="16" style={{ color: "rgba(0,0,0,0.5)" }}>
            Confirm this transaction in your wallet
          </Text>
        </TextArea>
      </LoadingCardContainer>
    </div>
  );
};

export default TransactionWaiting;
