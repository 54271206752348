import { IonAvatar, IonItem, IonList, IonPopover } from "@ionic/react";
import { FC } from "react";
import styled from "styled-components";
interface IPopoverState {
  showPopover: boolean;
  event: any;
}

export interface KeyValue {
  key?: string;
  value: any;
  href?: string;
  icon?: any;
}
interface IList {
  items: KeyValue[];
  action: (input?: KeyValue) => void;
}
interface PopInput {
  popoverState: IPopoverState;
  setPopover: (popState: IPopoverState) => void;
  list?: IList;
  header?: string;
  hasIcon?: boolean;
}

const ListHover = styled(IonItem)`
  cursor: pointer;
  color: #242323;
  &:hover {
    transform: scale(1.04);
  }
`;
const H6Bold = styled.h6`
  font-weight: 600;
  text-align: center;
  font-size: 18px;
`;
const Popover: FC<PopInput> = ({
  popoverState,
  setPopover,
  list,
  header,
  hasIcon = false,
}) => {
  return (
    <IonPopover
      mode="ios"
      cssClass="my-custom-class"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => {
        setPopover({ showPopover: false, event: undefined });
      }}
    >
      {list && list?.items?.length ? (
        <IonList
          onMouseLeave={(e: any) => {
            // e.persist();
            setPopover({ showPopover: false, event: e });
          }}
          mode="ios"
        >
          {header ? <H6Bold>{header}</H6Bold> : null}{" "}
          {list?.items.map((item, key) => (
            <ListHover mode="ios" onClick={() => list.action(item)} key={key}>
              {hasIcon && (
                <IonAvatar
                  style={{ maxWidth: 20, maxHeight: 20, margin: "0px 10px" }}
                >
                  <img src={item.icon} />
                </IonAvatar>
              )}
              <strong>{item?.value}</strong>
            </ListHover>
          ))}{" "}
        </IonList>
      ) : null}
    </IonPopover>
  );
};

export default Popover;
