import { IonIcon } from "@ionic/react";
import { Avatar, Flex, Spacer, Text } from "components";
import { ButtonPrimary, ButtonWhite } from "components/Button";
import {
  CheckIsFollowingDocument,
  GetTopCreatorsDocument,
  useCheckIsFollowingLazyQuery,
  User,
  UserAddressQueryDocument,
  useUpdateUserFollowingMutation,
} from "generated/graphql-frontend";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ISocial } from "types/nft-user-types";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import { getUserSocial } from "utils/nft-user-helper";
import { truncate } from "utils/text-help";
// https://ipfs.io/ipfs/QmPMzRbSmpYdhNfoLatxY4JBwTBD1kCjSdrr86rxYUdRXk

export const CardContainer = styled.div`
  width: 310px;
  min-height: 370px;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  /* margin: 20px 10px; */
  overflow: hidden;
  filter: drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.08));

  & .p {
    line-height: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    margin: 20px 0px;
  }
`;

const CardFooter = styled.div`
  position: relative;
  padding: 10px;
`;

const ImageCon = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface CardInterface {
  creator?: User;
  user?: User;
  account?: string;
}

const Card: React.FC<CardInterface> = ({ creator, user, account }) => {
  const history = useHistory();
  const [checkFollowing, { data, loading }] = useCheckIsFollowingLazyQuery();
  const handleClick = () => {
    history.push(
      `${user?.id === creator?.id ? "/me" : "/users/user/" + creator?.id}`
    );
  };
  const [social, setSocial] = useState<ISocial>(null);
  const userSocial = async () => {
    const _social = await getUserSocial(formatIpfsUrl(creator?.profileHash));
    setSocial(_social);
  };
  if (creator && !social) userSocial();
  const [updateUserFollowingMutation] = useUpdateUserFollowingMutation();

  const follow = async () => {
    if (user && creator) {
      await updateUserFollowingMutation({
        variables: {
          address: user.address,
          followingId: creator?.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CheckIsFollowingDocument,
            variables: {
              currentUserId: user?.id, // value for 'currentUerId'
              viewUserId: creator?.id, // value for 'viewUserId'
            },
          },
          {
            query: UserAddressQueryDocument,
            variables: {
              getUserAddress: account, // value for 'getUserByAddressAddress'
            },
          },
          {
            query: GetTopCreatorsDocument,
            variables: {
              limit: 8,
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (
      user &&
      creator &&
      user?.id?.toLowerCase() !== creator?.id?.toLowerCase()
    )
      checkFollowing({
        variables: {
          currentUserId: user?.id, // value for 'currentUerId'
          viewUserId: creator?.id, // value for 'viewUserId'
        },
      });
  }, [user, creator, loading]);

  return (
    <>
      {creator && (
        <CardContainer>
          <ImageCon onClick={handleClick} style={{ height: "250px" }}>
            <img
              src={formatIpfsUrl(social?.bannerHash) ?? "/assets/img1.svg"}
              alt={creator?.name}
            />
          </ImageCon>
          <CardFooter>
            <Avatar onClick={handleClick} size="64" className="creator">
              <img
                src={
                  formatIpfsUrl(social?.imageHash) ?? "/assets/user_avatar.jpg"
                }
                alt={creator?.name ?? creator?.username}
              />
            </Avatar>
            <Flex justify="space-between" style={{ marginTop: 30 }}>
              <Text size="16">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {creator?.name ?? creator?.username}
                  <IonIcon
                    style={{ marginLeft: "5px" }}
                    size="small"
                    src="/svgs/verified.svg"
                  />
                </div>
              </Text>
              <Flex align="center">
                <Text size="16">{creator?.followersCount}</Text>
                <Spacer width={4} />
                <Text>followers</Text>
              </Flex>
            </Flex>
            <Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{ width: "100%" }}
              >
                <div>
                  <Text size="10">{truncate(social?.bio, 100) ?? ``}</Text>
                </div>
                {user?.id !== creator?.id && (
                  <Flex>
                    {!data?.checkFollowing ? (
                      <div>
                        <ButtonWhite
                          style={{ cursor: "pointer" }}
                          height="32"
                          color="primary"
                          fill="outline"
                          onClick={() => follow()}
                        >
                          Follow
                        </ButtonWhite>
                      </div>
                    ) : (
                      <ButtonPrimary
                        style={{ cursor: "pointer" }}
                        height="32"
                        onClick={() => follow()}
                        fill="outline"
                        color="primary"
                      >
                        Following
                      </ButtonPrimary>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </CardFooter>
        </CardContainer>
      )}
    </>
  );
};

export default Card;
