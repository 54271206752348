import axios from "axios";

let nftEndpoint = "https://api.thegraph.com/subgraphs/name/makerzy/anothertry";
let userEndpoint = "https://api.thegraph.com/subgraphs/name/makerzy/first-try";
let query = `{
    auctions(first: 20) {
      id
      createdAt
      closedAt
      expiresAt
      basePrice
      owner {
        id
        nfts{
          id
          contractAddress
          tokenURI
          tokenId
          }
        }
        nft {
          id
          tokenURI
          tokenId
          history{
            currentOwner{
              address
            }
            currentPrice
            previousOwner{
              address
            }
          }
        }
        nftAddress
        paymentMethod{
          id
          symbol
          name
          tokenAddress
        }
        bids {
          id
          bidValue
          createdAt
          status
          trxnHash
          nft {
            id
          }
        }
      }
  }`;

let detailsQuery = (id: string) => {
  return `{
    auction(id: "${id}") {
        id
        createdAt
        closedAt
        expiresAt
        basePrice
        owner {
          id
          address
          nfts{
            id
            contractAddress
            tokenURI
            tokenId
          }
        }
        paymentsTo {
          id
          percent
        }
        nft {
          id,
          tokenURI
          tokenId
          history{
            currentOwner{
              address
            }
            currentPrice
            previousOwner{
              address
            }
          }
        }
        nftAddress
        paymentMethod {
          id
          symbol
          name
          tokenAddress
        }
        bids {
          id
          bidValue
          createdAt
          status
          nft {
            id
          }
        }
      }
  }`;
};

let profileQuery = (id: string) => {
  return `
    {
      account(id:"${id}") {
        id
        profileHash
        referrer {
          address
        }
        classification
        accounts{
          mintedTokens{
            id
            tokenId
            tokenURI
        }
       }
      }
    }
    `;
};

// const jsonUrl = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
const Api = {
  nft: {
    pool: () => axios.post(nftEndpoint, { query }),
    details: (id: string) =>
      axios.post(nftEndpoint, { query: detailsQuery(id) }),
    profile: (id: string) =>
      axios.post(userEndpoint, { query: profileQuery(id) }),
    ipfs: (link: string) => link?.toString()?.length && axios.get(link),
    create: (data: any) =>
      axios.post(url, data, {
        headers: {
          "Content-Type": `multipart/form-data`,
          pinata_api_key: "3bf96ecb81c558794608",
          pinata_secret_api_key:
            "cfd76b4a50aa1555a3a777085d749bda1610028169f0abf50c7841f427e45f89",
        },
      }),
  },
};

export default Api;
