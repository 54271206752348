import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonListHeader,
  useIonModal,
  useIonPopover,
} from "@ionic/react";
import { Flex, Footer, GridComponent, Loading, Spacer, Text } from "components";
import { ButtonWhite } from "components/Button";
import { NftCard } from "components/Card";
import SuccessModal from "components/Modal/MessageModal";
import TConfirmModal from "components/Modal/TConfirmModal";
import FilterItem from "components/Pool/FilterItem";
import { Cancel, DownAngle, Filter, Plus } from "components/SvgIcons";
import {
  Auction,
  AuctionState,
  Blockchain,
  useCreateUserMutation,
  UserAddressQueryDocument,
} from "generated/graphql-frontend";
import React, { useEffect, useState } from "react";
import { useNfts, useUSDPrice } from "store/hooks";
import styled from "styled-components";
import { IUserReceiver } from "types/nft-user-types";
import { filterDuplicatesObj, scrollHelper } from "utils/nft-user-helper";

const Container = styled.div`
  min-height: 100%;
  overflow: auto;
  transition: all 0.3s;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

const FilterCon = styled.div`
  width: 0px;
  flex-grow: 0px;
  flex-shrink: 0;
  background: #f9f9f9;
  border-radius: 3px;
  transition: width 0.3s;
  display: none;

  @media (max-width: 640px) {
    display: none;
  }

  &.open {
    flex-basis: 320px;
    width: 320px;
    overflow: hidden;
    padding: 10px;
    display: block;

    @media (max-width: 640px) {
      display: none;
    }
  }
`;
const FilterHeader = styled(Flex)`
  flex-basis: 320px;
  padding: 0px 10px;

  &.mobile {
    display: none;
  }
  @media (max-width: 640px) {
    display: none;

    &.mobile {
      display: block;
    }
  }
`;

const PCount = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`;

const Body = styled.div`
  width: 100%;
  transition: all 0.3s ease;
`;

const SelectButton = styled.div`
  transition: 0.3s;
  background: rgba(106, 100, 255, 0.1);
  border: 1px solid #6a64ff;
  box-sizing: border-box;
  padding: 10px 15px;
  margin: 10px 4px;
  display: block;
  white-space: nowrap;
  width: calc(50% - 8px);
  border: 1px solid #6a64ff;
  border-radius: 5px;
  cursor: pointer;

  &.selected,
  :hover {
    background: #6a64ff;
    border: 1px solid #ffffff;
    color: #fff;
  }
`;

const CInput = styled.input`
  border-radius: 5px;
  padding: 10px 15px;
  flex: 1 0 0%;
  width: 50%;
  font-size: 20px;
`;

const Nfts: React.FC<IUserReceiver> = ({ user, account, social, scroll }) => {
  const [createUserMutation] = useCreateUserMutation();
  const limit = 20;
  const [open, setOpen] = useState<boolean>(true);
  const [auctionStatus, setAuctionStatus] = useState<{
    closingSoon?: boolean;
    hasOffer?: boolean;
    isNew?: boolean;
    onChain?: boolean;
    chain?: Blockchain;
    sold?: boolean;
  }>({
    closingSoon: false,
    hasOffer: false,
    isNew: true,
    onChain: false,
    sold: false,
    chain: null,
  });
  const { bnbUsd, ethUsd, avaxUsd, busdUsd, maticUsd } = useUSDPrice();

  const { closingSoon, hasOffer, isNew, onChain, sold, chain } = auctionStatus;
  const [auctionsCursor, setAuctionsCursor] = useState<string>("");
  const [, setState] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const [filters, setFilters] = useState([]);
  const [price, setPrice] = useState({
    min: null,
    max: null,
  });

  const {
    auctions,
    error,
    loading,
    fetchMoreNfts,
    fetchMoreHasOffer,
    fetchMoreOnChain,
    fetchMoreSold,
  } = useNfts(limit, closingSoon, hasOffer, isNew, onChain, chain, sold);

  const [auctionsRender, setAuctionsRender] = useState<Auction[]>(null);

  const loadMore = () => {
    return fetchMoreNfts({
      variables: {
        limit,
        cursor: auctionsCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult.getAuctions?.length) {
          setCanLoadMore(false);
          if (prev.getAuctions?.length)
            return Object.assign({}, prev, {
              getAuctions: filterDuplicatesObj([
                ...auctions,
                ...prev.getAuctions,
              ]),
            });
          return prev;
        }

        fetchMoreResult.getAuctions.length &&
          setAuctionsCursor(
            () =>
              fetchMoreResult.getAuctions[
                fetchMoreResult.getAuctions.length - 1
              ]?.id
          );
        const combined = Object.assign({}, prev, {
          getAuctions: filterDuplicatesObj([
            ...auctions,
            ...fetchMoreResult.getAuctions,
          ]),
        });
        if (prev?.getAuctions?.length < limit) setCanLoadMore(false);
        return combined;
      },
    });
    // }
  };

  useEffect(() => {
    if (account && user === null) {
      createUserMutation({
        variables: {
          address: account,
        },
        refetchQueries: [
          {
            query: UserAddressQueryDocument,
            variables: {
              getUserAddress: account, // value for 'getUserByAddressAddress'
            },
          },
        ],
      });
    }

    if (!error && !loading && auctions) {
      setResultCount(auctions?.length);
      setAuctionsCursor(() => auctions[auctions?.length - 1]?.id);
      setAuctionsRender(auctions);
    }

    return () => {
      setState(true);
    };
  }, [user, account, auctions]);

  function handleSort(
    sortOption:
      | "ending_soon"
      | "most_favorite"
      | "most_viewed"
      | "oldest"
      | "recently_sold"
      | "closing_soon"
      | "recently_listed"
      | "price_high"
      | "price_low"
  ) {
    switch (sortOption) {
      case "ending_soon":
        setAuctionsRender(
          auctions.sort(
            (auction, auctionB) => +auction?.closedAt - +auctionB?.closedAt
          )
        );
        break;
      case "most_favorite":
        setAuctionsRender(
          auctions.sort(
            (auction, auctionB) => +auction?.likeCount - +auctionB?.likeCount
          )
        );
        break;
      case "most_viewed":
        setAuctionsRender(
          auctions.sort(
            (auction, auctionB) => +auction?.viewCount - +auctionB?.viewCount
          )
        );
        break;
      case "oldest":
        setAuctionsRender(() =>
          auctions.sort(
            (auction, auctionB) => +auctionB?.createdAt - +auction?.createdAt
          )
        );
        break;
      case "recently_sold":
        const _auctions = auctions
          .filter(({ state }) => state === AuctionState.Sold)
          .sort(
            (auctionA, auctionB) => +auctionA?.closedAt - +auctionB?.closedAt
          );
        setAuctionsRender(() =>
          filterDuplicatesObj([...(_auctions || []), ...(auctions || [])])
        );
        break;
      case "closing_soon":
        setAuctionsRender(() =>
          filterDuplicatesObj([
            ...(auctions
              .filter(({ state }) => state === AuctionState.InBid)
              .sort(
                (auctionA, auctionB) =>
                  +auctionA?.closedAt - +auctionB?.closedAt
              ) || []),
            ...(auctions || []),
          ])
        );
        break;
      case "recently_listed":
        setAuctionsRender(() =>
          auctions.sort(
            (auction, auctionB) => +auction?.createdAt - +auctionB?.createdAt
          )
        );
        break;
      case "price_high":
        setAuctionsRender(() =>
          auctions?.sort(
            (auction, auctionB) =>
              +auction?.closingValue - +auctionB?.closingValue ||
              +auction?.basePrice - +auctionB?.basePrice
          )
        );
        break;
      case "price_low":
        setAuctionsRender(() =>
          auctions?.sort(
            (auction, auctionB) =>
              +auctionB?.closingValue - +auction?.closingValue ||
              +auctionB?.basePrice - +auction?.basePrice
          )
        );

        break;
      default:
        setAuctionsRender(() => auctions);
        break;
    }
  }

  function handlePriceFilter() {
    setAuctionsRender(
      auctions?.filter((auction) => {
        if (auction?.closingValue) {
          return (
            +auction?.closingValue >= price.min &&
            +auction?.closingValue <= price.max
          );
        } else {
          return (
            +auction?.basePrice >= price.min && +auction?.basePrice <= price.max
          );
        }
      })
    );
  }

  const PopoverList: React.FC<{
    onHide: () => void;
  }> = ({ onHide }) => (
    <IonList>
      <IonListHeader>Sort By</IonListHeader>
      <IonItem button>Showcase</IonItem>
      <IonItem button>GitHub Repo</IonItem>
      <IonItem lines="none" detail={false} button onClick={onHide}>
        Close
      </IonItem>
    </IonList>
  );
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
  });

  const handleDismiseProps = () => {
    dismissPresentConfirm();
  };
  const handleDismiseSuccess = () => {
    dismissPresentSuccess();
  };

  const [presentConfirm, dismissPresentConfirm] = useIonModal(TConfirmModal, {
    close: handleDismiseProps,
  });

  const [presentSuccess, dismissPresentSuccess] = useIonModal(SuccessModal, {
    close: handleDismiseSuccess,
    type: "share",
  });

  const handleFilter = (val: string) => {
    let index = filters.findIndex((data) => data === val);
    if (index < 0) {
      setFilters([...filters, val]);
    } else {
      setFilters((old) => old.filter((data, i) => i !== index));
    }
  };

  const setPriceVal = (e: any) => {
    const { name, value } = e.target;
    setPrice((old) => ({ ...old, [name]: value }));
  };
  const handleScroll = () => {
    scrollHelper(loadMore, canLoadMore);
  };
  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll ?? undefined}>
      <Container>
        <div className="pool-p10">
          <Flex align="center">
            <FilterHeader justify="space-between" align="center">
              <Flex>
                <Filter />
                <Text size="20">Filter</Text>
              </Flex>
              <div onClick={() => setOpen(!open)}>
                {open ? <Cancel /> : <Plus />}
              </div>
            </FilterHeader>

            <FilterHeader className="mobile">
              <ButtonWhite
                width="110px"
                onClick={(e) => present({ event: e.nativeEvent })}
              >
                <Flex>
                  <Filter />
                  <Text size="20"> Filter</Text>
                </Flex>
              </ButtonWhite>
            </FilterHeader>

            <Flex justify="space-between" align="center" style={{ flex: 1 }}>
              <PCount>
                <Text size="20" padding="10" color="rgba(0, 0, 0, 0.5)">
                  {resultCount} results
                </Text>
              </PCount>
              <Flex>
                <ButtonWhite
                  width="150"
                  onClick={(e) => present({ event: e.nativeEvent })}
                >
                  Sort by <Spacer width={5} /> <DownAngle />
                </ButtonWhite>
              </Flex>
            </Flex>
          </Flex>
          <Body>
            <Flex>
              <FilterCon className={open ? "open" : ""}>
                <FilterItem title="Status">
                  <div>
                    <Flex style={{ flexWrap: "wrap" }}>
                      <SelectButton
                        onClick={() =>
                          setAuctionStatus({
                            closingSoon: false,
                            hasOffer: false,
                            isNew: true,
                            onChain: false,
                            sold: false,
                            chain: null,
                          })
                        }
                        className={
                          filters.filter((filter) => filter === "buyNow")
                            .length > 0
                            ? "selected"
                            : ""
                        }
                      >
                        New Auction
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          setAuctionStatus({
                            closingSoon: true,
                            hasOffer: false,
                            isNew: false,
                            onChain: false,
                            sold: false,
                            chain: null,
                          })
                        }
                        className={
                          filters.filter((filter) => filter === "onAuction")
                            .length > 0
                            ? "selected"
                            : ""
                        }
                      >
                        Closing Soon
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          setAuctionStatus({
                            closingSoon: false,
                            hasOffer: true,
                            isNew: false,
                            onChain: false,
                            sold: false,
                            chain: null,
                          })
                        }
                        className={
                          filters.filter((filter) => filter === "new").length >
                          0
                            ? "selected"
                            : ""
                        }
                      >
                        Has Offer(s)
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          setAuctionStatus({
                            closingSoon: false,
                            hasOffer: false,
                            isNew: false,
                            onChain: false,
                            sold: true,
                            chain: null,
                          })
                        }
                        className={
                          filters.filter((filter) => filter === "hasOffer")
                            .length > 0
                            ? "selected"
                            : ""
                        }
                      >
                        Completed
                      </SelectButton>
                    </Flex>
                  </div>
                </FilterItem>

                <FilterItem title="Price">
                  <div>
                    <Flex align="center">
                      <CInput
                        onChange={(e) => setPriceVal(e)}
                        value={price.min}
                        type="number"
                        name="min"
                        placeholder="Min"
                        inputMode="decimal"
                      />
                      <span style={{ padding: "10px" }}>to</span>
                      <CInput
                        onChange={(e) => setPriceVal(e)}
                        value={price.max}
                        type="number"
                        name="max"
                        placeholder="Max"
                        inputMode="decimal"
                      />
                    </Flex>
                    <Spacer />
                    <IonButton
                      onClick={handlePriceFilter}
                      disabled={price.min || price.max ? false : true}
                    >
                      Apply
                    </IonButton>
                  </div>
                </FilterItem>

                <FilterItem title="Chains">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setAuctionStatus({
                        chain: Blockchain.Bsc,
                      })
                    }
                  >
                    Binance Smart Chain
                  </div>
                </FilterItem>

                {/* <ButtonWhite onClick={() => presentConfirm()}>
                  <div style={{ color: "red" }}>Click Me</div>{" "}
                </ButtonWhite>
                <ButtonWhite onClick={() => presentSuccess()}>
                  <div style={{ color: "red" }}>Click Me</div>{" "}
                </ButtonWhite> */}
              </FilterCon>
              <div style={{ flex: 1, padding: " 0px 10px 0px 0px" }}>
                <GridComponent>
                  {!auctionsRender ? (
                    Array.from(Array(8)).map((_, i) => <Loading key={i} />)
                  ) : !auctionsRender?.length ? (
                    <p>Empty</p>
                  ) : (
                    auctionsRender?.length &&
                    auctionsRender?.map((auction: any, i: number) => (
                      <NftCard key={i} {...{ auction }} />
                    ))
                  )}
                </GridComponent>
              </div>
            </Flex>
          </Body>
        </div>
        <Footer />
      </Container>
    </IonContent>
  );
};

export default Nfts;
