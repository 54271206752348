import { Flex, Text } from "components";
import React, { useRef } from "react";
import DonutChart from "react-donut-chart";
// / <reference path='./react-donut-chart.d.ts' />
import styled from "styled-components";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface CardInterface {}

export const CardContainer = styled.div`
  width: 100%;
  background: #292929;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  min-width: 325px;
  height: 419px;
  
  
  .donut{
    width:300px;
    height:100%;

    .donut-legend-item-label{
      fill: white;
      font-size:18px;
    }
    .donut-innertext-label{
      fill: white;
      font-size:16px;
    }
    .donut-innertext-value{
      font-size:72px;
      fill: white;
    }
      

    }
  }

  div {
    color: #fff;
  }
  
`;

const Legend = styled.div`
  padding: 10px;
  /* text-align:center; */
  margin: auto;

  div {
    margin: 3px 0px;
  }
`;

const Chart = styled.div`
  flex: 1;
  width: 100%;
  max-width: 300px;
  text-align: center;

  .chartContainer {
    max-width: 300px;

    .highcharts-container {
      width: 100%;
    }

    svg {
      width: 299px !important;
      transform: scale(1.3);
      top: -58px;
      position: relative;

      .highcharts-background {
        fill: transparent;
      }
    }
  }
`;

const BtnC = styled.button`
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  background: transparent;
  color: white;
`;

const options: Highcharts.Options = {
  title: {
    text: "My chart",
  },
  series: [
    {
      type: "pie",
      data: [1, 2, 3],
    },
  ],
};

const EarningsCard: React.FC<CardInterface> = ({}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <CardContainer>
      <Text size="18" weight="600">
        Earnings
      </Text>
      <Flex direction="column" style={{ height: "100%" }}>
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
          <Text>See how you performed in the last </Text>
          <div>
            <BtnC>
              Week{" "}
              <svg
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1663 1.66602L5.49967 6.33268L0.833008 1.66602"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </BtnC>
          </div>
        </Flex>

        <Chart>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
            containerProps={{ className: "chartContainer" }}
          />
          {/* <DonutChart
            className="donut"
            data={[
              {
                label: "Option1 a very long label really... ",
                value: 25,
              },
              {
                label: "Option2",
                value: 70,
              },
              {
                label: "Option3",
                value: 5,
              },
            ]}
          /> */}
        </Chart>

        {/* <Legend>
          <Text>Content sales</Text>
          <Text>Content referrer</Text>
          <Text>Content sales</Text>
        </Legend> */}
      </Flex>
    </CardContainer>
  );
};

export default EarningsCard;
