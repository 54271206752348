import { web3Provider } from "connector/web3modal";
import Web3 from "web3";

export const web3 = async () => {
  try {
    let web3 = await web3Provider();
    return web3 as Web3;
  } catch (error) {
    console.log(error, "err");
  }
};
