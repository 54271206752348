import { IonContent, IonLabel, IonTextarea, useIonModal } from "@ionic/react";
import { Avatar, Flex, Footer, GlobalLoading, Spacer, Text } from "components";
import { ButtonPrimary, ButtonWhite } from "components/Button";
import AlertModal from "components/Modal/Alert";
import { Camera, Twitter } from "components/SvgIcons";
import {
  useCreateUserMutation,
  UserAddressQueryDocument,
  UserAddressQueryQuery,
  useUpdateUserMutation,
} from "generated/graphql-frontend";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ISocialFull, IUserReceiver } from "types/nft-user-types";
import { twitterLogin } from "utils/firebase";
import { formatIpfsUrl, uploadFile } from "utils/ipfsUrlHelper";
import pinataHelper from "utils/pinataHelper";
import { BannerCon } from "./Profile";
interface UserFormData {
  name?: string;
  email?: string;
  username?: string;
  instagram?: string;
  twitter?: string;
  telegram?: string;
  linkedIn?: string;
  bio?: string;
  website?: string;
}
const Container = styled.div`
  min-height: 100%;
  overflow: auto;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
`;
const ICard = styled.div`
  height: auto;
  max-width: 450px;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const TextArea = styled(IonTextarea)`
  width: 100% !important;
  border-bottom: 1px solid #ccc;
  --background: rgba(106, 100, 255, 0.05);
`;
const CardBody = styled.div``;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const IInput = styled.input`
  /* border-bottom: 1px solid #ccc; */
  background: rgba(106, 100, 255, 0.05);
  padding: 15px 10px;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 10px 0px;
`;

const EditButton = styled.button`
  border: 1px solid #ffffff;
  outline: none;
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  background: transparent;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const EditButton2 = styled(EditButton)`
  right: auto;
  bottom: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const UpdateProfile: React.FC<IUserReceiver> = ({ user, social, account }) => {
  const [avtFile, setAvtFile] = useState<File>(null);
  const [bnrFile, setBnrFile] = useState<File>(null);
  const [bnrUrl, setBnrUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleDismise = () => {
    dismissPresentError();
  };
  const handleDismiseSuccess = async () => {
    dismissPresentSuccess();
    history.push("/me");
  };
  const [presentSuccess, dismissPresentSuccess] = useIonModal(AlertModal, {
    close: handleDismiseSuccess,
    type: "success",
    title: "Profile Updated",
    description: "Your Profile has been updated successfully",
  });

  let userData = {
    name: "",
    email: "",
    username: "",
    telegram: "",
    twitter: "",
    instagram: "",
    linkedIn: "",
    bio: "",
    website: "",
  };
  const [
    { email, website, instagram, telegram, linkedIn, bio },
    setUserProfile,
  ] = useState<UserFormData>(userData);
  const [twitterProfile, setTwitterProfile] = useState({
    name: "",
    username: "",
    twitter: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const [presentError, dismissPresentError] = useIonModal(AlertModal, {
    close: handleDismise,
    type: "error",
    title: "Validation Error",
    description: errorMessage ?? "Profile Update Failed",
  });

  const [createUserMutation] = useCreateUserMutation();
  const [updateUserMutation, { error }] = useUpdateUserMutation({
    onError: (err) => {
      setErrorMessage(err.message);
      presentError();
      setLoading(false);
    },
  });

  const [btnDisabled, setBtnDisabled] = useState(true);

  const [userMetadata, setUserMetadata] = useState<ISocialFull>({
    imageHash: "",
    bannerHash: "",
    twitter: "",
    instagram: "",
    telegram: "",
    linkedIn: "",
    bio: "",
    email: "",
    website: "",
    name: "",
  });

  const getProfile = () => {
    let _email, _username, _name;
    if (user?.email) _email = user.email;
    if (user?.username) _username = user.username;
    if (user?.name) _name = user.name;

    setUserMetadata({
      ...social,
      username: _username,
      email: _email,
      name: _name,
    });
  };

  useEffect(() => {
    if (account && !user) {
      createUserMutation({
        variables: {
          address: account,
        },
      });
      getProfile();
    } else getProfile();

    // eslint-disable-next-line
  }, [user, account, social, error]);

  const handleTwitterLogin = async () => {
    const { username, name } = await twitterLogin();
    setTwitterProfile({ username, name, twitter: username });
    setBtnDisabled(
      () =>
        (!username?.length || !user?.username?.length) &&
        (!name?.length || !user?.name?.length) &&
        !bio?.length &&
        !email?.length
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfile((prev: any) => {
      return { ...prev, [e.target.name]: e.target.value };
    });

    setBtnDisabled(
      () =>
        (!twitterProfile?.username?.length || !user?.username?.length) &&
        (!twitterProfile?.name?.length || !user?.name?.length) &&
        (!email?.length || !user?.email?.length) &&
        !bio?.length
    );
  };

  const handleUpload = async (_file: File) => {
    const _data = await uploadFile(_file);
    return _data.data.IpfsHash;
  };
  const removeAtchar = (username: string) => {
    if (username?.length)
      return username?.startsWith("@") ? username?.substring(1) : username;
  };
  const handleSubmit = async () => {
    let bnrHash = "";
    let avtHash = "";
    setLoading(true);

    if (user) {
      if (bnrFile) bnrHash = await handleUpload(bnrFile);

      if (avtFile) avtHash = await handleUpload(avtFile);
      const { username, twitter, name } = twitterProfile;
      const body = {
        telegram: telegram.length
          ? removeAtchar(telegram)
          : userMetadata.telegram,
        linkedIn: linkedIn.length ? linkedIn : userMetadata.linkedIn,
        twitter: twitter.length ? twitter : userMetadata.twitter,
        instagram: instagram.length
          ? removeAtchar(instagram)
          : userMetadata.instagram,
        bio: bio.length ? bio : userMetadata.bio,
        imageHash: avtHash.length
          ? formatIpfsUrl(avtHash)
          : userMetadata.imageHash,
        bannerHash: bnrHash.length
          ? formatIpfsUrl(bnrHash)
          : userMetadata?.bannerHash,
      };
      const ipfsRes = await pinataHelper.pin_json(
        body,
        username.length ? username : "Coterie_user",
        account
      );

      await updateUserMutation({
        variables: {
          address: account,
          username: username.length ? username : user?.username,
          name: name.length ? name : user?.name,
          email: email?.length ? email : user?.email,
          profileHash: ipfsRes?.IpfsHash?.length
            ? formatIpfsUrl(ipfsRes?.IpfsHash)
            : user?.profileHash,
        },

        update: (cache, { data: { updateUser } }) => {
          try {
            const current = cache.readQuery<UserAddressQueryQuery>({
              query: UserAddressQueryDocument,
              variables: { getUserAddress: account },
            });

            const data = Object.assign(
              {},
              updateUser,
              current.getUserByAddress,
              {
                username: username.length ? username : user?.username,
                name: name.length ? name : user?.name,
                email: email?.length ? email : user?.email,
                profileHash: ipfsRes?.IpfsHash?.length
                  ? formatIpfsUrl(ipfsRes?.IpfsHash)
                  : user?.profileHash,
              }
            );

            cache.writeQuery<UserAddressQueryQuery>({
              query: UserAddressQueryDocument,
              variables: {
                getUserAddress: account,
              },
              data: {
                getUserByAddress: data,
                __typename: "Query",
              },
            });
            setLoading(false);
            presentSuccess();
          } catch (e) {
            console.error(e);
            setLoading(false);
            presentError();
          }
        },
      });
    }
    setUserProfile(userData);
    setAvtFile(null);
    setBnrFile(null);
    setTwitterProfile({
      name: "",
      username: "",
      twitter: "",
    });
  };

  const avatarMgr = () => document.getElementById("avatar").click();

  const getAvtFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      const fs = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(fs);
      reader.onloadend = () => {
        const userAvt: any = document.getElementById("user_avatar");
        if (fs) {
          userAvt.src = reader.result;
          setAvtFile(fs);
        }
      };
    }
  };
  const bannerMgr = () => document.getElementById("banner").click();

  const getBnrFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      const fs = e.target.files[0];
      setBnrFile(fs);
      const reader = new FileReader();
      reader.readAsDataURL(fs);
      reader.onloadend = () => {
        if (fs) {
          setBnrUrl(reader.result);
        }
      };
    }
  };
  return (
    <IonContent>
      <Container>
        <div className="pool-p10">
          {loading && <GlobalLoading type="Updating..." />}
          <ICard>
            <BannerCon
              overlay={true}
              bgImg={
                bnrUrl
                  ? bnrUrl
                  : social?.bannerHash && formatIpfsUrl(social?.bannerHash)
              }
            >
              <div className="img">
                <Avatar size="100" style={{ background: "#000" }}>
                  <img
                    id="user_avatar"
                    style={{ opacity: 0.5 }}
                    src={
                      social?.imageHash?.length
                        ? formatIpfsUrl(social?.imageHash)
                        : `https://avatars.dicebear.com/api/male/${
                            account ?? "account"
                          }.svg?background=%236d64ff&&mood[]=happy`
                    }
                    alt="user"
                  />

                  <input
                    onChange={(e: any) => getAvtFile(e)}
                    accept="image/*"
                    style={{ display: "none" }}
                    name="avatar"
                    id="avatar"
                    type="file"
                  />
                </Avatar>
                <EditButton2 onClick={avatarMgr}>
                  <Camera />
                  <Spacer width={5} />
                  <Text color="#fff">Edit</Text>
                </EditButton2>
              </div>

              <EditButton onClick={bannerMgr}>
                <Camera />
                <Spacer width={5} />
                <input
                  accept="image/*"
                  onChange={(e: any) => getBnrFile(e)}
                  style={{ display: "none" }}
                  name="banner"
                  id="banner"
                  type="file"
                />
                <Text color="#fff">Edit</Text>
              </EditButton>
            </BannerCon>

            <Spacer height={50} />
            <CardBody>
              <Text size="32" color="#333333">
                Edit Profile
              </Text>
              <br />

              {/* Basic Section */}
              <section>
                <Flex>
                  <Text size="16" weight="500" color="#333333">
                    Basic Information
                  </Text>
                  <Spacer width={5} />
                  {/* <Text
                size="16"
                weight="500"
                color="#333333"
                style={{ opacity: 0.5 }}
              >
                Fields are required
              </Text> */}
                </Flex>
                <br />
                <CardItem>
                  <ButtonWhite
                    disabled={twitterProfile?.name?.length > 0}
                    onClick={() => handleTwitterLogin()}
                  >
                    {/* <IonIcon size="large" src="/svgs/twitter.svg" /> */}
                    <Twitter />
                    <Spacer width={5} />
                    <Text>
                      {userMetadata?.twitter?.length
                        ? `Rejoin with Twitter`
                        : `Join with Twitter`}
                    </Text>
                  </ButtonWhite>
                </CardItem>

                <CardItem>
                  <IonLabel>Email:</IonLabel>
                  <IInput
                    onChange={(e: any) => handleChange(e)}
                    value={email}
                    placeholder={
                      user?.email?.length ? user?.email : "my_email@mail.com"
                    }
                    name="email"
                  />
                </CardItem>

                <CardItem>
                  <IonLabel>About me:</IonLabel>
                  <TextArea
                    rows={9}
                    value={bio}
                    name="bio"
                    placeholder={
                      userMetadata?.bio?.length
                        ? userMetadata?.bio
                        : "Introduce yourself to Coterie community."
                    }
                    onIonChange={(e: any) => handleChange(e)}
                  ></TextArea>
                </CardItem>
              </section>

              {/* Social Media Section */}

              <br />
              <section>
                <Text size="16" weight="500" color="#333333">
                  Others
                </Text>
                <br />
                <CardItem>
                  <IonLabel>Website:</IonLabel>
                  <IInput
                    onChange={(e: any) => handleChange(e)}
                    value={website}
                    placeholder={
                      userMetadata?.website?.length
                        ? `@${userMetadata?.website}`
                        : "https://mywebsite.com"
                    }
                    name="website"
                  />
                </CardItem>

                <CardItem>
                  <IonLabel>Instagram username:</IonLabel>
                  <IInput
                    onChange={(e: any) => handleChange(e)}
                    value={instagram}
                    placeholder={
                      userMetadata?.instagram?.length
                        ? userMetadata?.instagram
                        : "@instagram_username"
                    }
                    name="instagram"
                  />
                </CardItem>
                <CardItem>
                  <IonLabel>Telegram username:</IonLabel>
                  <IInput
                    onChange={(e: any) => handleChange(e)}
                    value={telegram}
                    placeholder={
                      userMetadata?.telegram?.length
                        ? userMetadata?.telegram
                        : "@telegram_username"
                    }
                    name="telegram"
                  />
                </CardItem>
                <CardItem>
                  <IonLabel>LinkedIn URL:</IonLabel>
                  <IInput
                    onChange={(e: any) => handleChange(e)}
                    value={linkedIn}
                    placeholder={
                      userMetadata?.linkedIn?.length
                        ? userMetadata?.linkedIn
                        : "https://linkedin.com/profile_url"
                    }
                    name="linkedIn"
                  />
                </CardItem>
              </section>
              <br />
              <ButtonPrimary
                disabled={btnDisabled}
                shape="round"
                fill="solid"
                onClick={handleSubmit}
              >
                Update Profile
              </ButtonPrimary>
            </CardBody>
          </ICard>
        </div>
        <div style={{ marginTop: "70px" }}>
          <Footer />
        </div>
      </Container>
    </IonContent>
  );
};

export default UpdateProfile;
