import React, { FC } from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonText,
  IonIcon,
} from "@ionic/react";
import styled from "styled-components";
import { timeOutline } from "ionicons/icons";
import { CircularProgressbar } from "react-circular-progressbar";

// import Moment from "react-moment";

interface AuctionProps {
  tokenName: string | undefined;
  roi: number | undefined;
  rate: number | undefined;
  auctionAddress?: string | undefined;
  amountRaised: number | undefined;
  salesStarted?: boolean;
  salesCompleted?: boolean;
  imageURL?: string;
  projectURL?: string;
  openPeriod?: string | undefined;
}

export const AuctionCard = styled(IonCard)`
  background: var(--ion-color-card);
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  height: 340px;
  /*padding:20px;
  */
  margin: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  min-width: calc(25% - 20px);
  width: calc(25% - 20px);
  position: relative;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    box-shadow: 0 30px 30px rgba(154, 160, 185, 0.09),
      0 30px 40px rgba(166, 173, 201, 0.2);
  }

  @media (max-width: 640px) {
    min-width: 100%;
  }
`;
const CardImg = styled(IonImg)`
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 100%;
  height: 170px;
`;
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Status = styled(IonButton)``;

const Card: FC<AuctionProps> = ({
  tokenName,
  roi,
  rate,
  amountRaised,
  auctionAddress,
  projectURL,
  salesCompleted,
  salesStarted,
  imageURL,
  openPeriod,
}) => {
  const saleState = () =>
    salesCompleted ? (
      <Status size='small' color='success'>
        Completed
      </Status>
    ) : !salesCompleted && salesStarted ? (
      <Status size='small' color='success'>
        Active
      </Status>
    ) : (
      <Status size='small' color='success'>
        Inactive
      </Status>
    );

  return (
    <AuctionCard
      routerLink={`/auction/${auctionAddress}`}
      routerDirection='forward'>
      <CardImg
        src={imageURL && imageURL.length > 0 ? imageURL : "default.png"}
      />
      <IonCardHeader>
        <IonCardSubtitle>
          <IonText color='cardtext'>
            <IonIcon icon={timeOutline} />{" "}
          </IonText>
        </IonCardSubtitle>
        <Details>
          <Col>
            <IonCardTitle>
              <IonText color='cardtext'> {tokenName} </IonText>
            </IonCardTitle>
            <IonText color='danger'>
              <a href='/'>
                {auctionAddress &&
                  auctionAddress.substr(0, 5) +
                    "..." +
                    auctionAddress.substr(-5)}{" "}
              </a>
            </IonText>
            <br />
          </Col>
          <div style={{ width: "50px" }}>
            <CircularProgressbar
              value={amountRaised || 0}
              maxValue={1}
              text={`${amountRaised || 0}%`}
            />
          </div>
        </Details>
        <Details>
          <Col>
            <IonText color='cardtext'> ROI : {roi} </IonText>
            <IonText color='cardtext'>Selling Rate : {rate} </IonText>
          </Col>
          {saleState()}
        </Details>
      </IonCardHeader>
    </AuctionCard>
  );
};

export default Card;
