import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { Flex, Spacer, Text } from "components";
import { ButtonPrimary } from "components/Button";
import { ConfirmCheck, SuccessCheck, Twitter } from "components/SvgIcons";
import { close } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
const Container = styled(IonCard)`
  max-width: 500px;
  height: auto;
  border-radius: 5px;
  color: #333;
  padding: 0 20px;
  overflow-y: auto;
  margin: auto;
`;

const Close = styled.div`
  padding: 15px 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
`;

const ButtonFlex = styled(Flex)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px;
`;

const Contain = styled.div`
  padding: 10px;
  text-align: center;
`;

interface PropertyInt {
  close?: () => void;
  primaryFunc?: () => void;
  secondaryFunc?: () => void;
  primaryText?: string;
  secondaryText?: string;
  type: "success" | "generate" | "share";
}

const RenderSuccess = (close: any) => (
  <Container>
    <Contain>
      <Spacer height={30} />
      <Text size="32" weight="500">
        successful
      </Text>
      <Spacer height={30} />
      <SuccessCheck />
      <Spacer />
      <Text size="14">
        Your token has been updated successfully, you can now view it in your
        wallet
      </Text>

      <Spacer height={30} />
      <ButtonPrimary onClick={close}>Done</ButtonPrimary>
    </Contain>
  </Container>
);

const RenderGenerate = (callback: any) => (
  <Container>
    <Contain>
      <Spacer height={30} />

      <ConfirmCheck />
      <Spacer />

      <Text size="32" weight="500">
        Congratulations you are now an early adopter
      </Text>
      <Spacer height={30} />
      <Text size="14">
        Click the link the link below to generate a unique code
      </Text>

      <Spacer height={30} />
      <ButtonPrimary onClick={callback}>Genenerate Unique Token</ButtonPrimary>
    </Contain>
  </Container>
);

const RenderShare = (
  _close: () => void,
  secondaryFunc: () => void,
  primaryText?: string,
  secondaryText?: string
) => (
  <Container>
    <Contain>
      <div
        style={{
          /* padding: "7px", */ display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IonButton onClick={_close} color="dark" fill="clear" size="small">
          <IonIcon icon={close} />
        </IonButton>
      </div>
      <Spacer height={30} />

      <ConfirmCheck />
      <Spacer />

      <Text size="32" weight="500">
        {primaryText ?? `Congratulations you are now an early adopter`}
      </Text>
      <Spacer height={30} />
      <Text size="14">
        {secondaryText ??
          `Click the link the link below to generate a unique code`}
      </Text>

      <Spacer height={30} />
      <Flex justify="center">
        <ButtonFlex
          style={{ cursor: "pointer" }}
          onClick={secondaryFunc}
          align="center"
        >
          <Twitter />
          <Spacer width={5} />
          <Text>Share on Twitter</Text>
        </ButtonFlex>

        {/* <ButtonFlex onClick={secondaryFunc} align="center">
          <Instagram />
          <Spacer width={5} />
          <Text>Copy to Share</Text>
        </ButtonFlex> */}
      </Flex>
      <Spacer height={30} />
    </Contain>
  </Container>
);

const MessageModal: React.FC<PropertyInt> = ({
  primaryFunc,
  secondaryFunc,
  close,
  primaryText,
  secondaryText,
  type,
}: PropertyInt) => {
  return (
    <>
      {type === "success" && RenderSuccess(close)}
      {type === "share" &&
        RenderShare(close, primaryFunc, primaryText, secondaryText)}
      {type === "generate" && RenderGenerate(primaryFunc)}
    </>
  );
};

export default MessageModal;
