import { IonIcon } from "@ionic/react";
import Api from "api";
import { Avatar, Flex, Spacer, Text } from "components";
import { ButtonWhite } from "components/Button";
import { openOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { INFTMetadata, IWalletNFT } from "types/nft-user-types";
import { formatIpfsUrl, getMimeType } from "utils/ipfsUrlHelper";
import { stringToCapitalized, truncate } from "utils/text-help";
// https://ipfs.io/ipfs/QmPMzRbSmpYdhNfoLatxY4JBwTBD1kCjSdrr86rxYUdRXk

export const CardContainer = styled.div`
  width: 310px;
  background: var(--ion-color-card);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 20px 10px;
  overflow: hidden;
  filter: drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.08));

  & .p {
    line-height: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    margin: 20px 0px;
  }
`;

const FlexCommon = `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
`;

const CardHeader = styled.div`
  ${FlexCommon};
  padding: 10px;
`;
const CardFooter = styled.div`
  /* ${FlexCommon}; */
  padding: 10px;
`;

const ImageCon = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & video {
    width: 100%;
    height: 100%;
    margin: auto;
    transition: filter 0.3s ease-in-out 0s;
    cursor: zoom-in;
  }
`;

interface CardInterface {
  walletNft?: IWalletNFT;
}

const WalletCard: React.FC<CardInterface> = ({ walletNft }) => {
  const history = useHistory();
  const [metadata, setMetadata] = useState<INFTMetadata>(null);
  const [mimeType, setMimeType] = useState<string>("image");
  const handleClick = () => {
    if (walletNft)
      history.push(`/nfts/token?action=list`, {
        ...metadata,
        tokenImageUri: walletNft?.tokenUri,
        tokenAddress: walletNft?.contractAddress,
        tokenId: walletNft?.tokenId,
        tokenUri: walletNft.tokenUri,
      });
  };
  const [, setState] = useState({});
  useEffect(() => {
    let isCalled = false;
    async function setMeta() {
      try {
        if (walletNft) {
          const meta = await Api.nft.ipfs(formatIpfsUrl(walletNft?.tokenUri));
          const _data: any = meta?.data;
          setMetadata(meta?.data);
          const mime = await getMimeType(formatIpfsUrl(_data?.image));
          setMimeType(mime);
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
    !isCalled && setMeta();

    return () => {
      isCalled = true;
      setState({});
    };
  }, [walletNft]);

  return (
    <>
      {metadata && (
        <CardContainer>
          <ImageCon style={{ height: "250px" }}>
            {mimeType === "image" && (
              <img src={formatIpfsUrl(metadata?.image)} alt={metadata?.name} />
            )}
            {/* {mimeType === "model" && (
              <ModelViewer src={formatIpfsUrl(metadata?.image)} />
            )} */}
            {mimeType === "video" && (
              <video controls autoPlay poster="" loop playsInline>
                <source src={formatIpfsUrl(metadata?.image)} />
              </video>
            )}
          </ImageCon>
          <Spacer height={5} />
          <CardFooter>
            <Flex justify="space-between" style={{ flex: 1 }} align="center">
              <Text weight="bold" size="16">
                {stringToCapitalized(metadata?.name)}
              </Text>
              <Spacer height={5} />
              <Flex>
                <Text weight="bold" size="16">
                  BSC
                </Text>
                <Spacer width={7} />
                <Avatar size="20">
                  <img src="/assets/bsc.png" alt="network" />
                </Avatar>
              </Flex>
            </Flex>
            <br />
            <Text>{metadata && truncate(metadata?.description, 140)}</Text>
            <Flex justify="space-between" style={{ flex: 1 }} align="center">
              <Text /* style={{ cursor: "pointer" }} */ weight="bold" size="16">
                <a
                  href={`https://testnet.bscscan.com/address/${walletNft?.contractAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonIcon color="dark" size="large" icon={openOutline} />
                </a>
              </Text>
              <div>
                <ButtonWhite onClick={handleClick}>List Token</ButtonWhite>
              </div>
            </Flex>
            {/* </Flex> */}
          </CardFooter>
        </CardContainer>
      )}
    </>
  );
};

export default WalletCard;
