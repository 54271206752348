import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RefreshContextProvider } from "./contexts/RefreshContext";
import "./index.css";
import MetamaskProvider from "./provider/metaMaskProvider";
import store from "./store";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.length &&
    graphQLErrors.map(({ message, path }) => {
      console.log(`Graphql error ${message} at location ${path}`);
    });
});

const link = from([
  errorLink,
  new HttpLink({
    uri:
      window.location.origin === "https://coteriefinance.org"
        ? process.env.REACT_APP_MAIN_API
        : process.env.REACT_APP_TEST_API,
  }),
]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

function getLibrary(provider: any) {
  return new Web3Provider(provider);
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RefreshContextProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Web3ReactProvider getLibrary={getLibrary}>
              <MetamaskProvider>
                <App />
              </MetamaskProvider>
            </Web3ReactProvider>
          </BrowserRouter>
        </ApolloProvider>
      </RefreshContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
