import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
// import { ARCHIVED_NODE } from 'config/constants/endpoints'
// import getRpcUrl from './getRpcUrl'

// const RPC_URL = getRpcUrl()
const RPC_URL ="https://data-seed-prebsc-1-s1.binance.org:8545/"

const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions)
const web3NoAccount = new Web3(httpProvider)

// const archivedHttpProvider = new Web3.providers.HttpProvider(ARCHIVED_NODE, { timeout: 10000 } as HttpProviderOptions)
// export const web3WithArchivedNodeProvider = new Web3(archivedHttpProvider)

export default web3NoAccount