import { Blockchain } from "generated/graphql-frontend";

const tokens = {
  coterie: {
    symbol: "DLF",
    address: {
      bsc: "",
      test: "0x3F1156782304a46b349A1E86DE50b5a320A1Ca60",
      matic: "",
      avax: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },
  coterie_gov: {
    symbol: "DLFG",
    address: {
      bsc: "",
      test: "0x69C491Ff793508216cD9858D5ceF799f290f4EE9",
      matic: "",
      avax: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },

  //not compatible because of decimal
  usdt: {
    symbol: "USDT",
    address: {
      // bsc: "0x55d398326f99059fF775485246999027B3197955",
      bsc: "0x6EF5A91f40AcFDE1425bBCB4c91F3d1ae18763C8",
      test: "0x6EF5A91f40AcFDE1425bBCB4c91F3d1ae18763C8",
      matic: "",
      avax: "",
      eth: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },
  bnb: {
    symbol: "BNB",
    address: {
      bsc: "0x0000000000000000000000000000000000000000",
      test: "0x0000000000000000000000000000000000000000",
      matic: "0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3",
      avax: "0x264c1383EA520f73dd837F915ef3a732e204a493",
      eth: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },
  eth: {
    symbol: "ETH",
    address: {
      // bsc: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      bsc: "0xE872167664d7412Db01b1456e2D5777f7489132D",
      test: "0xE872167664d7412Db01b1456e2D5777f7489132D",
      matic: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      avax: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      eth: "0x0000000000000000000000000000000000000000",
    },
    decimals: 18,
    projectLink: "https://ethereum.org/",
  },
  avax: {
    symbol: "AVAX",
    address: {
      bsc: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
      test: "0xE872167664d7412Db01b1456e2D5777f7489132D",
      matic: "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
      avax: "0x0000000000000000000000000000000000000000",
      eth: "",
    },
    decimals: 18,
    projectLink: "https://www.avax.network/",
  },

  matic: {
    symbol: "MATIC",
    address: {
      bsc: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
      test: "0xE872167664d7412Db01b1456e2D5777f7489132D",
      matic: "0x0000000000000000000000000000000000000000",
      avax: "",
      eth: "",
    },
    decimals: 18,
    projectLink: "https://polygon.technology/",
  },
  basic: {
    symbol: "BASIC",
    address: {
      bsc: "",
      test: "0x6f310344AfBe3A1360E6D23A676C27097a407302",
      matic: "",
      eth: "",
      avax: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },
  busd: {
    symbol: "BUSD",
    address: {
      eth: "",
      // bsc: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      bsc: "0xFEc6F3F07F28F4C87D564ae40e08e145C4B4E99B",
      test: "0xFEc6F3F07F28F4C87D564ae40e08e145C4B4E99B",
      matic: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
      avax: "0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98",
    },
    decimals: 18,
    projectLink: "https://www.paxos.com/busd/",
  },
  wbnb: {
    symbol: "wBNB",
    address: {
      bsc: "",
      test: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      matic: "",
      eth: "",
      avax: "",
    },
    decimals: 18,
    projectLink: "https://coterie.finance/",
  },
};

export default tokens;

export const getTokenAddress = (
  token_name: "avax" | "bnb" | "busd" | "usdt" | "matic" | "eth",
  network: Blockchain | "test"
) => {
  return tokens[token_name].address[network];
};

// ["0xFEc6F3F07F28F4C87D564ae40e08e145C4B4E99B", "0xE872167664d7412Db01b1456e2D5777f7489132D", "0x6EF5A91f40AcFDE1425bBCB4c91F3d1ae18763C8", "0x69C491Ff793508216cD9858D5ceF799f290f4EE9", "0x3F1156782304a46b349A1E86DE50b5a320A1Ca60"]
