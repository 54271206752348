import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  useIonModal,
} from "@ionic/react";
import { Avatar, Flex, Footer, GridComponent, Spacer, Text } from "components";
import { ButtonWhite } from "components/Button";
import { NftCard } from "components/Card";
import NftUser from "components/Card/NftUser";
import WalletCard from "components/Card/NftWallet";
import AlertModal from "components/Modal/Alert";
import { Pencel, Twitter } from "components/SvgIcons";
import { INSTAGRAM_URL, LINKEDIN_URL, TELEGRAM_URL, TWITTER_URL } from "config";
import {
  CheckIsFollowingDocument,
  Status,
  useCheckIsFollowingLazyQuery,
  useGetUserFollowersLazyQuery,
  useGetUserFollowingLazyQuery,
  User,
  UserAddressQueryDocument,
  useRequestMinterAccessMutation,
  useUpdateUserFollowingMutation,
  useUserIdQueryLazyQuery,
} from "generated/graphql-frontend";
import { UserType } from "generated/types";
import { web3 } from "hooks/useWeb3Provider";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useMoralisTokens,
  useParamsQuery,
  useUserAuctionCollected,
  useUserAuctionsOnsale,
  useUserNftCreated,
  useUserNFTMinted,
  useUserRequestsReceived,
  useUserRequestsSent,
} from "store/hooks";
import styled from "styled-components";
import { ISocial, ISocialFull, IUserReceiver } from "types/nft-user-types";
import { formatIsoToMY } from "utils/conv";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import {
  calculateProfileStrength,
  extract6Follower,
  filterDuplicatesObj,
  getUserSocial,
  scrollHelper,
} from "utils/nft-user-helper";
import { stringToCapitalized, truncate } from "utils/text-help";
import Background from "./banner.png";
import { EarningView, PeopleView } from "./ProfileViews";

const Container = styled.div`
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
`;
export const BannerCon = styled.div<{ overlay?: boolean; bgImg?: any }>`
  height: 130px;
  background-image: ${({ overlay, bgImg }) =>
    overlay
      ? "linear-gradient(45deg, #00000080, #00000080) ,url(" +
        `${bgImg ? bgImg : Background}` +
        ")"
      : "url(" + `${bgImg ? bgImg : Background}` + ")"};
  /* background: #464c4c; */
  width: 100%;
  /* height: 100%; */
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  .img {
    position: absolute;
    top: 80px;
    left: 50px;
    @media (max-width: 640px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const FilterCon = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  /* background: #f9f9f9; */
  border-radius: 3px;
  transition: width 0.3s;
  display: none;
  /* height: 380px; */
  /* overflow-y: scroll; */
  background: #fcfcff;
  flex-basis: 300px;
  width: 300px;
  padding: 10px;
  display: block;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const FollowedByContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
`;
const FollowedByAvatars = styled.div`
  display: inline-block;
`;
const FollowedByAvatar = styled(IonAvatar)`
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 2px solid #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &.avatar:not(:first-child) {
    margin-left: -15px;
  }
  &.avatar img {
    width: 100%;
    display: block;
  }
`;

const TabFlex = styled(Flex)`
  @media (max-width: 640px) {
    width: 100%;
    overflow-x: auto;
    border-bottom: 2px solid #e1e0ff;
  }
`;

const TabText = styled(Text)`
  padding: 15px 30px;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
  &.active {
    background: rgba(106, 100, 255, 0.2);
  }
  &:hover {
    background: rgba(106, 100, 255, 0.2);
  }
  @media (max-width: 640px) {
    padding: 10px;
    font-size: 12px;
    white-space: nowrap;
  }
`;

const BodyContainer = styled.div`
  padding: 15px;
`;

const Range = styled.div<{ width?: string }>`
  height: 5px;
  background: #6a64ff;
  border-radius: 3px;
  width: ${({ width }) => (width ? width + "%" : "0%")};
`;

const RangeCon = styled.div`
  height: 5px;
  background: rgba(106, 100, 255, 0.5);
  margin: 20px 0px;
  border-radius: 3px;
`;

const FeedCon = styled.div`
  padding: 10px 20px;
  @media (max-width: 640px) {
    padding: 10px;
  }
`;

const FlexModify = styled(Flex)`
  width: 100%;
  @media (max-width: 640px) {
    width: auto;
    align-items: center !important;
  }
`;

const ConBody = styled.div`
  width: calc(100% + 260px);

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const UserProfile: React.FC<IUserReceiver> = ({ user, account, social }) => {
  const params: any = useParams();
  const { userId } = params;
  const limit = 20;

  const history = useHistory();

  const { created, fetchMoreCreated, createdLoading, createdErr } =
    useUserNftCreated(userId ?? user?.id, limit);

  const { collected, collectedErr, collectedLoading, fetchMoreCollected } =
    // eslint-disable-next-line
    useUserAuctionCollected(limit, userId ?? user?.id);
  // eslint-disable-next-line
  const { nfts, fetchMoreNfts, nftsErr, nftsLoading } = useUserNFTMinted(
    limit,
    userId ?? user?.id
  );
  const { onsale, onsaleErr, onsaleLoading, fetchMoreOnsale } =
    useUserAuctionsOnsale(limit, userId ?? user?.id);

  const {
    pendingRequests,
    requestsReceived,
    completedRequests,
    requestReceivedErr,
    requestReceivedLoading,
    fetchMoreRequestReceived,
  } = useUserRequestsReceived(limit, userId ?? user?.id);
  const {
    requestSentErr,
    requestSentLoading,
    requestsSent,
    fetchMoreRequestSent,
  } = useUserRequestsSent(limit, userId ?? user?.id);

  const [checkIsFollowing, isFollowing] = useCheckIsFollowingLazyQuery();
  const [checkImFollowing, imFollowing] = useCheckIsFollowingLazyQuery();

  const [getFollowing, followingRaw] = useGetUserFollowingLazyQuery();
  const [getFollowers, followersRaw] = useGetUserFollowersLazyQuery();

  const [requestMinterAccessMutation] = useRequestMinterAccessMutation();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [socialFollower, setSocialFollower] = useState<ISocialFull[]>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [renderUser, setRenderUser] = useState<User>(null);
  const [socialRender, setSocialRender] = useState<ISocial>(null);
  const [rqSent, setRqSent] = useState<boolean>(false);

  const [createdCursor, setCreatedCursor] = useState<string>(null);
  const [collectedCursor, setCollectedCursor] = useState<string>(null);
  const [onsaleCursor, setOnsaleCursor] = useState<string>(null);
  const [followersCursor, setFollowersCursor] = useState<string>(null);
  const [followingCursor, setFollowingCursor] = useState<string>(null);
  const [requestSentCursor, setRequestSentCursor] = useState<string>(null);
  const [requestReceivedCursor, setRequestReceivedCursor] =
    useState<string>(null);

  const [canLoadMoreOnsale, setCanLoadMoreOnsale] = useState<boolean>(true);
  const [canLoadMoreCreated, setCanLoadMoreCreated] = useState<boolean>(true);
  const [canLoadMoreCollected, setCanLoadMoreCollected] =
    useState<boolean>(true);
  const [canLoadMoreRequestSent, setCanLoadMoreRequestSent] =
    useState<boolean>(true);
  const [canLoadMoreRequestReceived, setCanLoadMoreRequestReceived] =
    useState<boolean>(true);
  const [canLoadMoreFollowers, setCanLoadMoreFollowers] =
    useState<boolean>(true);
  const [canLoadMoreFollowing, setCanLoadMoreFollowing] =
    useState<boolean>(true);

  const [updateUserFollowingMutation] = useUpdateUserFollowingMutation();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDismise = () => {
    dismissPresentError();
  };

  const [presentError, dismissPresentError] = useIonModal(AlertModal, {
    close: handleDismise,
    type: "error",
    title: errorMessage,
  });

  const [isFollowingUsers, setIsFollowingUsers] = useState<boolean>(false);
  const query = useParamsQuery();
  const [following, setFollowing] = useState(false);

  const page = query.get("page");
  const { walletNfts, isWalletLoading } = useMoralisTokens();

  const checkReqRent = () => {
    if (renderUser?.id !== user?.id) {
      const rrq = user?.requestsSent?.some(
        (reqq) => reqq?.to?.id === renderUser?.id
      );
      setRqSent(rrq);
    }
  };

  const [getUser, { data, loading, error }] = useUserIdQueryLazyQuery();

  const handleFollowBtns = (type: boolean) => {
    history.push({
      search: "?page=followers",
    });
    setIsFollowingUsers(type);
    if (userId || user?.id) {
      if (!type) {
        getFollowers({
          variables: {
            userId: userId ?? user?.id,
            limit,
          },
        });
      } else {
        getFollowing({
          variables: {
            userId: userId ?? user?.id,
            limit,
          },
        });
      }
    }
  };

  const follow = () => {
    if (user && userId) {
      updateUserFollowingMutation({
        variables: {
          address: user.address,
          followingId: userId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CheckIsFollowingDocument,
            variables: {
              currentUserId: user?.id, // value for 'currentUerId'
              viewUserId: userId, // value for 'viewUserId'
            },
          },
          {
            query: UserAddressQueryDocument,
            variables: {
              getUserAddress: account, // value for 'getUserByAddressAddress'
            },
          },
        ],
      });
    }
  };

  const handler = async () => {
    if (userId) {
      getUser({
        variables: {
          getUserId: userId,
        },
      });
      if (!error && !loading && data?.getUser) {
        setRenderUser(data?.getUser as User);
        const _social = await getUserSocial(
          formatIpfsUrl(data?.getUser?.profileHash)
        );
        setSocialRender(_social);
        setIsOwner(false);
      }

      getFollowProfile();
    }
  };

  const getFollowProfile = async () => {
    if (renderUser) {
      const { followers } = extract6Follower(renderUser);
      const resolved = await Promise.all(
        followers.map(async (ff: User) => {
          let prof = await getUserSocial(formatIpfsUrl(ff?.profileHash));
          return {
            ...prof,
            email: ff.email,
            name: ff.name,
            username: ff.username,
          };
        })
      );
      setSocialFollower(resolved);
    }
  };

  const requestAccess = async () => {
    if (!user?.name || !user?.username) {
      setErrorMessage("Please complete your profile set up.");
      return presentError();
    }
    if (!user) await web3();
    if (user && account) {
      await requestMinterAccessMutation({
        variables: {
          fromId: user?.id, // value for 'requestMinterAccessFromId'
          toId: renderUser?.id, // value for 'requestMinterAccessToId'
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UserAddressQueryDocument,
            variables: { getUserAddress: account },
          },
        ],
      });
      setRqSent(() =>
        user?.requestsSent?.some(({ to }) => to.id === renderUser?.id)
      );
    }
  };

  const strength = calculateProfileStrength({
    name: renderUser?.name,
    username: renderUser?.username,
    bio: socialRender?.bio,
    imgUrl: socialRender?.imageHash,
    telegram: socialRender?.telegram,
    twitter: socialRender?.twitter,
    instagram: socialRender?.instagram,
    linkedIn: socialRender?.linkedIn,
    bannerHash: socialRender?.bannerHash,
    email: renderUser?.email,
  });

  const [, setState] = useState(false);

  useEffect(() => {
    userId?.length && handler();

    if (account && user && !userId && !renderUser) {
      setRenderUser(user);
      setSocialRender(social);
      setIsOwner(true);
      getFollowProfile();
    }

    checkReqRent();
    setFollowing(() => imFollowing?.data?.checkFollowing);

    if (userId && renderUser) {
      setRqSent(() =>
        user?.requestsSent?.some(({ to }) => to.id === renderUser?.id)
      );
    }
    if (user?.id && userId) {
      checkIsFollowing({
        variables: {
          currentUserId: user?.id,
          viewUserId: userId,
        },
      });

      checkImFollowing({
        variables: {
          viewUserId: userId,
          currentUserId: user?.id,
        },
      });
    }

    if (userId || user?.id) {
      getFollowers({
        variables: {
          userId: userId ?? user?.id,
          limit,
        },
      });

      getFollowing({
        variables: {
          userId: userId ?? user?.id,
          limit,
        },
      });
    }
    return () => {
      setState(true);
    };
    // eslint-disable-next-line
  }, [userId, account, user, social, data, page]);

  useEffect(() => {
    if (!createdLoading && !createdErr && created?.length) {
      setCreatedCursor(() => created[created?.length - 1]?.id);
    }
    if (!collectedLoading && !collectedErr && collected?.length) {
      setCollectedCursor(() => collected[collected?.length - 1]?.id);
    }
    if (!onsaleLoading && !onsaleErr && onsale?.length) {
      setOnsaleCursor(() => onsale[onsale?.length - 1]?.id);
    }
    if (
      !requestReceivedErr &&
      !requestReceivedLoading &&
      requestsReceived?.length
    ) {
      setRequestReceivedCursor(
        () => requestsReceived[requestsReceived?.length - 1]?.id
      );
    }
    if (!requestSentErr && !requestSentLoading && requestsSent?.length) {
      setRequestSentCursor(() => requestsSent[requestsSent?.length - 1]?.id);
    }
    if (
      !followingRaw.error &&
      followingRaw.loading &&
      followingRaw?.data?.getUserFollowing
    ) {
      setFollowingCursor(
        () =>
          followingRaw.data?.getUserFollowing[
            followingRaw.data?.getUserFollowing?.length - 1
          ]?.id
      );
    }
    if (
      !followersRaw.error &&
      followersRaw.loading &&
      followersRaw?.data?.getUserFollowers
    ) {
      setFollowingCursor(
        () =>
          followersRaw.data?.getUserFollowers[
            followersRaw.data?.getUserFollowers?.length - 1
          ]?.id
      );
    }
    // eslint-disable-next-line
  }, [created, onsale, collected, requestsReceived, requestsSent]);

  const loadMoreCollected = () => {
    return fetchMoreCollected({
      variables: {
        limit,
        userId: userId ?? user?.id,
        cursor: collectedCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserAuctionsCollected?.length) {
          setCanLoadMoreCollected(false);
          if (prev.getUserAuctionsCollected?.length)
            return Object.assign({}, prev, {
              getUserAuctionsCollected: filterDuplicatesObj([
                ...prev.getUserAuctionsCollected,
                ...collected,
              ]),
            });
          return prev;
        }
        fetchMoreResult.getUserAuctionsCollected.length &&
          setCreatedCursor(
            () =>
              fetchMoreResult.getUserAuctionsCollected[
                fetchMoreResult.getUserAuctionsCollected?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserAuctionsCollected: filterDuplicatesObj([
            ...collected,
            ...fetchMoreResult.getUserAuctionsCollected,
          ]),
        });
        if (prev?.getUserAuctionsCollected?.length < limit)
          setCanLoadMoreCollected(false);
        return combined;
      },
    });
  };
  const loadMoreCreated = () => {
    return fetchMoreCreated({
      variables: {
        limit,
        userId: userId ?? user?.id,
        cursor: createdCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserCreatedAuctions?.length) {
          setCanLoadMoreCreated(false);
          if (prev.getUserCreatedAuctions?.length)
            return Object.assign({}, prev, {
              getUserCreatedAuctions: filterDuplicatesObj([
                ...created,
                ...prev.getUserCreatedAuctions,
              ]),
            });
          return prev;
        }
        fetchMoreResult?.getUserCreatedAuctions?.length &&
          setCreatedCursor(
            () =>
              fetchMoreResult?.getUserCreatedAuctions[
                fetchMoreResult?.getUserCreatedAuctions?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserCreatedAuctions: filterDuplicatesObj([
            ...collected,
            ...fetchMoreResult.getUserCreatedAuctions,
          ]),
        });
        if (prev?.getUserCreatedAuctions?.length < limit)
          setCanLoadMoreCreated(false);
        return combined;
      },
    });
  };
  const loadMoreOnsale = () => {
    return fetchMoreCollected({
      variables: {
        limit,
        userId: userId ?? user?.id,
        cursor: onsaleCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserAuctionsOnsale?.length) {
          setCanLoadMoreOnsale(false);
          if (prev?.getUserAuctionsOnsale?.length)
            return Object.assign({}, prev, {
              getUserAuctionsOnsale: filterDuplicatesObj([
                ...prev?.getUserAuctionsOnsale,
                ...onsale,
              ]),
            });
          return prev;
        }
        fetchMoreResult.getUserAuctionsOnsale.length &&
          setCreatedCursor(
            () =>
              fetchMoreResult.getUserAuctionsOnsale[
                fetchMoreResult.getUserAuctionsOnsale?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserAuctionsOnsale: filterDuplicatesObj([
            ...onsale,
            ...fetchMoreResult.getUserAuctionsOnsale,
          ]),
        });
        if (prev?.getUserAuctionsOnsale?.length < limit)
          setCanLoadMoreOnsale(false);
        return combined;
      },
    });
  };

  const loadMoreRequestSent = () => {
    return fetchMoreRequestSent({
      variables: {
        getUserRequestsSentId: userId ?? user?.id,
        limit,
        cursor: requestSentCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserRequestsSent?.length) {
          setCanLoadMoreRequestSent(false);
          if (prev?.getUserRequestsSent?.length)
            return Object.assign({}, prev, {
              getUserRequestsSent: filterDuplicatesObj([
                ...prev.getUserRequestsSent,
                ...requestsSent,
              ]),
            });
          return prev;
        }
        fetchMoreResult.getUserRequestsSent?.length &&
          setRequestSentCursor(
            () =>
              fetchMoreResult.getUserRequestsSent[
                fetchMoreResult.getUserRequestsSent?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserRequestsSent: filterDuplicatesObj([
            ...requestsSent,
            ...fetchMoreResult.getUserRequestsSent,
          ]),
        });
        if (prev?.getUserRequestsSent?.length < limit)
          setCanLoadMoreRequestSent(false);
        return combined;
      },
    });
  };

  const loadMoreRequestReceived = () => {
    return fetchMoreRequestReceived({
      variables: {
        getUserRequestsReceivedId: userId ?? user?.id,
        limit,
        cursor: requestReceivedCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserRequestsReceived?.length) {
          setCanLoadMoreRequestReceived(false);
          if (prev?.getUserRequestsReceived?.length)
            return Object.assign({}, prev, {
              getUserRequestsReceived: filterDuplicatesObj([
                ...prev.getUserRequestsReceived,
                ...requestsReceived,
              ]),
            });
          return prev;
        }
        fetchMoreResult.getUserRequestsReceived.length &&
          setRequestReceivedCursor(
            () =>
              fetchMoreResult.getUserRequestsReceived[
                fetchMoreResult.getUserRequestsReceived?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserRequestsReceived: filterDuplicatesObj([
            ...requestsReceived,
            ...fetchMoreResult.getUserRequestsReceived,
          ]),
        });
        if (prev?.getUserRequestsReceived?.length < limit)
          setCanLoadMoreRequestReceived(false);
        return combined;
      },
    });
  };

  const loadMoreFollowers = () => {
    return followersRaw.fetchMore({
      variables: {
        userId: userId ?? user?.id,
        limit,
        cursor: followersCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getFollowers?.length) {
          setCanLoadMoreFollowers(false);
          if (prev?.getFollowers?.length)
            return Object.assign({}, prev, {
              getFollowers: filterDuplicatesObj([...prev.getFollowers]),
            });
          return prev;
        }
        fetchMoreResult.getFollowers.length &&
          setFollowersCursor(
            () =>
              fetchMoreResult.getFollowers[
                fetchMoreResult.getFollowers?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getFollowers: filterDuplicatesObj([
            ...prev.getFollowers,
            ...fetchMoreResult.getFollowers,
          ]),
        });
        if (prev?.getFollowers?.length < limit) setCanLoadMoreFollowers(false);
        return combined;
      },
    });
  };
  const loadMoreFollowing = () => {
    return followingRaw.fetchMore({
      variables: {
        userId: userId ?? user?.id,
        limit,
        cursor: followingCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult?.getUserFollowing?.length) {
          setCanLoadMoreFollowing(false);
          if (prev?.getUserFollowing?.length)
            return Object.assign({}, prev, {
              getUserFollowing: filterDuplicatesObj([...prev.getUserFollowing]),
            });
          return prev;
        }
        fetchMoreResult.getUserFollowing.length &&
          setFollowingCursor(
            () =>
              fetchMoreResult.getUserFollowing[
                fetchMoreResult.getUserFollowing?.length - 1
              ]?.id
          );

        const combined = Object.assign({}, prev, {
          getUserFollowing: filterDuplicatesObj([
            ...prev.getUserFollowing,
            ...fetchMoreResult.getUserFollowing,
          ]),
        });
        if (prev?.getUserFollowing?.length < limit)
          setCanLoadMoreFollowing(false);
        return combined;
      },
    });
  };

  const loadMore = () => {
    if (page === "dashboard" || page === null) {
      switch (activeTab) {
        case 2:
          scrollHelper(loadMoreOnsale, canLoadMoreOnsale);
          break;
        case 3:
          scrollHelper(loadMoreCollected, canLoadMoreCollected);
          break;
        case 4:
          scrollHelper(loadMoreCreated, canLoadMoreCreated);
          break;

        default:
          break;
      }
    } else if (page === "tree") {
      if (renderUser?.userType === UserType.Minter)
        scrollHelper(loadMoreRequestReceived, canLoadMoreRequestReceived);
      else scrollHelper(loadMoreRequestSent, canLoadMoreRequestSent);
    } else if (page === "followers") {
      if (isFollowingUsers) {
        scrollHelper(loadMoreFollowing, canLoadMoreFollowing);
      } else {
        scrollHelper(loadMoreFollowers, canLoadMoreFollowers);
      }
    } else if (page === "earning") {
    } else return;
  };

  const DashboardView = () => (
    <ConBody>
      <section>
        {(account || userId) && isOwner ? (
          <FeedCon>
            <Spacer height={20} />
            <Flex justify="space-between">
              <TabFlex>
                <TabText
                  onClick={() => setActiveTab(1)}
                  className={activeTab === 1 ? "active" : ""}
                >
                  Wallet ({walletNfts?.length ?? 0})
                </TabText>
                <TabText
                  className={activeTab === 2 ? "active" : ""}
                  onClick={() => setActiveTab(2)}
                >
                  On Sale ({onsale?.length ?? 0})
                </TabText>
                <TabText
                  className={activeTab === 3 ? "active" : ""}
                  onClick={() => setActiveTab(3)}
                >
                  Collected ({collected?.length ?? 0})
                </TabText>
                <TabText
                  className={activeTab === 4 ? "active" : ""}
                  onClick={() => setActiveTab(4)}
                >
                  Created ({created?.length ?? 0})
                </TabText>
              </TabFlex>
            </Flex>
            <GridComponent>
              {!isWalletLoading && walletNfts?.length && activeTab === 1
                ? walletNfts?.map((nft, k) => (
                    <WalletCard key={k} walletNft={nft} />
                  ))
                : activeTab === 1 && (
                    <div className="alert alert-info">
                      You do not have any NFTs holding at this moment
                    </div>
                  )}
            </GridComponent>
            <GridComponent>
              {onsale?.length && activeTab === 2
                ? onsale?.map((nft, k) => <NftCard key={k} auction={nft} />)
                : activeTab === 2 && (
                    <div className="alert alert-info">
                      You do not have any NFTs listed at this moment
                    </div>
                  )}
            </GridComponent>
            <GridComponent>
              {collected?.length && activeTab === 3
                ? collected?.map((nft, k) => <NftCard key={k} auction={nft} />)
                : activeTab === 3 && (
                    <div className="alert alert-info">
                      You have not collected any NFTs until this moment
                    </div>
                  )}
            </GridComponent>
            <GridComponent>
              {created?.length && activeTab === 4
                ? created?.map((nft, k) => <NftCard key={k} auction={nft} />)
                : activeTab === 4 && (
                    <div>You have not created any NFTs until now</div>
                  )}
            </GridComponent>
          </FeedCon>
        ) : (
          <FeedCon>
            <Spacer height={20} />
            <TabFlex>
              <TabText
                className={activeTab === 1 ? "active" : ""}
                onClick={() => setActiveTab(1)}
              >
                Collected(
                {collected?.length ?? 0})
              </TabText>
              <TabText
                className={activeTab === 2 ? "active" : ""}
                onClick={() => setActiveTab(2)}
              >
                Created ({created?.length ?? 0})
              </TabText>
            </TabFlex>
            <GridComponent>
              {collected?.length && activeTab === 1
                ? collected?.map((nft, k) => <NftCard key={k} auction={nft} />)
                : activeTab === 1 && (
                    <div className="alert alert-info">
                      This user has not collected any NFTs until this moment
                    </div>
                  )}
            </GridComponent>
            <GridComponent>
              {created?.length && activeTab === 2
                ? created?.map((nft, k) => <NftCard key={k} auction={nft} />)
                : activeTab === 2 && (
                    <div className="alert alert-info">
                      This user has not created any NFTs until now
                    </div>
                  )}
            </GridComponent>
          </FeedCon>
        )}
      </section>
    </ConBody>
  );

  const TreeView = () => (
    <ConBody>
      <section>
        {isOwner && (
          <FeedCon>
            <Spacer height={20} />
            <TabFlex>
              <TabText
                className={activeTab === 1 ? "active" : ""}
                onClick={() => setActiveTab(1)}
              >
                Active ({completedRequests?.length ?? 0})
              </TabText>

              <TabText
                className={activeTab === 2 ? "active" : ""}
                onClick={() => setActiveTab(2)}
              >
                Pending ({pendingRequests?.length ?? 0})
              </TabText>
            </TabFlex>
            {renderUser?.userType === UserType.Minter ? (
              <GridComponent>
                {!requestReceivedErr &&
                !requestReceivedLoading &&
                completedRequests?.length &&
                activeTab === 1
                  ? completedRequests
                      ?.map(({ from, status }) => ({ from, status }))
                      ?.map(({ status, from }, k) => (
                        <NftUser
                          key={k}
                          mode="tree"
                          current_user={renderUser}
                          showRequestBtns={status === Status.Pending}
                          user={from as User}
                          account={renderUser?.address}
                        />
                      ))
                  : activeTab === 1 && (
                      <div className="alert alert-info">
                        You do not have any request so far
                      </div>
                    )}
              </GridComponent>
            ) : (
              renderUser?.userType === UserType.Collector && (
                <GridComponent>
                  {!requestSentLoading &&
                  !requestSentErr &&
                  requestsSent?.length &&
                  activeTab === 1
                    ? requestsSent
                        ?.map(({ to, status }) => ({ to, status }))
                        ?.map(({ to, status }, k) => (
                          <NftUser
                            key={k}
                            mode="tree"
                            current_user={renderUser}
                            user={to as User}
                            showRequestBtns={status === Status.Pending}
                            account={renderUser?.address}
                          />
                        ))
                    : activeTab === 1 && (
                        <div className="alert alert-info">
                          You do not have pending request at the moment
                        </div>
                      )}
                </GridComponent>
              )
            )}
            <GridComponent>
              {!requestReceivedLoading &&
              !requestReceivedErr &&
              pendingRequests?.length &&
              activeTab === 2
                ? pendingRequests
                    ?.map(({ from, status }) => ({ from, status }))
                    ?.map(({ from, status }, k) => (
                      <NftUser
                        key={k}
                        mode="tree"
                        current_user={renderUser}
                        user={from as User}
                        showRequestBtns={status === Status.Pending}
                        account={renderUser?.address}
                      />
                    ))
                : activeTab === 2 && (
                    <div className="alert alert-info">
                      You do not have any pending request at the moment
                    </div>
                  )}
            </GridComponent>
          </FeedCon>
        )}
      </section>
    </ConBody>
  );

  return (
    <IonContent scrollEvents={true} onIonScroll={loadMore ?? undefined}>
      <Container>
        <div className="pool-p10">
          <Banner
            userId={userId}
            account={renderUser?.address}
            bnrHash={socialRender?.bannerHash}
            name={renderUser?.name ? renderUser?.name : "Coterie user"}
            imgHash={socialRender?.imageHash}
          />
          <BodyContainer>
            <Flex directionM="column">
              <FilterCon className={true ? "open" : ""}>
                <Spacer height={50} />

                {/* Check if viewer is the owner */}
                {/* {true && ( */}
                <Flex justify="space-between">
                  <Text></Text>
                  {isOwner && (
                    <Text>
                      <ButtonWhite routerLink={"/me/update"}>
                        <Flex justify="space-between">
                          <Pencel />
                          <Spacer width={5} />
                          Edit Profile
                        </Flex>
                      </ButtonWhite>
                    </Text>
                  )}
                </Flex>

                {/* Make this match the design */}
                {isOwner ? (
                  <>
                    <Flex justify="space-between">
                      <Text>Profile Strength</Text>
                      <Text>{(strength * 100).toFixed(2)} %</Text>
                    </Flex>
                    <RangeCon>
                      <Range width={(strength * 100).toString()} />
                    </RangeCon>
                  </>
                ) : (
                  {
                    /* <>
                    <Flex justify="space-between">
                      <Text>
                        {!isFollowing?.loading &&
                        isFollowing?.data?.checkFollowing
                          ? "Follows you"
                          : null}
                      </Text>

                      <IonButton onClick={() => follow()} size="small">
                        {following ? "Following" : "Follow"}
                      </IonButton>
                    </Flex>
                  </> */
                  }
                )}

                <Text size="16">
                  {renderUser?.name
                    ? stringToCapitalized(renderUser?.name)
                    : "Coterie user"}
                </Text>
                <Text size="16">
                  {renderUser?.username?.length
                    ? `@${stringToCapitalized(renderUser?.username)}`
                    : `@${renderUser?.address?.slice(0, 8)?.toUpperCase()}`}
                </Text>

                <Spacer />
                <div style={{ borderTop: "0.5px solid #ccc" }} />
                <Spacer />

                {/* <Flex>
                  <Flex direction="column" directionM="row">
                    <Text size="16" weight="600">
                      {renderUser?.followersCount}
                    </Text>
                    <Spacer width={10} />
                    <Text
                      style={{ cursor: "pointer" }}
                      onClick={() => handleFollowBtns(false)}
                      size="16"
                      color="rgba(0,0,0,0.5)"
                    >
                      Follower
                    </Text>
                  </Flex>
                  <Spacer width={20} />
                  <Flex direction="column" directionM="row">
                    <Text size="16" weight="600">
                      {renderUser?.followingCount}
                    </Text>
                    <Spacer width={10} />
                    <Text
                      style={{ cursor: "pointer" }}
                      onClick={() => handleFollowBtns(true)}
                      size="16"
                      color="rgba(0,0,0,0.5)"
                    >
                      Following
                    </Text>
                  </Flex>
                </Flex> */}
                {/* <Spacer />
                <div style={{ borderTop: "0.5px solid #ccc" }} />
                <Spacer /> */}
                {/* {!isOwner && socialFollower?.length && (
                  <>
                    <Text size="16" color="rgba(0,0,0,0.5)">
                      Followed by:
                    </Text>
                    <Spacer />
                    <Flex align="center">
                      <FollowedByContainer>
                        <FollowedByAvatars>
                          {socialFollower?.map((follower, key) => (
                            <FollowedByAvatar key={key} className="avatar">
                              <img
                                src={
                                  formatIpfsUrl(follower?.imageHash) ??
                                  `https://avatars.dicebear.com/api/male/${
                                    follower?.address ?? follower?.id
                                  }.svg?background=%236d64ff&&mood[]=happy`
                                }
                                alt="This is the title"
                              />
                            </FollowedByAvatar>
                          ))}
                        </FollowedByAvatars>
                      </FollowedByContainer>
                      <Text size="16" style={{ marginLeft: -20 }}>
                        {socialFollower[0]?.username
                          ? `@${socialFollower[0]?.username}`
                          : `@${
                              renderUser?.followersCount &&
                              renderUser?.followers[0]?.address
                                ?.slice(0, 6)
                                ?.toUpperCase()
                            }`}{" "}
                        {renderUser?.followersCount > 1
                          ? `& ${renderUser?.followersCount - 1} more`
                          : null}{" "}
                      </Text>
                    </Flex>
                    <Spacer />
                    <Text
                      onClick={() =>
                        history.push({
                          search: "?page=followers",
                        })
                      }
                      style={{ cursor: "pointer" }}
                      size="16"
                      weight="600"
                    >
                      View All
                    </Text>
                  </>
                )}
 */}
                <Spacer />
                <Text size="16" weight="600">
                  Bio
                </Text>
                <Text size="16" style={{ lineHeight: "20px" }}>
                  {socialRender?.bio?.length &&
                    truncate(socialRender?.bio, 200)}
                </Text>

                <Spacer />
                <div style={{ borderTop: "0.5px solid #ccc" }} />
                <Spacer />
                <Flex
                  directionM="column"
                  direction="column"
                  justify="space-between"
                >
                  <Flex justify="space-between">
                    <Text size="16" color="rgba(0,0,0,0.5)">
                      Joined:
                    </Text>
                    &nbsp;&nbsp;
                    <Text size="16" weight="600">
                      {renderUser?.memberSince &&
                        formatIsoToMY(renderUser?.memberSince)}
                    </Text>
                  </Flex>
                  {/* {(renderUser?.invitedBy?.username ||
                    renderUser?.invitedBy?.name) && (
                    <Flex justify="space-between">
                      <Text size="16" color="rgba(0,0,0,0.5)">
                        Referred by:
                      </Text>
                      &nbsp;&nbsp;
                      <Text size="16" weight="600">
                        {renderUser?.invitedBy?.username
                          ? `@${renderUser?.invitedBy?.username}`
                          : renderUser?.invitedBy?.username}
                      </Text>
                    </Flex>
                  )} */}

                  <Spacer height={20} />

                  <FlexModify justify="space-between">
                    {socialRender?.telegram && (
                      <IonButton
                        href={`${TELEGRAM_URL}${socialRender?.telegram}`}
                        target="_blank"
                        fill="clear"
                        size="small"
                      >
                        <IonIcon size="large" src="/svgs/telegram.svg" />
                      </IonButton>
                    )}
                    {socialRender?.twitter && (
                      <IonButton
                        href={`${TWITTER_URL}${socialRender?.twitter}`}
                        target="_blank"
                        fill="clear"
                        size="small"
                      >
                        <Twitter />
                      </IonButton>
                    )}
                    {socialRender?.instagram && (
                      <IonButton
                        href={`${INSTAGRAM_URL}${socialRender?.instagram}`}
                        target="_blank"
                        fill="clear"
                        size="small"
                      >
                        <IonIcon size="large" src="/svgs/instagram.svg" />
                      </IonButton>
                    )}
                    {socialRender?.linkedIn && (
                      <IonButton
                        href={`${LINKEDIN_URL}${socialRender?.linkedIn}`}
                        fill="clear"
                        target="_blank"
                        size="small"
                      >
                        <IonIcon
                          style={{ color: "#0a66c2" }}
                          size="large"
                          src="/svgs/linkedIn.svg"
                        />
                      </IonButton>
                    )}
                  </FlexModify>

                  <Spacer height={20} />

                  {/* {!isOwner &&
                    renderUser?.userType === UserType.Minter &&
                    user?.userType === UserType.Collector && (
                      <>
                        {!rqSent ? (
                          <ButtonWhite onClick={requestAccess}>
                            Become A Creator
                          </ButtonWhite>
                        ) : (
                          <ButtonWhite disabled={true}>
                            Request Sent
                          </ButtonWhite>
                        )}

                        
                      </>
                    )} */}
                </Flex>
              </FilterCon>

              {(!page || page === "dashboard") && <DashboardView />}
              {page === "followers" && (
                <PeopleView
                  {...{
                    isFollowingUsers,
                    activeTab,
                    followingRaw,
                    followersRaw,
                    renderUser,
                  }}
                />
              )}
              {page === "tree" && <TreeView />}
              {page === "earnings" && <EarningView />}
            </Flex>
          </BodyContainer>
        </div>
        <Footer />
      </Container>
    </IonContent>
  );
};

export default UserProfile;

interface IBnr {
  imgHash?: string;
  bnrHash?: string;
  name?: string;

  account?: string;
  userId?: string;
}
const Banner: FC<IBnr> = ({ imgHash, bnrHash, name, account, userId }) => (
  <div>
    <BannerCon bgImg={bnrHash?.length && formatIpfsUrl(bnrHash)}>
      <div className="img">
        <Avatar size="100">
          <img
            src={
              imgHash?.length
                ? formatIpfsUrl(imgHash)
                : `https://avatars.dicebear.com/api/male/${
                    userId ?? account
                  }.svg?background=%236d64ff&&mood[]=happy`
            }
            alt={name}
          />
        </Avatar>
      </div>
    </BannerCon>
  </div>
);
