import addresses from "config/contracts";
import { Address } from "config/types";
import {
  BSC_ADDRESS_URL,
  BSC_DELFY_LOCKER_ADDRESS,
  BSC_FACTORY_ADDRESS,
  DELFY_LOCKER_ADDRESS,
  ETHERSCAN_ADDRESS_URL,
  FACTORY_ADDRESS,
} from "../address";

// let network=store.getState().user.networkId

export const getFactoryAddress = (network: any) => {
  switch (network) {
    case "4":
      return FACTORY_ADDRESS;
    case "97":
      return BSC_FACTORY_ADDRESS;
    default:
      return "";
  }
};

export const getLockerAddress = (network: string) => {
  switch (network) {
    case "4":
      return DELFY_LOCKER_ADDRESS;
    case "97":
      return BSC_DELFY_LOCKER_ADDRESS;
    default:
      return "";
  }
};

export const getNSyb = (network: string) => {
  switch (network) {
    case "4":
      return "ETH";
    case "97":
      return "BSC";
    default:
      return "";
  }
};

export const getScanAddress = (network: string) => {
  switch (network) {
    case "4":
      return ETHERSCAN_ADDRESS_URL;
    case "97":
      return BSC_ADDRESS_URL;
    default:
      return "";
  }
};

export let getAddress = (address: Address): any => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return chainId === "56" ? address.bsc : address.test;
  // return  address.test
};

export const getStakingAddress = () => {
  return getAddress(addresses.staking) as string;
};

export const getOwnedTokenAddress = () => {
  return getAddress(addresses.owned_token) as string;
};

export const getNFTMktplaceAddress = () => {
  return getAddress(addresses.nftMktplace) as string;
};
export const getNFTERC721Address = () => {
  return getAddress(addresses.nftToken) as string;
};
export const getMultiCallAddress = () => {
  return getAddress(addresses.multiCall) as string;
};

export const getBnbBusdLPAddress = () => {
  return getAddress(addresses.bnbBusd) as string;
};

export const getBnbCoterieLPAddress = () => {
  return getAddress(addresses.bnbCoterie) as string;
};

export const getCoterieAddress = () => {
  return getAddress(addresses.coterie) as string;
};
export const getBnbCoterieGovLPAddress = () => {
  return getAddress(addresses.bnbCoterie) as string;
};

export const getCoterieGovAddress = () => {
  return getAddress(addresses.coterie) as string;
};

export const getAddressZero = () => {
  return getAddress(addresses.bnb) as string;
};
