import airdropAbi from "abi/airdrop.json";
import Api from "api";
import BigNumber from "bignumber.js";
import tokens, { getTokenAddress } from "config/tokens";
import { ethers } from "ethers";
import moment from "moment";
import { AbiItem } from "web3-utils";
import {
  Bid,
  Blockchain,
  NftStandard,
  User,
} from "../generated/graphql-frontend";
import {
  IOfferTable,
  IPaymentMethod,
  IPaymentsTo,
  IProfileC,
  ISocial,
  IUserSearch,
} from "../types/nft-user-types";
import { toBN } from "./BigNumber";
import {
  formartUSD,
  getBalanceAmount,
  getFullDisplayBalance,
} from "./formatBalance";
import { formatIpfsUrl } from "./ipfsUrlHelper";
import { truncateWalletAddress } from "./truncateWalletAddress";
import web3NoAccount from "./web3NoAccount";

export const getIpfsDetails = (val: string) => {
  let link = formatIpfsUrl(val);
  return Api.nft.ipfs(link);
};

export const extract6Follower = (user: any) => {
  if (user) {
    const followers = user?.followers;
    if (followers?.length) {
      let sorted = followers.slice().sort((f1: any, f2: any) => {
        return f2?.followers?.length - f1?.followers?.length;
      });
      let len = sorted.length;
      if (sorted.length > 6) {
        let firsts = sorted.slice(0, 5);
        return { length: len, followers: firsts as User[] };
      } else return { length: len, followers: sorted as User[] };
    }
    return {
      length: 0,
      followers: [],
    };
  }
};
export const extract6Following = (user: any) => {
  if (user) {
    const following = user?.following;
    if (following && following?.length) {
      let sorted = following.slice().sort((f1: any, f2: any) => {
        return f2?.following?.length - f1?.following?.length;
      });
      let len = sorted.length;
      if (sorted.length > 6) {
        let firsts = sorted.slice(0, 5);
        return { len, followings: firsts as User[] };
      } else return { len, followings: sorted as User[] };
    }
    return {
      len: 0,
      followings: [],
    };
  }
};

export const checkFollowing = (user: any, userId: string): boolean => {
  const followIds = user?.following?.map((_user: any) =>
    _user.id.toLowerCase()
  );
  if (followIds) {
    return followIds.includes(userId?.toString().toLowerCase());
  }
  return false;
};

export const calculateProfileStrength = (profileInput: IProfileC): number => {
  const {
    name,
    email,
    username,
    twitter,
    instagram,
    linkedIn,
    telegram,
    bannerHash,
    imgUrl,
    bio,
  } = profileInput;
  let points = 0;
  if (name) points += 1;
  if (email) points += 3;
  if (username) points += 3;
  if (twitter) points += 3;
  if (telegram) points += 2;
  if (instagram) points += 3;
  if (linkedIn) points += 3;
  if (bannerHash) points += 2;
  if (imgUrl) points += 5;
  if (bio) points += 5;
  return +(points / 30).toFixed(2);
};

export const makeFollow = async (users: User[]) => {
  return users.map(async (user) => {
    let imgHash = "";
    if (user.profileHash) {
      const data: any = (await getIpfsDetails(user.profileHash)).data;
      imgHash = data?.imageHash;
    }
    return {
      imageUrl: imgHash,
      id: user.id,
      username: user?.username,
      userType: user.userType,
    };
  });
};

export const getFollow = (user: User, mtd: string) => {
  if (mtd === "following") return user?.following;
  if (mtd === "followers") return user?.followers;
};

export const getFollowersAndTree = (user: User) => {
  let users: User[] = [];
  let followers = user?.followers;
  if (followers && followers.length) users = followers;
  let following = user?.following;
  if (following && following.length) users = [...users, ...following];
  let referral = user?.referral;
  if (referral && referral?.length) users = [...users, ...referral];
  let referredBy = user?.invitedBy;
  if (referredBy) users.push(referredBy);
  return users.filter((elem, index, self) => index === self.indexOf(elem));
};

export const getUserSocial = async (
  userProfileHash: string
): Promise<ISocial> => {
  let profile = {};
  if (userProfileHash) {
    Object.assign(profile, (await getIpfsDetails(userProfileHash))?.data);
  }
  return profile as ISocial;
};

export const getPaymentMethods = (chainId: number): IPaymentMethod[] => {
  const pMtds = [
    {
      token_address: getTokenAddress(
        "busd",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.busd.symbol,
      icon: "/assets/busd.png",
    },
    {
      token_address: getTokenAddress(
        "usdt",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.usdt.symbol,
      icon: "/assets/usdt.png",
    },
    {
      token_address: getTokenAddress(
        "bnb",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.bnb.symbol,
      icon: "/assets/bnb.png",
    },
    {
      token_address: getTokenAddress(
        "avax",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.avax.symbol,
      icon: "/assets/avax.jpg",
    },
    {
      token_address: getTokenAddress(
        "matic",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.matic.symbol,
      icon: "/assets/matic.png",
    },
    {
      token_address: getTokenAddress(
        "eth",
        getChainFromChainId(chainId) ?? "test"
      ),
      value: tokens.eth.symbol,
      icon: "/assets/eth.png",
    },
  ];
  switch (chainId) {
    case 1:
      return pMtds.filter(
        ({ value }) =>
          value.toLowerCase() !== "matic" && value.toLowerCase() !== "avax"
      );
    case 97:
      const m = pMtds.filter(
        ({ value }) =>
          value.toLowerCase() !== "matic" && value.toLowerCase() !== "avax"
      );
      return m;
    case 56:
      return pMtds.filter(
        ({ value }) =>
          value.toLowerCase() !== "matic" && value.toLowerCase() !== "avax"
      );
    case 137:
      return pMtds.filter(({ value }) => value.toLowerCase() !== "avax");
    case 43114:
      return pMtds.filter(({ value }) => value.toLowerCase() !== "matic");
    default:
      return pMtds.filter(
        ({ value }) =>
          value.toLowerCase() !== "matic" && value.toLowerCase() !== "avax"
      );
  }
};

export const getUserSearchArray = async (user: User, users: User[]) => {
  let _users: User[] = [];
  let usersSocial: ISocial[] = [];
  _users = getFollowersAndTree(user);
  if (!_users?.length) {
    if (users?.length) {
      _users = users;
    }
  }
  _users = [...users, user].filter(Boolean);
  // _users.filter(Boolean);
  if (_users?.length) {
    usersSocial = await Promise.all(
      _users.map(async (_user, i) => {
        const meta = await getUserSocial(_user.profileHash);
        return {
          name: _users[i]?.name?.length ? _users[i]?.name : "new user",
          username: _users[i]?.username?.length
            ? _users[i]?.username
            : "new_user",
          address: _users[i]?.address,
          imageHash: meta?.imageHash?.length
            ? meta?.imageHash
            : "/assets/user_avatar.jpg",
        };
      })
    );
  }
  return usersSocial as IUserSearch[];
};

export const toChecksum = (address: string) =>
  web3NoAccount.utils.toChecksumAddress(address);

export const getGasLimit = () => web3NoAccount.eth.getBlock("latest");

export const sumPayTos = (payTos: IPaymentsTo[]) =>
  payTos.reduce(
    (acc, person) => toBN(acc).plus(person.percent.multipliedBy(10)),
    toBN(0)
  );

export const getTokenSymbol = (tokenAddress: string) => {
  if (tokenAddress) {
    const found = Object.values(tokens).find(({ address }) =>
      Object.values(address).find(
        (addr) => addr.toLowerCase() === tokenAddress.toLowerCase()
      )
    );
    return found.symbol;
  }
};

export const getUsdValue = (value: string, usdValue: number) => {
  return getBalanceAmount(value).times(usdValue).toNumber().toFixed(2);
};

export const getTokenSymbolFromChain = () => {};

export const getTokenSymbolFromChainId = () => {};

export const makeOffertable = (
  bids: Bid[],
  usdPrice: BigNumber,
  paymentMethod?: string
) => {
  if (bids?.length && paymentMethod) {
    const bidsTable: IOfferTable[] = bids.map((bid, i) => {
      const price = getFullDisplayBalance(bid.value, 18, 3);
      return {
        id: i,
        price,
        price_usd: `${formartUSD(usdPrice.multipliedBy(price).toString(10))} `,
        qty: "1",
        expiration: moment(bid.createdAt).fromNow(),
        from: truncateWalletAddress(bid?.bidder?.address, 4, 4),
      };
    });
    return bidsTable;
  }
  return [];
};

export const getChainLogo = (chain?: Blockchain) => {
  switch (chain) {
    case Blockchain.Bsc:
      return "/assets/bsc.png";
    case Blockchain.Eth:
      return "/assets/eth.png";
    case Blockchain.Avax:
      return "/assets/avax.png";
    case Blockchain.Matic:
      return "/assets/matic.png";
    default:
      break;
  }
};

export const getChainFullName = (chain: Blockchain) => {
  switch (chain) {
    case Blockchain.Avax:
      return "Avax Chain";
    case Blockchain.Bsc:
      return "Binance Smart Chain";
    case Blockchain.Eth:
      return "Ethereum Chain";
    case Blockchain.Matic:
      return "Matic Chain";
    default:
      return "UNKNOWN NETWORK";
  }
};

export const getChainFullNameFromChainId = (chainId: number) => {
  switch (chainId) {
    case 43114:
      return "Avax Chain";
    case 97:
      return "Binance Test Chain";
    case 56:
      return "Binance Smart Chain";
    case 1:
      return "Ethereum Chain";
    case 137:
      return "Matic Chain";
    default:
      return "UNKNOWN NETWORK";
  }
};
export const getChainHexFromChainId = (chainId: number) => {
  switch (chainId) {
    case 43114:
      return "avalanche";
    case 97:
      return "bsc testnet";
    case 56:
      return "bsc";
    case 1:
      return "eth";
    case 137:
      return "polygon";
    default:
      return "eth";
  }
};

export const getChainNumberFromBlockchain = (chain: Blockchain | "test") => {
  switch (chain) {
    case Blockchain.Avax:
      return 43114;
    case "test":
      return 97;
    case Blockchain.Bsc:
      return 56;
    case Blockchain.Eth:
      return 1;
    case Blockchain.Matic:
      return 137;
    default:
      return 97;
  }
};

export const getChainSymbol = (chain: Blockchain) => {
  switch (chain) {
    case Blockchain.Avax:
      return "Avax";
    case Blockchain.Bsc:
      return "BSC";
    case Blockchain.Eth:
      return "ETH";
    case Blockchain.Matic:
      return "Matic";
    default:
      return "UNKNOWN CHAIN";
  }
};

export const getChainFromChainId = (chainId: number) => {
  switch (chainId) {
    case 97:
      return Blockchain.Bsc;
    case 56:
      return Blockchain.Bsc;
    case 137:
      return Blockchain.Matic;
    case 43114:
      return Blockchain.Avax;
    case 1:
      return Blockchain.Eth;
    default:
      return;
  }
};

export const getNFTsTokenStandard = (nftStandard: NftStandard) => {
  if (nftStandard === NftStandard.Erc1155) return "ERC-1155";
  if (nftStandard === NftStandard.Erc721) return "ERC-721";
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/* Message:
Welcome to OpenSea!

Click "Sign" to sign in. No password needed!
This request will not trigger a blockchain transaction or cost any gas fees.

Your authentication status will be reset after 24 hours.

I accept the OpenSea Terms of Service: https://opensea.io/tos

Wallet address:
0x0dcf02728f1162ecb512b0d73fc9ee3f57feed8e

Nonce:
475055
 */

export const signer = async () => {
  try {
    const { providers, utils } = ethers;
    if (window.ethereum) {
      // await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const EIP712Domain = {
        name: "Coterie Early Adopter",
        version: "1",
        chainId: 97,
        verifyingContract: "0xA7cFe86cd9b8d65402D7E15C0b70F02361B0D0de",
      }; // 0xd46E4E6b73c005a5B707c63e4dD62f49525d5D71
      // 0xBEab09ED8b46f02ae5287B5fc6278EB2a0b12759
      const types = {
        EarlyAdopter: [
          { name: "user", type: "address" },
          { name: "attestation", type: "string" },
        ],
      };
      const values = {
        user: address,
        attestation: "I verify as Coterie early adopter.",
      };
      const signature = await signer._signTypedData(
        EIP712Domain,
        types,
        values
      );
      const airdrop = new web3NoAccount.eth.Contract(
        airdropAbi as unknown as AbiItem,
        EIP712Domain.verifyingContract
      );
      const { v, r, s } = utils.splitSignature(signature);
      const verify = await airdrop.methods
        .verify(address, values.attestation, v, r, s)
        .call();

      const verified = utils.verifyTypedData(
        EIP712Domain,
        types,
        values,
        signature
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const filterDuplicatesObj = (arr: any) => {
  return (
    arr.length &&
    arr.filter(
      (v: any, i: any, a: any) => a.findIndex((t: any) => t.id === v.id) === i
    )
  );
};

export const scrollHelper = async (func: () => any, canLoadMore: boolean) => {
  // only fetch the data once it is over 80%
  if (func && canLoadMore) {
    const content = document.querySelector("ion-content");

    const scrollElement = await content.getScrollElement();
    // minus clientHeight because trigger is scrollTop
    // otherwise you hit the bottom of the page before
    // the top screen can get to 80% total document height
    const scrollHeight =
      scrollElement.scrollHeight - scrollElement.clientHeight;

    const scrolled = scrollElement.scrollTop;
    const scrolledPercent = (scrolled / scrollHeight) * 100;
    const targetPercent = 80;

    if (scrolledPercent >= targetPercent && canLoadMore) {
      await func();
    }
  }
};

export function openExternalLink(location: string) {
  return window.open(location, "_blank");
}
