import { IonCard, IonIcon } from "@ionic/react";
import { Flex } from "components";
import { ButtonPrimary, ButtonWhite } from "components/Button";
import { closeOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";

const Container = styled(IonCard)`
  max-width: 600px;
  height: auto;
  border-radius: 5px;
  color: #333;
  padding: 0 20px;
  overflow-y: auto;
`;

const Header = styled.div`
  padding: 12px 12px 0 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 15px 10px;
  outline: none;
  flex: 1;
  height: 50px;
  border: 1px solid #ccc;
`;

const Close = styled.div`
  padding: 15px 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
`;

interface PropertyInt {
  msg: string;
  close: () => void;
  properties: any;
  setProperty: (args: any) => void;
  save: () => void;
}

const PropertyMessage: React.FC<PropertyInt> = ({
  properties,
  setProperty,
  save,
}: PropertyInt) => {
  const renderForm = (element: any, i: number) => (
    <Flex style={{ marginTop: 10 }} key={i}>
      <Close onClick={() => remove(i)}>
        <IonIcon icon={closeOutline} style={{ fontSize: 20 }} />{" "}
      </Close>
      <Input
        required={true}
        type="text"
        name="type"
        value={element.type || ""}
        placeholder="Type"
        onChange={(e: any) => handleChange(i, e)}
      />
      <Input
        required={true}
        type="text"
        value={element.name || ""}
        name="name"
        placeholder="Name"
        onChange={(e: any) => handleChange(i, e)}
      />
    </Flex>
  );

  const add = () => {
    setProperty([...properties, { type: "", name: "" }]);
  };

  const remove = (i: number) => {
    let newFormValues = [...properties];
    if (i === 0) return;
    newFormValues.splice(i, 1);
    setProperty(newFormValues);
  };

  let handleChange = (i: number, e: any) => {
    let newFormValues = [...properties];
    newFormValues[i][e.target.name] = e.target.value;

    console.log(newFormValues);
    setProperty(newFormValues);
  };

  return (
    <Container>
      <div
        onClick={save}
        style={{ float: "right", padding: "20px", cursor: "pointer" }}
      >
        <IonIcon icon={closeOutline} style={{ fontSize: 20 }} />
      </div>
      <Header>
        <h2>Add Properties</h2>
      </Header>
      <div style={{ overflowY: "auto", height: 400 }}>
        {/* <h5>{error.msg}</h5> */}
        <p>
          These properties show up underneath your item. They are clickable anc
          can help collectors find your collections.
        </p>
        <br />

        {properties.map((element: any, i: number) => renderForm(element, i))}

        <br />

        <div>
          <ButtonWhite width="100px" onClick={() => add()}>
            Add More
          </ButtonWhite>
        </div>

        <br />

        <ButtonPrimary
          style={{ margin: "auto" }}
          width="100px"
          onClick={() => save()}
        >
          Save
        </ButtonPrimary>
        <br />
      </div>
    </Container>
  );
};

export default PropertyMessage;
