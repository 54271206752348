import { IonCard, IonText } from "@ionic/react";
import { Flex, Text } from "components";
import { KeyValue } from "components/Pool/Popover";
import {
  use_approval,
  use_eRC721Burn,
  use_updateRoyalty,
  use_updateTokenURI,
} from "hooks/nfts/nft-hooks";
import { web3 } from "hooks/useWeb3Provider";
import React, { useEffect, useState } from "react";
import { useActiveWeb3React } from "store/hooks";
import styled from "styled-components";
import { ICoterieNFTs, INFTDetails } from "types/nft-user-types";
import { toBN } from "utils/BigNumber";
import { formatIpfsUrl, getMimeType } from "utils/ipfsUrlHelper";
import { getChainFromChainId } from "utils/nft-user-helper";

const Card = styled(IonCard)`
  /* width: 100%;
  height: 100%;
  border-radius: 25px !important; */
`;

const CardImg = styled.img`
  width: 100%;
  /* height: 180px; */
  /* object-fit: cover; */
`;

const VideoCard = styled.video`
  width: 100%;
  height: 180px;
`;

const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin-top: 16px;
`;

const Name = styled.h6`
  color: #6470cf;
`;

interface IDetail {
  tokenData?: INFTDetails | ICoterieNFTs;
  account?: string;
}

const NftDetail: React.FC<IDetail> = ({ tokenData, account }) => {
  const { chainId } = useActiveWeb3React();
  const [metadata, setMetadata] = useState<INFTDetails | ICoterieNFTs>(null);
  const [, setState] = useState(false);
  const [mimeType, setMimeType] = useState("unknown");
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const getMime = async (imgHash: string) => {
    const fileType = await getMimeType(imgHash);
    setMimeType(fileType);
  };
  useEffect(() => {
    if (tokenData && !metadata) setMetadata(tokenData);
    getMime(tokenData?.image);
    return () => {
      setState(true);
    };
  }, [tokenData]);

  const action = async (_action: KeyValue) => {
    const _web3 = await web3();
    if (_action.key === "aprove") {
      use_approval(
        tokenData.tokenAddress,

        account,
        _web3
      );
    }
    if (_action.key === "burn") {
      use_eRC721Burn(
        toBN(tokenData.tokenId),
        account,
        _web3,
        getChainFromChainId(chainId)
      );
    }
    if (_action.key === "update-metadata") {
      use_updateTokenURI(
        toBN(tokenData.tokenId),
        "",
        _web3,
        account,
        getChainFromChainId(chainId)
      );
    }
    if (_action.key === "update-royalty") {
      use_updateRoyalty(
        toBN(tokenData.tokenId),
        [""],
        [toBN(0)],
        _web3,
        account,
        getChainFromChainId(chainId)
      );
    }
  };
  return (
    <>
      {metadata && (
        <Flex directionM="column">
          <div>
            {mimeType === "image" && (
              <CardImg src={formatIpfsUrl(metadata?.image)} />
            )}
            {mimeType === "video" && (
              <VideoCard>
                <source src={formatIpfsUrl(metadata?.image)} />
              </VideoCard>
            )}

            <div style={{ marginTop: 10 }}>
              <Text size="42" sizeM="24" weight="500">
                {metadata?.name}
              </Text>
              <IonText>{metadata?.description}</IonText>
            </div>
          </div>
        </Flex>
      )}
    </>
  );
};

export default NftDetail;
