import { IonContent, useIonModal } from "@ionic/react";
import { Flex, Logo, Spacer, Text } from "components";
import { ButtonPrimary, ButtonWhite } from "components/Button";
import AlertModal from "components/Modal/Alert";
import ConnectModal from "components/Modal/ConnectModal";
import SuccessModal from "components/Modal/MessageModal";
import { SectionTitle } from "components/SectionTitle";
import {
  Blockchain,
  GetEarlyAdopterDocument,
  GetEarlyAdopterQuery,
  useCreateEarlyAdopterMutation,
  useCreateUserMutation,
  useGetEarlyAdopterLazyQuery,
  UserAddressQueryDocument,
  UserAddressQueryQuery,
} from "generated/graphql-frontend";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useActiveWeb3React, useTopCreators } from "store/hooks";
import styled from "styled-components";
import { IUserReceiver } from "types/nft-user-types";
import { addNetwork } from "utils/metamask";
import { openExternalLink } from "utils/nft-user-helper";
import { truncateWalletAddress } from "utils/truncateWalletAddress";
import { MetaMask, WalletConnect } from "../connector";

/* fetch("https://api.twitter.com/1.1/users/show.json?screen_name=twitterdev", {
  headers: {
    Authorization: "Bearer <API_TOKEN>"
  }
}) */

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const HeroSection = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  z-index: -100;
  overflow: hidden;
  padding-top: 100px;
  background: linear-gradient(
      0deg,
      rgba(119, 72, 241, 0.1),
      rgba(119, 72, 241, 0.1)
    ),
    url("/bg/bg2.png"), url("/bg/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: rgba(119, 72, 241, 0.1);
    z-index: 1;
    position: absolute;
  }
  @media (max-width: 640px) {
    padding-top: 100px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-top: 150px;
  }
`;

const BgRapper = styled.div`
  width: 100%;
  position: relative;
  z-index: -1;
  padding: 20px;
  background: linear-gradient(
      0deg,
      rgba(119, 72, 241, 0.1),
      rgba(119, 72, 241, 0.1)
    ),
    url("/bg/bg2.png"), url("/bg/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: rgba(119, 72, 241, 0.1);
    z-index: 0;
    position: absolute;
  }
  .content {
    z-index: 1;
    position: relative;
  }
`;

const Right = styled.div`
  flex: 1;
`;

const Left = styled.div`
  background: linear-gradient(
    347.37deg,
    #6f59f9 0%,
    rgba(191, 89, 249, 0.51) 51.67%,
    rgba(89, 147, 249, 0.5) 95.26%
  );
  width: 40%;
  height: 100%;
  position: relative;
  @media (max-width: 640px) {
    background: #fff;
    width: 100%;
  }
`;

const Content = styled.div`
  margin: auto;
  max-width: 500px;
  margin-top: 150px;
  z-index: 9999;
  position: relative;

  @media (max-width: 640px) {
  }

  @media (min-width: 640px) and (max-width: 960px) {
    margin-left: 100px;

    #h1 {
      font-size: 46px;
      line-height: 52px;
    }

    /* #h2{
        font-size:42px;
        line-height: 23px;
       } */
  }
`;

const Nav = styled(Flex)`
  padding-left: 15px;
  padding-right: 20px;
  height: 100px;
  background: rgba(0, 0, 0, 0.1);
  mix-blend-mode: normal;
  position: absolute;
  width: 100%;

  div {
    color: #fff;
  }
`;

const Box = styled.div`
  @media (max-width: 640px) {
    top: 70px;
    position: relative;
    flex: 0;
    height: 100%;
  }
  #w-m {
    display: none;
    @media (max-width: 640px) {
      display: block;
    }
  }
  #w {
    @media (max-width: 640px) {
      display: none;
    }
  }
  img {
    position: absolute;
    top: 50%;
    transform: translate(-260px, -40%);
    max-width: 100%;
    @media (max-width: 640px) {
      transform: translate(-50%, 40%);
      transform: translate(-50%);
      max-width: 420px;
      margin: auto;
      left: 50%;
      position: relative;
      top: 50%;
      object-fit: cover;
    }

    @media (min-width: 640px) and (max-width: 1200px) {
      display: none;
      position: relative;
    }
  }
`;
// const Logo = styled.div`
//   margin-left: 10px;
//   display: flex;
//   align-items: center;
//   padding-left: 10px;
//   color: #6a64ff;
//   & .logo-text {
//     margin: 0px 10px;
//     font-size: 20px;
//     font-weight: bold;
//   }
// `;

const Inner = styled.div`
  max-width: 800px;
  margin: auto;
  text-align: center;
  color: white;
  z-index: 2;
  position: relative;

  @media (max-width: 640px) {
    width: 100%;
    padding: 10px;

    br {
      display: none;
    }
  }
`;

const CustomText1 = styled(Text)`
  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: white;
  text-align: center;
  @media (max-width: 640px) {
    line-height: 36px;
    font-size: 32px;
    padding: 0px 10px;
  }

  @media (min-width: 641px) and (max-width: 992px) {
    line-height: 46px;
    font-size: 42px;
  }
`;

const CustomText2 = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  @media (max-width: 640px) {
    line-height: 36px;
    font-size: 18px;
    padding: 0px 10px;
  }

  @media (min-width: 641px) and (max-width: 992px) {
    line-height: 32px;
    font-size: 20px;
  }
`;

const SocialCon = styled(Flex)`
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.08);
  width: 165px;
  height: 181px;
  margin: 10px;
  cursor: pointer;

  @media (max-width: 640px) {
    height: auto;
    width: 100;

    img {
      width: 40px;
    }
  }
`;

const Home: React.FC<IUserReceiver> = ({ user }) => {
  const [locked, setLocked] = useState(true);
  const { library, account, activate, chainId } = useActiveWeb3React();

  const [getEarlyAdopter, { data, error, loading }] =
    useGetEarlyAdopterLazyQuery();
  const { creators } = useTopCreators(8);
  const [createUserMutation] = useCreateUserMutation();
  const handleDismiseSuccess = () => {
    dismissPresentSuccess();
  };
  const [presentSuccess, dismissPresentSuccess] = useIonModal(SuccessModal, {
    close: handleDismiseSuccess,
    type: "share",
    primaryFunc: tweet,
    secondaryText: `Click the botton below to complete your paticipation.`,
  });

  const [createEarlyAdopterMutation] = useCreateEarlyAdopterMutation();
  const [text, setText] = useState("");
  // // eslint-disable-next-line
  // const triedEager = useEagerConnect();

  const handleDismise = () => {
    dismissPresentError();
  };

  const [presentError, dismissPresentError] = useIonModal(AlertModal, {
    close: handleDismise,
    type: "error",
    title: "Web3 Error",
    description: "Please use a web3 browser",
  });

  const dispatch = useDispatch();

  const unlockWallet = async (connector: string) => {
    try {
      switch (connector) {
        case "metamask":
          if (!window.ethereum) return presentError();
          await activate(MetaMask);
          break;
        case "walletconnect":
          await activate(WalletConnect);
          break;
        default:
          await activate(MetaMask);
          break;
      }
      if (chainId !== 1 && chainId !== 137) await addNetwork(137);
      dispatch({ type: "SET_ACCOUNT", payload: account });
      handleDismiss();
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleDismiss = () => {
    dismissConnectModal();
  };

  const [presentConnector, dismissConnectModal] = useIonModal(ConnectModal, {
    close: handleDismiss,
    connect: unlockWallet,
  });

  const openConnectors = () => {
    presentConnector();
  };

  const hashTags = `Coterie,CoterieMarketplace,CoterieEarlyAdopterVerification,NFTs,AnythingTokenized`;
  function tweet() {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        "https://coteriefinance.org"
      )}&text=${text}&hashtags=${hashTags}`,
      "_blank"
    );
  }

  const signerr = async () => {
    try {
      if (user) {
        if (!user?.isEarlyAdopter) {
          if (window.ethereum) {
            const EIP712Domain = [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              { name: "verifyingContract", type: "address" },
            ];

            const EarlyAdopter = [
              { name: "user", type: "address" },
              { name: "attestation", type: "string" },
            ];
            let address: string, chain: Blockchain;
            if (chainId === 1) {
              address = "0x7f4999E57d6A77aaa450Fe243595F90af9FDFb8C";
              chain = Blockchain.Eth;
            } else if (chainId === 137) {
              address = "0x5d343dAD9618861fA55ce700607ab7bE23690729";
              chain = Blockchain.Matic;
            }

            const domain = {
              name: "Coterie Early Adopter",
              version: "1",
              chainId,
              verifyingContract: address,
            };

            const message = {
              user: account,
              attestation: "I verify as Coterie early adopter.",
            };

            const _data = JSON.stringify({
              types: {
                EIP712Domain,
                EarlyAdopter,
              },
              domain,
              primaryType: "EarlyAdopter",
              message,
            });

            library?.provider?.sendAsync(
              {
                method: "eth_signTypedData_v4",
                params: [account, _data],
              },
              async (err, { result }) => {
                if (err) {
                  console.error(err);
                }
                if (result) {
                  setText(`${result} @CoterieDaoHQ `);
                  await createEarlyAdopterMutation({
                    variables: {
                      address: account, // value for 'address'
                      signature: result, // value for 'signature'
                      chain,
                    },

                    update: (cache, dataa) => {
                      const data_ = Object.assign({}, user, {
                        getUserByAddress: {
                          isEarlyAdopter: true,
                        },
                      });
                      const data2 = Object.assign({}, data?.getEarlyAdopter, {
                        ...dataa.data.createEarlyAdopter,
                      });
                      cache.writeQuery<GetEarlyAdopterQuery>({
                        query: GetEarlyAdopterDocument,
                        variables: { address: account },
                        data: {
                          __typename: "Query",
                          getEarlyAdopter: data2,
                        },
                      });

                      cache.writeQuery<UserAddressQueryQuery>({
                        query: UserAddressQueryDocument,
                        variables: { getUserAddress: account },
                        data: {
                          __typename: "Query",
                          getUserByAddress: data_,
                        },
                      });
                    },
                  });
                  presentSuccess();
                }
              }
            );
          }
        } else presentSuccess();
      }
      return unlockWallet("metamask");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (account) {
      setLocked(false);
      if (!user) {
        createUserMutation({
          variables: {
            address: account,
          },
        });
      }

      if (user?.isEarlyAdopter)
        getEarlyAdopter({
          variables: {
            address: account, // value for 'address'
          },
        });
      if (!error && !loading && data?.getEarlyAdopter) {
        setText(`${data?.getEarlyAdopter?.signature} @CoterieDaoHQ `);
      }
    }
    // eslint-disable-next-line
  }, [account, data, user]);

  return (
    <IonContent>
      <Container>
        <Nav justify="space-between" align="center">
          <Logo>
            <img src="/svgs/coterie_wt.svg" alt="Coterie logo" width="100" />
          </Logo>
          <div>
            {locked && (
              <Text
                size="16"
                weight="600"
                style={{ cursor: "pointer" }}
                onClick={() => openConnectors()}
              >
                Connect Wallet
              </Text>
            )}

            {!locked && (
              <Link to={"/"}>
                <Text size="16" weight="600">
                  {truncateWalletAddress(account, 6, 4).toUpperCase()}
                </Text>
              </Link>
            )}
          </div>
        </Nav>
        <HeroSection>
          <Inner>
            <CustomText1 sizeM="32">
              Join a thriving community <br />
              of NFT creators <br />& curators
            </CustomText1>
            <CustomText2 sizeM="18">
              Create, Collaborate, Collect, and <br /> Flaunt unique tokenized
              assets
            </CustomText2>
            <Spacer height={20} />
            <Flex
              directionM="column"
              align="center"
              justify="center"
              className="home-p"
            >
              <ButtonPrimary
                onClick={() =>
                  openExternalLink("https://discord.gg/6HvfnSk7zK")
                }
                width="300px"
                mHeight="66"
              >
                {" "}
                Join as an early adopter
              </ButtonPrimary>
              <Spacer width={20} />
              <ButtonWhite
                disabled={false}
                onClick={() => signerr()}
                color="light"
                fill="outline"
                width="300px"
                mHeight="66"
              >
                {" "}
                Verify as an early adopter
              </ButtonWhite>
            </Flex>

            {/* <Flex justify="center" style={{ marginTop: 20 }}>
              <Flex align="center">
                <Polygon />
                <Flex
                  direction="column"
                  style={{ marginLeft: 10 }}
                  justify="center"
                >
                  <Text size="64" sizeM="32" weight="600" color="#fff">
                    5453
                  </Text>
                  <Text size="24" sizeM="18" color="#fff">
                    Curators
                  </Text>
                </Flex>
              </Flex>

              <Spacer width={30} />

              <Flex align="center">
                <Polygon />
                <Flex
                  direction="column"
                  style={{ marginLeft: 10 }}
                  justify="center"
                >
                  <Text size="64" sizeM="32" weight="600" color="#fff">
                    2532
                  </Text>
                  <Text size="24" sizeM="18" color="#fff">
                    Creators
                  </Text>
                </Flex>
              </Flex>
            </Flex> */}
          </Inner>
        </HeroSection>
        {/* <BgRapper>

          <div style={{ textAlign: "center" }}>
            <SectionTitle color="#fff" title="Top Creators" shadowTitle="" />
          </div>

          <Spacer height={20} />
          <GridCreator>
            {creators?.length > 0 &&
              creators?.map((creator: any, i: number) => (
                <NftCreatorCard {...{ user, creator, account }} key={i} />
              ))}
          </GridCreator>

        </BgRapper> */}

        <BgRapper style={{ height: "300px" }}>
          <div style={{ textAlign: "center" }}>
            <SectionTitle
              color="#fff"
              title="Join us on social media"
              shadowTitle=""
            />
          </div>

          <Flex className="content" align="center" justify="center">
            <SocialCon
              onClick={() => openExternalLink("https://discord.gg/9dnmVWjArH")}
              direction="column"
              align="center"
              justify="center"
            >
              <img
                height="54"
                width="55"
                src="/svgs/socials/discord.svg"
                alt="Discord"
              />
              <Text size="18" weight="600" style={{ margin: "5px 0px" }}>
                Discord
              </Text>
              {/* <Text weight="500" style={{ color: "#9CA3AF" }}>
                2.4k Member
              </Text> */}
            </SocialCon>

            <SocialCon
              onClick={() =>
                openExternalLink("https://twitter.com/CoterieDaoHQ?t=7")
              }
              direction="column"
              align="center"
              justify="center"
            >
              <img
                height="54"
                width="55"
                src="/svgs/socials/twitter.svg"
                alt="Twitter"
              />
              <Text size="18" weight="600" style={{ margin: "5px 0px" }}>
                Twitter
              </Text>
              {/* <Text weight="500" style={{ color: "#9CA3AF" }}>
                2.4 Member
              </Text> */}
            </SocialCon>

            <SocialCon
              onClick={() => openExternalLink("https://t.me/CoterieDaoAnn")}
              direction="column"
              align="center"
              justify="center"
            >
              <img
                height="54"
                width="55"
                src="/svgs/socials/telegram.svg"
                alt="Telegram"
              />
              <Text size="18" weight="600" style={{ margin: "5px 0px" }}>
                Telegram
              </Text>
              {/* <Text weight="500" style={{ color: "#9CA3AF" }}>
                2.4 Member // update member count
              </Text> */}
            </SocialCon>

            {/* <SocialCon direction="column" align="center" justify="center">
              <img
                height="54"
                width="55"
                src="/svgs/socials/instagram.svg"
                alt="Instagram"
              />
              <Text size="18" weight="600" style={{ margin: "5px 0px" }}>
                Instagram
              </Text>
              <Text weight="500" style={{ color: "#9CA3AF" }}>
                2.4 Member
              </Text> 
            </SocialCon> */}
          </Flex>
        </BgRapper>
      </Container>
    </IonContent>
  );
};

export default Home;
