import { useEffect, useState,useCallback } from 'react'
import {auctionContract,toEth} from './../utils/contractHelper'
import { getFactoryAddress} from './../utils/addressHelper'
import { useSelector } from "react-redux";


interface AuctionInt {
  min:any;
  max:any;
  hardCap:any
}
/**
 * Returns whether or not the current lottery has drawn number
 *
 * @return Array
 */
export const useMinMax=(auctionAddress:string) => {
   let [minmax,setMinMax]=useState<AuctionInt>({min:'--',max:'--',hardCap:'--'})
   const network = useSelector((state: any) => state.user.networkId); 
 
 const getMm = useCallback(
   async (network)=>{
    let address:string= getFactoryAddress(network)
       try {
               let auction=await auctionContract(auctionAddress)
  
               const minimumPurchaseEth = await auction.methods.minimumPurchaseEth().call();
               const maximumPurchaseEth = await auction.methods.maximumPurchaseEth().call();
               const hardCap = await auction.methods.hardCap().call();
  
        
               setMinMax({
                   min:await toEth(minimumPurchaseEth),
                   max:await toEth(maximumPurchaseEth),
                   hardCap:await toEth(hardCap)
               }) 

               console.log(minmax,'val')

        } catch (error) {
          console.log(error)
        }
  },[])

  useEffect(() => {
    getMm(network)
  },[getMm,network])
   
  return {...minmax}

  
}




