import BigNumber from "bignumber.js";
import { Blockchain } from "generated/graphql-frontend";
import { IPaymentsTo } from "types/nft-user-types";
import { toDefaultDecimal } from "utils/BigNumber";
import { getNFTMarketplaceContract } from "utils/contractHelps/contractsHelper";
import {
  cancelAuction,
  changeAuctionOwnerAddress,
  closeAuction,
  createAuction,
  makeBid,
  updateBasePrice,
  updatePaymentMethod,
} from "utils/contractHelps/NFTs/nft-mktplace/nftMktplaceCalls";
import { toChecksum } from "utils/nft-user-helper";
import Web3 from "web3";

export const use_createAuction = (
  payTo: IPaymentsTo[],
  tokenAddress: string,
  tokenId: BigNumber,
  basePrice: BigNumber,
  paymentMethod: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  console.log(`payTo`, payTo);
  return createAuction(
    getNFTMarketplaceContract(_web3, network),
    payTo,
    tokenAddress,
    tokenId.toString(10),
    toDefaultDecimal(basePrice).toString(10),
    toChecksum(paymentMethod),
    account
  );
};

export const use_closeAuction = (
  auctionId: BigNumber,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return closeAuction(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(10),
    account
  );
};

export const use_cancelAuction = (
  auctionId: BigNumber,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return cancelAuction(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(10),
    account
  );
};

export const use_makeBid = (
  auctionId: BigNumber,
  bidValue: BigNumber,
  paymentMethod: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  console.log("Bid value: ", toDefaultDecimal(bidValue).toString(10));
  return makeBid(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(10),
    toDefaultDecimal(bidValue).toString(10),
    paymentMethod,
    account
  );
};

export const use_changeAuctionOwnerAddress = (
  auctionId: BigNumber,
  newOwnerAddress: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return changeAuctionOwnerAddress(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(10),
    newOwnerAddress,
    account
  );
};

export const use_updatePaymentMethod = (
  auctionId: BigNumber,
  paymentMethod: string,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return updatePaymentMethod(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(),
    paymentMethod,
    account
  );
};

export const use_updateBasePrice = (
  auctionId: BigNumber,
  newBasePrice: BigNumber,
  _web3: Web3,
  account: string,
  network: Blockchain | "test"
) => {
  return updateBasePrice(
    getNFTMarketplaceContract(_web3, network),
    auctionId.toString(10),
    newBasePrice.toString(10),
    account
  );
};
