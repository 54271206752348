import {
  IonButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonText,
} from "@ionic/react";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import Moment from "react-moment";
import { useNetwork } from "store/hooks";
import styled from "styled-components";
import { getNSyb, getScanAddress } from "utils/addressHelper";
import { enableRelease } from "utils/conv";
import { AuctionDetailsProps } from "utils/types";

const DetailContainer = styled.div`
  background: var(--ion-color-card);
  box-shadow: 0px 0px 4px #aaa;
  border-radius: 5px;
  margin: 0px 10px;

  @media (max-width: 640px) {
    margin: 0px;
  }
`;

const DetailHeader = styled.div`
  background-color: #2a2290;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  min-height: 150px;
`;

const ProgressContainer = styled.div`
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Details = styled.div`
  padding: 10px;
  background: var(--ion-color-card);
  margin: 10px;
`;

interface Details {
  auction: AuctionDetailsProps;
  showBuy: () => void;
  release: any;
  cases0: any;
}

const AuctionDetails: React.FC<Details> = ({
  auction: {
    amountPercent,
    roi,
    rate,
    hardCap,
    availableTokenForSale,
    initialLiquidity,
    symbol,
    auctionAddress,
    amountRaised,
    tokenName,
    exchangeLaunched,
    salesCompleted,
    ethBal,
  },
  showBuy,
  release: {
    firstRelease,
    secondRelease,
    thirdRelease,
    isFirstR,
    isSecondR,
    isThirdR,
  },
  cases0,
}) => {
  const network = useNetwork();
  const networkSymbol = getNSyb(network);
  const scanUrl = getScanAddress(network);

  const burnLiquidityFromAuction = () => {
    alert("burn liqidity");
  };
  const checkBurnable = () => true;
  // cases0.filter(({ passed }) => passed).length >= 2 ||
  // cases0.filter(({ time }) => time > secondRelease).passed === true;

  const launchToken = () => {
    alert("launch token");
  };

  const releaseLiquidity = () => {
    alert("release loquidity");
  };

  return (
    <DetailContainer>
      <DetailHeader>
        <div>
          <IonButton href={scanUrl + auctionAddress} target="_blank">
            {auctionAddress.substr(0, 5)} ... {auctionAddress.substr(-5)}
          </IonButton>
        </div>
        <ProgressContainer>
          <div style={{ width: "70px" }}>
            <CircularProgressbar
              value={amountPercent}
              maxValue={1}
              text={`${amountPercent || 0}%`}
            />
          </div>
          <h4>
            Total Amount Raised: {amountRaised} {networkSymbol}
          </h4>
          <p>
            <b>Closes on: </b>
            <Moment fromNow>{new Date()}</Moment>
          </p>
        </ProgressContainer>
      </DetailHeader>
      <Details>
        <h4> {tokenName}</h4>
        <IonItem>
          <IonLabel>Initial Liquidity Value</IonLabel>
          <IonText>
            <b>{initialLiquidity || "--"}% of HC</b>
          </IonText>
        </IonItem>

        <IonItem>
          <IonLabel> Hardcap {networkSymbol}</IonLabel>
          <IonText>
            <b>
              {hardCap} {networkSymbol}
            </b>
          </IonText>
        </IonItem>

        <IonItem>
          <IonLabel>Return on Investment</IonLabel>
          <IonText>
            <b>{roi}%</b>
          </IonText>
        </IonItem>

        <IonItem>
          <IonLabel>Rate</IonLabel>
          <IonText>
            <b>{rate}</b>
          </IonText>
        </IonItem>

        <IonItem>
          <IonLabel>Available {symbol} for sale</IonLabel>
          <IonText>
            <b>
              {availableTokenForSale} {symbol}
            </b>
          </IonText>
        </IonItem>

        <IonItemDivider />

        {exchangeLaunched && (
          <IonButton
            fill="solid"
            onClick={burnLiquidityFromAuction}
            disabled={!checkBurnable()}
          >
            Burn LP Token
          </IonButton>
        )}

        {salesCompleted && !exchangeLaunched && (
          <IonButton onClick={launchToken} shape="round" disabled={false}>
            Launch Exchange
          </IonButton>
        )}
        <IonButton
          onClick={releaseLiquidity}
          disabled={
            !enableRelease(
              firstRelease,
              secondRelease,
              thirdRelease,
              isFirstR,
              isSecondR,
              isThirdR,
              ethBal,
              exchangeLaunched
            ) || false
          }
        >
          Release Liquidity
        </IonButton>

        {!salesCompleted && (
          <IonButton
            fill="solid"
            expand="block"
            onClick={showBuy}
            disabled={salesCompleted || +availableTokenForSale === 0}
          >
            Buy
          </IonButton>
        )}
      </Details>
    </DetailContainer>
  );
};

export default AuctionDetails;
