import React from "react";
import styled from "styled-components";
import { PoolObject } from "../../types/farm-types";
import ListItem from "./ListItem";


const Container = styled.div`
    background: var(--ion-color-card);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 100%;
`;

const Header = styled.div`
    display:grid;
    grid-template-columns: repeat(6, 1fr);
    padding:10px;
    justify-content:space-between;
    background: #2A3390;
    border-radius: 10px 10px 0px 0px;
    color: #CACCE3;
    font-size: 16px;
    line-height: 26px;
@media(max-width:600px){
  grid-template-columns: repeat(3, 1fr);
}
`;
const IonTextCustom = styled.div`
   width: 95%;
   margin: auto;
   @media(max-width: 600px){
   font-size: 14px;
   width: 100%;
   margin-right:20px;
   &:nth-of-type(2), &:nth-of-type(5){
     @media(max-width: 600px){
       display: none;
     }
   }
}
`

const ListItemCon = styled.div`
width: 100%;

`

 
interface BannerInterface {
    farms: Array<PoolObject>
    unlock: ()=>void
    locked: boolean
    openWithdrawModal: (poolObj:PoolObject)=> void
    openDepositModal: (poolObj:PoolObject)=> void
    claim: (pool:PoolObject)=> void
}

const PoolBanner: React.FC<BannerInterface> = ({farms, unlock, locked, openDepositModal, openWithdrawModal, claim}) => {
    return (
    <Container >
        <Header>
          <IonTextCustom><strong>Token</ strong></IonTextCustom>
          <IonTextCustom><strong>Earned</strong></IonTextCustom>
          <IonTextCustom><strong>APR</strong></IonTextCustom>
          <IonTextCustom><strong>Liquidity</strong></IonTextCustom>
          <IonTextCustom><strong>Multiplier</strong></IonTextCustom>
          <IonTextCustom></IonTextCustom>
        </Header>
        <ListItemCon>
          {
            farms.map((farm:PoolObject,i:any) => (
              < ListItem key={i} farm={farm} locked={locked} openDepositModal={openDepositModal} openWithdrawModal={openWithdrawModal} unlock={unlock} claim={claim} />
            ))
          }
        </ListItemCon>
    </Container>
    )
}

export default PoolBanner


