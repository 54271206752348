import { ButtonPrimary, ButtonWhite } from "components/Button";
import { NftCard } from "components/Card";
import { Auction } from "generated/graphql-frontend";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const HeroContainer = styled.div`
  background: #fff;
`;

const HeroWraper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const Left = styled.div`
  background: #fff;
  padding: 20px 50px;
  width: 100%;
  margin: auto;

  @media (min-width: 640px) {
    width: 50%;
  }

  .inner {
    margin: auto;
    width: 100%;

    @media (min-width: 640px) {
      max-width: 530px;
    }
  }

  h1 {
    font-weight: 600;
  }
`;

const Right = styled.div`
  background: #fff;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0px 0px;

  .card {
    margin: auto;
    height: 450px;
    margin: auto;
    width: 100%;
    padding: 10px;

    @media (min-width: 640px) {
      width: 440px;
      padding: 20px 10px;
    }
  }

  @media (min-width: 640px) {
    padding: 20px 10px;
  }
`;

const Action = styled.div`
  display: flex;
`;

interface IHero {
  auction?: Auction;
  backward?: () => void;
  forward?: () => void;
}

const Hero: FC<IHero> = ({ auction, backward, forward }) => {
  const [auctionDisplay, setAuctionDisplay] = useState(auction);
  const history = useHistory();
  useEffect(() => {
    setAuctionDisplay(auction);
  }, [auction]);
  return (
    <HeroContainer>
      <HeroWraper>
        <Left>
          <div className="inner">
            <h1>Join the Largest NFTs shop in the world</h1>
            <p>
              Create, Collaborate, Collect, and Flaunt unique tokenized crypto
              assets
            </p>
            <Action>
              <ButtonPrimary
                onClick={() => history.push("/nfts")}
                width="120px"
              >
                <b>Explore</b>
              </ButtonPrimary>
              <ButtonWhite
                onClick={() => history.push("/nfts/create")}
                width="120px"
              >
                <b>Create</b>
              </ButtonWhite>
            </Action>
          </div>
        </Left>
        <Right>
          <div className="card">
            <NftCard auction={auctionDisplay} />
          </div>
        </Right>
      </HeroWraper>
    </HeroContainer>
  );
};

export default Hero;
