import { Blockchain } from "generated/graphql-frontend";
import { BIG_TEN, toBN } from "../utils/BigNumber";

export const BSC_BLOCK_TIME = 3;

export const COTERIE_PER_BLOCK = toBN(40);
export const BLOCKS_PER_YEAR = toBN((60 / BSC_BLOCK_TIME) * 60 * 24 * 365); // 10512000
export const COTERIE_PER_YEAR = COTERIE_PER_BLOCK.times(BLOCKS_PER_YEAR);
export const BASE_URL = "https://coteriefinance.org";
export const BASE_EXCHANGE_URL = "https://exchange.delfyfinance.org";
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`;
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`;
export const TWITTER_URL = "https://twitter.com/";
export const TELEGRAM_URL = "https://t.me/";
export const INSTAGRAM_URL = "https://instagram.com/";
export const LINKEDIN_URL = "https://linkedin.com/in/";
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const DEFAULT_GAS_LIMIT = 200000;
export const DEFAULT_GAS_PRICE = 5;
export const TESTNET_CHAIN_ID = "97";
export const MAINNET_CHAIN_ID = "56";

const scan_url = {
  bsc: {
    trx: "https://bscscan.com/tx/",
    address: "https://bscscan.com/address",
  },
  test: {
    trx: "https://testnet.bscscan.com/tx/",
    address: "https://testnet.bscscan.com/address/",
  },
  eth: {
    trx: "https://etherscan.io/tx/",
    address: "https://etherscan.io/address/",
  },
  avax: {
    trx: "https://snowtrace.io/tx/",
    address: "https://snowtrace.io/address/",
  },
  matic: {
    trx: "https://polygonscan.com/tx/",
    address: "https://polygonscan.com/address/",
  },
};

export const getExplorerUrl = (
  chain: Blockchain | "test",
  type: "trx" | "address"
) => {
  return scan_url[chain][type];
};
