import { Blockchain } from "generated/graphql-frontend";

const contracts = {
  nftMktplace: {
    test: "0x6A305Ed83b2a432052A90Be931Dc6823c7850615", //bsc testnet
    bsc: "0x6A305Ed83b2a432052A90Be931Dc6823c7850615",
    matic: "",
    avax: "",
    eth: "",
  },

  nftRegistry: {
    test: "0xc56608c953936D9424925DA3BE3a38AB242ff235", //bsc testnet
    bsc: "0xc56608c953936D9424925DA3BE3a38AB242ff235",
    matic: "",
    avax: "",
    eth: "",
  },
  nftToken: {
    test: "0x666164F8dB6a8973AAf37a6cf465990e6Ca4d4C7", //bsc testnet
    bsc: "0x666164F8dB6a8973AAf37a6cf465990e6Ca4d4C7",
    matic: "",
    avax: "",
    eth: "",
  },

  multiCall: {
    test: "0x36643Cf4e44f9928878606813376Bf857435B43A",
    // bsc: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B", // original bsc
    bsc: "0x36643Cf4e44f9928878606813376Bf857435B43A",
    matic: "",
    avax: "",
    eth: "",
  },
};

export default contracts;

export const getNftContractAddress = (
  contract_name: "nftMktplace" | "nftRegistry" | "nftToken" | "multiCall",
  network: Blockchain | "test"
) => {
  return contracts[contract_name][network];
};
