import Api from "api";
import { Flex } from "components";
import {
  Auction,
  Bid,
  EventType,
  GetNotificationDocument,
  MinterAccessRequest,
  Notification,
  useGetAuctionLazyQuery,
  useGetBidLazyQuery,
  useGetRequestLazyQuery,
  User,
  useUpdateNotificationMutation,
  useUserIdQueryLazyQuery,
} from "generated/graphql-frontend";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { getFullDisplayBalance } from "utils/formatBalance";
import { formatIpfsUrl } from "utils/ipfsUrlHelper";
import { getTokenSymbol, sleep } from "utils/nft-user-helper";
interface NotiItemsInt {
  type?: EventType;
  description?: string;
}

const NotiCon = styled.div`
  background: #fff;
  margin: 20px 10px !important;

  h4 {
    padding: 0px !important;
    font-weight: 600;
    margin: 0px !important;
  }
  p {
    margin: 5px 0px;
  }
  /* padding: 10px 10px !important; */
  /* cursor:pointer;

   &:hover{
    background:#ecf1f5;
   } */

  .dot {
    height: 50px;
    width: 50px;
    text-align: center;
    /* background: #ccc; */
    display: flex;

    span {
      margin: 8px auto;
      display: block;
      height: 10px;
      width: 10px;
      background: linear-gradient(
        0deg,
        #6a64ff 0%,
        rgba(106, 100, 255, 0) 292.71%
      );
      border-radius: 50%;
    }
  }

  a {
    font-weight: bold;
    color: #000000;
  }
`;

const formatNotData = (type: EventType) => {
  switch (type) {
    case EventType.Announcement:
      return "Announcement ";
    case EventType.AuctionDelisted:
      return "Auction has been delisted";
    case EventType.AuctionSold:
      return (
        <p>
          Auction sold out <Link to="/">View Auction</Link>
        </p>
      );
    case EventType.BidAccepted:
      return (
        <p>
          Your Bid has been Accepted <Link to="/">View Bid</Link>
        </p>
      );
    case EventType.Claimable:
      return (
        <p>
          You have a claimable Assets <Link to="/">View Auction</Link>
        </p>
      );
    case EventType.NewBid:
      return (
        <p>
          You have a new Bid <Link to="/">View Bid</Link>
        </p>
      );
    case EventType.NewFollow:
      return (
        <p>
          You have a new Follower{" "}
          <Link to="/me?page=people">View Followers</Link>
        </p>
      );
    case EventType.OutBid:
      return "Out Bid";
    case EventType.RequestConfirmed:
      return (
        <p>
          Auction sold out<Link to="/">Request Confirmed</Link>
        </p>
      );
    case EventType.RequestRejected:
      return <p>Request Rejected</p>;
    case EventType.NewRequest:
      return <p>New Creator Auctorization Request from</p>;
    default:
      return "Got Unknown notification";
  }
};

const NotifItems = (notification: Notification) => {
  const [notificationData, setNotificationData] = useState<{
    desc: JSX.Element;
    header: string;
    link: string;
    extra?: string;
  }>(null);
  const [getUser, userRaw] = useUserIdQueryLazyQuery();
  const [getAuction, auctionRaw] = useGetAuctionLazyQuery();
  const [getBid, bidRaw] = useGetBidLazyQuery();
  const [getRequest, requestRaw] = useGetRequestLazyQuery();
  const [updateNotificationMutation] = useUpdateNotificationMutation();

  const [auction, setAuction] = useState<Auction>(null);
  const [user, setUser] = useState<User>(null);
  const [bid, setBid] = useState<Bid>(null);
  const [request, setRequest] = useState<MinterAccessRequest>(null);
  const history = useHistory();
  const handler = (url: string) => {
    url && history.push(url);
    updateNotificationMutation({
      variables: {
        notificationId: notification?.id,
      },
      refetchQueries: () => [
        {
          query: GetNotificationDocument,
          variables: {
            limit: 20,
            userId: notification?.to?.id,
          },
        },
      ],
    });
  };
  async function formatNotification() {
    const { eventType, description, eventId } = notification;
    // console.log(
    //   "eventType,description,eventId :>> ",
    //   eventType,
    //   description,
    //   eventId
    // );

    switch (eventType) {
      case EventType.Announcement:
        // announcement desc
        return;
      case EventType.AuctionDelisted:
        // auction name and auction ID
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: eventId,
            },
          });

          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          console.log("auction :>> ", auction);
        }
        return;
      case EventType.AuctionSold:
        // auction name and auction ID
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: eventId,
            },
          });
          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          const meta =
            auctionRaw.data?.getAuction?.nftToken?.tokenUri &&
            (await Api.nft.ipfs(
              formatIpfsUrl(auctionRaw.data?.getAuction?.nftToken?.tokenUri)
            ));
          const data: any = meta?.data;
          console.log("data :>> ", data);
          setNotificationData({
            link: `/nfts/nft/${auctionRaw.data?.getAuction?.id}`,
            header: "Auction Sold",
            desc: (
              <p>
                Your <i>{data?.name}</i> auction sold.
              </p>
            ),
          });
        }
        break;
      case EventType.BidAccepted:
        // auction name, auction ID, and Bid Value
        if (!bid) {
          await getBid({
            variables: {
              bidId: eventId,
            },
          });
          setBid(
            () =>
              !bidRaw.error && !bidRaw.loading && (bidRaw.data?.getBid as Bid)
          );
        }
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: bid?.auction?.id,
            },
          });
          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          const meta = await Api.nft.ipfs(
            formatIpfsUrl(auctionRaw.data?.getAuction?.nftToken?.tokenUri)
          );
          const data: any = meta?.data;
          setNotificationData({
            link: `/nfts/nft/${auctionRaw.data?.getAuction?.id}`,
            header: "Auction Sold",
            desc: (
              <p>
                Your offer for <i>{data?.name}</i> auction was accepted.
              </p>
            ),
          });
        }
        break;
      case EventType.Claimable:
        // claimable value, type, and auction ID
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: eventId,
            },
          });
          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          return <p></p>;
        }
        break;
      case EventType.NewBid:
        // auction name, auction ID, and Bid Value
        if (!bid) {
          await getBid({
            variables: {
              bidId: eventId,
            },
          });
          setBid(
            () =>
              !bidRaw.error && !bidRaw.loading && (bidRaw.data?.getBid as Bid)
          );
        }
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: bid.auction.id,
            },
          });
          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          const meta = await Api.nft.ipfs(
            formatIpfsUrl(auctionRaw.data?.getAuction?.nftToken?.tokenUri)
          );
          const data: any = meta?.data;
          setNotificationData({
            link: `/nfts/nft/${auctionRaw.data?.getAuction?.id}`,
            header: "New Offer",
            desc: (
              <p>
                <u style={{ color: "cyan" }}>
                  <i>{data?.name}</i>
                </u>
                received a new offer.
              </p>
            ),
            extra: `Current Value: ${getFullDisplayBalance(
              auctionRaw.data?.getAuction?.closingValue
            )} ${getTokenSymbol(auctionRaw.data?.getAuction?.paymentMethod)}`,
          });
        }
        break;
      case EventType.NewFollow:
        // new follower name and his ID
        if (!user) {
          await getUser({
            variables: {
              getUserId: eventId,
            },
          });

          setUser(
            () =>
              !userRaw.loading &&
              !userRaw.error &&
              (userRaw.data?.getUser as User)
          );
          setNotificationData({
            link: `/users/user/${userRaw.data?.getUser?.id}`,
            header: "New follow",
            desc: (
              <p>
                <u>
                  <i>{userRaw.data?.getUser?.name ?? "A user"}</i>
                </u>{" "}
                started following you.
              </p>
            ),
          });
        }
        break;
      case EventType.OutBid:
        // auction name, auction ID, and Bid Value
        if (!bid) {
          await getBid({
            variables: {
              bidId: eventId,
            },
          });
          setBid(
            () =>
              !bidRaw.error && !bidRaw.loading && (bidRaw.data?.getBid as Bid)
          );
        }
        if (!auction) {
          await getAuction({
            variables: {
              getAuctionId: bidRaw.data?.getBid?.auction?.id,
            },
          });

          setAuction(
            () =>
              !auctionRaw.error &&
              !auctionRaw.loading &&
              (auctionRaw.data?.getAuction as Auction)
          );
          const meta = await Api.nft.ipfs(
            formatIpfsUrl(auctionRaw.data?.getAuction?.nftToken?.tokenUri)
          );
          const data: any = meta?.data;
          setNotificationData({
            header: "Auction Outbid",
            link: `/nfts/nft/${auctionRaw.data?.getAuction?.id}`,
            desc: (
              <p>
                You offer for{" "}
                <u>
                  <i>{data?.name}</i>
                </u>{" "}
                auction was dropped.
              </p>
            ),
            extra: `Current Value: ${getFullDisplayBalance(
              auctionRaw.data?.getAuction?.closingValue
            )} ${getTokenSymbol(auctionRaw.data?.getAuction?.paymentMethod)}`,
          });
        }
        break;
      case EventType.RequestConfirmed:
        // request confirmed by USER (name & ID),
        if (!request) {
          await getRequest({
            variables: {
              requestId: eventId,
            },
          });
          setRequest(
            () =>
              !requestRaw.error &&
              !requestRaw.loading &&
              (requestRaw.data?.getRequest as MinterAccessRequest)
          );
          setNotificationData({
            link: `/users/user/${requestRaw.data?.getRequest?.to?.id}`,
            header: "Creator Access Granted",
            desc: (
              <p>
                Your creator access request was confirmed by{" "}
                <u>
                  <i>{requestRaw.data?.getRequest?.to?.name}.</i>
                </u>
              </p>
            ),
          });
        }
        return;
      // case EventType.RequestRejected:
      //   // request sent to USER (name & ID),
      //   return;
      case EventType.NewRequest:
        // new request from USER (name & ID),
        if (!request) {
          await getRequest({
            variables: {
              requestId: eventId,
            },
          });
          setRequest(
            () =>
              !requestRaw.error &&
              !requestRaw.loading &&
              (requestRaw.data?.getRequest as MinterAccessRequest)
          );
          setNotificationData({
            link: `/users/user/${requestRaw.data?.getRequest?.from?.id}`,
            desc: (
              <p>
                You have a new access request from{" "}
                <u>
                  <i>
                    {requestRaw.data?.getRequest?.from?.name ?? "a new user"}
                  </i>
                </u>
                .
              </p>
            ),
            header: "Creator Access Request",
          });
        }
        break;
      default:
        sleep(1000);
        break;
    }
  }

  const [, setState] = useState(null);
  useEffect(() => {
    formatNotification();
    return () => {
      setState({});
    };
    // eslint-disable-next-line
  }, [
    notification,
    requestRaw.data?.getRequest,
    bidRaw.data?.getBid,
    auctionRaw.data?.getAuction,
    userRaw.data?.getUser,
  ]);

  return (
    <NotiCon>
      <Flex
        style={{
          background: notification?.seen ? "#fff" : "#eee",
        }}
      >
        <div className="dot">
          <span></span>
        </div>
        <div
          onClick={() => handler(notificationData?.link)}
          style={{
            flex: 1,
            paddingLeft: 10,
            cursor: "pointer",
          }}
        >
          <h4>{notificationData?.header}</h4>
          {notificationData?.desc}
          <p>
            <i>{notificationData?.extra}</i>
          </p>
        </div>
        <div></div>
      </Flex>
    </NotiCon>
  );
};

export default NotifItems;
