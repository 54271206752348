/* eslint-disable require-yield */
import { setAuctionLoading, setAuctions } from "features/nftsAuction/NftAuctionSlice2";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchAuctions } from "states/nftAuction/nft-mkt-m_calls";
import { FETCH_CONTRACT_NFTS } from "./types";

function* fetchContractNfts() {
    yield put(setAuctionLoading(true));
    const data: [] = yield call(fetchAuctions);
    if (data) {
        yield put(setAuctions(data));
        yield put(setAuctionLoading(false));
    } else {
        alert("error");
        yield put(setAuctionLoading(false));
    }
}

// use them in parallel
export default function* contractAuctionSaga() {
    yield takeLatest(FETCH_CONTRACT_NFTS, fetchContractNfts);
}
