import React from 'react';
import styled from 'styled-components';
import {Text} from "components";


interface SectionTitleProps{
    title:string;
    shadowTitle?:string;
    color?:string

}


const Container = styled.div`
  padding:10px;
  position: relative;
`


export const SectionTitle=({title,shadowTitle,color}:SectionTitleProps)=> {
    return (
        <Container>
            <Text style={{color:color || ''}} size="32" weight="500">{ title }</Text>
            <Text size="96" style={{marginTop:'-23px',position:'absolute'}} color="rgba(0,0,0,0.15)" weight="700">{shadowTitle}</Text>
        </Container>
    );
}
