export const FETCH_AUCTIONS = "FETCH_AUCTIONS";
export const AUCTIONS_FETCHED = "AUCTIONS_FETCHED";

export const FETCH_AUCTIONS_DETAILS = "FETCH_AUCTIONS_DETAILS";
export const FETCH_AUCTIONS_DETAILS_FETCHED = "FETCH_AUCTIONS_DETAILS_FETCHED";

export const FETCH_AUCTIONS_CASES = "FETCH_AUCTIONS_CASES";
export const FETCH_AUCTIONS_CASES_FETCHED = "FETCH_AUCTIONS_CASES_FETCHED";

export const FETCH_AUCTIONS_LOCKER = "FETCH_AUCTIONS_LOCKER";

export const FILTER_AUCTIONS = "FILTER_AUCTIONS";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_NETWORK = "SET_NETWORK";
export const FETCH_ADDRESS = "FETCH_ADDRESS";

export const FETCH_NFTS = "FETCH_NFTS";
export const FETCH_CONTRACT_NFTS = "FETCH_CONTRACT_NFTS";
export const FETCH_NFTS_DETAILS = "FETCH_NFTS_DETAILS";
export const FETCH_NFTS_USER = "FETCH_NFTS_USER";
export const FETCH_NFTS_USERS = "FETCH_NFTS_USERS";
export const FETCH_FARMS = "FETCH_FARMS";
