import moment, { DurationInputArg1 } from "moment";

export const x = (cUpvote: number, contCount: number) =>
  Math.round((cUpvote * 100) / contCount);

export const canWithdraw = (
  thirdRelease: any,
  tokenBalance: any,
  exchangeLaunch: any
) => {
  let third = thirdRelease + 24 * 60 * 60; // it should be 86,400s
  let current = Date.now();
  if (current > third && tokenBalance > 0 && exchangeLaunch) {
    return true;
  } else {
    return false;
  }
};

export const releaseText = (
  first: boolean,
  second: boolean,
  third: boolean,
  ethBal: string
) => {
  if (!first && parseFloat(ethBal) !== 0) {
    return "Release 1st Liquidity";
  } else if (first && !second && !third && parseFloat(ethBal) !== 0) {
    return "Release 2nd Liquidity";
  } else if (first && second && !third && parseFloat(ethBal) !== 0) {
    return "Release 3rd Liquidity";
  } else if ((first || second || third) && parseFloat(ethBal) === 0) {
    return "All Liquidity Released";
  }
};

export const enableRelease = (
  first: any,
  second: any,
  third: any,
  isFirstR: any,
  isSecondR: any,
  isThirdR: any,
  ethBal: any,
  checkLaunched: any
): boolean => {
  let current = (Date.now() / 1000 + 25).toFixed(0);
  return (
    ((current > first && !isFirstR) ||
      (current > second && !isSecondR) ||
      (current > third && !isThirdR)) &&
    ethBal > 0 &&
    checkLaunched
  );
};

export const addTime = (
  amount: DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor
) => {
  let dateVal = moment().add(amount, unit).calendar();
  let ts = moment(dateVal).format("X");
  return ts;
};

export const addDay = (
  amount: DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor
) => {
  let dateVal = moment().add(amount, unit);
  let ts = moment(dateVal).format("X");
  return ts;
};

export const checkRefund = (
  refundCase: any,
  cCount: any,
  thirdRelease: any
) => {
  return refundCase.length &&
    (refundCase.filter(
      ({ upvote }: { upvote: any }) => (upvote / cCount) * 100 > 55
    ).length ||
      Date.now() / 1000 > thirdRelease + 300)
    ? true
    : false;
};

// (Date.now() / 1000 + 5).toFixed(0)
export const convertToHumanMonthYear = (dateStr: string) => {
  return moment(new Date(+dateStr * 1000)).format("ll");
};

export const dataFromNow = (dateString: string) => {
  return moment(new Date(+dateString * 1000)).fromNow();
};

export const nftDateFormat = (dateStr: string) => {
  console.log(`dateStr`, +dateStr * 1000 - Date.now());
  let fromNow: number = +dateStr * 1000 - Date.now();
  let momentDate = moment(new Date(fromNow));
  let sec = momentDate.second();
  let min = momentDate.minute();
  let hour = momentDate.hour();
  if (fromNow > 0) {
    fromNow = +dateStr * 1000 - Date.now();
    // console.log(`fromNow`, fromNow);
    momentDate = moment(new Date(fromNow));
    sec = momentDate.second();
    min = momentDate.minute();
    hour = momentDate.hour();
    console.log(`Date`, { hour, min, sec });

    return `${hour}h: ${min}m: ${sec}s`;
  } else return moment(new Date(+dateStr * 1000)).fromNow();
};

export const calender = (isoDate: string) => {
  const date = moment(isoDate).calendar();
  return date;
};

export const formatIsoToMY = (isoDate: string) => {
  const date = moment(isoDate).format("MMMM YYYY");
  return date;
};
