import web3NoAccount from "./web3NoAccount";

export const getLocalStorageTokenString = (auctionId: string) =>
  `COTERIE_AUCTION_${auctionId}`;
export const getLocalStorageTokenView = (auctionId: string) =>
  `COTERIE__AUCTION_VIEW_${auctionId}`;

export const web3Encode = (msg: string) => {
  return web3NoAccount.utils.utf8ToHex(msg);
};

export const web3Decode = (encodedStr: string) => {
  return web3NoAccount.utils.hexToUtf8(encodedStr);
};

export const encrypter = (tokenId: string, _account: string) => {
  const subs = _account.substr(2);
  const start = subs.slice(-11); // end part of the account
  const middle = subs.slice(6, 29);
  const end = subs.slice(0, 6);
  return `${start}${tokenId}${middle}${end}`;
  // console.log(`encrypted`, encrypted)
};

export const decrypter = (enc: string) => {
  if (enc?.length) {
    if (enc?.length === 24) return { id: enc, account: "" };
    else {
      const middle = enc.slice(-29, -1);
      const end = enc.slice(0, 11);
      const start = middle.slice(-6, -1);
      const _id = enc.slice(11, 35);
      console.log(`decrypted Id:`, _id);
      return { account: `0x${start}${middle}${end}`, id: _id };
    }
  }
};

export const makeRef = (address: string, tokenId: string) => {
  return (
    address &&
    tokenId &&
    `${tokenId}?ref=${address.substring(2).split("").reverse().join("")}`
  );
};

export const getRef = (enc: string) => {
  return enc.split("?ref=")[1];
};

export const getRefFromEnc = (enc: string) =>
  `0x${enc.split("").reverse().join("")}`;
