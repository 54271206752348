import styled from 'styled-components'


export const Header=styled.div`
  padding:10px;
  text-transform:uppercase;
  font-size:18px;
`

export const SetupAction = styled.div`
  margin: 30px 0px;
  padding: 10px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
`