/* eslint-disable require-yield */
import { call, put, takeLatest } from 'redux-saga/effects';
import { setAccount, setNetwork } from '../features/user/UserSlice';
import { setAuction, setAuctions, setLoading } from "./../features/auctions/AuctionSlice";
import { getAuction } from './../services/auctionService';
import { FETCH_AUCTIONS, FETCH_AUCTIONS_DETAILS } from "./types";


function* fetchAuctions() { 
    // let data:[]=yield call(useFetchAuctions) 
    yield put(setAuctions(['emmer','tola']))
}
function* setNetworks(action:any) { 
    yield put(setNetwork(action.payload))
}

function* setAccounts(action:any) { 
    yield put(setAccount(action.payload))
}

function* fetchAuction(action:any) { 
    try {
        yield put(setLoading(true))
        let data:{}=yield call(()=>getAuction(action.payload)) 
        yield put(setAuction(data))
        yield put(setAuction(data))

        
    } catch (error) {
        console.log(error,"error")
    }

}

// use them in parallel
export default function* auctionSaga() {
    yield takeLatest(FETCH_AUCTIONS, fetchAuctions)
    yield takeLatest(FETCH_AUCTIONS_DETAILS, fetchAuction)
    yield takeLatest('SET_ACCOUNT', setAccounts)
    yield takeLatest('SET_NETWORK', setNetworks)
}