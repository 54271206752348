import { IonContent } from "@ionic/react";
import { Text } from "components";
import NotifItems from "components/NavBar/NotiItems";
import { useGetNotificationLazyQuery } from "generated/graphql-frontend";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IUserReceiver } from "types/nft-user-types";
import { filterDuplicatesObj, scrollHelper } from "utils/nft-user-helper";

const Container = styled.h6`
  max-width: 540px;
  margin: auto;
  background: #fff;
  margin-top: 100px;
  .header {
    padding-top: 20px;
  }

  @media (max-width: 640px) {
    padding: 10px;
  }
`;

const Notications = ({ user, account, social }: IUserReceiver) => {
  const [notCursor, setNotCursor] = useState<string>(null);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [getNotification, { loading, data, fetchMore }] =
    useGetNotificationLazyQuery();

  const limit = 10;

  const loadMore = () => {
    return fetchMore({
      variables: {
        limit,
        cursor: notCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult.getNotifications?.length) {
          setCanLoadMore(false);
          if (prev.getNotifications?.length)
            return Object.assign({}, prev, {
              getNotifications: filterDuplicatesObj([
                ...data.getNotifications,
                ...prev.getNotifications,
              ]),
            });
          return prev;
        }

        fetchMoreResult.getNotifications.length &&
          setNotCursor(
            () =>
              fetchMoreResult.getNotifications[
                fetchMoreResult.getNotifications.length - 1
              ]?.id
          );
        const combined = Object.assign({}, prev, {
          getNotifications: filterDuplicatesObj([
            ...data.getNotifications,
            ...fetchMoreResult.getNotifications,
          ]),
        });
        if (prev?.getNotifications?.length < limit) setCanLoadMore(false);
        return combined;
      },
    });
    // }
  };

  useEffect(() => {
    if (user) {
      const { id } = user;
      getNotification({
        variables: {
          userId: id,
          limit,
          cursor: "",
        },
      });
    }
  }, [getNotification, user, data]);

  const handleScroll = () => {
    scrollHelper(loadMore, canLoadMore);
  };

  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll ?? undefined}>
      <Container>
        <Text className="header" size="24" weight="600">
          Notifications
        </Text>
        <br />

        {loading ? (
          <p>Loading..</p>
        ) : data?.getNotifications?.length < 1 ? (
          <div>Empty</div>
        ) : (
          data?.getNotifications?.map((notification: any, k: number) => (
            <NotifItems
              key={k}
              // description={notification.description}
              {...notification}
            />
          ))
        )}
      </Container>
    </IonContent>
  );
};

export default Notications;
